const getLinearGradient = (
  gradient:
    | {
        from: string;
        via?: string;
        to: string;
      }
    | undefined,
  direction = '0deg',
) => {
  if (gradient?.via) {
    return `linear-gradient(${direction}, ${gradient?.from}, ${gradient?.via}, ${gradient?.to})`;
  }
  return `linear-gradient(${direction}, ${gradient?.from}, ${gradient?.to})`;
};

export { getLinearGradient };
