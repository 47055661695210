import { Fragment } from 'react';
import type { ImageProps } from 'next/image';
import { ImageWrapper, StyledImage } from './Image.styles';

type Props = ImageProps & {
  fetchpriority?: 'auto' | 'high' | 'low';
  roundedBorder?: boolean;
  verticalAlign?: 'top' | 'middle' | 'bottom';
};

// Generic <Image /> component, preparation work for PAGES-70
const ResponsiveImage = ({
  roundedBorder,
  verticalAlign,
  objectFit,
  ...props
}: Props) => {
  const Wrapper = roundedBorder || verticalAlign ? ImageWrapper : Fragment;
  const wrapperProps =
    roundedBorder || verticalAlign ? { roundedBorder, verticalAlign } : {};

  return (
    <Wrapper {...wrapperProps}>
      <StyledImage
        {...props}
        width={props.fill ? undefined : props.width}
        height={props.fill ? undefined : props.height}
        objectFit={objectFit}
      />
    </Wrapper>
  );
};

export { ResponsiveImage as Image };
