type Whitelabel = 'DE_billiger-mietwagen' | 'FR_carigami';

// Map locales to Whitelabels
const whitelabels: Record<string, Whitelabel> = {
  'de-DE': 'DE_billiger-mietwagen',
  'fr-FR': 'FR_carigami',
};

const getWhitelabel = (locale: string = 'de-DE') => whitelabels[locale];

const DEFAULT_TIMEOUT = 4000;

export { DEFAULT_TIMEOUT, getWhitelabel };
