import React from 'react';

const FamilyTripPictogram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287 124">
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M11.468 123.637h274.574"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M45.222 120.193c3.841-5.946-.101-13.473-1.754-10.714-2.003 3.346-2.003 2.596-3.613 2.008-1.967-.717-2.148 5.403-4.43 6.559-2.282 1.157.105-4.013-2.072-5.228-2.178-1.214-3.042 4.068-4.16 3.423-1.117-.646-2.416-3.408-3.41-1.193-1.07 2.386 0 3.042-2.282 4.217-2.282 1.174.828 4.325.828 4.325l.054.055H8.521a2.29 2.29 0 0 1-2.185-2.963c.988-3.201 2.724-7.809 4.453-7.447 4.278.896 5.63-6.004 8.621-5.308 0 0 5.668.199 7.429-1.167 1.761-1.367 5.895-8.593 13.02 2.277"
    />
    <path
      fill="#1D1D1B"
      d="M26.781 106.818c-.268-1.128-.857-2.317-1.848-3.485-3.602-4.25-4.025 3.825-5.085 2.339-1.06-1.487 2.543-4.036-1.06-8.499-3.605-4.463-3.815 5.736-4.665 4.249-.846-1.486.636-10.837-2.12-10.199-2.755.638-1.909 13.176-3.392 11.262-1.483-1.915.214-12.538-2.756-11.262-2.238.965-1.341 9.297-.766 12.607-.995-2.23-4.56-5.747-4.959-2.618-1.041 8.263 4.253 14.307 7.277 17.027.448-1.237.973-2.47 1.533-3.427a134.57 134.57 0 0 1-1.11-1.668c-1.07-1.907-2.177-3.883-1.855-20.014a.326.326 0 0 1 .329-.319.325.325 0 0 1 .314.33c-.321 15.957.789 17.937 1.773 19.688.057.105.495.649 1.009 1.283.55-.746 1.124-1.178 1.703-1.058 2.781.58 4.33-2.132 5.895-3.909.192-.979.333-2.092.42-3.346.25-3.684-.058-7.234-.062-7.27a.322.322 0 0 1 .64-.058c.03.355.467 5.493-.217 9.884.597-.501 1.223-.776 1.95-.609 0 0 1.41.05 3.045-.073.597-1.247.35-2.36.347-2.371a.325.325 0 0 1 .239-.388.32.32 0 0 1 .387.239c.014.058.26 1.153-.25 2.455 1.226-.127 2.477-.363 3.284-.794v.004Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M206.504 83.12c3.335-1.276 4.46-1.755 4.217-3.23-.567-3.467-8.69-1.02-8.983-4.721-.362-4.587 6.571-7.8 6.571-7.8s7.628-5.344 1.107-4.47c-6.517.874 1.461-2.636 7.602-6.392 5.179-3.165 3.693-.362 12.676 9.732 2.622 2.947-6.56 1.102-3.62 4.358 2.843 3.15 11.461 4.691 12.065 11.664.604 6.972 5.298 6.247 6.727 4.017 2-3.122 3.045-5.054 4.94-5.928 4.315-1.99 7.718.602 7.718.602m-2.56-38.716c1.84.689 5.584 2.346 8.477 2.578 2.893.232 4.904-4.974-.882-10.511-5.787-5.536-11.071-14.329-14.923-15.8-2.376-.91-7.508 5.619-10.321 10.343-1.845 3.093-1.903 3.082-5.754 4.47-3.852 1.386-1.302 4.475 1.634 4.99m27.801 8.999s5.689 8.459 9.725 11.055c3.812 2.45-1.845 7.132-10.897 4.264-5.269-1.668-7.801-6.505-12.184-3.923m18.871 9.506c.59 6.349 15.787 7.067 15.819 17.219.022 6.584-13.938 4.492-8.011 9.02 8.489 6.483 15.19 10.729 11.071 14.605-6.875 6.465-18.286-7.298-21.805 5.207-2.651 9.423-17.066-8.539-17.066-14.449M231.025 41.95c-5.783 5.05-7.175 4.195-7.66 6.41-.593 2.708 1.284 3.539 4.217 3.539 4.778 0 2.89 4.115 6.499 4.38 6.105.45 7.787-8.322 11.465-7.158"
    />
    <path
      fill="#1D1D1B"
      d="M114.321 68.414s.408.634.615 1.657l2.51-.167c.332-.67-.217-.696.484-.076l.662-.026c.669-.504.709-1.185.571-1.591-.408-1.186-1.602-2.009-2.77-2.092-1.168-.084-2.47-.301-3.924 1.145-.68.675-2.296 2.346-1.881 4.942.416 2.596 1.324 2.372 1.519 2.372.673 0 1.147.021 1.49.054.431.043.919-.167.67-1.255-.25-1.087-2.207-2.675-1.169-3.14.503-.224.503.95 1.046-.21.126-.272.188-1.613.188-1.613h-.011Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M118.802 71.58s.55 2.03-1.063 2.534c-.876.271-1.252.21-1.252.438m.477-3.56s.586 1.222.105 1.443c-.347.16-.781-.105-.781-.105"
    />
    <path
      fill="#1D1D1B"
      d="m108.621 74.244-13.334-.127c-.214-.268-1.161-.953-1.559-2.088.951.801 1.873 1.127 2.477 1.011 1.599-.308.973-3.745.112-4.467 0 0-.473-.721-.918-1.381.177.018.394.087.694.138 1.223.221 4.677 3.223 5.262 4.702.695 1.766 2.615 1.48 2.615 1.48s4.109-.28 4.651.728v.004Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M108.411 69.875s-.054 1.08-.969 1.791c-1.526 1.19-2.228.156-2.98.761-.752.606-.702 1.57-.702 1.57"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M105.659 69.726s1.117.602 1.291-.174l.173-.776"
    />
    <path
      fill="#1D1D1B"
      d="M103.095 61.597c-.98 1.607-.318 3.985-.166 4.475-.12.05-.235.112-.351.174-.167.083-.351.177-.46.203-.737.072-1.218.054-1.218.054s-.955-3.539 2.195-4.906Zm7.316 5.015a3.69 3.69 0 0 0-.658-.345.483.483 0 0 0-.058-.018s-.011-.007-.014 0a1.542 1.542 0 0 0-.662-.018c-.033 0-.062.011-.09.015a3.16 3.16 0 0 0-.818.279c-.025.01-.047.029-.076.043-.568.287-1.772 1.226-1.772 1.226-1.175.855-1.783.315-2.477-.134-.311-.203-.695-.595-.988-.925.008-.007.018-.007.026-.014.17-.088.343-.178.473-.218 1.071-.297 2.033-.7 2.861-1.204 1.031-.627 2.138-.188 2.289-.145l.409.102c.029.01.04.018.04.018s.647.446 1.161.943c.134.134.26.268.362.398l-.008-.003Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeMiterlimit="10"
      d="M109.844 67.141a3.695 3.695 0 0 1-.673-.598c-.181-.196-.293-.37-.293-.37.271-.043.528-.036.734.04.275.105.695.392.868.682.159.268-.079.623-.636.243v.003Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M103.319 60.342c.585-.14 1.011-.456.951-.706-.059-.249-.582-.337-1.166-.197-.585.14-1.011.456-.951.705.059.25.581.338 1.166.198Zm2.199-.572c.567-.197.957-.555.872-.799-.084-.244-.611-.282-1.177-.085-.567.197-.957.554-.873.798.085.244.612.283 1.178.086Z"
    />
    <path
      fill="#1D1D1B"
      d="M108.968 64.792c-.068-.037-1.866-.7-3.092.047a10.6 10.6 0 0 1-2.289 1.019c-.145-.483-.919-3.322.647-4.496l-.014-.127-.073-.645-.141-1.215.593-.073.217 1.846c3.049-.308 3.975 2.49 4.152 3.64v.004Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinejoin="round"
      d="M97.164 57.903c-.413.083.08-.986 1.067-2.06.987-1.07 1.15-2.103.741-2.059-.35.036-1.664 1.574-2.087 2.175.326-.609 1.136-2.244 1.262-2.752.167-.66-.499-.554-.947.124a44.254 44.254 0 0 0-1.27 2.07c.16-.479.684-2.4.612-2.687-.08-.33-.575-.58-.904.37-.326.95-.659 2.187-.659 2.187s-.083-1.487-.162-1.98c-.084-.493-.659-.577-.742.58-.018.25-.04.522-.065.809-.098 1.022-.26 2.171-.55 2.817-.224.493-1.294 3.394-2.007 6.011-.354 1.31-.618 2.545-.647 3.38-.011.311.014.565.076.746.72 2.07 1.797 3.525 2.842 4.406.951.8 1.874 1.127 2.478 1.011 1.598-.308.972-3.745.112-4.467 0 0-.474-.721-.919-1.381-.253-.38-.499-.743-.64-.936a.913.913 0 0 1-.137-.322c-.16-.613-.037-1.7.217-2.81.372-1.654 1.016-3.347 1.42-3.575.742-.41 2.634-1.276 2.797-2.103.166-.823-1.48.373-1.888.453v-.007Zm-1.277-2.397c-.032.036-.014 0 .043-.098a.52.52 0 0 1-.043.098Zm.86.667a.832.832 0 0 1 .131-.206c-.094.174-.145.26-.13.206Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M155.568 70.586s-.228.674-.152 1.682c.029.377-1.331 3.42-2.763 3.807-.778.21-2.311-.62-2.662 2.694m4.037-8.379s-.337 1.095-.47 1.603c-.134.507-.995.007-.995.007"
    />
    <path
      fill="#1D1D1B"
      d="M149.127 70.644a.444.444 0 0 0 .655-.026c.101-.12.22-.264.358-.435.444-.558-.876-2.386 1.92-2.262 1.244.054-5.067-1.215-5.067-1.215s-.806 1.436-1.031 3.223c-.224 1.788-.061 3.626.586 3.699 2.087.235.467-2.94 1.422-3.322.484-.192.897.077 1.157.338Zm1.92 2.28s-.785.015-.788.555c-.004.54 1.168-.283 1.616.29.727.921.604.548 1.31.551 1.088.011 2.094 1.313 2.115.548.022-.816-2.578-2.419-3.417-2.18l-.836.24v-.004Zm-14.307 2.759c-.832-.333-1.805.019-2.163.798-.752 1.635-1.468 4.434.926 6.624 4.271 3.912 9.251 6.799 18.061 4.717 6.163-1.454 5.523-7.153 8.282-6.435 2.756.718-4.043-2.694-8.307-2.274-4.267.421-12.799-1.82-12.799-1.82l-3.996-1.606-.004-.004Zm-12.976-13.959c2.893 2.19 7.313-.377 9.526.918 1.696.99 2.083 2.94 2.083 2.94s2.503-1.436 2.062-4.449c-.713-4.847-4.46-5.268-8.333-6.07-3.877-.8-2.137-5.56-2.137-5.56s-12.89 4.89-3.201 12.221Z"
    />
    <path
      fill="#1D1D1B"
      d="M134.107 66.159s.836-2.56 3.4-2.716c2.839-.17 3.515.167 4.933 3.278.622 1.363-.727-.508-2.684-.044-1.96.46-2.459.943-2.578 2.277-.167 1.842-1.082.084-1.545.91-.322.577.141 1.556.543 2.364.405.809-3.657-1.381-2.065-6.073l-.004.004Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m137.706 69.509-1.837-.221m5.074-.283s.445 1.577.531 1.802c.254.67-.806.602-.947.57"
    />
    <path
      fill="#1D1D1B"
      d="M139.11 70.042c.837-.162 1.455-.608 1.381-.998-.075-.389-.815-.573-1.652-.412-.838.162-1.456.608-1.381.998.074.39.814.574 1.652.412Zm3.357-.59c.838-.162 1.456-.608 1.381-.998-.074-.389-.814-.573-1.651-.412-.838.162-1.456.609-1.381.998.074.39.814.574 1.651.412Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M143.033 71.06s-.249 3.184-2.256 2.93c-1.328-.166-.753 1.592-.669 2.763"
    />
    <path
      fill="#1D1D1B"
      stroke="#1D1D1B"
      strokeLinejoin="round"
      d="M72.014 68.998h7.758L69.446 79.07H60.69l11.324-10.072Z"
    />
    <path
      fill="#1D1D1B"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M233.822 103.312v4.59c0 1.802-.879 3.455-2.203 4.72h-11.457v-9.31h13.663-.003Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M57.873 91.782V81.575m173.525 13.26-.011-.011c-.426-.522-3.088-3.43-11.475-6.168-17.758-5.805-33.02-7.084-33.02-7.084M71.457 94.21l-8.365.11c-1.92 0-3.714.58-5.215 1.573a9.542 9.542 0 0 0-4.293 7.959v3.053c0 .638.253 1.247.702 1.7l5.8 5.819a2.4 2.4 0 0 0 1.697.704h6.868a15.977 15.977 0 0 1-.586-4.289c0-.57.032-1.135.094-1.694.839-8.013 7.595-14.249 15.812-14.249 8.217 0 14.965 6.24 15.812 14.249.058.559.09 1.124.09 1.694 0 1.486-.203 2.922-.586 4.289M165.45 93l-32.644.42-31.522.486m98.549-1.533-18.192.42"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M231.615 112.622c-1.605 1.534-3.873 2.502-6.054 2.502h-11.638a15.95 15.95 0 0 0 .492-5.979c-.846-8.013-7.599-14.249-15.816-14.249s-14.969 6.24-15.811 14.249a15.862 15.862 0 0 0 .491 5.983H99.276m126.155-22.904h.13c2.072 0 4.235 1.04 5.826 2.6a.03.03 0 0 0 .011.014c1.45 1.432 2.423 3.3 2.423 5.2v3.274M102.249 62.439c-2.332.2-5.28.46-7.417.685m77.785 14.88c2.207.689 4.724 2.02 4.836 3.571h9.443s-21.132-15.63-43.374-18.756c-5.197-.725-10.666-1.109-16.166-1.236-6.626-.16-14.405.13-20.662.526m-15.237 1.392c-8.694 1.026-15.005 2.125-16.744 2.44-4.575.834-2.076 2.498-2.076 2.498l-.623.555h7.758L69.447 79.066H60.69l-2.818 2.502h33.39l22.426-.072M65.681 91.782h5.772c0-4.195-3.396-7.6-7.58-7.6h-6.004"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M172.618 78.004c-1.024-.316-1.979-.493-2.593-.493-1.982 0-3.552 1.03-3.664 3.34a5.404 5.404 0 0 0 0 .648h-53.049s-27.754-.192-28.893-7.614c-.387-2.527 1.129-4.354 4.644-6.272.47-.258 1.056-.5 1.754-.733m17.996-2.414c.333-.019.673-.033 1.009-.048.651-.029 1.309-.05 1.967-.069.069 0 .138 0 .214-.007.958-.029 1.931-.047 2.908-.058h.094a182.69 182.69 0 0 1 10.383.174c.391.018.77.04 1.157.058m32.864 5.87c-7.949-2.875-17.927-4.695-28.224-5.558-.057-.007-.115-.01-.177-.018a603.56 603.56 0 0 0-2.991-.214l-1.472-.083m-25.783.543c-2.282.24-4.358.537-6.144.878m77.287 46.551h10.835"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M91.985 79.139s-2.93 5.837 1.783 12.367c4.712 6.53 11.609 20.979 20.235 20.979h30.17m75.916-15.442c-2.076-.874-4.484-2.836-5.768-4.67-.554-.802-.894-1.574-.897-2.223a.45.45 0 0 1 .546-.453l.875.181 10.423 2.284c.051.022.105.044.163.062 1.183.475 2.698 1.338 3.584 2.846 3.11 5.276 2.962 6.951-8.926 1.973Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m214.848 89.878-37.392-8.201s3.642 4.434 4.185 11.113a20.535 20.535 0 0 1-.981 8.161 6065.422 6065.422 0 0 0-2.687 8.191l-.918 2.788m-45.678-30.427c.004.025.004.047.014.072.084.508.167 1.005.257 1.498.554 3.335 1.335 12.28 1.393 15.87"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M177.457 81.68c0 2.317-6.908 2.499-8.89 2.499-1.7 0-2.166-1.186-2.213-2.604v-.072c-.011-.21-.004-.428.003-.65.109-2.312 1.678-3.342 3.664-3.342.615 0 1.57.178 2.593.493 2.206.689 4.727 2.02 4.835 3.571a.925.925 0 0 1 .004.105h.004ZM90.81 73.889l36.77.939m7.208 12.926h10.531a1.367 1.367 0 0 1-1.298 1.7h-3.968m-44.057-1.7h10.532a1.368 1.368 0 0 1-1.299 1.7h-3.967"
    />
    <path
      fill="#1D1D1B"
      d="M131.005 64.814c-.854-.07-1.722-.127-2.59-.185 1.024 4.815 2.416 11.74 3.32 16.885l.011.058h1.848c-.014-.026-.022-.047-.033-.073-.73-1.878-.231-3.778.098-4.677a2.45 2.45 0 0 1 .803-1.095l-3.457-10.917v.004Zm-3.309-.232c-.764-.044-1.534-.087-2.308-.127l1.425 17.04v.073h4.224c-.911-5.196-2.322-12.193-3.345-16.99l.004.004Zm-30.933 44.563c-.825-6.356-6.243-11.257-12.8-11.257-6.556 0-11.985 4.901-12.81 11.257a12.503 12.503 0 0 0-.115 1.694c0 1.497.256 2.944.727 4.289 1.764 5.043 6.556 8.665 12.195 8.665 5.638 0 10.427-3.618 12.188-8.665a12.97 12.97 0 0 0 .607-5.983h.008Zm-6.64 5.983a7.484 7.484 0 0 1-6.16 3.23 7.515 7.515 0 0 1-7.312-9.213c.766-3.343 3.75-5.833 7.312-5.833 3.563 0 6.54 2.49 7.306 5.833.126.544.192 1.113.192 1.694a7.512 7.512 0 0 1-1.338 4.289Zm121.286-5.983c-.825-6.356-6.242-11.257-12.807-11.257-6.564 0-11.982 4.901-12.806 11.257-.08.555-.116 1.121-.116 1.694 0 1.497.253 2.944.723 4.289 1.769 5.043 6.561 8.665 12.196 8.665 5.634 0 10.426-3.618 12.195-8.665a12.975 12.975 0 0 0 .608-5.983h.007Zm-6.64 5.983a7.503 7.503 0 0 1-6.167 3.23 7.509 7.509 0 0 1-7.504-7.519c0-.584.065-1.15.192-1.694.77-3.343 3.754-5.833 7.312-5.833 3.559 0 6.55 2.49 7.313 5.833.127.544.192 1.113.192 1.694a7.517 7.517 0 0 1-1.338 4.289Z"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M198.603 116.118c2.908 0 5.265-2.364 5.265-5.279a5.272 5.272 0 0 0-5.265-5.279 5.272 5.272 0 0 0-5.266 5.279 5.272 5.272 0 0 0 5.266 5.279Zm-114.64 0a5.273 5.273 0 0 0 5.266-5.279 5.272 5.272 0 0 0-5.266-5.279 5.272 5.272 0 0 0-5.266 5.279 5.273 5.273 0 0 0 5.266 5.279ZM64.097 3.698c-2.38-3.84-18.918-4.713-19.685 8.862-4.626-1.4-7.823 2.04-8.072 4.626a12.195 12.195 0 0 0-8.951 11.762c0 6.736 5.446 12.197 12.166 12.197a12.06 12.06 0 0 0 3.089-.399c-.008.13-.015.265-.015.399 0 4.985 6.18 10.402 13.154 10.402 6.972 0 11.873-5.061 12.043-9.76"
    />
    <path
      fill="none"
      stroke="#1D1D1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M135.319 18.129c1.519-6.81 7.58-11.9 14.828-11.9 6.59 0 12.195 4.202 14.304 10.08 14.282 2.124 11.175 17.87 4.419 20.546 1.501 4.032-3.396 8.162-8.217 5.976-2.524 5.598-26.332 7.472-26.242-11.523.149-8.945-3.276-14.376-14.56-14.361.014-.182-3.827-13.785-17.516-8.34-2.882-4.9-8.712-8.252-15.428-8.252-7.515 0-13.917 4.192-16.325 10.054-6.9 3.731-11.523 10.58-11.523 18.416v.16c.062 7.642 6.347 13.773 13.971 13.773h14.8"
    />
  </svg>
);

export { FamilyTripPictogram };
