import { Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { ContentfulRichText as ContentfulRichTextContent } from '@silvertours/common-landingpages-view';

import { options } from './options';
import { ContentfulRichTextWrapper } from './ContentfulRichText.styles';

import { HasTextStyleRules } from '../rules';

type ContentfulRichTextRules = HasTextStyleRules;

type ContentfulRichTextProps = {
  content: ContentfulRichTextContent;
  rules?: ContentfulRichTextRules;
};

export const ContentfulRichText = ({
  content,
  rules,
}: ContentfulRichTextProps) => {
  const document = content.text as unknown as Document;
  return (
    <ContentfulRichTextWrapper textStyle={rules?.textStyle}>
      {documentToReactComponents(document, options)}
    </ContentfulRichTextWrapper>
  );
};
