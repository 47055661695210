import type { HTMLAttributes } from 'react';
import { Wrapper, Button } from './SegmentedControl.styles';

type Segment = {
  label: string;
  isSelected: boolean;
} & HTMLAttributes<HTMLButtonElement>;

type Props = {
  segments: Segment[];
};

export const SegmentedControl = ({ segments }: Props) => (
  <Wrapper>
    {segments.map(({ label, isSelected, ...rest }) => (
      <Button key={label} $isSelected={isSelected} {...rest}>
        {label}
      </Button>
    ))}
  </Wrapper>
);
