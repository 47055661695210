import { FormEvent, useRef } from 'react';
import { useTranslations } from 'next-intl';
import { Button, Heading2, Ui } from '@silvertours/front-shared';
import { HostLegacy } from '@silvertours/front-legacy-entities';

import {
  Container,
  Form,
  Headline,
  Image,
  LegalText,
} from './NewsletterRegistration.styles';

type NewsletterSignupProps = {
  showImage?: boolean;
  showTitle?: boolean;
  showHeadline?: boolean;
  onSubmit: (email: string) => void;
};

const NewsletterSignup = ({
  showImage,
  showTitle,
  showHeadline,
  onSubmit,
}: NewsletterSignupProps) => {
  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );
  const { formatImage } = HostLegacy.useHost();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (inputRef.current) {
      onSubmit(inputRef.current.value);
    }
  };

  return (
    <>
      {showTitle && <Heading2>{t('title')}</Heading2>}
      <Container>
        {showImage && (
          <Image
            src={formatImage(t('imageSource'))}
            alt=""
            width="300"
            height="300"
            roundedBorder
          />
        )}
        <Form method="post" id="newsletter-form" onSubmit={handleSubmit}>
          {showHeadline && <Headline>{t('headline')}</Headline>}
          <Ui.Input
            ref={inputRef}
            type="email"
            id="newsletter-email"
            name="email"
            label={t('label')}
            autoComplete="email"
            autoCorrect="off"
            autoCapitalize="none"
            required
          />
          <LegalText dangerouslySetInnerHTML={{ __html: t.raw('legalText') }} />
          <Button type="submit" fullWidth>
            {t('buttonText')}
          </Button>
        </Form>
      </Container>
    </>
  );
};

export { NewsletterSignup };
