import styled from '@emotion/styled';
import StarFull from '@engineering/icons/star-full';
import { BodyText, Button, Heading3, Ui } from '@silvertours/front-shared';
import { ThirdPartyLogo } from '../ThirdPartyLogo';

const Container = styled(Ui.Card)<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-shrink: 0;
  flex-direction: column;
  justify-items: stretch;
  padding: ${({ theme }) => theme.spacing[50]};

  ${({ theme }) => theme.mq('sm')} {
    max-width: 37.75rem;
  }
`;

const Header = styled.header`
  display: grid;
  grid-template-areas: 'provider provider' 'logo aggregated-rating' 'logo ratings-count';
  grid-template-columns: 1fr max-content;
  grid-gap: ${({ theme }) => theme.spacing[10]};
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[50]};

  ${({ theme }) => theme.mq('lg')} {
    grid-template-areas: 'provider aggregated-rating' 'logo ratings-count';
  }
`;

const Provider = styled(Heading3)`
  grid-area: provider;
  margin: 0 0 ${({ theme }) => theme.spacing[10]};
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.125rem;
  line-height: 1.2;

  ${({ theme }) => theme.mq('lg')} {
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.2;
  }
`;

const StyledThirdPartyLogo = styled(ThirdPartyLogo)`
  grid-area: logo;
  align-self: start;
  height: 2rem;

  ${({ theme }) => theme.mq('sm')} {
    height: 2.5rem;
  }
`;

const StarReview = styled.data`
  grid-area: aggregated-rating;
  display: flex;
  align-items: center;
  justify-content: end;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.125rem;
  line-height: 1.2;

  ${({ theme }) => theme.mq('sm')} {
    font-size: 1.5rem;
  }
`;

const StyledStar = styled(StarFull)`
  margin-right: ${({ theme }) => theme.spacing[20]};
  width: 1.5rem;
  height: 1.5rem;
  fill: ${({ theme }) => theme.color.brand};
`;

const CurrentValue = styled.span`
  color: ${({ theme }) => theme.color.brand};
`;

const MaxValue = styled.span`
  color: ${({ theme }) => theme.color.grey02};
`;

const RatingsCount = styled(BodyText)`
  grid-area: ratings-count;
  align-self: start;
  color: ${({ theme }) => theme.color.grey02};
  font-size: 0.875rem;
  line-height: 1.5;
  height: 1.5rem;
  text-align: end;

  ${({ theme }) => theme.mq('lg')} {
    font-size: 0.875rem;
    line-height: 1.5;
    height: 2.5rem;
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  padding-top: ${({ theme }) => theme.spacing[20]};
  gap: ${({ theme }) => theme.spacing[20]};
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  line-height: 1.5;
`;

const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing[20]} 0;
  width: auto;
`;

const ReviewsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr auto;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[20]};
`;

export {
  Container,
  Header,
  Provider,
  StyledThirdPartyLogo,
  StarReview,
  ReviewsWrapper,
  RatingsCount,
  Footer,
  StyledButton,
  CurrentValue,
  MaxValue,
  StyledStar,
};
