import { WaveSvg } from '../Wave.styles';
import { svgProps } from './defaults';
import type { WaveSet } from './types';

const fixed3 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 17">
        <path d="M375 0H0v14.197a108.7 108.7 0 0 1 6.66-2.078c30.232-8.38 72.47-5.322 127.758-1.319 62.83 4.55 142.514 10.319 240.582 1.908V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 36">
        <path d="M1440 0H0v30.065a683.03 683.03 0 0 1 25.573-4.402c116.092-17.745 278.287-11.27 490.592-2.792C757.434 32.504 1063.42 44.72 1440 26.91V0Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 87">
        <path d="M0 9.941V87h375V76.681C349.07 66.14 112.99-27.65 6.66 8.072A66.575 66.575 0 0 1 0 9.941Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 133">
        <path d="M0 15.197V133h1440v-15.775C1340.43 101.109 433.883-42.27 25.573 12.341A583.752 583.752 0 0 1 0 15.197Z" />
      </WaveSvg>
    ),
  },
  height: {
    small: 680,
    large: 520,
  },
} as WaveSet;

export { fixed3 };
