import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Image from 'next/image';

const ImageWrapper = styled.div<{
  roundedBorder?: boolean;
  verticalAlign?: 'top' | 'middle' | 'bottom';
}>`
  ${({ roundedBorder }) =>
    roundedBorder &&
    css`
      img {
        border-radius: 0.75rem;
      }
    `};
  ${({ verticalAlign }) => {
    if (!verticalAlign) return '';

    switch (verticalAlign) {
      case 'middle':
        return css`
          height: 100%;
          display: flex;
          align-items: center;
        `;
      case 'bottom':
        return css`
          height: 100%;
          display: flex;
          align-items: flex-end;
        `;
      default:
        return css`
          height: 100%;
          display: flex;
          align-items: flex-start;
        `;
    }
  }}
`;

const StyledImage = styled(Image)<{ objectFit?: string }>`
  object-fit: ${({ objectFit }) => objectFit};
`;

export { ImageWrapper, StyledImage };
