import Search from '@engineering/icons/search';
import { useTranslations } from 'next-intl';
import { analytics } from '@silvertours/front-entities';
import {
  FormContainer,
  Headline,
  HighlightedText,
  SearchButton,
} from './Form.styles';
import { useSearchContext } from '../../SearchFormLegacy/context';

type Props = {
  searchAction?: () => void;
};

export const SearchForm = ({ searchAction }: Props) => {
  const t = useTranslations('features.stage');
  const { state } = useSearchContext();

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    analytics.gtm.trackBeginSearchClicked();
    if (searchAction) {
      searchAction();
    }
  };

  return (
    <FormContainer id="search-wizard" role="search" as="form">
      <Headline>{t('search.headline')}</Headline>
      <SearchButton
        aria-label={t('search.input.placeholder')}
        aria-haspopup="true"
        onClick={handleSubmit}
      >
        <span>
          {state?.inputValue.departure || t('search.input.placeholder')}
        </span>
        <HighlightedText>
          <Search height="24" />
        </HighlightedText>
      </SearchButton>
    </FormContainer>
  );
};
