import React from 'react';

import {
  HostedLayout,
  CustomPageContent,
} from '@silvertours/common-landingpages-view';
import { BodyLight } from './body';

const CustomContent = ({ ...commonProps }: HostedLayout<CustomPageContent>) => (
  <BodyLight {...commonProps} />
);

export { CustomContent };
