import styled from '@emotion/styled';
import { mq } from '../../Theme';
import { StyledTextProps, mapColor } from '../textUtils';

const TertiaryTextWrapper = styled.p<StyledTextProps>`
  font-size: 0.813rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.538em;

  ${mq('lg')} {
    font-size: 0.938rem;
    line-height: 1.467em;
  }

  color: ${({ textColor, theme }) => mapColor(theme, textColor)};
`;

export { TertiaryTextWrapper };
