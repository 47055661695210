import styled from '@emotion/styled';

const ButtonContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;

  a {
    width: 46%;
  }
`;

export { ButtonContainer };
