import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';

const loading = keyframes`
    0% {
        left: 50%;
        width: 0;
        z-index: 100;
    }

    33.3333% {
        left: 0;
        width: 100%;
        z-index: 10;
    }

    100% {
        left: 0;
        width: 100%;
        z-index: 1;
    }`;

const LoadingIndicator = styled.div`
  text-align: center;
  position: absolute;
  display: inline;
  width: 0;
  top: 0;
  height: 2px;
  left: 50%;
  background-color: ${({ theme }) => theme.color.brand};
  animation-name: ${loading};
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-delay: 0.1s;

  &:first-of-type,
  &:last-child {
    background-color: ${({ theme }) => theme.color.secondary};
  }

  &.loading-indicator-top {
    top: 0;
  }

  &.loading-indicator-bottom {
    bottom: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => shade(0.271, theme.color.brand!)};
`;

export { LoadingIndicator, Wrapper };
