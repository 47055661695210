import styled from '@emotion/styled';
import { HasColumns } from '../../rule';
import { formatColumns } from '../../particle';

const Container = styled.div<{ rules: HasColumns }>`
  ${({ theme, rules }) =>
    rules.columns?.map(columns => formatColumns(columns, theme))};

  display: flex;
  columns: 12;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[70]};
  }

  ${({ theme }) => theme.mq('sm')} {
    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[0]};
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
`;

export { ColumnContainer, Container, TextContainer };
