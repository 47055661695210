import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { mq } from '../../Theme';
import { StyledTextProps, TextColor, mapColor } from '../textUtils';

type BodyMixinProps = {
  textColor?: TextColor;
  theme: Theme;
};

const body = ({ textColor, theme }: BodyMixinProps) => css`
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 1.412em;

  ${mq('lg')} {
    font-size: 1.125rem;
    line-height: 1.333em;
  }

  color: ${mapColor(theme, textColor)};
`;

const BodyTextWrapper = styled.p<StyledTextProps>`
  ${body}
`;

const InlineBodyTextWrapper = styled.span<StyledTextProps>`
  ${body}
`;

export { body, BodyTextWrapper, InlineBodyTextWrapper };
export type { BodyMixinProps };
