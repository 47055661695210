import styled from '@emotion/styled';
import { lighten } from 'polished';

const itemHeight = '3em'; // "em" is used to make the item’s height dependent on the applied font size
const visibleItems = 5; // must be an odd number

export const Wrapper = styled.div`
  display: grid;
  grid-template: calc(${visibleItems} * ${itemHeight}) / 1fr auto 1fr;
  align-items: center;
  position: relative;
  z-index: 1;
  min-width: 290px;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.5;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      ${({ theme }) => theme.color.white},
      #fff0 calc(${itemHeight} * 1.5),
      #fff0,
      #fff0 calc(100% - ${itemHeight} * 1.5),
      ${({ theme }) => theme.color.white}
    );
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: ${({ theme }) => theme.spacing[40]};
    right: ${({ theme }) => theme.spacing[40]};
    z-index: -1;
    transform: translateY(-50%);
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    height: ${itemHeight};
    background-color: ${({ theme }) =>
      lighten(0.285, theme.color.primaryHighlight!)};
  }
`;

const TimeSelectorList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-block: calc(${(visibleItems - 1) / 2} * ${itemHeight});
  height: 100%;
  overflow: hidden scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:focus-visible {
    outline: 0;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      z-index: 1;
      border-radius: ${({ theme }) => theme.borderRadius.xs};
      border: 0.125rem dashed ${({ theme }) => theme.color.secondary};
      width: 50%;
      height: 100%;
      transform: translateY(-50%);
      box-sizing: border-box;
      pointer-events: none;
    }
  }
`;

export const Hours = styled(TimeSelectorList)`
  text-align: end;

  &:focus-visible {
    &::before {
      left: 0;
    }
  }
`;

export const Minutes = styled(TimeSelectorList)`
  text-align: start;

  &:focus-visible {
    &::before {
      right: 0;
    }
  }
`;

export const Item = styled.li`
  display: block;
  padding: ${({ theme }) => `${theme.spacing[30]} ${theme.spacing[50]}`};
  scroll-snap-align: center;
  transition: transform 0.2s;
  cursor: default;

  &[aria-selected] {
    font-weight: 600;
  }

  @supports (selector(:has(*))) {
    &:has(~ [aria-selected]) {
      z-index: 0;
      transform: rotate3d(1, 0, 0, -45deg);
    }

    &:has(+ [aria-selected]) {
      z-index: 0;
      transform: rotate3d(1, 0, 0, -30deg);
    }

    &[aria-selected] ~ & {
      z-index: 0;
      transform: rotate3d(1, 0, 0, 45deg);
    }

    &[aria-selected] + & {
      z-index: 0;
      transform: rotate3d(1, 0, 0, 30deg);
    }
  }
`;

export const Separator = styled.span`
  font-weight: 600;
`;
