import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Theme, mq } from '../../Theme';
import { mapColor } from '../textUtils';
import type { TextColor } from '../textUtils';

type SubtitleMixinProps = {
  textColor?: TextColor;
  theme: Theme;
};

const subtitle = ({ textColor, theme }: SubtitleMixinProps) => css`
  font-weight: 600;
  color: ${mapColor(theme, textColor)};
  font-size: 1.313rem;
  line-height: 1.238em;

  ${mq('lg')} {
    font-size: ${theme.fontSize.md.size};
    font-weight: ${theme.fontWeight.heading};
    line-height: 1.3rem;
  }
`;

type SubtitleProps = {
  textColor?: TextColor;
};

const SubtitleWrapper = styled.div<SubtitleProps>`
  ${subtitle}
`;

export { subtitle, SubtitleWrapper };
