import { StationsMapContentModule } from '@silvertours/common-landingpages-view';
import {
  GoogleMap,
  PlainContentModuleContainer,
  TextContentBlock,
  useContentModule,
} from '@silvertours/front-entities';
import { Ui } from '@silvertours/front-shared';
import { Spacer } from './StationsMap.styles';

export const StationsMap = ({ ...content }: StationsMapContentModule) => {
  const { getStationsMap } = useContentModule();
  const { stations, boundingBox, translations } = getStationsMap();

  return stations && stations.length > 0 ? (
    <PlainContentModuleContainer>
      <TextContentBlock
        content={content}
        rules={{
          textStyle: { style: Ui.RichTextStyle.Primary },
          isContainerMultiColumn: false,
          badge: { style: 'orange' },
        }}
      />
      <Spacer />
      <GoogleMap
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''}
        {...(boundingBox ? { bbox: boundingBox } : {})}
        stations={stations}
        translations={translations}
      />
    </PlainContentModuleContainer>
  ) : null;
};
