import styled from '@emotion/styled';
import { css } from '@emotion/react';

const imagePath = '/bm/global/graphic/image/widget';
const sizes = [
  {
    width: 250,
    height: 250,
    media: '(max-width: 250px) and (max-height: 250px)',
  },
  {
    width: 300,
    height: 250,
    media: '(min-width: 251px) and (max-width: 300px) and (max-height: 250px)',
  },
  {
    width: 300,
    height: 400,
    media:
      '(min-width: 251px) and (min-height: 251px) and (max-width: 300px) and (max-height: 400px)',
  },
  {
    width: 320,
    height: 480,
    media: '(min-width: 301px) and (max-width: 320px) and (max-height: 480px)',
  },
  {
    width: 300,
    height: 600,
    media:
      '(min-width: 251px) and (min-height: 401px) and (max-width: 300px) and (max-height: 600px)',
  },
  {
    width: 800,
    height: 250,
    media: '(min-width: 321px) and (max-width: 800px)',
  },
  {
    width: 970,
    height: 250,
    media: '(min-width: 801px) and (max-width: 970px)',
  },
];

const Wrapper = styled.div<{ baseImageUrl: string }>`
  position: relative;
  z-index: 1;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ baseImageUrl }) =>
    sizes.map(
      ({ width, height, media }) => css`
        /* stylelint-disable-next-line media-query-no-invalid */
        @media ${media} {
          background-image: url('${baseImageUrl}${imagePath}/bg-${width}-${height}.jpg');

          @media (min-resolution: 2dppx) {
            background-image: url('${baseImageUrl}${imagePath}/bg-${width}-${height}@2x.jpg');
          }
        }
      `,
    )}

  @media (min-width: 780px) {
    flex-direction: row-reverse;
  }
`;

const LogoWrapper = styled.div`
  position: relative;
`;

const Logo = styled.span`
  display: inline-flex;
  margin: 0.3125rem 0.625rem;

  @media (min-height: 400px) or (min-width: 800px) {
    position: absolute;
    left: 0;
    bottom: 1rem;
    margin: 0;
    padding: 0.25rem 0.625rem;
    background: #fffb;
  }

  img {
    width: auto;
    height: 25px;
    vertical-align: bottom;
  }
`;

export { Wrapper, LogoWrapper, Logo };
