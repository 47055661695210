import { useTranslations } from 'next-intl';
import {
  ContentLayout,
  SupplierRatingsPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  RentalCompaniesLegacy,
  StageLegacy,
} from '@silvertours/front-features';

import { Body } from '../body';

const SupplierRatingsContent = ({
  content: {
    article,
    globalRating,
    introduction,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<SupplierRatingsPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage content={stage.content} headline={primaryHeadline} />
      }
      content={({ containerModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
        containerModule(
          'introduction-content',
          <ContentArticle
            modules={introduction?.segments ?? []}
            translations={translations}
          />,
          !!introduction?.segments,
        ),

        containerModule(
          'rental-companies-links',
          <RentalCompaniesLegacy.RentalCompaniesLinks />,
        ),
        containerModule(
          'article',
          <ContentArticle
            modules={article.segments}
            translations={translations}
          />,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { SupplierRatingsContent };
