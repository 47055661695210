import { Image } from '@silvertours/common-landingpages-view';
import {
  Figure as FigureComponent,
  FigureImage,
  FigureListContainer,
  PositionLabel,
} from './FigureList.styles';

export type Figure = Image & {
  position: number;
};

export type FigureListProps = {
  content: Array<Figure>;
  positionLabel: string;
};

export const FigureList = ({ content, positionLabel }: FigureListProps) => (
  <FigureListContainer contentLength={content.length}>
    {content.map(({ position, src }) => (
      <FigureComponent key={position}>
        <FigureImage src={src} alt="" />
        <PositionLabel textColor="current">
          {position}. {positionLabel}
        </PositionLabel>
      </FigureComponent>
    ))}
  </FigureListContainer>
);
