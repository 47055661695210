import React, { StrictMode } from 'react';
import { useTranslations } from 'next-intl';
import { StaticLink } from '@silvertours/common-landingpages-view';
import ArrowUp from '@engineering/icons/arrow-up';
import { Runtime, BrandLogo } from '@silvertours/front-entities';
import {
  Wrapper,
  BackToTop,
  Grid,
  InnerGrid,
  Copyright,
  Abbr,
  LogoCell,
  SocialMediaLinksCell,
  FooterLinksCell,
  SupportCell,
  AppLinksCell,
  LegalLinksCell,
  HomeLink,
} from './Footer.styles';
import { FooterLinks } from './FooterLinks';
import { LegalLinks } from './LegalLinks';
import { AppLinks } from './AppLinks';
import { SocialMediaLinks } from './SocialMediaLinks';
import { Support } from './Support';

type Props = {
  footerLinks: StaticLink[];
  legalLinks: StaticLink[];
  socialMediaLinks: StaticLink[];
  appLinks: StaticLink[];
};

const Footer = ({
  footerLinks,
  legalLinks,
  socialMediaLinks,
  appLinks,
}: Props) => {
  const { baseLocalizedUrl } = Runtime.useHost();
  const { brandName } = Runtime.useBrand();
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  const t = useTranslations('features.navigationLegacy.footer');

  return (
    <StrictMode>
      <Wrapper id="page-footer" linksCount={legalLinks.length}>
        <Grid>
          <BackToTop aria-label={t('backToTop')} onClick={scrollToTop}>
            <span>{t('backToTop')}</span>
            <ArrowUp />
          </BackToTop>
          <InnerGrid>
            <LogoCell>
              <HomeLink href={baseLocalizedUrl} target="_self">
                <BrandLogo brand={brandName} height={40} />
              </HomeLink>
            </LogoCell>
            <SocialMediaLinksCell>
              <SocialMediaLinks links={socialMediaLinks} />
            </SocialMediaLinksCell>
            <FooterLinksCell>
              <FooterLinks links={footerLinks} />
            </FooterLinksCell>
            <SupportCell>
              <Support />
            </SupportCell>
            <AppLinksCell>
              <AppLinks links={appLinks} />
            </AppLinksCell>
            <LegalLinksCell>
              <LegalLinks links={legalLinks} />
            </LegalLinksCell>
            <Copyright>
              &copy; {new Date().getFullYear()} FLOYT Mobility{' '}
              <Abbr title="Gesellschaft mit beschränkter Haftung" lang="de">
                GmbH
              </Abbr>
            </Copyright>
          </InnerGrid>
        </Grid>
      </Wrapper>
    </StrictMode>
  );
};

export { Footer };
