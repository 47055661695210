import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const scalable6 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 44">
        <path d="M375 0H0v2.737c12.513 4.253 24.677 9.16 36.855 14.07C82.515 35.221 128.37 53.713 193.5 38.191 261.527 21.98 296-4.949 375 9.404V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 116">
        <path d="M1440 19.191V0H0v39.033c417.248 128.799 705.691 68.518 956.992 16C1125.48 19.82 1277.28-11.904 1440 19.19Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} style={{ height: '223px' }} viewBox="0 0 375 105">
        <path d="M0 0v105h375V89.841c-57-54.673-128.5-4.898-229.5-14.482S2.5 28 0 0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} style={{ height: '223px' }} viewBox="0 0 1440 223">
        <path d="M0 223h1440v-9.204c-48.05-14.301-94.76-30.796-141.52-47.31-175.34-61.914-351.417-124.092-601.518-71.901C435.737 149.097 229.241 106.227 0 0v223Z" />
      </WaveSvg>
    ),
  },
} as WaveSet;

export { scalable6 };
