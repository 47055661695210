import {
  StaticLink,
  StaticLinkType,
} from '@silvertours/common-landingpages-view';
import { TextLinkProps } from '../Ui/LinkProps';

export type NavigationLink = TextLinkProps & {
  masked: boolean;
  highlightText?: string;
};

export type NavigationLinksProps = {
  links: Array<NavigationLink>;
};

const makeLinkTypeMapper =
  (staticLinks: Array<StaticLink>) => (type: StaticLinkType) =>
    staticLinks
      .filter(link => link.type === type)
      .sort((a, b) => (b.weight ?? 0) - (a.weight ?? 0))
      .map(({ title, ...link }): NavigationLink => {
        if (!title) {
          return link;
        }

        return {
          ...link,
          title,
        };
      });

export { makeLinkTypeMapper };
