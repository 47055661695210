import styled from '@emotion/styled';
import { Button } from '@silvertours/front-shared';

/**
 * TODO: Maybe use @emotion/babel-plugin to target Title, Subtitle, Badge, ButtonWrapper here
 * instead of passing props repeatedly to every single component
 * or introduce additional smaller `size` type to SearchInput, DateRangePicker components
 */

const Wrapper = styled.div`
  margin: 0 0.625rem;
`;

const Title = styled.h1`
  margin: 0;
  width: 100%;
  overflow: hidden;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.875;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.brand};

  @media (min-width: 300px) and (min-height: 400px) {
    font-size: 1.125rem;
    line-height: 2.75;
    text-shadow: 0 0 1rem ${({ theme }) => theme.color.white};
  }

  @media (min-width: 780px) {
    font-size: 1.125rem;
    line-height: 2.7778;
    text-shadow: 0 0 1rem ${({ theme }) => theme.color.white};
  }
`;

const FormContainer = styled.div`
  width: 505px;
  flex-grow: 0;

  > *:not(:last-of-type) {
    margin-bottom: 0.625rem;
  }

  input {
    height: 2.5rem;
    padding: 1rem 0.75rem 0.25rem;
    font-size: 0.75rem;
    line-height: 1;

    & + svg {
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  select {
    height: 2.5rem;
    padding: 1rem 0.75rem 0.25rem;
    font-size: 0.75rem;
    line-height: 1;

    & + svg {
      top: 62.5%;
      right: 0.5rem;
    }
  }

  #datepicker {
    padding-left: 0.75rem;

    & + svg {
      right: 0.5rem;
    }
  }

  label {
    top: 1px;
    left: calc(0.75rem - 2px);
    color: ${({ theme }) => theme.color.grey02};
  }

  @media (min-width: 780px) {
    max-width: 300px;
  }
`;

const StyledButton = styled(Button)`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

export { Wrapper, Title, FormContainer, StyledButton };
