import { ReactNode } from 'react';
import { StyledQuote } from './Quote.styles';

type QuoteProps = {
  children: ReactNode;
};

const Quote = ({ children }: QuoteProps) => (
  <StyledQuote>{children}</StyledQuote>
);

export { Quote };
