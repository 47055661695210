import { useRouter } from 'next/router';
import { useRuntime } from './useRuntime';

export const useHost = () => {
  const {
    host: { hostName, imageHost },
    locale,
  } = useRuntime();

  const router = useRouter();
  let localeString = '';
  if (
    router?.locales?.map(l => l.toLowerCase()).includes(locale.toLowerCase())
  ) {
    localeString = `/${locale.toLowerCase()}`;
  }

  const cleanHostName = hostName
    // remove subdomains
    .replace(/^[^.]+\./g, '')
    // remove port
    .replace(/:\d+$/, '');
  const cookieDomain =
    // @see https://stackoverflow.com/a/1188145
    cleanHostName === 'localhost' ? undefined : cleanHostName;

  /**
   * @todo: Align with the BM/CG host context, driven by the same
   * settings-based approach
   * */
  return {
    baseUrl: `https://${hostName}`,
    baseLocalizedUrl: `https://${hostName}${localeString}`,
    baseImageUrl: `https://${imageHost}`,
    hostName,
    cookieDomain,
  };
};
