import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const fixed4 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 44">
        <path d="M375 0H0v2.737c12.513 4.253 24.677 9.16 36.855 14.07C82.515 35.221 128.37 53.713 193.5 38.191 261.527 21.98 296-4.949 375 9.404V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 169">
        <path d="M1440 0H0v36s370 146.5 632 86 577.5-42.5 808 47V0Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 87">
        <path d="M375 9.941V87H0V76.681C25.93 66.14 262.01-27.65 368.34 8.072A66.565 66.565 0 0 0 375 9.941Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 206">
        <path d="M0 185.961v19.207h1440V47.538c-448-109.09-722.5 1.05-839.5 25.522C475 99.31 242 205.168 0 185.96Z" />
      </WaveSvg>
    ),
  },
  height: {
    small: 701,
    large: 676,
  },
} as WaveSet;

export { fixed4 };
