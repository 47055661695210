import { ImageWrapper } from './PixelIcon.styles';
import { iconDefinitions } from './pixelIconDefinitions';

type IconNameType = keyof typeof iconDefinitions;

type IconProps = {
  name: IconNameType;
  hasMarginRight?: boolean;
  label?: string;
  width?: string; // default 19px
  height?: string; // default 19px
};

const PixelIcon = ({
  hasMarginRight,
  height,
  name,
  label,
  width,
}: IconProps) => {
  const iconDefinition = iconDefinitions[name];

  return (
    iconDefinition && (
      <ImageWrapper hasMarginRight={hasMarginRight} w={width} h={height}>
        <img
          src={iconDefinition.src}
          alt={label || ''}
          width={iconDefinition.width}
          height={iconDefinition.height}
        />
      </ImageWrapper>
    )
  );
};

export { PixelIcon };
export type { IconNameType };
