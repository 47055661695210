import { SupplierReview } from '@silvertours/common-landingpages-view';
import { DataInterop } from '@silvertours/front-shared';

type MapAutoRentalStructuredDataProps = {
  supplierReviews: SupplierReview[];
};

const SupplierReviewsData = ({
  supplierReviews,
}: MapAutoRentalStructuredDataProps) => {
  if (!supplierReviews.length) {
    return null;
  }

  return (
    <>
      {supplierReviews.map(review => (
        <DataInterop.StructuredData
          key={`${review.name}-supplierReviews`}
          type="Product" // this should be `AutoRental` but we have no `address` data which is required then
          data={{
            name: review.name,
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: review.totalRating.value,
              bestRating: review.totalRating.max,
              worstRating: review.totalRating.min,
              reviewCount: review.reviewCount,
            },
          }}
          includeContext
        />
      ))}
    </>
  );
};

export { SupplierReviewsData };
