const countryCodes: Record<string, string> = {
  de: '+49',
  fr: '+33',
  es: '+34',
  it: '+39',
  nl: '+31',
  en: '+44',
};

const formats = ['national', 'international', 'url'] as const;
type PhoneNumberFormat = (typeof formats)[number];

const formatPhoneNumber = (
  phoneNumber: string,
  language: string,
  format?: PhoneNumberFormat,
) => {
  if (format === 'national') {
    if (!phoneNumber.startsWith('+')) {
      // is already national
      return phoneNumber;
    }

    return phoneNumber.replace(/^\+\d+\s/, '0');
  }

  const internationalPhoneNumber = phoneNumber.startsWith('+')
    ? phoneNumber
    : phoneNumber
        .replace('-', '')
        .replace(/\s+/, ' ')
        .replace(/^0+/, `${countryCodes[language]} `);

  if (format === 'url') {
    return `tel:+${internationalPhoneNumber.replace(/\D/g, '')}`;
  }

  return internationalPhoneNumber;
};

/**
 * Parses a phone number and returns different formats.
 * This is by no means a fully implemented phone number formatter, but
 * it covers the needs of FLOYT and all current phone numbers
 * provided by the Site Settings v1 API.
 */
const parsePhoneNumber = (phoneNumber: string, language: string) =>
  Object.fromEntries(
    formats.map(f => [f, formatPhoneNumber(phoneNumber, language, f)]),
  ) as Record<PhoneNumberFormat, string>;

export { parsePhoneNumber };
