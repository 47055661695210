import type {
  ComponentProps,
  JSX,
  JSXElementConstructor,
  ReactNode,
} from 'react';
import { StyledButton } from './Button.styles';
import { ActivityIndicator } from '../ActivityIndicator';

type ButtonProps<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any> = 'button',
> = ComponentProps<T> & {
  children?: ReactNode;
  loading?: boolean;
};

const Button = ({ children, loading, ...rest }: ButtonProps) => (
  <StyledButton {...rest}>
    {loading && <ActivityIndicator />}
    {children}
  </StyledButton>
);

const LinkButtonStyle = StyledButton.withComponent('a');

const LinkButton = ({ children, ...rest }: ButtonProps<'a'>) => (
  <LinkButtonStyle {...rest}>{children}</LinkButtonStyle>
);

export { Button, LinkButton };
