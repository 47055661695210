import { GridSetProps } from './GridSet';
import { GridImageLink } from './GridImageLink';

export const GridSetOf1 = ({ content: { set } }: GridSetProps) => {
  if (set.length === 0) {
    return null;
  }

  return (
    <GridImageLink
      content={set[0]}
      rules={{ color: set[0].color, layout: [12], hidden: set[0].hidden }}
    />
  );
};
