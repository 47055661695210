import { FC } from 'react';

import { I18nLegacy } from '@silvertours/front-legacy-shared';

import { LandingPage as DELandingPage } from './de';
import { LandingPage as FRLandingPage } from './fr';
import { LandingPageProps } from './LandingPageProps';

const localizedComponents: Record<string, FC<LandingPageProps>> = {
  de: DELandingPage,
  fr: FRLandingPage,
};

const useLocalizedLandingPage = () => {
  const { language } = I18nLegacy.useLocale();

  if (!Object.keys(localizedComponents).includes(language)) {
    /** @todo: this is an error, we should log it as high priority if it occurs */
    return null;
  }

  return localizedComponents[language];
};

export { useLocalizedLandingPage };
