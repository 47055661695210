import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const scalable3 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 57">
        <path d="M0 0h375v52.916c-48 7.144-101.5 4.166-158.5-3.158s-101-9.553-213.285 0A68.175 68.175 0 0 1 0 50.782V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M0 .219h1440V53.41c-178.5-27.293-283.5-25.42-573 6.315C577.5 91.46 450.5 130.139 0 64.054V.218Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 60">
        <path d="M0 60V30.329c197.5 9.733 222.5-38.12 375-29.22V60H0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M0 100V16.126C498 100 908-20.617 1440 3.17V100H0Z" />
      </WaveSvg>
    ),
  },
} as WaveSet;

export { scalable3 };
