import styled from '@emotion/styled';
import { bodyBold } from '../BodyBoldText';
import { mq } from '../../Theme';
import { StyledTextProps } from '../textUtils';

// @todo: Use heading sizes from theme config via `props.theme.fontSize`

const Heading1 = styled.h1<StyledTextProps>`
  ${bodyBold}
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: ${({ theme }) => theme.fontWeight.heading};
  font-size: 2.125rem;
  line-height: 1.235em;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  ${mq('lg')} {
    font-size: ${({ theme }) => theme.fontSize.xl.size};
    line-height: 1.188em;
  }
`;

const Heading2 = styled.h2<StyledTextProps>`
  ${bodyBold}
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: ${({ theme }) => theme.fontWeight.heading};
  font-size: 1.688rem;
  line-height: 1.259em;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  ${mq('lg')} {
    font-size: ${({ theme }) => theme.fontSize.lg.size};
    line-height: 1.25em;
  }
`;

const Heading3 = styled.h3<StyledTextProps>`
  ${bodyBold}
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: ${({ theme }) => theme.fontWeight.heading};
  font-size: 1.375rem;
  line-height: 1.273em;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  ${mq('lg')} {
    font-size: ${({ theme }) => theme.fontSize.md.size};
    line-height: 1.4em;
  }
`;

const Heading4 = styled.h4<StyledTextProps>`
  ${bodyBold}
`;

const Heading5 = styled.h5<StyledTextProps>`
  ${bodyBold}
`;

const Heading6 = styled.h6<StyledTextProps>`
  ${bodyBold}
`;

export { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 };
