import React, { useEffect } from 'react';
import { Ui } from '@silvertours/front-shared';
import { useTranslations } from 'next-intl';
import { useSearchContext } from '../SearchFormLegacy/context';

const ErrorMessages = ({ hideDateError }: { hideDateError?: boolean }) => {
  const { state, setError } = useSearchContext();

  // hide all errors on unmount so the user is not distracted by old errors
  // when he comes back to the form

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setError(null), []);

  const t = useTranslations('features.stage.search');

  if (state === null) {
    return null;
  }

  return (
    <>
      {state.axiosError && (
        <Ui.Message severity={Ui.MessageSeverity.ERROR}>
          {t('errors.request')}
        </Ui.Message>
      )}
      {state.locationError && (
        <Ui.Message severity={Ui.MessageSeverity.ERROR}>
          {state.locationError}
        </Ui.Message>
      )}
      {!hideDateError && state.dateError && (
        <Ui.Message severity={Ui.MessageSeverity.ERROR}>
          {state.dateError}
        </Ui.Message>
      )}
    </>
  );
};

export { ErrorMessages };
