import {
  HasProducts,
  PageContent,
} from '@silvertours/common-landingpages-view';
import {
  AutoRentalData,
  FaqData,
  OrganizationData,
  SupplierReviewsData,
} from '@silvertours/front-entities';
import { useStructuredData } from './useStructuredData';

type StructuredDataProps = {
  page?: PageContent;
} & HasProducts;

export const StructuredDataWidget: React.FC<StructuredDataProps> = ({
  page,
  products,
}) => {
  const { stations, supplierReviews, faqs, name } = useStructuredData(
    products,
    page,
  );
  return (
    <>
      <OrganizationData products={products} />
      {faqs && faqs.length > 0 && <FaqData faqs={faqs} name={name} />}
      {supplierReviews && supplierReviews.length > 0 && (
        <SupplierReviewsData supplierReviews={supplierReviews} />
      )}
      {stations && stations.length > 0 && (
        <AutoRentalData stations={stations} />
      )}
    </>
  );
};
