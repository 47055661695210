import styled from '@emotion/styled';
import { Trustpilot } from '../TrustSeals';

const MobileSearchContainer = styled.div`
  position: relative;
  display: flex;
  height: 350px;
  padding: ${({ theme }) => theme.spacing['50']};
  z-index: 10;
`;

const TrustpilotWidget = styled(Trustpilot)`
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
`;

export { MobileSearchContainer, TrustpilotWidget };
