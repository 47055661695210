import type { JSX } from 'react';
import {
  Cell,
  ColumnHead,
  Row,
  TableContainer,
  TableHead,
} from './Table.styles';

type TableCellProps<TCell> = {
  data: TCell;
};

type TableColumn<TKey, TValue> = {
  key: TKey;
  columnHeader: string;
  component?: (props: TableCellProps<TValue>) => JSX.Element;
};

type TableColumns<TRow extends Record<string, unknown>> = Array<
  TableColumn<keyof TRow, TRow[keyof TRow]>
>;

type TableProps<TRow extends Record<string, unknown>> = {
  columns: TableColumns<TRow>;
  id?: string;
  rows: Array<TRow>;
};

const makeTableRowWithColumns =
  <TRow extends Record<string, unknown>>(columns: TableColumns<TRow>) =>
  (row: TRow) => (
    <Row>
      {columns.map(({ key, component: CellContent }) => {
        // eslint-disable-next-line react/destructuring-assignment
        const data = row[key];

        if (CellContent) {
          return (
            <Cell key={String(key)}>
              <CellContent data={data} />
            </Cell>
          );
        }

        return <Cell key={String(key)}>{`${data}`}</Cell>;
      })}
    </Row>
  );

const Table = <TRow extends Record<string, unknown>>({
  columns,
  id,
  rows,
}: TableProps<TRow>) => {
  const SupplierRow = makeTableRowWithColumns(columns);

  return (
    <TableContainer id={id}>
      <TableHead>
        <Row>
          {columns.map(({ columnHeader }) => (
            <ColumnHead key={columnHeader}>{columnHeader}</ColumnHead>
          ))}
        </Row>
      </TableHead>
      <tbody>
        {rows.map((row, index) => (
          <SupplierRow key={`row-${String(index)}`} {...row} />
        ))}
      </tbody>
    </TableContainer>
  );
};

export { Table };
export type { TableCellProps, TableColumn, TableColumns, TableProps };
