import { useRuntime } from './useRuntime';

const join = (baseUrl: string, path: string) => {
  const delimiter = path.indexOf('/') === 0 ? '' : '/';

  return `${baseUrl}${delimiter}${path}`;
};

export const useAccountConfig = () => {
  const {
    account: { apiBaseUrl, sessionBaseUrl },
  } = useRuntime();

  return {
    formatApiUrl(path: string) {
      return join(apiBaseUrl, path);
    },
    formatSessionUrl(path: string) {
      return join(sessionBaseUrl, path);
    },
  };
};
