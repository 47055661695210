import React from 'react';
import { LandingPageProps, useLocalizedLandingPage } from './content';

const DynamicLandingPage = (props: LandingPageProps) => {
  const { content } = props;
  const LocalizedLandingPage = useLocalizedLandingPage();

  if (!content || !LocalizedLandingPage) {
    return null;
  }

  return <LocalizedLandingPage {...props} />;
};

export { DynamicLandingPage };
