import { forwardRef, useContext } from 'react';
import Close from '@engineering/icons/close';
import { Station } from './types';
import {
  CloseButton,
  IconContainer,
  InfoBox,
  StationIcon,
  StationNameAndAddress,
  StationText,
  TextContainer,
  Wave,
} from './GoogleMap.styles';
import { getStationIcon } from './utils';
import { MapContentContext } from './Context';

type Props = {
  closable?: boolean;
  handleClose?: () => void;
  onClick?: (stationId: string) => void;
  selected: boolean;
  station: Station;
};

export const StationInfoBox = forwardRef<HTMLDivElement, Props>(
  ({ closable = false, handleClose, onClick, selected, station }, ref) => {
    const context = useContext(MapContentContext);
    const handleClick = () => {
      if (onClick) {
        onClick(station.id);
      }
    };

    const Icon = getStationIcon(station.stationKind);

    const onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (handleClose) {
        handleClose();
      }
    };

    return (
      <InfoBox
        onClick={handleClick}
        selected={selected}
        ref={ref}
        key={station.id}
      >
        <IconContainer>
          <Wave />
          <StationIcon>
            <Icon width={24} />
          </StationIcon>
        </IconContainer>
        {closable && <CloseButton icon={Close} onClick={onClose} />}
        <TextContainer closable={closable}>
          <StationText as="div" variant="body">
            <b title={station.supplier}>
              {context?.translations.supplier}: {station.supplier}
            </b>
            <br />
            <StationNameAndAddress>{station.address}</StationNameAndAddress>
          </StationText>
        </TextContainer>
      </InfoBox>
    );
  },
);
