import { useState } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';
import { useTranslations } from 'next-intl';
import { Ui } from '@silvertours/front-shared';
import { Seal, Wrapper } from './BestPriceGuarantee.styles';

type Props = Pick<HTMLAttributes<HTMLElement>, 'className'>;

const BestPriceGuarantee = ({ className }: Props) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const t = useTranslations('features.trustElements');

  const handleShowOverlay = () => {
    setShowOverlay(true);
  };

  const handleHideOverlay = () => {
    setShowOverlay(false);
  };

  const link = (chunks: ReactNode) => (
    <Ui.TextLink href={t('bestPriceGuarantee.link')} target="_blank">
      {chunks}
    </Ui.TextLink>
  );

  const newLine = () => (
    <>
      <br />
      <br />
    </>
  );

  return (
    <Wrapper role="presentation" className={className}>
      <Seal onClick={handleShowOverlay} />
      {showOverlay && (
        <Ui.Overlay
          open
          title={t('bestPriceGuarantee.title')}
          onClose={handleHideOverlay}
        >
          <p>{t.rich('bestPriceGuarantee.text', { link, break: newLine })}</p>
        </Ui.Overlay>
      )}
    </Wrapper>
  );
};

export { BestPriceGuarantee };
