import { IconSize } from '../Icon';
import { RatingContainer } from './Rating.styles';
import { RatingStar, RatingStarAppearance } from './RatingStar';

type RatingProps = {
  value: number;
  maxStars?: number;
  starSize?: IconSize;
  className?: string;
};

const Rating = ({ value, maxStars = 5, starSize, className }: RatingProps) => {
  if (!value) {
    return null;
  }

  const stars = new Array(maxStars).fill(null).map((_, index) => index + 1);
  const halfStarsValue = Math.round(value * 2);

  return (
    <RatingContainer
      role="img"
      aria-label={`Rating: ${value} out of ${maxStars} stars`}
      className={className}
    >
      {stars.map(i => {
        let appearance = RatingStarAppearance.Empty;

        if (halfStarsValue >= i * 2) {
          appearance = RatingStarAppearance.Full;
        } else if (halfStarsValue >= i * 2 - 1) {
          appearance = RatingStarAppearance.Half;
        }

        return (
          <RatingStar
            key={i}
            appearence={appearance}
            aria-hidden
            size={starSize}
          />
        );
      })}
    </RatingContainer>
  );
};

export { Rating };
export type { RatingProps };
