import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';

import type {
  HasColumns,
  HasOptionalVerticalSpacingRules,
  SpacingDirection,
} from '../rule';
import { formatColumns } from '../particle';

type HeadingWrapperProps = {
  rules: HasColumns & HasOptionalVerticalSpacingRules<'spacingDirection'>;
  hasIcon?: boolean;
};

const getSpacingDirection = (
  spacingDirection: SpacingDirection,
): SerializedStyles | string =>
  ({
    bottom: css`
      margin-bottom: 1rem;
    `,
    both: css`
      margin-bottom: 1rem;
      margin-top: 1rem;
    `,
    top: css`
      margin-top: 1rem;
    `,
    neither: '',
  })[spacingDirection];

const HeadingWrapper = styled.div<HeadingWrapperProps>`
  ${({ theme, rules }) =>
    rules.columns?.map(columns => formatColumns(columns, theme))};

  ${({ rules }) =>
    rules.verticalSpacing?.spacingDirection
      ? getSpacingDirection(rules.verticalSpacing.spacingDirection)
      : getSpacingDirection('bottom')};

  ${({ hasIcon }) =>
    hasIcon &&
    `
    & > h1, h2, h3 {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
    `}
`;

export { HeadingWrapper };
