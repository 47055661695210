import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

const Card = styled(Ui.Card)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[40]};
  row-gap: ${({ theme }) => theme.spacing[20]};
  max-width: 285px;
  cursor: pointer;
`;

const Upper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[40]};
`;

const Lower = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xxs.size};
`;

const SvgContainer = css`
  display: flex;
  align-items: center;

  & > svg {
    width: auto;
    height: 24px;
  }
`;

const LogoContainer = styled.div`
  ${SvgContainer}
`;

const StarsContainer = styled.div`
  ${SvgContainer}
`;

export { Card, Upper, Lower, LogoContainer, StarsContainer };
