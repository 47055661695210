import { Features } from '@silvertours/common-landingpages-i18n';

type CheckboxOptionKey =
  keyof Features['stageLegacy']['stage']['searchForm']['options']['checkbox'];

type CheckboxOptions = Array<{
  key: CheckboxOptionKey;
  id: string;
  value: string;
  name?: string;
}>;

const CAR_CATEGORY_OPTIONS: CheckboxOptions = [
  { key: 'carSmall', id: 'fib_ab', value: 'M|N|E|H:::' },
  { key: 'carFullSize', id: 'fib_al', value: 'S|F|R|G|X|P|U|L|W:::' },
  { key: 'carCompact', id: 'fib_ad', value: 'C|D:-V|-M::' },
  { key: 'carWagonEstate', id: 'fib_ae', value: ':W|Y::' },
  { key: 'carMiddleClass', id: 'fib_af', value: 'I|J|S|F|R|G|X|P|U|L|W:::' },
];

const EXTRA_OPTIONS: CheckboxOptions = [
  { key: 'kmFree', id: 'fib_ed', value: 'a[K*]', name: 'extras[]' },
  {
    key: 'fairFuelPolicy',
    id: 'fib_ee',
    value: 'full-full',
    name: 'fuel_policy[]',
  },
  { key: 'additionalDriver', id: 'fib_ec', value: 'a[A]', name: 'extras[]' },
  { key: 'winterTires', id: 'fib_eb', value: 'a[W]', name: 'extras[]' },
];

export { CAR_CATEGORY_OPTIONS, EXTRA_OPTIONS };
