import styled from '@emotion/styled';
import { darken } from 'polished';
import { ColorProps } from '../../Theme';
import { RichTextStyle } from '../RichText';

const styles: Record<
  'active' | 'inactive',
  Record<
    RichTextStyle,
    {
      color: keyof ColorProps;
      underlineColor: keyof ColorProps;
      underlineHoverColor: keyof ColorProps;
    }
  >
> = {
  active: {
    alternative: {
      color: 'white',
      underlineColor: 'white',
      underlineHoverColor: 'brand80',
    },
    muted: {
      color: 'text',
      underlineColor: 'brand80',
      underlineHoverColor: 'brand80',
    },
    primary: {
      color: 'text',
      underlineColor: 'brand80',
      underlineHoverColor: 'brand80',
    },
  },
  inactive: {
    alternative: {
      color: 'white',
      underlineColor: 'white',
      underlineHoverColor: 'white',
    },
    muted: {
      color: 'grey02',
      underlineColor: 'grey02',
      underlineHoverColor: 'grey02',
    },
    primary: {
      color: 'grey02',
      underlineColor: 'grey02',
      underlineHoverColor: 'grey02',
    },
  },
};

const ButtonBase = styled.button<{
  textStyle: RichTextStyle;
}>`
  white-space: nowrap;
  padding-bottom: ${({ theme }) => theme.spacing[40]};
  position: relative;
  cursor: pointer;
  border: 0;
  background: transparent;

  &::after {
    background-color: ${({ theme, textStyle }) =>
      theme.color[styles.active[textStyle].underlineColor]};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.xxs};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.xxs};
    content: '';
    transform: ${({ theme }) => `translateY(${theme.spacing[40]})`};
    position: absolute;
    bottom: ${({ theme }) => theme.spacing[40]};
    left: 0;
    height: ${({ theme }) => theme.spacing[10]};
    width: 100%;
  }
`;

const ActiveButton = styled(ButtonBase)`
  ${ButtonBase};

  color: ${({ textStyle, theme }) =>
    theme.color[styles.active[textStyle].color]};
`;

const InactiveButton = styled(ButtonBase)`
  ${ButtonBase};

  color: ${({ textStyle, theme }) =>
    theme.color[styles.inactive[textStyle].color]};

  &:hover {
    color: ${({ textStyle, theme }) =>
      theme.color[styles.active[textStyle].color]};

    &::after {
      display: initial;
      background-color: ${({ theme, textStyle }) =>
        darken(
          0.1,
          theme.color[styles.inactive[textStyle].underlineHoverColor]!,
        )};
    }
  }

  &::after {
    display: none;
  }
`;

export { ActiveButton, InactiveButton };
