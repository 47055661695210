import { useTranslations } from 'next-intl';

import { UiLegacy } from '@silvertours/front-legacy-shared';
import { Ui } from '@silvertours/front-shared';
import { LinksList, LinkItem } from './RentalCompaniesLinks.styles';

type Link = {
  href: string;
  rating: number;
  text: string;
};

const RentalCompaniesLinks = () => {
  const t = useTranslations('features.rentalCompaniesLinksLegacy');

  const links = t.raw('links') as Array<Link>;

  if (!links) {
    return null;
  }

  return (
    <>
      <UiLegacy.SectionTitle>{t('title')}</UiLegacy.SectionTitle>
      <LinksList>
        {links.map(link => (
          <LinkItem key={link.href}>
            <UiLegacy.Rating value={link.rating} />
            <Ui.TextLink href={link.href} text={link.text} />
          </LinkItem>
        ))}
      </LinksList>
    </>
  );
};

export { RentalCompaniesLinks };
