import { css } from '@emotion/react';
import styled from '@emotion/styled';

const TextStyles = css`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
`;

const RatingBarWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr auto;
  gap: ${({ theme }) => theme.spacing[20]};
  align-items: center;
`;

const RatingBarLabel = styled.span<{ hasNoValue: boolean }>`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing[20]};
  color: ${({ theme, hasNoValue }) =>
    hasNoValue ? theme.color.grey02 : theme.color.text};
  ${TextStyles};
`;

const RatingBarChart = styled.data<{ value: number }>`
  display: block;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  height: 0.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.color.grey04};
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    width: ${({ value }) => `${value}%`};
    height: 100%;
    background-color: ${({ theme }) => theme.color.brand};
  }
`;

const RatingBarCount = styled.data`
  color: ${({ theme }) => theme.color.text};
  ${TextStyles};
`;

export { RatingBarWrapper, RatingBarLabel, RatingBarChart, RatingBarCount };
