import styled from '@emotion/styled';
import { secondaryText, StyledTextProps } from '@silvertours/front-shared';

const IconGridContainer = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[40]};

  ${({ theme }) => theme.mq('xl')} {
    height: 146px;
  }
`;

const IconWrapper = styled.div`
  background-image: ${({ theme }) =>
    theme.linearGradient(theme.gradient.orange, '270deg')};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.mq('xl')} {
    width: 146px;
  }

  & svg {
    max-height: 104px;
  }
`;

const Label = styled.div<StyledTextProps>`
  ${secondaryText}
  margin: ${({ theme }) => theme.spacing[10]};
  justify-self: center;
`;

export { IconWrapper, IconGridContainer, Label };
