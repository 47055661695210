import { useHost } from './useHost';

export const useCarImage = (imageUrl: string) => {
  const { baseUrl, baseImageUrl } = useHost();

  if (imageUrl.match(/^\/default\//)) {
    return baseImageUrl + imageUrl;
  }

  if (imageUrl.match(/^https?:\/\//)) {
    return imageUrl;
  }

  return baseUrl + imageUrl;
};
