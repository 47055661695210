import React from 'react';

import {
  HostedLayout,
  CustomPageContent,
} from '@silvertours/common-landingpages-view';

import { CustomContent } from './content';

const CustomPage = (props: HostedLayout<CustomPageContent>) => (
  <CustomContent {...props} />
);

export { CustomPage };
