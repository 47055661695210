import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Theme, mq } from '../../Theme';
import { TextColor, mapColor } from '../textUtils';

type SecondaryTextMixinProps = {
  textColor?: TextColor;
  theme: Theme;
};

const secondaryText = ({ textColor, theme }: SecondaryTextMixinProps) => css`
  font-size: 0.875rem;
  line-height: 1.429em;

  ${mq('lg')} {
    font-size: 0.938rem;
    line-height: 1.467em;
    font-weight: 600;
  }

  color: ${mapColor(theme, textColor)};
`;

type SecondaryTextProps = {
  textColor?: TextColor;
};

const SecondaryTextWrapper = styled.p<SecondaryTextProps>`
  ${({ theme, textColor }) => secondaryText({ theme, textColor })}
`;

const InlineSecondaryTextWrapper = styled.span<SecondaryTextProps>`
  ${({ theme, textColor }) => secondaryText({ theme, textColor })}
`;

export { secondaryText, SecondaryTextWrapper, InlineSecondaryTextWrapper };
