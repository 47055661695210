import { analytics } from '@silvertours/front-entities';
import { Ui } from '@silvertours/front-shared';
import { useTranslations } from 'next-intl';

type Props = {
  isLoggedIn: boolean;
};

const Footer = ({ isLoggedIn }: Props) => {
  const t = useTranslations('features.navigationLegacy.navigation');
  return (
    <>
      {t('login.noAccountText')}{' '}
      <Ui.TextLink
        href={t('newUser.href')}
        text={t('newUser.text')}
        onClick={() => analytics.gtm.trackNewUser({ isLoggedIn })}
      />
    </>
  );
};

export { Footer };
