import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

const Block = styled.section<{
  isHidden: boolean;
  textStyle: Ui.RichTextStyle;
}>`
  padding-top: ${({ theme }) => theme.spacing[60]};

  & > :first-of-type h1,
  & > :first-of-type h2,
  & > :first-of-type h3,
  & > :first-of-type h4,
  & > :first-of-type h5,
  & > :first-of-type h6 {
    margin-top: 0;
    padding-top: 0;
  }

  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  color: ${({ textStyle, theme }) =>
    textStyle === Ui.RichTextStyle.Alternative
      ? theme.color.white
      : theme.color.text};
`;

export { Block };
