import { useContext } from 'react';
import { SiteInfoContext } from '../context';

const useSiteInfo = () => {
  const context = useContext(SiteInfoContext);

  if (context === undefined) {
    throw new Error(
      'useSiteInfo must be used within a SiteInfoContext Provider',
    );
  }
  return context;
};

export { useSiteInfo };
