import { getRoutes, RegexType, PLACEHOLDER_VAR } from './routes';
import type { DataLayerSettings } from './dataLayer';

/**
 * Transform strings like 'loueur-jucy-rentals' to "Loueur Jucy Rentals"
 */
const upperCaseWord = (str: string) => {
  const arr = str.replace(/-/g, ' ').split(' ');
  let i;
  for (i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};

const getTrackingDataForPath = (settings: DataLayerSettings, path: string) => {
  // remove query string params
  const [pathname] = path.split('?');
  const routes = getRoutes(settings);

  const route = routes.find(
    r =>
      r.path === pathname ||
      (typeof r.path === typeof RegexType && new RegExp(r.path).test(pathname)),
  );

  if (!route) {
    return null;
  }

  const pathIsRegex = typeof route.path === typeof RegexType;
  if (pathIsRegex) {
    const regex = new RegExp(route.path);
    const [, fullMatch, match] = pathname.match(regex) || [];
    if (match) {
      route.tracking.virtPath = route.tracking.virtPath.replace(
        PLACEHOLDER_VAR,
        match,
      );
      // TODO: assign Country or State to `landingpageKategorie`
      route.tracking.landingpageKategorie = upperCaseWord(fullMatch);
      route.tracking.englishPageName = route.tracking.englishPageName.replace(
        PLACEHOLDER_VAR,
        match,
      );
    }
  }

  return route.tracking;
};

export { getTrackingDataForPath };
