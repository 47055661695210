import { useState } from 'react';

const MARKETS = [
  {
    country: { name: 'Deutschland', code: 'DE' },
    language: { name: 'Deutsch', code: 'de' },
    redirectInfo: {
      text: 'Wir können dich auf unseren deutschen Marktführer billiger-mietwagen.de weiterleiten, damit du dein bestes Angebot findest.',
      header: 'Weiterleiten',
      link: {
        text: 'Zu billiger-mietwagen.de',
        url: 'https://www.billiger-mietwagen.de',
      },
    },
  },
  {
    country: { name: 'Frankreich', code: 'FR' },
    language: { name: 'Französisch', code: 'fr' },
    redirectInfo: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      header: 'Weiterleiten',
      link: { text: 'Zu carigami.fr', url: 'https://www.carigami.fr' },
    },
  },
];

const useMockedMarketPicker = () => {
  // Use mock data in storybook / testing
  const [selectedMarket, setMarket] = useState(MARKETS[0]);
  return { selectedMarket, selectMarket: setMarket, markets: MARKETS };
};

export { MARKETS, useMockedMarketPicker };
