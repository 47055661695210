import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const ClientOnlyPortal: ({
  children,
  selector,
}: {
  children: any;
  selector: string;
}) => React.ReactPortal | null = ({ children, selector }) => {
  const ref = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export { ClientOnlyPortal };
