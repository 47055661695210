import { TextProps } from '../textUtils';
import {
  BodyBoldTextWrapper,
  InlineBodyBoldTextWrapper,
} from './BodyBoldText.styles';

const BodyBoldText: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className,
  inline = false,
  textColor,
}) => {
  const Wrapper = inline ? InlineBodyBoldTextWrapper : BodyBoldTextWrapper;
  return (
    <Wrapper className={className} textColor={textColor}>
      {children}
    </Wrapper>
  );
};

export { BodyBoldText };
