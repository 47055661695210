import { css, Theme as ThemeType } from '@emotion/react';
import { Columns } from '../rule';

export const formatColumns = (
  [span, breakpoint, { override = false, condition = true } = {}]: Columns,
  theme: ThemeType,
) => {
  if (!condition) {
    return '';
  }

  const columnStyle = css`
    grid-column: span ${span}${override ? ' !important' : ''};
  `;

  if (breakpoint) {
    return css`
      ${theme.mq(breakpoint)} {
        ${columnStyle}
      }
    `;
  }

  return css`
    ${columnStyle}
  `;
};
