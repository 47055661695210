import styled from '@emotion/styled';

const Container = styled.div<{ hasHeader: boolean }>`
  padding: ${({ theme, hasHeader }) => (hasHeader ? theme.spacing[50] : '')};
`;

const Element = styled.div`
  margin: ${({ theme }) => `${theme.spacing[50]} 0`};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  /* On large screens header is displayed in the overlay */
  ${({ theme }) => theme.mq('lg')} {
    display: none;
  }
`;

export { Container, Header, Element };
