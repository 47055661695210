import { css } from '@emotion/react';
import styled from '@emotion/styled';

const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[40]};

  ${({ theme }) => css`
    ${theme.mq('xl')} {
      flex-direction: row;
      justify-content: center;
    }
  `}
`;

export { ResponsiveWrapper };
