import { SVGAttributes } from 'react';
import { HasSize, StyledSvg } from './SvgIcon.styles';
import { iconDefinitions } from './svgIconDefinitions';

type IconNameType = keyof typeof iconDefinitions;

type IconProps = {
  name: IconNameType;
} & HasSize &
  SVGAttributes<SVGElement>;

const SvgIcon = ({ name, size = 'small', ...props }: IconProps) => {
  const iconDefinition = iconDefinitions[name];

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={iconDefinition?.viewBox}
      role="img"
      size={size}
      {...props}
    >
      {iconDefinition?.svg}
    </StyledSvg>
  );
};

export { SvgIcon };
