import { TextImageContentModule } from '@silvertours/common-landingpages-view';
import {
  selectBackgroundStyle,
  selectBadgeStyle,
  selectLayoutFlow,
  ImageContentBlock,
  PlainContentModuleContainer,
  TextContentBlock,
  WaveContentModuleContainer,
} from '@silvertours/front-entities';
import { Ui } from '@silvertours/front-shared';

import { getSpacingDirectionFromImagePosition } from '../../particle';

export const TextImage = (props: TextImageContentModule) => {
  const { badge, image, rules = {} } = props;
  const style = rules.style || 'default';
  const imagePosition = rules.imagePosition || 'right';
  const backgroundStyle = selectBackgroundStyle(style);
  const badgeStyle = selectBadgeStyle(style);
  const isPlain = !['green', 'mint', 'orange'].includes(style);
  const layoutFlow = selectLayoutFlow(imagePosition);
  const spacingDirection = getSpacingDirectionFromImagePosition(imagePosition);

  const moduleContainerRules = {
    backgroundStyle,
    layoutFlow,
    verticalSpacing: {
      isLarge: !!image,
      spacingDirection,
    },
  };

  const ContentModuleContainer = isPlain
    ? PlainContentModuleContainer
    : WaveContentModuleContainer;

  return (
    <ContentModuleContainer rules={moduleContainerRules}>
      <TextContentBlock
        content={props}
        rules={{
          badge: { shouldHide: imagePosition === 'top', style: badgeStyle },
          isContainerMultiColumn: !!image,
          isSecondColumn: imagePosition === 'left',
          textStyle: {
            style: isPlain
              ? Ui.RichTextStyle.Primary
              : Ui.RichTextStyle.Alternative,
          },
          verticalSpacing: {
            isLargeAtXL: image && !isPlain,
            isSmallAtXL: image && isPlain,
          },
        }}
      />
      <ImageContentBlock
        content={props}
        rules={{
          badge: { shouldHide: imagePosition !== 'top', style: badgeStyle },
          imagePosition,
          isFirstColumn: imagePosition === 'left',
          verticalAlign: ['left', 'right'].includes(imagePosition)
            ? 'middle'
            : 'top',
          verticalSpacing: {
            isLargeAtLG: badge && !isPlain,
            isNoneAtXL: !isPlain,
          },
        }}
      />
    </ContentModuleContainer>
  );
};
