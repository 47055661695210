import { forwardRef } from 'react';
import Close from '@engineering/icons/close';
import { CloseButton } from './Popover.styles';

type Props = React.PropsWithChildren<{
  onClose?: () => void;
}>;

export const ContentWrapper = forwardRef<HTMLDivElement | null, Props>(
  ({ children, onClose }, ref) => (
    <div ref={ref}>
      {onClose && <CloseButton icon={Close} onClick={onClose} />}
      {children}
    </div>
  ),
);
