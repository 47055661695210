import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { tint } from 'polished';
import Checked from '@engineering/icons/checked';
import Info from '@engineering/icons/info';
import Tipp from '@engineering/icons/tipp';
import Warning from '@engineering/icons/warning';
import { MessageSeverity } from './Message.types';

const getColorBySeverity = (severity: MessageSeverity) => {
  switch (severity) {
    case MessageSeverity.SUCCESS:
      return 'success';
    case MessageSeverity.ERROR:
      return 'error';
    default:
      return 'info';
  }
};

const Wrapper = styled.div<{
  visible: boolean;
  severity: MessageSeverity;
}>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  margin: ${({ theme }) => theme.spacing[40]} 0;
  padding: ${({ theme }) => theme.spacing[40]};
  border: ${({ theme }) => `1px solid ${theme.color.grey04}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  gap: ${({ theme }) => theme.spacing[40]};
  background: ${({ theme, severity }) =>
    tint(0.8, theme.color[getColorBySeverity(severity)]!)};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 1rem;
  line-height: 1.5;
  text-align: start;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Headline = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
`;

const IconStyles = css`
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
`;

const InfoIcon = styled(Info)`
  ${IconStyles};
  fill: ${({ theme }) => theme.color.info};
`;

const TipIcon = styled(Tipp)`
  ${IconStyles};
  fill: ${({ theme }) => theme.color.info};
`;

const ErrorIcon = styled(Warning)`
  ${IconStyles};
  fill: ${({ theme }) => theme.color.error};
`;

const SuccessIcon = styled(Checked)`
  ${IconStyles};
  fill: ${({ theme }) => theme.color.success};
`;

export {
  Wrapper,
  Headline,
  Content,
  InfoIcon,
  TipIcon,
  ErrorIcon,
  SuccessIcon,
};
