import { Ui } from '@silvertours/front-shared';
import Menu from '@engineering/icons/menu';
import React from 'react';
import { useTheme } from '@emotion/react';
import { IconButtonContainer } from '../Header.styles';
import { List } from './HiddenLinksPopover.styles';

const HiddenLinksPopover = ({ children }: React.PropsWithChildren) => {
  const { spacing } = useTheme();
  return (
    <Ui.Popover
      button={{
        as: 'div',
        children: (
          <IconButtonContainer key="actionButton-hiddenLinks">
            <Ui.IconButton icon={Menu} size="medium" />
          </IconButtonContainer>
        ),
      }}
      triggerAction="click"
    >
      <Ui.PopoverPanel>
        <Ui.PanelContentWrapper padding={spacing[50]} width="255px">
          <List>{children}</List>
        </Ui.PanelContentWrapper>
      </Ui.PopoverPanel>
    </Ui.Popover>
  );
};

export { HiddenLinksPopover };
