import styled from '@emotion/styled';
import { Button } from '@silvertours/front-shared';

const OptionsWrapper = styled.div<{ isMobile: boolean }>`
  border-left: ${({ isMobile, theme }) =>
    isMobile ? '0' : `1px solid ${theme.color.grey03}`};
  margin-left: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
  padding-left: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
  padding-right: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
`;

const OptionsSection = styled.div`
  &:not(&:first-of-type) {
    margin-top: 1.5rem;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0.625rem;
`;

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  flex-wrap: wrap;
  gap: 0.75rem;

  > label {
    width: 45%;
    align-items: center;
  }
`;

export {
  OptionsSection,
  OptionsWrapper,
  StyledLabel,
  StyledButton,
  CheckboxContainer,
};
