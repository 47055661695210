import { useState } from 'react';
import type { HTMLAttributes } from 'react';
import { Overlay } from '../../Ui';
import { Scroller } from './Scroller';
import { SegmentedControl } from './SegmentedControl';
import { SpinnerButton } from './SpinnerButton';
import { ConfirmButton, Wrapper, ErrorMessage } from './TimePicker.styles';

const FROM_SCROLLER_ID = 'fromScroller';
const TO_SCROLLER_ID = 'toScroller';

type TimeRange = {
  /** ISO 8601 time string (hh:mm) */
  from: string;
  /** ISO 8601 time string (hh:mm) */
  to: string;
};

type TimePickerOverlayProps = {
  initialSegment: 'from' | 'to';
  translations: {
    title: string;
    from: string;
    to: string;
    hours: string;
    minutes: string;
    confirmButton: string;
    closeButton: string;
  };
  onChange?: ({ from, to }: TimeRange) => void;
  onClose: () => void;
} & TimeRange;

export const TimePickerOverlay = ({
  from,
  to,
  initialSegment,
  translations: {
    title,
    from: fromLabel,
    to: toLabel,
    hours,
    minutes,
    closeButton,
    confirmButton,
  },
  onChange,
  onClose,
}: TimePickerOverlayProps) => {
  const [intermediateFrom, setIntermediateFrom] = useState(from);
  const [intermediateTo, setIntermediateTo] = useState(to);
  const [visibleTimeSelector, setVisibleTimeSelector] = useState<'from' | 'to'>(
    initialSegment,
  );

  const isFromVisible = visibleTimeSelector === 'from';

  const handleClick = (type: 'from' | 'to') => {
    setVisibleTimeSelector(type);
  };

  const handleConfirm = () => {
    if (from !== intermediateFrom || to !== intermediateTo) {
      if (typeof onChange === 'function') {
        onChange({ from: intermediateFrom, to: intermediateTo });
      }
    }

    onClose();
  };

  return (
    <Overlay
      open
      title={title}
      titlePosition="left"
      closeLabel={closeButton}
      withoutPadding
      centered
      onClose={onClose}
    >
      <SegmentedControl
        segments={[
          {
            label: fromLabel,
            isSelected: isFromVisible,
            onClick: () => handleClick('from'),
            'aria-controls': FROM_SCROLLER_ID,
          },
          {
            label: toLabel,
            isSelected: !isFromVisible,
            onClick: () => handleClick('to'),
            'aria-controls': TO_SCROLLER_ID,
          },
        ]}
      />
      <Scroller
        id={isFromVisible ? FROM_SCROLLER_ID : TO_SCROLLER_ID}
        value={isFromVisible ? from : to}
        translations={{
          label: isFromVisible ? fromLabel : toLabel,
          hours,
          minutes,
        }}
        onChange={isFromVisible ? setIntermediateFrom : setIntermediateTo}
      />
      <ConfirmButton variant="secondary" onClick={handleConfirm}>
        {confirmButton}
      </ConfirmButton>
    </Overlay>
  );
};

type Props = {
  translations: {
    title: string;
    from: string;
    to: string;
    hours: string;
    minutes: string;
    confirmButton: string;
    closeButton: string;
    errorMessage?: string;
  };
  onChange?: ({ from, to }: TimeRange) => void;
} & TimeRange &
  Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export const TimePicker = ({
  from,
  to,
  translations: {
    title,
    from: fromLabel,
    to: toLabel,
    hours,
    minutes,
    errorMessage,
    closeButton,
    confirmButton,
  },
  className,
  onChange,
}: Props) => {
  const [visibleTimeSelector, setVisibleTimeSelector] = useState<
    'from' | 'to' | null
  >(null);

  const isInvalid = !!errorMessage;

  const handleClick = (type: 'from' | 'to') => {
    setVisibleTimeSelector(type);
  };

  return (
    <>
      <div>
        <Wrapper role="group" className={className}>
          <SpinnerButton
            label={fromLabel}
            value={from}
            invalid={isInvalid}
            onClick={() => handleClick('from')}
          />
          <SpinnerButton
            label={toLabel}
            value={to}
            invalid={isInvalid}
            onClick={() => handleClick('to')}
          />
        </Wrapper>
        {isInvalid && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
      {visibleTimeSelector && (
        <TimePickerOverlay
          from={from}
          to={to}
          translations={{
            title,
            from: fromLabel,
            to: toLabel,
            hours,
            minutes,
            confirmButton,
            closeButton,
          }}
          initialSegment={visibleTimeSelector}
          onChange={onChange}
          onClose={() => setVisibleTimeSelector(null)}
        />
      )}
    </>
  );
};
