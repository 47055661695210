import styled from '@emotion/styled';

const Section = styled.section`
  padding-top: ${({ theme }) => theme.spacing[90]};
  ${({ theme }) => theme.mq('md')} {
    padding-top: ${({ theme }) => theme.spacing[100]};
  }
  ${({ theme }) => theme.mq('xl')} {
    padding-top: ${({ theme }) => theme.spacing[110]};
  }
`;

const Offers = styled.ul<{ hasTitle?: boolean }>`
  display: grid;
  scroll-snap-type: x mandatory;
  grid-template-columns: repeat(3, minmax(282px, 396px));
  gap: ${({ theme }) => theme.spacing[40]};
  overflow-x: auto;
  padding: ${({ hasTitle, theme }) =>
    `${hasTitle ? 0 : theme.spacing[60]} 2rem ${theme.spacing[60]} 2rem`};
  margin-left: -2rem;
  margin-right: -2rem;

  ${({ theme }) => theme.mq('sm')} {
    margin-left: -4rem;
    margin-right: -4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  ${({ theme }) => theme.mq('lg')} {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    overflow-x: visible;
  }
`;

const Offer = styled.li`
  scroll-snap-align: center;
`;

export { Offer, Offers, Section };
