import { useRouter } from 'next/router';
import { useEffect, useCallback, RefObject, useState } from 'react';
import { useIntersection } from 'react-use';
import { onINP } from 'web-vitals/attribution';

import { trackCriteo, trackPageView, trackWebVitalsMetric } from './events';
import { getTrackingDataForPath } from './utils';
import type { DataLayerSettings } from './dataLayer';
import { useSiteInfo } from '../../SiteInfo/hooks/useSiteInfo';
import { SiteSettings } from '../../SiteInfo';

const mapDataLayerSettings = (
  siteSettings: SiteSettings,
): DataLayerSettings => ({
  environment: siteSettings?.sessionData?.gtmEnvironment,
  anonymizeIP: siteSettings?.sessionData?.anonymizeIP,
  locale: siteSettings?.language, // re-using "language" here to be consistent with previous implementation
  language: siteSettings?.language,
  market: siteSettings?.market,
});

/**
 * React Hook to track custom pageView event on every route change
 */
const usePageView = () => {
  const siteInfo = useSiteInfo();
  const router = useRouter();

  const triggerEvent = useCallback(
    async (path: string) => {
      if (siteInfo.settingsLoaded) {
        const settings = mapDataLayerSettings(siteInfo.settings);
        const data = getTrackingDataForPath(settings, path);
        trackPageView(data);

        if (!siteInfo.settings.isInternalCall) {
          trackCriteo();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteInfo.settingsLoaded],
  );

  // initial page view tracking
  useEffect(() => {
    if (router.isReady && siteInfo.settingsLoaded) {
      triggerEvent(router.asPath);
    }
  }, [router.isReady, router.asPath, triggerEvent, siteInfo.settingsLoaded]);

  // route change page view tracking
  useEffect(() => {
    const handleRouteChange = (path: string) => {
      triggerEvent(path);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, triggerEvent]);
};

const useElementScrolledToView = (
  ref: RefObject<HTMLElement>,
  callback: (...args: any[]) => void,
) => {
  const [seen, setSeen] = useState(false);
  const intersection = useIntersection(ref, {
    root: null,
    threshold: 1,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && !seen) {
      callback();
      setSeen(true);
    }
  }, [callback, intersection, seen]);
};

/** @deprecated Use `next/web-vitals` when Next.js 13+ is available. */
const useReportWebVitals = () => {
  useEffect(() => {
    onINP(trackWebVitalsMetric, { reportAllChanges: true });
    // onLCP(trackWebVitalsMetric);
    // onCLS(trackWebVitalsMetric);
    // onFID(trackWebVitalsMetric);
  });
};

export { usePageView, useElementScrolledToView, useReportWebVitals };
