import { ElementType } from 'react';
import { MarkupContainer } from './Markup.styles';

type MarkupProps = {
  content: string;
  element?: ElementType;
  isHidden?: boolean;
};

const Markup = ({ content, isHidden, element }: MarkupProps) => {
  if (!content) {
    return null;
  }

  return (
    <MarkupContainer
      hidden={isHidden || false}
      dangerouslySetInnerHTML={{ __html: content }}
      as={element}
    />
  );
};

Markup.displayName = 'raw';

export { Markup };
export type { MarkupProps };
