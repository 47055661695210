import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ArrowDown from '@engineering/icons/arrow-down';
import ArrowUp from '@engineering/icons/arrow-up';

export type CollapseToggleRules = {
  iconSize: 40 | 60;
};

const ArrowStyle40 = css`
  width: 1rem;
  height: 1rem;
  fill: currentcolor;
`;

const ArrowStyle60 = css`
  width: 2rem;
  height: 2rem;
  fill: currentcolor;
`;

const StyledArrowUp = styled(ArrowUp, {
  shouldForwardProp: prop => prop !== 'iconSize',
})<CollapseToggleRules>`
  ${({ iconSize }: CollapseToggleRules) =>
    iconSize === 40 ? ArrowStyle40 : ArrowStyle60}
`;

const StyledArrowDown = styled(ArrowDown, {
  shouldForwardProp: prop => prop !== 'iconSize',
})<CollapseToggleRules>`
  ${({ iconSize }: CollapseToggleRules) =>
    iconSize === 40 ? ArrowStyle40 : ArrowStyle60}
`;

export { StyledArrowUp, StyledArrowDown };
