import type { HTMLAttributes, ReactNode } from 'react';
import { Wrapper } from './Badge.styles';
import type { BadgeType, BadgeSize } from './types';

type BadgeProps = {
  text?: string;
  icon?: ReactNode;
  type?: BadgeType;
  size?: BadgeSize;
} & Pick<HTMLAttributes<HTMLSpanElement>, 'className'>;

const Badge = ({
  text,
  icon,
  type = 'default',
  size = 'default',
  className,
}: BadgeProps) => {
  if (!text) {
    return null;
  }

  const hasIcon = !!icon;

  return (
    <Wrapper
      badgeType={type}
      badgeSize={size}
      hasIcon={hasIcon}
      className={className}
    >
      {hasIcon ? (
        <>
          <span>{text}</span>
          {icon}
        </>
      ) : (
        text
      )}
    </Wrapper>
  );
};

export { Badge };
