import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { useTranslations } from 'next-intl';
import concat from 'lodash/concat';
import range from 'lodash/range';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { DropdownWrapper, Dropdown, InfoIcon } from './AgeDropdown.styles';
import { DEFAULT_AGE } from '../context';

type AgeDropdownProps = {
  value: number;
  label: string;
  onChange: (age: number) => void;
};

const AgeDropdown = ({ value, label, onChange }: AgeDropdownProps) => {
  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const [showDropdown, setShowDropdown] = useState<boolean>(
    value < 26 || value > 65,
  );

  const ageDropdownData = concat(
    range(18, 26).map(item => ({
      label: item.toString(),
      value: item.toString(),
    })),
    {
      label: t('age.defaultAge'),
      value: DEFAULT_AGE.toString(),
    },
    range(66, 100).map(item => ({
      label: item.toString(),
      value: item.toString(),
    })),
  );

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const age = Number(event.target.value);
    onChange?.(age);
  };

  return (
    <DropdownWrapper>
      {showDropdown ? (
        <Dropdown
          id="customerAge"
          label={t('age.select.label')}
          value={value}
          options={ageDropdownData}
          onChange={handleChange}
        />
      ) : (
        <UiLegacy.Checkbox
          id="driverIsAdult"
          label={label}
          checked
          onChange={() => setShowDropdown(!showDropdown)}
        />
      )}
      <span>
        <UiLegacy.Tooltip
          content={<UiLegacy.Markup content={t('age.tooltip.text')} />}
          trigger={<InfoIcon />}
        />
      </span>
    </DropdownWrapper>
  );
};

export { AgeDropdown };
