/* eslint-disable react/no-danger */

type StructuredDataType<TData extends Record<string, unknown>> = {
  '@type': string;
  '@context'?: 'http://schema.org';
} & TData;

type StructuredDataProps<TData extends Record<string, unknown>> = {
  type: string;
  subType?: string;
  data: TData;
  includeContext?: boolean;
};

const CONTEXT = {
  '@context': 'https://schema.org',
};

const StructuredData = <TData extends Record<string, unknown>>({
  type,
  subType,
  data,
  includeContext = false,
}: StructuredDataProps<TData>) => {
  const context = includeContext ? CONTEXT : {};
  const subTypeId = subType ? `-${subType.toLowerCase()}` : '';
  const structuredData: StructuredDataType<TData> = {
    '@type': type,
    ...data,
    ...context,
  };

  return (
    <script
      id={`jsonld-${type.toLowerCase()}${subTypeId}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
    />
  );
};

export { StructuredData };
export type { StructuredDataProps, StructuredDataType };
