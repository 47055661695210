import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.125rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
`;

export const Button = styled.button<{ $isSelected: boolean }>`
  position: relative;
  margin: 0;
  padding: 0.5rem;
  border: 0;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font: inherit;
  text-align: center;
  color: ${({ theme }) => theme.color.text};
  background: none;
  cursor: pointer;

  &:focus-visible {
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
  }

  ${({ theme, $isSelected }) =>
    $isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0.5rem;
        right: 0.5rem;
        border-radius: ${theme.borderRadius.md};
        height: 3px;
        background-color: ${theme.color.brand};
      }
    `}
`;
