import React from 'react';

import { UiLegacy } from '@silvertours/front-legacy-shared';

import { ContentModulePageModule, ContentModule } from './ContentModule';
import { ModuleOfType, ModuleType } from './ModuleType';

type ContainerModuleProps = {
  id: string;
  modules: ContentModulePageModule | Array<ContentModulePageModule>;
  renderCondition: boolean;
};

type ContainerPageModule = ModuleOfType<
  ModuleType.Container,
  ContainerModuleProps
>;

const ContainerModule = ({
  id,
  modules,
  renderCondition,
}: ContainerModuleProps) => {
  const mods = Array.isArray(modules) ? modules : [modules];

  return renderCondition ? (
    <UiLegacy.Card id={id}>
      {mods.map(module => (
        <ContentModule {...module} key={module.key} />
      ))}
    </UiLegacy.Card>
  ) : null;
};

export { ContainerModule };
export type { ContainerPageModule, ContainerModuleProps };
