import { HasConsumerApiSettings } from '@silvertours/common-landingpages-view';
import { useRuntime } from './useRuntime';

const useConsumerApi = (): HasConsumerApiSettings => {
  const { consumerApiSettings } = useRuntime();

  return {
    consumerApiSettings,
  };
};

export { useConsumerApi };
