import { type ReactNode, SVGProps } from 'react';
import Close from '@engineering/icons/close';
import { useLockBodyScroll } from 'react-use';
import {
  BackgroundOverlay,
  CloseButton,
  FlexContainer,
  GridContainer,
  IconButton,
  PanelContainer,
  StyledContent,
  StyledDialog,
  StyledDialogPanel,
} from './MobileMenu.styles';

type MenuProps = {
  id?: string;
  closeMenuLabel: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  iconLabel: string;
  renderContent: (closeMenu: () => void) => ReactNode;
  open: boolean;
  toggleOpen: () => void;
  topPosition: number | null;
  onClose?: () => void;
};

const DEFAULT_HEADER_HEIGHT = 32;

const MobileMenu = ({
  id,
  closeMenuLabel,
  icon,
  iconLabel,
  onClose,
  open,
  toggleOpen,
  topPosition,
  renderContent,
}: MenuProps) => {
  useLockBodyScroll(open);

  const handleClose = () => {
    toggleOpen();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <>
      <IconButton
        id={id}
        aria-label={iconLabel}
        icon={icon}
        selected={open}
        size="medium"
        onClick={toggleOpen}
      />

      <StyledDialog
        show={open}
        topPosition={topPosition || DEFAULT_HEADER_HEIGHT}
      >
        <BackgroundOverlay show={open} onClick={handleClose} />

        <PanelContainer>
          <GridContainer>
            <FlexContainer>
              <StyledDialogPanel show={open}>
                <StyledContent data-testid="menu-content">
                  {renderContent(toggleOpen)}
                  <CloseButton
                    id={`${id}-close-button`}
                    aria-label={closeMenuLabel}
                    icon={Close}
                    onClick={handleClose}
                  />
                </StyledContent>
              </StyledDialogPanel>
            </FlexContainer>
          </GridContainer>
        </PanelContainer>
      </StyledDialog>
    </>
  );
};

export { MobileMenu };
