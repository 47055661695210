import { createContext, useContext } from 'react';

/** @todo: Integrate into `ClientContext` */
export const MetaInformationContext = createContext<any | undefined>(undefined);

export const useMetaInformationProps = () => {
  const context = useContext(MetaInformationContext) as any;

  if (context === undefined) {
    throw new Error(
      'useMetaIntormationProps must be used within a MetaInformationContext.Provider',
    );
  }

  return context;
};
