import React from 'react';

import {
  ContentLayout,
  NewsletterPageContent,
} from '@silvertours/common-landingpages-view';
import { BenefitsLegacy, StageLegacy } from '@silvertours/front-features';

import { Body } from './body';

const NewsletterContent = ({
  content,
  ...commonProps
}: ContentLayout<NewsletterPageContent>) => (
  <Body
    stage={<StageLegacy.NewsletterStage />}
    content={({ containerModule }) => [
      containerModule(
        'usp',
        <BenefitsLegacy.Usp translationKey="newsletterUsps" />,
      ),
    ]}
    {...commonProps}
  />
);

export { NewsletterContent };
