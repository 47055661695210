import { Translations } from '@silvertours/common-landingpages-i18n';
import { createContext, useContext } from 'react';

const I18nContext = createContext<Translations | undefined>(undefined);

const useTranslations = <T = Translations>() => {
  const context = useContext(I18nContext);

  if (context === undefined) {
    throw new Error(
      'useTranslations must be used within an I18nContext.Provider',
    );
  }

  return context as T;
};

export { I18nContext, useTranslations };
