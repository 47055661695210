import React from 'react';

import {
  NewsletterPageContent,
  ContentLayout,
} from '@silvertours/common-landingpages-view';

import { NewsletterContent } from './content';

const NewsletterPage = (props: ContentLayout<NewsletterPageContent>) => {
  const { content } = props;

  if (!content) {
    return null;
  }

  return <NewsletterContent {...props} />;
};

export { NewsletterPage };
