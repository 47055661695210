import {
  ContentLayout,
  ErrorPageContent,
} from '@silvertours/common-landingpages-view';
import { ErrorContent } from './content';

type PageProps = ContentLayout<ErrorPageContent>;

const ErrorPage = (props: PageProps) => <ErrorContent {...props} />;

export { ErrorPage };
export type { PageProps };
