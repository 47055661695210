import styled from '@emotion/styled';

export const Caption = styled.p`
  margin: 0 auto;
  max-width: 44rem;
  color: ${({ theme }) => theme.color.grey01};
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: center;

  button {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    color: inherit;
    background-color: transparent;
    font: inherit;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.color.text};
    }

    &:focus-visible {
      outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
      outline-offset: 0.25rem;
    }
  }
`;
