import { FC, ReactNode } from 'react';

type TextProps = {
  children: ReactNode;
  id?: string;
};

const withText =
  (TextComponent: FC<TextProps>): FC<TextProps> =>
  ({ children, id }: TextProps) => {
    if (!children) {
      return null;
    }

    return <TextComponent id={id}>{children}</TextComponent>;
  };

export { withText };
export type { TextProps };
