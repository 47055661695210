import Airport from '@engineering/icons/airport';
import CityStation from '@engineering/icons/city-station';
import TrainStation from '@engineering/icons/train-station';

import {
  AIRPORT_SHUTTLE_STATION_NAME,
  AIRPORT_STATION_NAME,
  AIRPORT_TERMINAL_STATION_NAME,
  RAIL_STATION_NAME,
} from './types';

export const getStationIcon = (stationKind: string) => {
  let StationIcon: typeof Airport | typeof CityStation | typeof TrainStation =
    CityStation;

  switch (stationKind) {
    case AIRPORT_STATION_NAME:
    case AIRPORT_SHUTTLE_STATION_NAME:
    case AIRPORT_TERMINAL_STATION_NAME:
      StationIcon = Airport;
      break;
    case RAIL_STATION_NAME:
      StationIcon = TrainStation;
      break;
    default:
      break;
  }

  return StationIcon;
};
