import { GridSetProps } from './GridSet';
import { GridImageLink } from './GridImageLink';

export const GridSetOf8 = ({ content: { set } }: GridSetProps) => {
  if (set.length === 0) {
    return null;
  }

  return (
    <>
      <GridImageLink
        content={set[0]}
        rules={{
          color: set[0].color,
          layout: [12, { xl: [4, 2] }],
          hidden: set[0].hidden,
        }}
      />
      <GridImageLink
        content={set[1]}
        rules={{
          color: set[1].color,
          layout: [6, { xl: 8 }],
          hidden: set[1].hidden,
        }}
      />
      <GridImageLink
        content={set[2]}
        rules={{
          color: set[2].color,
          layout: [6, { xl: 4 }],
          hidden: set[2].hidden,
        }}
      />
      <GridImageLink
        content={set[3]}
        rules={{
          color: set[3].color,
          layout: [6, { md: 4 }],
          hidden: set[3].hidden,
        }}
      />
      <GridImageLink
        content={set[4]}
        rules={{
          color: set[4].color,
          layout: [6, { md: 4, xl: 3 }],
          hidden: set[4].hidden,
        }}
      />
      <GridImageLink
        content={set[5]}
        rules={{
          color: set[5].color,
          layout: [12, { md: 4, xl: 3 }],
          hidden: set[5].hidden,
        }}
      />
      <GridImageLink
        content={set[6]}
        rules={{
          color: set[6].color,
          layout: [6, { md: 6, xl: 3 }],
          hidden: set[6].hidden,
        }}
      />
      <GridImageLink
        content={set[7]}
        rules={{
          color: set[7].color,
          layout: [6, { md: 6, xl: 3 }],
          hidden: set[7].hidden,
        }}
      />
    </>
  );
};
