import React from 'react';
import Close from '@engineering/icons/close';
import { ClientOnlyPortal } from './ClientOnlyPortal';
import { ForegroundLayerWrapper, CloseButton } from './ForegroundLayer.styles';

const ForegroundLayer: React.FC<
  React.PropsWithChildren<{
    id?: string;
    isShowing: boolean;
    onClose?: () => void;
  }>
> = ({ id, isShowing, children, onClose = () => null }) =>
  isShowing ? (
    <ClientOnlyPortal selector="#fglayer">
      <ForegroundLayerWrapper id={id}>
        <CloseButton
          id={`${id ? `${id}-close-button` : ''}`}
          icon={Close}
          title="close" // @TODO: Add translation
          onClick={onClose}
        />
        {children}
      </ForegroundLayerWrapper>
    </ClientOnlyPortal>
  ) : null;

export { ForegroundLayer };
