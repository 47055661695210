import { LandingPage } from '../../../entity';
import {
  PageClassificationStrategy,
  PageClassificationStrategyBase,
} from '../pageClassificationStrategy';
import { CatchAllClassificationRule } from './rule';

class CatchAllClassificationStrategy
  extends PageClassificationStrategyBase
  implements PageClassificationStrategy
{
  constructor({
    includeBreadcrumbs,
    pageType,
    pillars,
  }: CatchAllClassificationRule) {
    super(pageType, pillars, includeBreadcrumbs);

    this.classify = this.classify.bind(this);
    this.strategyApplies = this.strategyApplies.bind(this);
  }

  classify(_page: LandingPage) {
    return this.withCommonValues({});
  }

  strategyApplies(): boolean {
    return true;
  }
}

export { CatchAllClassificationStrategy };
