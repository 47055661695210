export const logoMap: Record<string, string> = {
  alamo: 'Alamo',
  arguscarhire: 'ArgusCarHire',
  autoeurope: 'Autoeurope',
  avis: 'Avis',
  buchbinder: 'Buchbinder',
  budget: 'Budget',
  dollar: 'Dollar',
  enterprise: 'Enterprise',
  europcar: 'Europcar',
  global: 'Global',
  hertz: 'Hertz',
  interrent: 'Interrent',
  'keddy by europcar': 'Keddy',
  national: 'National',
  recordgo: 'RecordGo',
  rentalcars: 'Rentalcars',
  sunnycars: 'Sunnycars',
  thrifty: 'Thrifty',
  wheego: 'Wheego',
};
