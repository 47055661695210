import { Logger } from '@silvertours/lib-core-logging';
import { LandingPage } from '../../entity';
import { CatchAllClassificationRule } from './catchAllClassification';
import { initializeStrategies } from './initializeStrategies';
import { PageClassification } from './pageClassification';
import { ClassificationRules } from './rules';

type ClassifyPage = (page: LandingPage) => PageClassification;

type PageClassifier = {
  classifyPage: ClassifyPage;
};

interface PageClassificationService {
  initializeRules(
    rulset: Array<ClassificationRules>,
    catchAllRule: CatchAllClassificationRule,
  ): PageClassifier;
}

const makePageClassificationService = (
  logger: Logger,
): PageClassificationService => ({
  initializeRules(ruleset, catchAllRule) {
    const strategies = initializeStrategies(ruleset, catchAllRule);

    logger.debug('Initialized the page classification strategies', {
      strategies,
    });

    return {
      classifyPage(page) {
        const strategy =
          strategies.find(({ strategyApplies }) => strategyApplies(page)) ||
          strategies[strategies.length - 1];

        return strategy.classify(page);
      },
    };
  },
});

export { makePageClassificationService };
export type { ClassifyPage, PageClassificationService };
