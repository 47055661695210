import React from 'react';

import { Breadcrumb } from '@silvertours/common-landingpages-view';
import { Ui } from '@silvertours/front-shared';
import { LayoutLegacy } from '@silvertours/front-legacy-entities';

import {
  BreadcrumbsContentContainer,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbText,
} from './Breadcrumbs.styles';

type BreadcrumbItemProps = {
  breadcrumb: Breadcrumb;
  isLast: boolean;
  index: number;
};

const BreadcrumbListItem = ({
  breadcrumb: { name, url },
  isLast,
  index,
}: BreadcrumbItemProps) => {
  const shouldRenderText = isLast || !url;

  return (
    <BreadcrumbItem
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
    >
      {shouldRenderText ? (
        <BreadcrumbText
          isLast={isLast}
          aria-current={isLast ? 'page' : undefined}
          itemProp={isLast ? 'name' : 'item'}
        >
          {isLast ? name : <span itemProp="name">{name}</span>}
        </BreadcrumbText>
      ) : (
        <Ui.TextLink href={url} itemProp="item">
          <span itemProp="name">{name}</span>
        </Ui.TextLink>
      )}
      <meta itemProp="position" content={String(index + 1)} />
    </BreadcrumbItem>
  );
};

type BreadcrumbsProps = {
  breadcrumbs: Array<Breadcrumb> | null;
};

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return null;
  }

  const lastIndex = breadcrumbs.length - 1;

  return (
    <LayoutLegacy.PlainSegment id="breadcrumbs" hasPadding spacingScale={0.5}>
      <BreadcrumbsContentContainer>
        <BreadcrumbList itemScope itemType="https://schema.org/BreadcrumbList">
          {breadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbListItem
              key={breadcrumb.name}
              isLast={index === lastIndex}
              breadcrumb={breadcrumb}
              index={index}
            />
          ))}
        </BreadcrumbList>
      </BreadcrumbsContentContainer>
    </LayoutLegacy.PlainSegment>
  );
};

export { Breadcrumbs };
export type { BreadcrumbsProps };
