import { PropsWithChildren } from 'react';

import {
  HasBackgroundStyle,
  HasContentModuleContainerSpacingRules,
  HasLayoutFlow,
} from './rule';

import { Container } from './Container.styles';

export type PlainContentModuleContainerRules = HasBackgroundStyle &
  HasLayoutFlow &
  HasContentModuleContainerSpacingRules;

export type PlainContentModuleContainerProps = PropsWithChildren & {
  rules?: PlainContentModuleContainerRules;
  className?: string;
};

export const PlainContentModuleContainer = ({
  children,
  rules,
  className,
}: PlainContentModuleContainerProps) => {
  const {
    backgroundStyle = 'default',
    layoutFlow = 'right',
    verticalSpacing,
  } = rules || {};
  const { isLarge } = verticalSpacing || {};

  return (
    <Container
      className={className}
      isLarge={isLarge}
      layoutFlow={layoutFlow}
      backgroundStyle={backgroundStyle}
    >
      {children}
    </Container>
  );
};
