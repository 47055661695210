import { css } from '@emotion/react';
import { Theme } from '@silvertours/front-shared';

const HeaderIconStyles = ({ theme }: { theme: Theme.Theme }) => css`
  fill: ${theme.color.brand};
  width: ${theme.spacing[50]};
  height: ${theme.spacing[50]};
`;

export { HeaderIconStyles };
