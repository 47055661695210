import React from 'react';

const useClickInside = (
  ref: React.MutableRefObject<null | HTMLDivElement>,
  callback: (target: EventTarget | null) => void,
) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && ref.current.contains(e.target as Node)) {
      callback(e.target);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export { useClickInside };
