import { RefObject, useEffect, useState } from 'react';

export const HEIGHT_LIMIT = 1500;

export const useCollapse = <TElement extends Element>(
  allowCollapse: boolean,
  ref: RefObject<TElement>,
) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [canBeCollapsed, setCanBeCollapsed] = useState(false);

  useEffect(() => {
    if (ref.current && allowCollapse) {
      // collapse the whole content if the height of the ref wrapping div is higher than the set limit
      setIsCollapsed(ref.current.clientHeight >= HEIGHT_LIMIT);
    } else {
      setIsCollapsed(false);
    }
  }, [ref, allowCollapse]);

  useEffect(() => {
    if (ref.current && ref.current.clientHeight > HEIGHT_LIMIT) {
      setCanBeCollapsed(true);
    }
  }, [ref]);

  return { canBeCollapsed, isCollapsed, setIsCollapsed };
};
