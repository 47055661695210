import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const fixed5 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 24">
        <path d="M375 0H0v6.036c33.107 23.561 91.287 17.98 152.172 12.14L154 18c71.588-6.864 149-18 221 5V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 78">
        <path d="M1440 0H0v20.47c131 32.51 331.216 54.093 566.062 23.28C836.471 8.27 1149.35-32.781 1440 78V0Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 41">
        <path d="M0 4.892V41h375V0c-27.142 28.107-122.411 24.119-179 16C128.157 6.267 116.384.36 26.719 3.866 18.521 4.186 9.65 4.533 0 4.892Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 125">
        <path d="M0 14.915V125h1440V0c-104.22 85.69-415.21 141.681-708.823 65.187C481.111.04 446.915 1.1 102.6 11.787h-.003C71.123 12.762 37.058 13.82 0 14.914Z" />
      </WaveSvg>
    ),
  },
  height: {
    small: 580,
    large: 700,
  },
} as WaveSet;

export { fixed5 };
