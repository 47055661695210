export { PageAttributesOrganizer } from './PageAttributes';
export { PageAttributeType } from './PageAttributeRefForType';

export type {
  OrderedPageAttributes,
  PageAttribute,
  PageAttributes,
} from './PageAttributes';

export type { AirportPageAttribute } from './airport';
export type { BookingAttributePageAttribute } from './bookingAttribute';
export type { CategoryPageAttribute } from './category';
export type { CityPageAttribute } from './city';
export type { CityDistrictPageAttribute } from './cityDistrict';
export type { CountryPageAttribute } from './country';
export type { IslandPageAttribute } from './island';
export type { PartnerPageAttribute } from './partner';
export type { ProductPageAttribute } from './product';
export type { RailStationPageAttribute } from './railStation';
export type { StatePageAttribute } from './state';
