import { Ui } from '@silvertours/front-shared';
import { ReviewsWrapper } from './ReviewCard.styles';
import { Ratings, RatingChartTranslations } from './types';

type ReviewChartProps = {
  ratings: Ratings;
  translations: RatingChartTranslations;
  className?: string;
};

export const ReviewsChart = ({
  ratings,
  translations,
  className,
}: ReviewChartProps) => (
  <ReviewsWrapper className={className}>
    <Ui.RatingBar
      value={ratings.carConditionRating.value}
      label={translations.carConditionRating}
      id="carConditionRating"
    />
    <Ui.RatingBar
      value={ratings.processingSpeedRating.value}
      label={translations.processingSpeedRating}
      id="processingSpeedRating"
    />
    <Ui.RatingBar
      value={ratings.serviceLevelRating.value}
      label={translations.serviceLevelRating}
      id="serviceLevelRating"
    />
  </ReviewsWrapper>
);
