import styled from '@emotion/styled';
import { shade } from 'polished';

const MarkupContainer = styled.div`
  box-sizing: border-box;

  p {
    font-size: 0.875rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.color.text};
    margin-bottom: 1rem;

    ${({ theme }) => theme.mq('lg')} {
      font-size: 1rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ol {
    font-size: 0.875rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.color.text};
    margin-bottom: 1rem;

    ${({ theme }) => theme.mq('lg')} {
      font-size: 1rem;
    }
  }

  a {
    &:link,
    &:visited {
      color: ${({ theme }) => theme.color.brand};
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.color.brand};
      text-decoration: none;
    }

    &:not([href]) {
      &:hover,
      &:focus,
      &:active {
        color: inherit;
        text-decoration: inherit;
      }
    }
  }

  h1 {
    color: ${({ theme }) => theme.color.text};
    margin: 0 0 1.25rem;
    font-size: 1.875rem;
    line-height: 1.875rem;
  }

  h2 {
    color: ${({ theme }) => theme.color.text};
    margin: 0 0 1rem;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.125;
  }

  h3 {
    color: ${({ theme }) => theme.color.text};
    margin: 1.5rem 0 1rem;
    font-size: 1.125rem;
    font-weight: normal;
  }

  ul {
    list-style-position: outside;
    list-style-type: disc;
    padding-left: 1.25rem;
    color: ${({ theme }) => theme.color.text};
    line-height: 1.5;

    &.three-row,
    &.two-row {
      overflow: hidden;
    }

    &.three-row li,
    &.two-row li {
      float: left;
    }

    &.three-row li {
      width: 33.333%;
    }

    &.two-row li {
      width: 50%;
      padding-left: 0.625rem;
    }

    &.places-pin {
      list-style: none;
    }

    &.places-pin > li::before {
      content: 'V';
      color: ${({ theme }) => shade(0.271, theme.color.brand!)};
      font-family: bmfont, serif;
      font-size: 1.125rem;
      margin-right: 0.5rem;
    }

    ${({ theme }) => theme.mq('lg')} {
      font-size: 1rem;
    }
  }

  /* native collapsibles using <details> / <summary> */
  details {
    border-top: 1px solid ${({ theme }) => theme.color.grey03};

    &:last-of-type:not(:last-child) {
      margin-bottom: 1.25rem;
      border-bottom: 1px solid ${({ theme }) => theme.color.grey03};
    }

    > summary:first-of-type {
      list-style-type: none;
    }

    summary {
      position: relative;
      margin-bottom: 1.25rem;
      padding-right: 2.225rem;
      min-height: 1.6rem; /* if no content is provided, at least keep it the same height as the icon */
      cursor: pointer;

      &:focus:not(:focus-visible) {
        outline: 0;
      }

      &::marker,
      &::-webkit-details-marker {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1.6rem;
        height: 1.6rem;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAyNSI+PHBhdGggZD0iTTE5Ljg1OSA3Ljc1M2wtNy4zNiA3LjMxLTcuMzU4LTcuMzA5YS44NzEuODcxIDAgMCAwLTEuMjI2IDAgLjg1Ny44NTcgMCAwIDAgMCAxLjIxOEwxMi41IDE3LjVsNS4xMjgtNS4wOTMgMy40NTgtMy40MzVhLjg1OC44NTggMCAwIDAgMC0xLjIxOC44NjguODY4IDAgMCAwLTEuMjI3IDB6Ii8+PC9zdmc+Cg==');
        transition: 0.2s linear transform;
      }
    }

    &[open] summary {
      &::after {
        transform: scaleY(-1);
      }
    }

    p {
      color: ${({ theme }) => theme.color.text};
      margin-bottom: 0.625rem;
    }
  }

  .tip-box {
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;

    & > strong {
      background-color: ${({ theme }) => theme.color.brand};
      display: block;
      width: 100%;
      padding: 0.5rem 0.625rem;
      color: ${({ theme }) => theme.color.white};
      position: relative;
    }

    & > strong::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-top: solid 10px ${({ theme }) => theme.color.brand};
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }

    .content {
      padding: 0.625rem;
      border: 1px solid ${({ theme }) => theme.color.grey03};
      border-top: none;
    }
  }

  & table {
    width: 100%;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1rem;
    line-height: 1.5rem;

    td {
      padding: 0.625rem !important;
      border: none !important;
    }

    tbody > tr:first-of-type td,
    thead tr th {
      background-color: ${({ theme }) => theme.color.brand} !important;
      color: ${({ theme }) => theme.color.white} !important;
      border: none !important;
    }

    thead + tbody > tr:first-of-type td {
      color: ${({ theme }) => theme.color.text} !important;
      background-color: ${({ theme }) => theme.color.grey05} !important;
    }

    tr:nth-of-type(odd),
    tr:nth-of-type(odd) td {
      background-color: ${({ theme }) => theme.color.grey05} !important;
    }

    tr:nth-of-type(even),
    tr:nth-of-type(even) td {
      background-color: ${({ theme }) => theme.color.white} !important;
      border: none !important;
    }
  }
`;

export { MarkupContainer };
