import type { ReactNode, SelectHTMLAttributes } from 'react';
import {
  Hint,
  DropdownWrapper,
  IconWrapper,
  Select,
  ArrowIcon,
  Label,
  ArrowIconWrapper,
} from './Dropdown.styles';

export interface DropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
  design?: 'compact' | 'default';
  hint?: string;
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  icon?: ReactNode;
  title?: string;
}

const Dropdown = ({
  label,
  options,
  icon,
  title,
  name,
  id,
  disabled,
  hint,
  design = 'default',
  ...props
}: DropdownProps) => {
  // handle controlled vs. uncontrolled components when a `value` prop is set
  const additionalProps = { ...props };
  if (additionalProps.value && additionalProps.defaultValue) {
    delete additionalProps.defaultValue;
  }

  const isCompact = design === 'compact';
  const isDisabled = !!disabled;

  const uniqueOptions = options.filter(
    (option, index, self) =>
      self.findIndex(
        o => o.value === option.value && o.label === option.label,
      ) === index,
  );

  return (
    <>
      <DropdownWrapper>
        <Label htmlFor={id} isCompact={isCompact}>
          {label}
        </Label>
        {icon && (
          <IconWrapper
            isDisabled={isDisabled}
            isCompact={isCompact}
            aria-hidden="true"
          >
            {icon}
          </IconWrapper>
        )}
        <Select
          {...additionalProps}
          name={name ?? id}
          id={id}
          disabled={isDisabled}
          title={label}
          icon={icon}
          isCompact={isCompact}
          aria-describedby={hint ? `${id}-hint` : undefined}
        >
          {title && (
            <option key={-1} disabled value={-1}>
              {title}
            </option>
          )}
          {uniqueOptions.map(({ label: text, value }) => (
            <option
              id={`${id ?? 'dropdown'}_${value}`}
              key={`${id ?? 'dropdown'}_${value}`}
              value={value}
            >
              {text}
            </option>
          ))}
        </Select>
        <ArrowIconWrapper
          isDisabled={isDisabled}
          isCompact={isCompact}
          aria-hidden="true"
        >
          <ArrowIcon />
        </ArrowIconWrapper>
      </DropdownWrapper>
      {hint && <Hint id={`${id}-hint`}>{hint}</Hint>}
    </>
  );
};

export { Dropdown };
