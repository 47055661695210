import { PropsWithChildren } from 'react';

import { Ui } from '@silvertours/front-shared';

import {
  HasBackgroundStyle,
  HasContentModuleContainerSpacingRules,
  HasLayoutFlow,
} from './rule';

import { WaveContainer } from './Container.styles';

export type WaveContentModuleContainerRules = HasBackgroundStyle &
  HasLayoutFlow &
  HasContentModuleContainerSpacingRules;

export type WaveContentModuleContainerProps = PropsWithChildren & {
  rules?: WaveContentModuleContainerRules;
};

export const WaveContentModuleContainer = ({
  children,
  rules,
}: WaveContentModuleContainerProps) => {
  const {
    backgroundStyle = 'default',
    layoutFlow = 'right',
    verticalSpacing,
  } = rules || {};
  const { isLarge, spacingDirection } = verticalSpacing || {};

  return (
    <Ui.Wave
      type={spacingDirection ? 'scalable4' : 'scalable1'}
      fullWidth
      backgroundStyle={backgroundStyle}
    >
      <WaveContainer
        isLarge={isLarge}
        layoutFlow={layoutFlow}
        backgroundStyle={backgroundStyle}
        spacingDirection={spacingDirection}
      >
        {children}
      </WaveContainer>
    </Ui.Wave>
  );
};
