import { useState, useEffect } from 'react';
import { checkConsent, CookieConsent } from './consentManagement';

const useConsent = () => {
  const [consent, setConsent] = useState(CookieConsent.Unknown);

  const handleConsentChange = () => {
    setConsent(checkConsent());
  };

  useEffect(() => {
    if (consent === CookieConsent.Unknown) {
      setConsent(checkConsent());
    }
    document.addEventListener('UC:consentChange', handleConsentChange);

    return () => {
      document.removeEventListener('UC:consentChange', handleConsentChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return consent;
};

export { useConsent };
