import styled from '@emotion/styled';
import Menu from '@engineering/icons/menu';
import User from '@engineering/icons/user';
import { HeaderIconStyles } from '@silvertours/front-entities';

const Header = styled.header`
  height: ${({ theme }) => theme.spacing[70]};
  position: relative;
  z-index: 30;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mq('lg')} {
    height: ${({ theme }) => theme.spacing[100]};
    z-index: auto;
  }
`;

const MenuIcon = styled(Menu)`
  ${HeaderIconStyles};
  /* For accessibility reasons, the menu icon should be slightly bigger. */
  width: ${({ theme }) => theme.spacing[60]};
  height: ${({ theme }) => theme.spacing[60]};
`;

const Separator = styled.hr`
  height: ${({ theme }) => theme.spacing[60]};
  width: 2px;
  flex: none;
  border: 0;
  background-color: #5e6e6a;
  display: block;
  margin-right: ${({ theme }) => theme.spacing[50]};
  align-items: center;
`;

const UserIcon = styled(User)`
  ${HeaderIconStyles}
`;

const IconButtonContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.brand};
`;

const ActionArea = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacing[50]};
  justify-content: flex-end;
`;

const DesktopActions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[50]};
`;

const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing[50]};
`;

export {
  ActionArea,
  ActionButtons,
  DesktopActions,
  Header,
  IconButtonContainer,
  MenuIcon,
  Separator,
  UserIcon,
};
