import styled from '@emotion/styled';
import {
  ImageWithFocalPoint,
  Theme,
  Subtitle as SubtitleComponent,
} from '@silvertours/front-shared';
import Image from 'next/image';
import { transparentize } from 'polished';
import { SerializedStyles, css } from '@emotion/react';
import { GridConfiguration, GridLayout } from '../types';

export const LinkGridContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: ${({ theme }) => theme.spacing[40]};

  ${({ theme }) => theme.mq('lg')} {
    gap: ${({ theme }) => theme.spacing[50]};
  }
`;

const getLayoutProperty = (value: GridConfiguration): SerializedStyles => {
  if (typeof value === 'number') {
    // Only column value provided
    return css`
      grid-column: span ${value} / span ${value};
    `;
  }

  const [column, row] = value;
  return css`
    grid-column: span ${column} / span ${column};
    grid-row: span ${row} / span ${row};
  `;
};

const makeLayout = (
  [baseValue, responsive]: GridLayout,
  theme: Theme.Theme,
): SerializedStyles => css`
  ${getLayoutProperty(baseValue)};

  ${responsive &&
  Object.entries(responsive).map(
    ([breakpoint, value]) => css`
      ${theme.mq(breakpoint)} {
        ${getLayoutProperty(value)}
      }
    `,
  )}
`;

export const Anchor = styled.a<{
  bgColor: Theme.Colors;
  layout: GridLayout;
  hidden: boolean;
}>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: flex-end;
  position: relative;
  height: 100%;
  min-height: 164px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ bgColor, theme }) => theme.color[bgColor]};
  text-decoration: none;

  ${({ layout, theme }) => makeLayout(layout, theme)};

  ${({ theme }) => theme.mq('md')} {
    min-height: 200px;
  }
  ${({ theme }) => theme.mq('xl')} {
    min-height: 250px;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  transition: transform 0.3s ease;
  object-fit: cover;

  ${Anchor}:hover & {
    transform: scale(1.1);
  }
`;

export const StyledImageWithFocalPoint = styled(ImageWithFocalPoint as any)`
  width: 100%;
  transition: transform 0.3s ease;

  ${Anchor}:hover & {
    transform: scale(1.1);
  }
`;

export const GradientBackground = styled.span`
  display: inline-block;
  width: 100%;
  z-index: 10;
  background-image: ${({ theme }) =>
    theme.linearGradient(
      {
        from: transparentize(0.5, theme.color.black || '#000'),
        to: transparentize(1, theme.color.black! || '#000'),
      },
      'to top',
    )};
`;

export const Container = styled.div`
  position: relative;
`;

export const Subtitle = styled(SubtitleComponent)`
  display: block;
  margin-left: ${({ theme }) => theme.spacing[60]};
  margin-bottom: ${({ theme }) => theme.spacing[60]};
  word-break: break-word;
  padding-top: ${({ theme }) => theme.spacing[60]};
  transition: transform 0.3s ease;

  /* Styles on hover */
  ${Anchor}:hover & {
    transform: translateY(-20px); /* Translate on hover */
  }
`;
