import { Badge as BadgeView } from '@silvertours/common-landingpages-view';

import { HasBadgeRules, HasBadgeSpacingRules } from './rule';
import { StyledBadge } from './Badge.styles';

export type BadgeRules = HasBadgeRules & HasBadgeSpacingRules;

export type BadgeProps = {
  content?: BadgeView;
  rules: BadgeRules;
};

export const Badge = ({
  content,
  rules: { badge: badgeRules, verticalSpacing },
}: BadgeProps) => {
  const { shouldHide = false, style = 'default' } = badgeRules || {};

  if (shouldHide) {
    return null;
  }

  return (
    <StyledBadge
      text={content?.text}
      type={style}
      isLargeAtMD={verticalSpacing?.isLargeAtMD}
    />
  );
};
