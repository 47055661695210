import styled from '@emotion/styled';
import ArrowNext from '@engineering/icons/arrow-next';
import { Button, Ui } from '@silvertours/front-shared';
import { lighten, desaturate } from 'polished';

const StyledArrowNext = styled(ArrowNext)`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
`;

const CallToAction = styled(Button)`
  grid-area: arrow;
  justify-self: end;
  flex-grow: 0;
  padding: 0;
  border-left: 1px solid ${({ theme }) => theme.color.grey04};
  border-radius: 0;
  width: 2.75rem;
  height: inherit;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.brand};
  fill: currentcolor;
`;

const Wrapper = styled.article<{ hasAction: boolean }>`
  display: grid;
  grid-template-areas:
    'category category price price'
    'image image image image'
    'caption caption caption caption'
    'attributes attributes attributes attributes'
    'stations stations stations arrow';
  height: 100%;
  border: 1px solid ${({ theme }) => theme.color.grey04};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.color.white};
  font-size: 0.75rem;
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : '')};
  overflow: hidden;

  &:hover ${CallToAction} {
    background-color: ${({ theme }) =>
      desaturate(0.3, lighten(0.11, theme.color.brand!))};
  }
`;

const Price = styled.data`
  grid-area: price;
  place-self: center end;
  margin-top: ${({ theme }) => theme.spacing[40]};
  margin-right: ${({ theme }) => theme.spacing[40]};
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
`;

const PriceValue = styled.span`
  font-size: 1.25rem;
`;

const PriceSuffix = styled.span`
  font-size: 0.875rem;
  font-weight: normal;
`;

const CarCategory = styled.header`
  grid-area: category;
  align-self: center;
  margin-top: ${({ theme }) => theme.spacing[40]};
  margin-left: ${({ theme }) => theme.spacing[40]};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: 700;
  text-transform: uppercase;
`;

const Stations = styled.div`
  grid-area: stations;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[20]};
  padding: ${({ theme }) => `${theme.spacing[30]} ${theme.spacing[40]}`};
  font-size: 0.875rem;
`;

const Sample = styled.figure`
  grid-area: image;
  position: relative;
  margin: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[40]}`};
  height: 102px;
`;

const Image = styled(Ui.Image)`
  object-fit: contain;
`;

const Caption = styled.div`
  grid-area: caption;
  margin: ${({ theme }) =>
    `${theme.spacing[5]} ${theme.spacing[40]} ${theme.spacing[40]}`};
  text-align: center;
`;

const CarAttributes = styled.ul`
  grid-area: attributes;
  display: grid;
  grid-template-columns: repeat(auto-fit, 3.5rem);
  justify-content: center;
  padding: ${({ theme }) =>
    `${theme.spacing[0]} ${theme.spacing[40]} ${theme.spacing[30]}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey04};
`;

const CarAttribute = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[10]};
`;

const StyledIcon = styled.div`
  width: 1.125rem;
  height: 1.125rem;
  fill: currentcolor;
`;

export {
  Wrapper,
  Sample,
  Image,
  Caption,
  CarCategory,
  CarAttributes,
  CarAttribute,
  StyledIcon,
  Stations,
  Price,
  PriceValue,
  PriceSuffix,
  CallToAction,
  StyledArrowNext,
};
