import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Ui } from '@silvertours/front-shared';
import { PlainContentModuleContainer } from '@silvertours/front-entities';

const StyledPlainContentModuleContainer = styled(PlainContentModuleContainer)`
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
`;

const ReviewText = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
  gap: ${({ theme }) => theme.spacing[50]};
  margin-bottom: ${({ theme }) => theme.spacing[60]};

  ${({ theme }) => theme.mq('lg')} {
    grid-template-columns: 1fr 447px;
  }

  ${({ theme }) => theme.mq('xl')} {
    gap: ${({ theme }) => theme.spacing[150]};
  }
`;

const StyledAdditionalInfo = styled(Ui.AdditionalInfo)`
  max-width: 447px;

  ${({ theme }) => theme.mq('lg')} {
    max-width: none;
  }
`;

const ReviewContainer = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
      width: 100vw;
    `}

  ${({ theme }) => theme.mq('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[50]};
  }

  margin-top: -223px;
  transform: translateY(1.75rem);

  ${({ theme }) => theme.mq('md')} {
    transform: translateY(2.25rem);
  }

  ${({ theme }) => theme.mq('lg')} {
    transform: translateY(0.75rem);
  }

  nav {
    margin-right: ${({ theme }) => theme.spacing[40]};
  }
`;

const ReviewList = styled.div<{ amount: number }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing[40]};
  width: 100%;

  ${({ theme }) => theme.mq('md')} {
    grid-template-columns: ${({ amount }) =>
      `repeat(${Math.min(amount, 2)}, 1fr)`};
    gap: ${({ theme }) => theme.spacing[50]};
  }

  ${({ theme }) => theme.mq('xl')} {
    grid-template-columns: ${({ amount }) =>
      `repeat(${Math.min(amount, 3)}, 1fr)`};
  }
`;

const ReviewCarousel = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  ${({ theme }) => theme.mq('sm')} {
    padding-left: 2rem;
    padding-right: 2rem;

    & > * {
      width: 360px;
    }
  }

  display: flex;
  gap: ${({ theme }) => theme.spacing[50]};

  & > * {
    width: 313px;
  }
`;

export {
  ReviewContainer,
  ReviewList,
  ReviewText,
  StyledAdditionalInfo,
  StyledPlainContentModuleContainer,
  ReviewCarousel,
};
