import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import type { Type } from '@silvertours/front-shared';

export type TextColor =
  | 'green'
  | 'grey'
  | 'default'
  | 'white'
  | 'orange'
  | 'error'
  | 'current';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  /**
   * Typography polymorphic prop
   */
  as: Type.HeadlineLevel | 'p' | 'article' | 'span' | 'div';
  /** Typography style */
  variant?: 'none' | 'h1' | 'h2' | 'h3' | 'subtitle' | 'body';
  color?: TextColor;
}

const Typography = ({
  children,
  className,
  as,
  variant,
  color = 'default',
  ...props
}: TypographyProps) => {
  const style =
    variant ?? (as === 'h1' || as === 'h2' || as === 'h3' ? as : 'default');

  const VARIANT_CLASSES = {
    none: '',
    h1: 'lg:text-[2.5rem] lg:leading-[1.188em] font-quicksand font-bold text-[2.125rem] leading-[1.235em]',
    h2: 'lg:text-[2.375rem] lg:leading-[1.25em] font-quicksand font-bold text-[1.688rem] leading-[1.259em]',
    h3: 'lg:text-[1.75rem] lg:leading-[1.4em] font-quicksand font-bold text-[1.375rem] leading-[1.273em]',
    subtitle:
      'lg:text-[1.5rem] lg:leading-[1.3em] text-[1.313rem] leading-[1.238em] font-semibold',
    default:
      'lg:text-[1.125rem] lg:leading-[1.333em] text-[1.063rem] leading-[1.412em] font-semibold',
    body: 'lg:text-[1.125rem] lg:leading-[1.778em] font-normal text-[1.063rem] leading-[1.588em]',
  }[style];

  const COLOR_CLASSES = {
    green: 'text-color1',
    grey: 'text-color7',
    default: 'text-primary',
    white: 'text-white',
    orange: 'text-color3',
    error: 'text-error',
    current: 'text-current',
  }[color];

  const Component = as;

  return (
    <Component
      {...props}
      className={clsx(VARIANT_CLASSES, COLOR_CLASSES, className)}
    >
      {children}
    </Component>
  );
};

export { Typography };
