import { LandingPage, PageAttribute, PageAttributeType } from '../../entity';
import { makeAttributePicker } from './pickAttribute';

type AttributeContentLoader = (
  slug: string,
  attribute: PageAttribute | undefined,
) => Promise<LandingPage | undefined>;

const makeAttributePagesLoader =
  (loadAttributePageContent: AttributeContentLoader) =>
  async (slug: string, primaryAttribute: PageAttribute) => {
    /**
     * We'll use the primary associated attribute as our fallback when finding
     * other anscestor entities.
     */
    const tryPickAttribute = makeAttributePicker(primaryAttribute);

    /**
     * Walk up the associated attribute tree to find the correct ancestor pages.
     *
     * @remarks
     * If the provided attribute doesn't have the correct association, we'll also
     * check the fallback.
     */
    const cityDistrict = tryPickAttribute(PageAttributeType.CityDistrict);
    const city = tryPickAttribute(PageAttributeType.City, cityDistrict);
    const state = tryPickAttribute(PageAttributeType.State, city);
    const country = tryPickAttribute(PageAttributeType.Country, state);

    /**
     * For each of the ancestor attibutes located above, we'll try to load a
     * page but there may be none available or could be gone (i.e. status
     * 404/410).
     */
    return {
      attributes: { city, cityDistrict, country, state },
      pages: await Promise.all([
        loadAttributePageContent(slug, primaryAttribute),
        loadAttributePageContent(slug, cityDistrict),
        loadAttributePageContent(slug, city),
        loadAttributePageContent(slug, state),
        loadAttributePageContent(slug, country),
      ]),
    };
  };

export { makeAttributePagesLoader };
