import { useState, useEffect } from 'react';

import type { Type } from '@silvertours/front-shared';

export enum Services {
  trustpilot = 'qEs4t49Rg',
  googlemaps = 'S1pcEj_jZX',
}

export type ServiceIds = Type.ValueOf<typeof Services>;

type Service = {
  history: Record<string, unknown>[];
  id: string;
  processorId: string;
  status: boolean;
};

const checkConsent = (serviceId: ServiceIds): boolean => {
  const ucSettings = localStorage.getItem('uc_settings');

  if (ucSettings) {
    try {
      const { services } = JSON.parse(ucSettings);
      const service = (services as Service[]).find(
        ({ id }) => serviceId === id,
      );

      return service ? service.status : false;
    } catch {
      return false;
    }
  }

  return false;
};

export const useConsent = (serviceId: ServiceIds) => {
  const [consent, setConsent] = useState(false);

  const handleConsentChange = () => {
    setConsent(checkConsent(serviceId));
  };

  useEffect(() => {
    document.addEventListener('UC:consentChange', handleConsentChange);

    handleConsentChange();

    return () => {
      document.removeEventListener('UC:consentChange', handleConsentChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return consent;
};
