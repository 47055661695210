/* eslint-disable react/destructuring-assignment */
import {
  HeaderContainer,
  NavigationContainer,
  SegmentContainer,
  SegmentForegroundContainer,
} from './Segment.styles';
import {
  BasicSegmentProps,
  ConfigurableSegmentProps,
  SegmentOptions,
  SegmentType,
} from './props';
import { SegmentImageBackground } from './SegmentImageBackground';

const isBasicSegment = (
  props: ConfigurableSegmentProps,
): props is BasicSegmentProps =>
  !Object.prototype.hasOwnProperty.call(props, 'image');

const selectContainer = (segmentType: SegmentType) => {
  switch (segmentType) {
    case 'header':
      return HeaderContainer;
    case 'nav':
      return NavigationContainer;
    case 'segment':
    default:
      return SegmentContainer;
  }
};

const DEFAULT_OPTIONS: SegmentOptions = {
  hasPadding: false,
  segmentType: 'segment',
  spacingScale: 1,
};

const processOptions = (props: ConfigurableSegmentProps): SegmentOptions => {
  if (isBasicSegment(props)) {
    return {
      ...props,
      hasPadding: Boolean(props.hasPadding),
      segmentType: props.segmentType || DEFAULT_OPTIONS.segmentType,
      spacingScale: props.spacingScale || DEFAULT_OPTIONS.spacingScale,
    };
  }

  return DEFAULT_OPTIONS;
};

const Segment = (props: ConfigurableSegmentProps) => {
  const { fillColor, hasPadding, segmentType, spacingScale } =
    processOptions(props);

  const Container = selectContainer(segmentType);

  return (
    <Container
      fillColor={fillColor}
      hasPadding={hasPadding}
      id={props.id}
      spacingScale={spacingScale}
      needLayoutContentPadding={
        isBasicSegment(props) || !props.isStage || props.includeSearch
      }
    >
      <SegmentImageBackground {...props} />
      <SegmentForegroundContainer>{props.children}</SegmentForegroundContainer>
    </Container>
  );
};

export { Segment };
