import dynamic from 'next/dynamic';

import { ResponsiveLogo } from '@silvertours/front-entities';
import {
  LogoList,
  MgpWrapper,
  CarigamiWrapper,
} from './CarigamiGarantie.styles';

const Garantie = dynamic(() =>
  import('./Garantie').then(module => module.Garantie),
);

const CarigamiGarantie = () => (
  <LogoList>
    <MgpWrapper key="gmp">
      <Garantie />
    </MgpWrapper>
    <CarigamiWrapper key="carigami">
      <ResponsiveLogo />
    </CarigamiWrapper>
  </LogoList>
);
export { CarigamiGarantie };
