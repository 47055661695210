import { useContext } from 'react';

import { clientContext } from './clientContext';

type ContextResultType<TType extends boolean> = {
  hasContext: TType;
};

type ContextResultOfType<
  TType extends boolean,
  TContext extends Record<string, unknown> | undefined = undefined,
> =
  TContext extends Record<string, unknown>
    ? ContextResultType<TType> & TContext
    : ContextResultType<TType>;

type SuccessResult<TContext extends Record<string, unknown>> =
  ContextResultOfType<
    true,
    {
      value: TContext;
    }
  >;

type NotFoundResult = ContextResultOfType<false>;

type ContextResult<TContext extends Record<string, unknown>> =
  | SuccessResult<TContext>
  | NotFoundResult;

const useClientContext = <TContext extends Record<string, unknown>>(
  key: string,
): ContextResult<TContext> => {
  const context = useContext(clientContext);

  if (!Object.prototype.hasOwnProperty.call(context, key)) {
    return { hasContext: false };
  }

  return { hasContext: true, value: context[key] as TContext };
};

export { useClientContext };
export type { ContextResult, SuccessResult };
