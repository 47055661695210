import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

type StyledButtonProps = {
  size?: 'small';
};

export const Button = styled.button<StyledButtonProps>`
  display: inline-block;
  margin: 0;
  padding: 0.625rem 1rem;
  border: 1px solid ${({ theme }) => theme.color.grey03};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  width: 100%;
  height: ${({ size }) => (size === 'small' ? '2.5rem' : '3.125rem')};
  font-size: ${({ size }) => (size === 'small' ? '0.75rem' : '1rem')};
  font-family: inherit;
  line-height: ${({ size }) => (size === 'small' ? 1 : 1.5)};
  text-transform: capitalize;
  text-align: left;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.color.grey01};
  }

  &:focus-visible {
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }

  & + svg {
    position: absolute;
    right: 1rem;
    top: ${({ size }) => (size === 'small' ? '0.5rem' : '0.75rem')};
    width: 1.6rem;
    height: 1.6rem;
    fill: ${({ theme }) => theme.color.grey03};
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    pointer-events: none;
  }
`;
