enum CookieConsent {
  Unknown,
  Disabled,
  Requested,
  Enabled,
}

const checkConsent = (): CookieConsent => {
  try {
    const ucUserInteraction = window.localStorage.getItem(
      'uc_user_interaction',
    );

    if (ucUserInteraction === 'true') {
      return CookieConsent.Enabled;
    }
    if (ucUserInteraction === null) {
      return CookieConsent.Requested;
    }
    return CookieConsent.Disabled;
  } catch (err) {
    // Catch `Usercentrics: Cross Domain Consent Sharing is not available.`
    return CookieConsent.Disabled;
  }
};

export { checkConsent, CookieConsent };
