type WithId<TIdentity, TEntityData> = TEntityData & { id: TIdentity };

type EntityMemento<TIdentity, TEntityMemento = {}> =
  | WithId<TIdentity, TEntityMemento>
  | TEntityMemento;

const isExistingEntity = <TIdentity, TEntityData>(
  data: EntityMemento<TIdentity, TEntityData>,
): data is WithId<TIdentity, TEntityData> => {
  return Object.prototype.hasOwnProperty.call(data, `id`);
};

abstract class Entity<TIdentity, TMemento = {}> {
  protected constructor(
    protected entityName: string,
    protected memento: EntityMemento<TIdentity, TMemento>,
  ) {}

  get id() {
    return isExistingEntity(this.memento) ? this.memento.id : null;
  }

  get idRequired() {
    if (!isExistingEntity(this.memento)) {
      throw new Error(
        `The ${this.entityName} entity's ID is required, but it is missing`,
      );
    }

    return this.memento.id;
  }
}

export { Entity };
export type { EntityMemento, WithId };
