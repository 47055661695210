import { Ui } from '@silvertours/front-shared';
import { LoginForm } from './LoginForm';
import { useLogin } from './LoginState';
import { Footer } from './Footer';

export const LoginModal = () => {
  const { showLogin, setShowLogin } = useLogin();

  return (
    <Ui.Overlay
      footerContent={<Footer isLoggedIn={false} />}
      open={showLogin}
      onClose={() => setShowLogin(false)}
    >
      <LoginForm withinModal />
    </Ui.Overlay>
  );
};
