import {
  ConsumerApiSettings,
  ALLOWED_MARKETS,
  LanguageCode,
  MarketCode,
} from '@silvertours/common-landingpages-view';
import { http } from '@silvertours/front-shared';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

import { SearchSuggestion, SuggestProviderInterface } from './types';

declare global {
  interface Window {
    newrelic: any;
  }
}

function assertIsValidMarket(market: MarketCode): asserts market is MarketCode {
  if (!ALLOWED_MARKETS.includes(market)) {
    throw new Error(`Value ${market} is not a MarketCode`);
  }
}

const getXWhitelabel = (market: MarketCode) => {
  assertIsValidMarket(market);
  switch (market) {
    case 'DE':
      return 'DE_billiger-mietwagen';
    case 'FR':
      return 'FR_carigami';
    default:
      assertIsValidMarket(market);
      return `${market}_floyt`;
  }
};

type SuggestionResponse = {
  bm_code: string;
  city_name: string;
  country_name: string;
  id: string;
  image_path: string;
  latitude: string;
  longitude: string;
  min_price: string;
  state_name: string;
  title: string;
  type: string;
  type_display: string;
  airport_code: String;
  rail_code: String;
};

const authInterceptorGenerator =
  (bearerAuth: string) => (config: InternalAxiosRequestConfig<any>) => {
    Object.assign(config.headers, {
      Authorization: bearerAuth,
    });
    return config;
  };

class SuggestProvider implements SuggestProviderInterface {
  suggestClient: AxiosInstance;

  consumerApiSettings: ConsumerApiSettings;

  options: AxiosRequestConfig;

  token: string | null = null;

  private searchSuggestionsCache: { [key: string]: SearchSuggestion[] } = {};

  private static instance: SuggestProviderInterface;

  private constructor(
    consumerApiSettings: ConsumerApiSettings,
    language: LanguageCode,
    market: MarketCode,
  ) {
    this.consumerApiSettings = consumerApiSettings;
    this.options = {
      headers: {
        'X-Whitelabel': getXWhitelabel(market),
        'Accept-Language': language,
      },
    };
    this.suggestClient = http.getApi(
      consumerApiSettings.host,
      { ...this.options },
      '',
    );
    this.refreshToken();
  }

  public static getInstance(
    consumerApiSettings: ConsumerApiSettings,
    language: LanguageCode,
    market: MarketCode,
  ) {
    if (!this.instance) {
      this.instance = new this(consumerApiSettings, language, market);
    }
    return this.instance;
  }

  private async refreshToken() {
    if (!this.token) {
      http
        .getApi(this.consumerApiSettings.host, {}, '')
        .post(this.consumerApiSettings.refreshTokenPath)
        .then(result => {
          const bearerAuth = `Bearer ${result.data.token}`;
          this.token = bearerAuth;
          const authInterceptor = authInterceptorGenerator(bearerAuth);
          this.suggestClient.interceptors.request.clear();
          this.suggestClient.interceptors.request.use(authInterceptor);
        })
        .catch(e => {
          if (typeof window !== 'undefined') {
            const { newrelic } = window;
            newrelic?.noticeError(e);
          }
        });
    }
  }

  keyExistsInCache(key: string): boolean {
    return key in this.searchSuggestionsCache;
  }

  async sendRequest(
    searchString: string,
  ): Promise<Array<SearchSuggestion> | null> {
    if (this.keyExistsInCache(searchString)) {
      return this.searchSuggestionsCache[searchString];
    }
    const searchSuggestions = this.suggestClient
      .request({
        method: 'get',
        url: this.consumerApiSettings.locationSuggest,
        params: {
          query: searchString,
          context: 'car_rental',
        },
      })
      .then(result => {
        const suggestionResults = (result.data as Array<any>).map(
          ({
            bm_code,
            city_name,
            country_name,
            id,
            image_path,
            latitude,
            longitude,
            min_price,
            state_name,
            title,
            type,
            type_display,
            airport_code,
            rail_code,
          }: SuggestionResponse) =>
            ({
              bmCode: bm_code,
              cityName: city_name,
              countryName: country_name,
              id,
              image_path,
              latitude,
              longitude,
              minPrice: min_price,
              objectID: id,
              stateName: state_name,
              title,
              type,
              typeDisplay: type_display,
              airportCode: airport_code,
              railCode: rail_code,
            }) as SearchSuggestion,
        );

        this.searchSuggestionsCache[searchString] = suggestionResults;
        return suggestionResults;
      })
      .catch((e: unknown) => {
        if (e && axios.isAxiosError(e)) {
          // eslint-disable-next-line no-console
          console.error(
            'Request error when fetching suggestions',
            e.message,
            searchString,
          );
        }

        if (typeof window !== 'undefined') {
          const { newrelic } = window;
          newrelic?.noticeError(e);
        }
        return null;
      });
    return searchSuggestions;
  }
}

export { SuggestProvider };
