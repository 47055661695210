import { AccordionEntry } from './AccordionEntry';
import { AccordionItemPrompt } from './AccordionItemPrompt';
import { AccordionItemContent } from './AccordionItemContent';

import { AccordionItemWrapper } from './AccordionItem.styles';

export type AccordionItemProps = {
  item: AccordionEntry;
  index: number;
};

export const AccordionItem = ({
  item: { content, image, prompt },
  index,
}: AccordionItemProps) => (
  <AccordionItemWrapper isFirst={index === 0}>
    <AccordionItemPrompt prompt={prompt} />
    <AccordionItemContent content={content} image={image} />
  </AccordionItemWrapper>
);
