import type { HTMLAttributes } from 'react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Label,
  Spinner,
  Button,
  Value,
} from './SpinnerButton.styles';

type Props = {
  label: string;
  value?: string;
  invalid?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export const SpinnerButton = ({ label, value, invalid, ...rest }: Props) => (
  <Button {...rest} type="button" aria-haspopup="true">
    <Label $isInvalid={!!invalid}>{label}</Label>
    <Value $isInvalid={!!invalid}>{value}</Value>
    <Spinner aria-hidden="true">
      <ArrowUpIcon />
      <ArrowDownIcon />
    </Spinner>
  </Button>
);
