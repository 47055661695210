import { TextProps } from '../textUtils';
import {
  InlineSecondaryTextWrapper,
  SecondaryTextWrapper,
} from './SecondaryText.styles';

const SecondaryText: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  inline = false,
  className,
  textColor,
}) => {
  const Wrapper = inline ? InlineSecondaryTextWrapper : SecondaryTextWrapper;
  return (
    <Wrapper className={className} textColor={textColor}>
      {children}
    </Wrapper>
  );
};

export { SecondaryText };
