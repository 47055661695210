import { PropsWithChildren } from 'react';
import {
  HasModuleContainerSpacingRules,
  HasOptionalColumnPosition,
} from './rule';
import { ModuleContainerWrapper } from './ModuleContainer.styles';

export type ModuleContainerRules = HasModuleContainerSpacingRules &
  HasOptionalColumnPosition;

export type ModuleContainerProps = PropsWithChildren & {
  rules?: ModuleContainerRules;
};

export const ModuleContainer = ({ children, rules }: ModuleContainerProps) => {
  const { isFirstColumn, isSecondColumn, verticalSpacing } = rules || {};
  const { isLargeAtLG, isLargeAtXL, isNoneAtXL, isSmallAtXL } =
    verticalSpacing || {};

  return (
    <ModuleContainerWrapper
      isFirstColumn={isFirstColumn}
      isSecondColumn={isSecondColumn}
      isLargeAtLG={isLargeAtLG}
      isLargeAtXL={isLargeAtXL}
      isNoneAtXL={isNoneAtXL}
      isSmallAtXL={isSmallAtXL}
    >
      {children}
    </ModuleContainerWrapper>
  );
};
