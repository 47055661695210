import Image from 'next/image';

import { HasColumns, HasImage } from '../../../rule';
import { AccordionFigure } from './AccordionItem.styles';

export type FigureRules = HasColumns;

export type FigureProps = {
  content: HasImage;
  rules: FigureRules;
};

export const Figure = ({ content: { image }, rules }: FigureProps) => {
  if (!image) {
    return null;
  }

  return (
    <AccordionFigure rules={rules}>
      <Image layout="responsive" {...image} />
    </AccordionFigure>
  );
};
