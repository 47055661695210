import { DestinationContentModule } from '@silvertours/common-landingpages-view';
import {
  PlainContentModuleContainer,
  TextContentBlock,
  TextContentBlockWithFigures,
  TextContentBlockWithIcons,
} from '@silvertours/front-entities';
import { Ui } from '@silvertours/front-shared';

export const Destination = (props: DestinationContentModule) => {
  const { icons, translations, providers, rules } = props;

  if (icons?.length) {
    return (
      <PlainContentModuleContainer>
        <TextContentBlockWithIcons
          content={{ ...props, icons }}
          rules={rules}
        />
      </PlainContentModuleContainer>
    );
  }

  if (providers?.length) {
    const { providerPositionLabel = '' } = translations || {};
    return (
      <PlainContentModuleContainer>
        <TextContentBlockWithFigures
          content={{
            ...props,
            figures: providers.map((provider, index) => ({
              ...provider,
              position: index + 1,
            })),
            positionLabel: providerPositionLabel,
          }}
          rules={rules}
        />
      </PlainContentModuleContainer>
    );
  }

  return (
    <PlainContentModuleContainer>
      <TextContentBlock
        content={props}
        rules={{
          ...rules,
          isContainerMultiColumn: false,
          textStyle: { style: rules?.textStyle || Ui.RichTextStyle.Primary },
        }}
      />
    </PlainContentModuleContainer>
  );
};
