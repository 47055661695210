import styled from '@emotion/styled';

const TrustWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  bottom: calc(100% - 30px);
  right: 0;
  max-width: 285px;
`;

const TrustElement = styled.div<{ isHighlighted?: boolean }>`
  padding: ${({ theme }) => theme.spacing[40]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ theme, isHighlighted }) =>
    isHighlighted ? '' : `1px solid ${theme.color.grey04}`};
  color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.color.white : theme.color.text};
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.color.brand : theme.color.white};
`;

const AppPromoWrapper = styled.div`
  display: grid;
  grid-template: 'title title' 'stores qr';
  grid-template-columns: minmax(50px, 1fr) max-content;
  gap: 0.5rem;
  align-items: center;
`;

const AppPromoHeadline = styled.p`
  grid-area: title;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.md.size};
  line-height: 1.125;
`;

const AppPromoStores = styled.ul`
  grid-area: stores;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: 0;
  padding: 0;
  max-width: 125px;
  list-style: none;
`;

const AppPromoQrCode = styled.svg`
  grid-area: qr;
  shape-rendering: crispedges;
  width: 4.5rem;
  height: 4.5rem;
`;

export {
  TrustWrapper,
  TrustElement,
  AppPromoWrapper,
  AppPromoHeadline,
  AppPromoStores,
  AppPromoQrCode,
};
