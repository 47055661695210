import styled from '@emotion/styled';

const LogoLink = styled.a`
  fill: ${({ theme }) => theme.color.brand};

  &:focus-visible {
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }
`;

export { LogoLink };
