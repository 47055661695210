import { createContext, useContext } from 'react';

export interface StageContextProps {
  stageImage: string;
  setStageImage: (src: string) => void;
}

const StageContext = createContext<StageContextProps>({
  stageImage: 'Stage-Bilder_PalmaDeMallorca1.jpg', // default image
  setStageImage: () => {},
});

const useStageContext = () => useContext(StageContext);

export { StageContext, useStageContext };
