import { ContextResult, SuccessResult } from './useClientContext';

type AssertHasContext = <TContext extends Record<string, unknown>>(
  key: string,
  result: ContextResult<TContext>,
) => asserts result is SuccessResult<TContext>;

export const guardContext: AssertHasContext = (key, result) => {
  if (!result.hasContext) {
    throw new Error(
      `The requested data is not been provided in the context (contextKey: ${key})`,
    );
  }
};

export type { AssertHasContext };
