import styled from '@emotion/styled';

const LinksList = styled.ul`
  margin-bottom: 1rem;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1;

  ${({ theme }) => theme.mq('sm')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const LinkItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 0.3125rem;
`;

export { LinksList, LinkItem };
