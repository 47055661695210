import {
  ConsumerApiSettings,
  Brand,
  Document,
  HasLocale,
  LanguageCode,
  LocaleValue,
  MarketCode,
  PagePath,
  RuntimeModule,
  ViewSettingsModule,
} from '@silvertours/common-landingpages-view';
import {
  SearchFormLegacy,
  NewsletterRegistrationLegacy,
} from '@silvertours/front-features';
import { HostLegacy } from '@silvertours/front-legacy-entities';

type SiteContext =
  | HasLocale
  | PagePath
  | HostLegacy.HostContext
  | SearchFormLegacy.SearchContext
  | NewsletterRegistrationLegacy.NewsletterContext
  | RuntimeModule
  | ConsumerApiSettings;

type BmClientContext = Record<string, SiteContext>;

const prependProtocol = (url: string, protocol?: string) =>
  `${protocol || 'https'}://${url}`;

const makeBmClientContext = (
  locale: LocaleValue,
  document: Document | null,
  { api, media, host }: ViewSettingsModule,
  brand: Brand,
): BmClientContext => {
  const path = document?.path || { current: '', alternatives: [] };
  const [languageFromLocale, marketFromLocale] = locale.split('-');

  return {
    host: {
      baseImageUrl: prependProtocol(media.imageHost.current),
      baseUrl: {
        current: prependProtocol(host.baseUrl.current),
        alternatives: Object.entries(host.baseUrl.alternatives).reduce(
          (accumulated, [_locale, url]) => ({
            ...accumulated,
            [_locale]: prependProtocol(url),
          }),
          {},
        ),
      },
    },
    locale: {
      locale,
      language: languageFromLocale as LanguageCode,
      market: (marketFromLocale ??
        languageFromLocale.toUpperCase()) as MarketCode,
    },
    path,
    search: {
      baseUrl: prependProtocol(api.mietwagen.host),
    },
    infoline: {
      baseUrl: prependProtocol(api.mietwagen.host),
    },
    newsletter: {
      baseUrl: prependProtocol(api.mietwagen.host),
    },
    runtime: {
      account: {
        apiBaseUrl: prependProtocol(api.consumer.host, api.consumer.protocol),
        sessionBaseUrl: prependProtocol(host.baseUrl.current),
      },
      apiConfig: {
        host: api.mietwagen.host,
      },
      brand: {
        brandName: brand.brandName,
        siteGroup: brand.siteGroup,
      },
      consumerApiSettings: api.consumer,
      host: {
        hostName: host.baseUrl.current,
        imageHost: media.imageHost.current,
      },
      language: languageFromLocale as LanguageCode,
      locale: locale as LocaleValue,
      market: (marketFromLocale ??
        languageFromLocale.toUpperCase()) as MarketCode,
      page: { path: path.current },
    },
  };
};

export { makeBmClientContext };
