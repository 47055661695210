import {
  BilligerMietwagenEmptySiteViewContent,
  PageOfSiteProps,
} from '@silvertours/common-landingpages-view';
import { PageLegacy } from '@silvertours/front-features';
import { Content } from '@silvertours/common-landingpages-i18n';

const LayoutlessDisplay = ({
  children,
  page,
}: React.PropsWithChildren<
  PageOfSiteProps<
    BilligerMietwagenEmptySiteViewContent,
    { color?: string },
    Content
  >
>) => (
  <PageLegacy.PageBase isAffiliate={page?.color !== undefined}>
    {children}
  </PageLegacy.PageBase>
);

export { LayoutlessDisplay };
