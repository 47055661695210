import { Image as ImageData } from '@silvertours/common-landingpages-view';
import { Container, StyledImage } from './Image.styles';

export type ImageRules = {
  hasMinSize: boolean;
};

export type ImageProps = {
  content?: ImageData;
  rules: ImageRules;
};

export const Image = ({ content, rules: { hasMinSize } }: ImageProps) => {
  if (!content) {
    return null;
  }

  return (
    <Container hasMinSize={hasMinSize}>
      <StyledImage fill {...content} width={undefined} height={undefined} />
    </Container>
  );
};
