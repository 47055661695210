import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { Theme as ThemeType } from '@emotion/react';
import {
  InteractiveText,
  SecondaryText,
  StyledTextProps,
} from '@silvertours/front-shared';

type OpeningHoursProps = {
  showVoucherInfo: boolean;
} & StyledTextProps;

const getLayout = ({ theme }: { theme: ThemeType }) => css`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.mq('lg')} {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    max-width: none;
  }
`;

const Anchor = styled.a`
  color: inherit;
  text-decoration: inherit;
`;

const OpeningTimesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoWrapper = styled.div`
  ${getLayout};
  padding-left: 1rem;
  padding-right: 1rem;
  row-gap: ${({ theme }) => theme.spacing[40]};

  > p:first-of-type {
    width: 100%;
  }
`;

const InteractivePhoneNumber = styled(InteractiveText)`
  margin-top: 1.5rem;
  text-align: center;
  font-size: 24px;

  ${({ theme }) => theme.mq('lg')} {
    margin-top: 1rem;
  }
`;

const OpeningHours = styled.div<OpeningHoursProps>`
  padding-bottom: ${({ showVoucherInfo }) => !showVoucherInfo && '2rem'};
  width: 100%;
  max-width: 222px;
  align-self: center;
`;

const Information = styled.div`
  margin-top: 0.5rem;
`;

const ScheduleRow = styled.div<{ type: 'common' | 'exception' }>`
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ type }) => type === 'common' && 'bold'};
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
`;

const Footer = styled.div`
  ${getLayout};
  background-color: #f5f5f5;
  margin-top: 2.5rem;
  padding: 1.5rem 1rem;

  ${({ theme }) => theme.mq('lg')} {
    margin-top: 2rem;
  }
`;

const VoucherWrapper = styled.div`
  text-align: center;
  max-width: 500px;
`;

const VoucherText = styled(SecondaryText)`
  margin-top: 1rem;
`;

export {
  Anchor,
  Footer,
  Information,
  InfoWrapper,
  InteractivePhoneNumber,
  OpeningHours,
  OpeningTimesWrapper,
  ScheduleRow,
  VoucherText,
  VoucherWrapper,
};
