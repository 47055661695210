import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { css } from '@emotion/react';
import { tint } from 'polished';
import { Theme, Ui } from '@silvertours/front-shared';
import { StaticLink } from '@silvertours/back-domain-page';

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  margin-right: 1.5rem;
`;

const getLinkStyles = ({ theme }: { theme: Theme.Theme }) => css`
  display: inline-flex;
  padding: 0.5rem;
  border-radius: ${theme.borderRadius.md};
  color: ${theme.color.white};
  background-color: ${theme.color.brand};
  background-position: center;
  fill: currentcolor;
  transition: background 1s;

  &:focus-visible {
    outline: 0.125rem dashed ${theme.color.error};
    outline-offset: 0.25rem;
  }

  &:hover {
    background-color: ${theme.color.primaryHighlight};
    background-image: radial-gradient(
      circle,
      transparent 1%,
      ${theme.color.primaryHighlight} 1%
    );
    background-position: center;
    background-size: 15000%;
  }

  &:active {
    background-color: ${tint(0.15, theme.color.primaryHighlight || '')};
    background-size: 100%;
    transition: background 0s;
  }

  svg {
    width: 1.5625rem;
    height: 1.5625rem;
    vertical-align: middle;
    fill: inherit;
    stroke: inherit;
  }
`;

const MaskedLink = styled(Ui.MaskedLink)`
  ${getLinkStyles}
`;

const Anchor = styled(Link)`
  ${getLinkStyles}
  text-decoration: none;
`;

const FacebookIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24">
    <path d="M22 12.06C22 6.505 17.523 2 12 2S2 6.504 2 12.06c0 5.023 3.656 9.185 8.437 9.94v-7.03H7.9v-2.91h2.538V9.844c0-2.521 1.493-3.915 3.778-3.915 1.093 0 2.238.197 2.238.197v2.476h-1.262c-1.242 0-1.628.776-1.628 1.572v1.887h2.773l-.443 2.908h-2.33V22c4.78-.755 8.436-4.917 8.436-9.94Z" />
  </svg>
);

const InstagramIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24">
    <path d="M12 3c-2.444 0-2.75.01-3.71.054-.959.044-1.613.196-2.186.419-.6.225-1.144.58-1.594 1.038-.457.45-.812.993-1.038 1.593-.221.573-.374 1.228-.418 2.186C3.011 9.25 3 9.555 3 12c0 2.445.01 2.75.054 3.71.044.959.196 1.613.419 2.186.225.6.58 1.144 1.038 1.594.45.458.993.812 1.593 1.038.573.222 1.227.374 2.186.418.96.044 1.266.054 3.71.054s2.75-.01 3.71-.054c.959-.044 1.613-.196 2.186-.419.6-.225 1.144-.58 1.594-1.038.458-.45.812-.993 1.038-1.593.222-.573.374-1.227.418-2.186.044-.96.054-1.266.054-3.71s-.01-2.75-.054-3.71c-.044-.959-.196-1.613-.419-2.186A4.413 4.413 0 0 0 19.49 4.51a4.415 4.415 0 0 0-1.593-1.038c-.573-.221-1.228-.374-2.186-.418C14.75 3.011 14.445 3 12 3Zm0 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.186 1.671.31.42.163.72.358 1.035.673.315.315.51.615.673 1.035.123.317.27.794.31 1.67.043.95.052 1.235.052 3.638 0 2.403-.009 2.688-.052 3.637-.04.877-.186 1.354-.31 1.671a2.78 2.78 0 0 1-.673 1.035 2.78 2.78 0 0 1-1.035.673c-.317.123-.794.27-1.67.31-.95.043-1.234.052-3.638.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.186-1.671-.31a2.787 2.787 0 0 1-1.035-.673 2.789 2.789 0 0 1-.673-1.035c-.123-.317-.27-.794-.31-1.67-.043-.95-.052-1.235-.052-3.638 0-2.403.009-2.688.052-3.637.04-.877.186-1.354.31-1.671.163-.42.358-.72.673-1.035a2.78 2.78 0 0 1 1.035-.673c.317-.123.794-.27 1.67-.31.95-.043 1.235-.052 3.638-.052Z" />
    <path d="M12 15.003a3.004 3.004 0 1 1 0-6.007 3.004 3.004 0 0 1 0 6.007Zm0-7.629a4.626 4.626 0 1 0 0 9.252 4.626 4.626 0 0 0 0-9.252Zm5.972-.084a1.093 1.093 0 1 1-2.187 0 1.093 1.093 0 0 1 2.187 0Z" />
  </svg>
);

const PinterestIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24">
    <path d="M11.996 2A9.99 9.99 0 0 0 2 11.996a10 10 0 0 0 6.351 9.313c-.09-.79-.164-2.008.033-2.871.181-.782 1.168-4.97 1.168-4.97s-.296-.6-.296-1.48c0-1.39.806-2.427 1.81-2.427.856 0 1.267.641 1.267 1.406 0 .856-.543 2.14-.83 3.332-.24.996.501 1.81 1.48 1.81 1.777 0 3.143-1.875 3.143-4.574 0-2.394-1.72-4.064-4.18-4.064-2.846 0-4.516 2.13-4.516 4.336 0 .855.329 1.777.74 2.279.083.098.09.189.066.287-.074.313-.247.996-.28 1.136-.04.18-.148.222-.337.131-1.234-.592-2.007-2.418-2.007-3.883 0-3.15 2.287-6.047 6.606-6.047 3.464 0 6.162 2.469 6.162 5.776 0 3.447-2.172 6.22-5.183 6.22-1.012 0-1.966-.527-2.287-1.152l-.625 2.377c-.223.872-.831 1.958-1.243 2.625.938.288 1.925.444 2.962.444C17.524 22 22 17.524 22 12.004 21.992 6.476 17.516 2 11.996 2Z" />
  </svg>
);

const TikTokIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24">
    <path d="M18.6506 6.41117C18.5113 6.33922 18.3758 6.26035 18.2445 6.17484C17.8626 5.92238 17.5125 5.62491 17.2016 5.28883C16.4239 4.39895 16.1334 3.49617 16.0265 2.8641H16.0308C15.9414 2.33945 15.9783 2 15.9839 2H12.4416V15.6976C12.4416 15.8815 12.4416 16.0632 12.4338 16.2429C12.4338 16.2652 12.4317 16.2858 12.4304 16.3099C12.4304 16.3198 12.4304 16.3301 12.4283 16.3404C12.4283 16.343 12.4283 16.3455 12.4283 16.3481C12.3909 16.8396 12.2334 17.3144 11.9695 17.7306C11.7056 18.1469 11.3434 18.492 10.9149 18.7355C10.4683 18.9896 9.96309 19.1229 9.44923 19.1222C7.7988 19.1222 6.46118 17.7764 6.46118 16.1144C6.46118 14.4523 7.7988 13.1066 9.44923 13.1066C9.76165 13.1063 10.0721 13.1554 10.3692 13.2522L10.3735 9.64543C9.47173 9.52895 8.55562 9.60061 7.68294 9.85591C6.81027 10.1112 5.99997 10.5446 5.30317 11.1287C4.69262 11.6592 4.17932 12.2922 3.78638 12.9991C3.63684 13.257 3.07267 14.2929 3.00434 15.9743C2.96138 16.9286 3.24798 17.9173 3.38462 18.326V18.3346C3.47056 18.5752 3.80356 19.3963 4.34626 20.0886C4.78387 20.6438 5.30089 21.1316 5.88067 21.5362V21.5276L5.88927 21.5362C7.60415 22.7015 9.50552 22.625 9.50552 22.625C9.83466 22.6117 10.9372 22.625 12.1893 22.0316C13.5781 21.3737 14.3687 20.3936 14.3687 20.3936C14.8738 19.808 15.2754 19.1406 15.5564 18.4201C15.8769 17.5775 15.9839 16.5668 15.9839 16.1629V8.89605C16.0269 8.92184 16.5992 9.30039 16.5992 9.30039C16.5992 9.30039 17.4238 9.82891 18.7103 10.1731C19.6332 10.418 20.8768 10.4696 20.8768 10.4696V6.95301C20.4411 7.00027 19.5563 6.86277 18.6506 6.41117Z" />
  </svg>
);

type Props = {
  links: StaticLink[];
};

const SocialMediaLinks = ({ links }: Props) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <List>
      {links.map(({ text, target, href, title, masked }) => {
        let icon;
        switch (text) {
          case 'Facebook':
            icon = FacebookIcon;
            break;
          case 'Instagram':
            icon = InstagramIcon;
            break;
          case 'Pinterest':
            icon = PinterestIcon;
            break;
          case 'TikTok':
            icon = TikTokIcon;
            break;
          default:
            break;
        }

        const linkProps = {
          href,
          target,
          title,
        };

        return (
          <Item key={`socialMedia_${href}`}>
            {masked ? (
              <MaskedLink {...linkProps} body={icon} />
            ) : (
              <Anchor
                {...linkProps}
                rel={target === '_blank' ? 'noopener noreferrer' : undefined}
              >
                {icon}
              </Anchor>
            )}
          </Item>
        );
      })}
    </List>
  );
};

export { SocialMediaLinks };
