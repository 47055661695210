/* eslint-disable react/no-unused-prop-types */
import React, { PropsWithChildren } from 'react';

import {
  BilligerMietwagenEmptySiteViewContent,
  BilligerMietwagenSiteViewContent,
  PageOfSiteProps,
} from '@silvertours/common-landingpages-view';

import { analytics } from '@silvertours/front-entities';
import { Content } from '@silvertours/common-landingpages-i18n';
import { ContentDisplay, LayoutlessDisplay } from './display';

type BilligerMietwagenSiteProps =
  | BilligerMietwagenSiteViewContent
  | BilligerMietwagenEmptySiteViewContent;

const isContentPage = (
  props: BilligerMietwagenSiteProps,
): props is BilligerMietwagenSiteViewContent => props.type === 'content';

const BilligerMietwagenSite = ({
  children,
  ...props
}: BilligerMietwagenSiteProps &
  PropsWithChildren<
    PageOfSiteProps<BilligerMietwagenSiteProps, any, Content>
  >) => {
  analytics.gtm.usePageView();
  analytics.gtm.useReportWebVitals();

  return isContentPage(props) ? (
    <ContentDisplay {...props}>{children}</ContentDisplay>
  ) : (
    <LayoutlessDisplay {...props}>{children}</LayoutlessDisplay>
  );
};

export { BilligerMietwagenSite };
export type { BilligerMietwagenSiteProps };
