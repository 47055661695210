import { StandardContentModule } from '@silvertours/common-landingpages-view';
import {
  CollapsableContentBlock,
  StandardContentRowBlock,
  PlainContentModuleContainer,
} from '@silvertours/front-entities';

import { Tabs } from '../tabs';
import { TabContainer } from './StandardContent.styles';

export const StandardContent = ({
  rules,
  translations,
  ...content
}: StandardContentModule) => {
  const { allowCollapse = true } = rules || {};

  return (
    <PlainContentModuleContainer>
      <CollapsableContentBlock
        content={content}
        translations={translations}
        rules={{ allowCollapse }}
      >
        {({ module }) => {
          switch (module.moduleType) {
            case 'tabs':
              return (
                <TabContainer>
                  <Tabs {...module} />
                </TabContainer>
              );
            default:
              return <StandardContentRowBlock content={module} />;
          }
        }}
      </CollapsableContentBlock>
    </PlainContentModuleContainer>
  );
};
