// helper for international price format
const formatPrice = (
  number: number,
  currency: string,
  locale?: string,
  options?: Intl.NumberFormatOptions,
) => {
  if (Number.isNaN(number) || currency === '') {
    return undefined;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(number);
};

export { formatPrice };
