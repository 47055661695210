import Link from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';
import { InteractiveText, Theme, Ui } from '@silvertours/front-shared';

const Nav = styled.nav<{ $mode: 'mobile' | 'desktop' }>`
  display: ${({ $mode }) => ($mode === 'desktop' ? 'none' : 'flex')};
  justify-content: center;
  overflow: ${({ $mode }) => ($mode === 'desktop' ? 'hidden' : 'initial')};

  ${({ theme }) => theme.mq('lg')} {
    display: ${({ $mode }) => ($mode === 'mobile' ? 'none' : 'block')};
  }
`;

const LinkList = styled.ul<{ hideChildren: number }>`
  max-width: 400px;
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  margin-left: ${({ theme }) => theme.spacing[40]};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;

  ${({ hideChildren }) => css`
    & > :nth-of-type(-n + ${hideChildren}) {
      visibility: hidden;
    }
  `};

  ${({ theme }) => theme.mq('lg')} {
    margin-bottom: 0;
    margin-left: 0;
    max-width: none;
    flex-direction: row;
    padding-top: 0;
  }
`;

const LinkListItem = styled.li<{ container: 'navbar' | 'popover' }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing[60]};
  white-space: nowrap;

  ${({ theme }) => theme.mq('lg')} {
    height: ${({ container }) => container === 'navbar' && '50px'};
    margin-bottom: ${({ container }) => (container === 'navbar' ? '25px' : 0)};
    align-items: ${({ container }) => container === 'navbar' && 'end'};
  }
`;

const getLinkStyles = ({ theme }: { theme: Theme.Theme }) => css`
  color: ${theme.color.brand};
  font-family: ${theme.fontFamily.default};

  ${theme.mq('lg')} {
    color: ${theme.color.text};
  }

  &:hover {
    color: ${shade(0.271, theme.color.brand!)};
  }

  &:focus-visible {
    border-radius: ${theme.borderRadius.xs};
    outline: 0.125rem dashed ${theme.color.secondary};
    outline-offset: 0.25rem;
  }

  &:active {
    color: ${shade(0.608, theme?.color.brand!)};
  }
`;

const MaskedLink = styled(Ui.MaskedLink)`
  ${getLinkStyles};
`;

const Anchor = styled(Link)`
  ${getLinkStyles}
  text-decoration: none;
`;

const StyledText = styled(InteractiveText)<{ withPadding: boolean }>`
  ${({ theme }) => theme.mq('lg')} {
    padding-left: ${({ theme, withPadding }) =>
      withPadding ? theme.spacing[50] : 0};
    margin-top: 0;
    list-style-type: none;
  }

  ${({ theme }) => theme.mq('xl')} {
    padding-left: ${({ theme, withPadding }) =>
      withPadding ? theme.spacing[60] : 0};
  }
`;

const StyledBadge = styled(Ui.Badge)<{ container: 'navbar' | 'popover' }>`
  font-size: 0.75rem; /* @TODO: Move this to Ui.Badge once the legacy header has been removed. */

  ${({ theme }) => theme.mq('lg')} {
    position: ${({ container }) => container === 'navbar' && 'absolute'};
    top: ${({ container }) => container === 'navbar' && '0.5rem'};
    right: ${({ container }) => container === 'navbar' && '-0.75rem'};
  }
`;

export {
  LinkList,
  LinkListItem,
  Anchor,
  MaskedLink,
  Nav,
  StyledText,
  StyledBadge,
};
