import React from 'react';
import { Scene, SceneProps } from './common';
import { SelectStationScene } from './SelectStationScene';
import { SearchFormScene } from './SearchFormScene';
import { useSearchContext } from '../SearchFormLegacy/context';

type Props = {
  scenes: Scene[];
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
};

const SearchWizard = ({ onClose, onConfirm, scenes, loading }: Props) => {
  const { state } = useSearchContext();

  // If the user has already selected a location, go directly to the final scene
  // as the search form is now in a valid state and a search can be performed
  const initialIndex = state?.depLocation?.cityCode ? scenes.length - 1 : 0;

  const [currentIndex, setCurrentIndex] = React.useState(initialIndex);
  const currentScene = scenes[currentIndex];
  return currentScene.node({
    loading,
    onClose: () => {
      if (currentScene.goBackOnClose && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      } else {
        onClose();
      }
    },
    onConfirm: () => {
      if (currentIndex === scenes.length - 1) {
        onConfirm();
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    },
  });
};

const useSearchWizard = (): {
  scenes: Scene[];
} => ({
  scenes: [
    {
      node: (props: SceneProps) => (
        <SelectStationScene {...props} direction="departure" />
      ),
    },
    {
      node: (props: SceneProps) => <SearchFormScene {...props} />,
    },
  ],
});

export { SearchWizard, useSearchWizard };
