import styled from '@emotion/styled';
import { tint } from 'polished';
import { Ui } from '@silvertours/front-shared';

export const Wrapper = styled.div`
  width: 100%;
  height: 25rem;

  ${({ theme }) => theme.mq('lg')} {
    height: 29.5rem;
  }

  ${({ theme }) => theme.mq('xl')} {
    height: 39rem;
  }
`;

export const InfoBox = styled.div<{ onClick: any; selected: boolean }>`
  height: 99px;
  width: 100%;
  min-width: 311px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 2px solid
    ${({ theme, selected }) =>
      selected ? tint(0.202, theme.color.brand!) : theme.color.grey03};
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
`;

const WaveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="98"
    viewBox="0 0 43 98"
    fill="none"
  >
    <path
      d="M40.2858 125C5.06596 60.6061 57.8958 53.9773 37.7701 0H-1.5V125H40.2858Z"
      fill="#f3f3f3"
    />
  </svg>
);

export const Wave = styled(WaveSVG)`
  overflow: hidden;
`;

export const IconContainer = styled.div`
  min-width: 44px;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
`;

export const TextContainer = styled.div<{ closable: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  padding: ${({ theme }) => theme.spacing[40]};
  padding-right: ${({ theme, closable }) =>
    closable ? theme.spacing[60] : theme.spacing[40]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[10]};
  overflow: hidden;
`;

export const StationIcon = styled.div`
  position: relative;
  top: -87px;
  padding-left: 14px;
`;

export const StationText = styled(Ui.Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MobileStationInfoContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing[30]};
  display: flex;
`;

export const StationNameAndAddress = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CloseButton = styled(Ui.IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing[30]};
  right: ${({ theme }) => theme.spacing[30]};
`;
