import { createContext, useContext } from 'react';

/** @todo: Integrate into `ClientContext` */
export const SitePropsContext = createContext<any | undefined>(undefined);

export const useSiteProps = () => {
  const context = useContext(SitePropsContext) as any;
  if (context === undefined) {
    throw new Error(
      'useSiteProps must be used within a SitePropsContext.Provider',
    );
  }
  return context;
};
