import {
  DestinationContentModule,
  Icon,
} from '@silvertours/common-landingpages-view';
import { Ui } from '@silvertours/front-shared';
import { Heading, ModuleContainer, RichText } from '../../element';
import { IconGrid } from './IconGrid';
import { ResponsiveWrapper } from './TextContentBlockWithIcons.styles';

export type TextContentBlockWithIconsContent = Pick<
  DestinationContentModule,
  'content' | 'heading'
> & { icons: Array<Icon> };

export type TextContentBlockWithIconsRules = {
  textStyle?: Ui.RichTextStyle;
};

export type TextContentBlockWithIconsProps = {
  content: TextContentBlockWithIconsContent;
  rules?: TextContentBlockWithIconsRules;
};

export const TextContentBlockWithIcons = ({
  content: { content, heading, icons },
  rules,
}: TextContentBlockWithIconsProps) => {
  const style = rules?.textStyle || Ui.RichTextStyle.Primary;
  return (
    <ModuleContainer>
      <Heading content={heading} rules={{ textStyle: { style } }} />
      <ResponsiveWrapper>
        <RichText
          content={content}
          rules={{
            columns: [[12], [7, 'lg']],
            textStyle: { hasSpacedParagraphs: true, style },
          }}
        />
        <IconGrid content={icons} />
      </ResponsiveWrapper>
    </ModuleContainer>
  );
};
