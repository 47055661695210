import type { TrackingProps } from './types';

type DataLayerSettings = {
  environment: 'dev' | 'live';
  anonymizeIP: boolean;
  locale: string;
  language: string;
  market: string;
};

const hasSearchParam = (param: string) =>
  typeof window !== 'undefined'
    ? Boolean(new URLSearchParams(window.location.search).get(param))
    : false;

const baseConfig: Omit<
  TrackingProps,
  | 'virtPath'
  | 'landingpageKategorie'
  | 'websitebereich'
  | 'englishPageName'
  | 'landingPageType'
> = {
  environment: 'live',
  affiliateName: 'bm',
  SPA: false,
  nextjs: true,
  twa: hasSearchParam('twa'),
  hasTduid: hasSearchParam('tduid'),
  blockUserCentrics: true,
  anonymizeIP: true,
  locale: '',
  language: '',
  market: '',
};

export { baseConfig };
export type { DataLayerSettings };
