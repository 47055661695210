import { useTranslations } from 'next-intl';
import { Runtime } from '@silvertours/front-entities';

import { LogoWrapper } from './Logo.styles';

const Logo = () => {
  const { baseUrl, baseImageUrl } = Runtime.useHost();
  const t = useTranslations('features.stageLegacy.stage.searchForm');

  return (
    <LogoWrapper>
      <span>{t('widget.poweredBy')}</span>
      <a
        href={`${baseUrl}/`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <img
          src={`${baseImageUrl}${t('widget.logo.src')}`}
          alt={t('widget.logo.alt')}
          title={t('widget.logo.title')}
          loading="lazy"
        />
      </a>
    </LogoWrapper>
  );
};

export { Logo };
