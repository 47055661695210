import styled from '@emotion/styled';
import { Header } from '@silvertours/front-features';

const StyledHeader = styled(Header)`
  background-color: ${({ theme }) => theme.color.white};
  padding-inline: ${({ theme }) => theme.spacing[40]};

  ${({ theme }) => theme.mq('sm')} {
    padding-inline: ${({ theme }) => theme.spacing[60]};
  }

  ${({ theme }) => theme.mq('lg')} {
    padding-inline: ${({ theme }) => theme.spacing[50]};
  }
`;

const MarketPickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export { StyledHeader, MarketPickerWrapper };
