import { HostLegacy } from '@silvertours/front-legacy-entities';
import { usePath } from './usePath';

const join = (baseUrl: string, path: string) => {
  const delimiter = path.indexOf('/') === 0 ? '' : '/';
  return `${baseUrl}${delimiter}${path}`;
};

const useDocumentUrls = () => {
  const { alternativeBaseUrls, baseUrl } = HostLegacy.useHost();
  const { current, alternatives } = usePath();

  return {
    canonicalUrl: {
      rel: 'canonical',
      href: join(baseUrl, current),
    },

    hreflangUrls: alternatives.map(({ locale, path }) => ({
      hrefLang: locale,
      href: join(alternativeBaseUrls[locale]!, path),
      rel: 'alternate',
    })),
  };
};

export { useDocumentUrls };
