import type { ReactNode } from 'react';
import { hideVisually } from 'polished';
import styled from '@emotion/styled';
import ArrowDown from '@engineering/icons/arrow-down';
import { css } from '@emotion/react';

type DropdownProps = {
  disabled?: boolean;
  icon?: ReactNode;
  isCompact?: boolean;
};

const DropdownWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
`;

const Label = styled.label<DropdownProps>`
  ${({ theme, isCompact }) =>
    isCompact
      ? hideVisually()
      : css`
          position: absolute;
          top: -0.5625rem;
          left: 0.75rem;
          z-index: 2;
          margin: 0;
          padding: 0 0.25rem;
          color: ${theme.color.grey01};
          background-color: ${theme.color.white};
          font-size: 0.75rem;
          line-height: 1.5;
          overflow: hidden;
          pointer-events: none;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:empty ~ select {
            padding-top: 0.1875rem;
            padding-bottom: 0.1875rem;
          }
        `}
`;

const Select = styled.select<DropdownProps>`
  position: relative;
  margin: 0;
  padding: ${({ theme, isCompact, icon }) =>
    isCompact
      ? `${theme.spacing[10]} ${theme.spacing[50]} ${theme.spacing[10]} ${icon ? theme.spacing[60] : theme.spacing[10]}`
      : `0.625rem ${theme.spacing[80]} 0.625rem ${icon ? theme.spacing[70] : theme.spacing[40]}`};
  border: ${({ theme, isCompact, disabled }) => {
    if (isCompact) {
      return 0;
    }
    const color = disabled ? theme.color.grey04 : theme.color.grey03;
    return `1px solid ${color}`;
  }};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  outline: 0;
  width: 100%;
  height: ${({ isCompact }) => (isCompact ? '2rem' : '3.125rem')};
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.5;
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.grey02 : theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  appearance: none;
  cursor: pointer;

  &:hover {
    &:not([disabled]) {
      border-color: ${({ theme }) => theme.color.grey01};
      background-color: ${({ theme, isCompact }) =>
        isCompact ? theme.color.grey04 : ''};
    }
  }

  /*
  @FIXME: Align select elements with CarSPA’s approach to fix the focus state.
  &:focus-visible {
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }
  */
`;

const ArrowIconWrapper = styled.span<DropdownProps & { isDisabled: boolean }>`
  position: absolute;
  right: ${({ isCompact }) => (isCompact ? '0.25rem' : '0.9375rem')};
  top: 50%;
  transform: translateY(-50%);
  fill: ${({ theme, isDisabled }) =>
    isDisabled ? theme.color.grey02 : theme.color.text};
  width: ${({ isCompact }) => (isCompact ? '0.75rem' : '1.6rem')};
  height: ${({ isCompact }) => (isCompact ? '0.75rem' : '1.6rem')};
  pointer-events: none;
`;

const ArrowIcon = styled(ArrowDown)`
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.span<DropdownProps & { isDisabled: boolean }>`
  position: absolute;
  left: ${({ isCompact }) => (isCompact ? '0.5rem' : '0.75rem')};
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  width: ${({ isCompact }) => (isCompact ? '1rem' : '1.5rem')};
  height: ${({ isCompact }) => (isCompact ? '1rem' : '1.5rem')};
  overflow: hidden;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.color.grey02 : theme.color.text};
  fill: currentcolor;
  pointer-events: none;
`;

const Hint = styled.p`
  margin: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[40]} 0`};
  color: ${({ theme }) => theme.color.grey02};
  font-size: ${({ theme }) => theme.fontSize.xxs.size};
`;

export {
  DropdownWrapper,
  Label,
  Select,
  ArrowIcon,
  ArrowIconWrapper,
  IconWrapper,
  Hint,
};
