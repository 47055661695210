import {
  SearchDestinationType,
  SearchSuggestion,
} from '@silvertours/front-entities';
import StationMarker from '@engineering/icons/station-marker';
import Airport from '@engineering/icons/airport';
import CityStation from '@engineering/icons/city-station';
import TrainStation from '@engineering/icons/train-station';
import { ComponentType, SVGProps } from 'react';
import { Country, IconWrapper, Station } from './SelectStation.styles';

const icons: {
  [K in SearchDestinationType]: ComponentType<SVGProps<SVGSVGElement>>;
} = {
  city: CityStation,
  citywithoutrail: CityStation,
  multiairports: Airport,
  multiairport_single: Airport,
  airport: Airport,
  multirail: TrainStation,
  rail_only: TrainStation,
  multirail_single: TrainStation,
  all: StationMarker,
  normal: StationMarker,
  rail: TrainStation,
};
export const StationItem = ({
  suggestion,
}: {
  suggestion: SearchSuggestion;
}) => {
  const Icon = icons[suggestion.type];

  return (
    <>
      <IconWrapper aria-hidden="true">
        <Icon width="32" height="32" />
      </IconWrapper>
      <Country role="gridcell">
        {suggestion.stateName} {suggestion.countryName}
      </Country>
      <Station role="gridcell">
        {suggestion.cityName} {suggestion.typeDisplay}
      </Station>
    </>
  );
};
