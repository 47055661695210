import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { analytics } from '@silvertours/front-entities';
import { http } from '@silvertours/front-shared';
import { NewsletterRegistration as NewsletterRegistrationProps } from '@silvertours/common-landingpages-view';

import { NewsletterSignup } from './NewsletterSignup';
import { NewsletterSignupSuccess } from './NewsletterSignupSuccess';
import { useNewsletter } from './useNewsletter';

const NewsletterRegistration = ({ type }: NewsletterRegistrationProps) => {
  const { formatApiUrl } = useNewsletter();
  const [success, setSuccess] = useState(false);
  const isIndexType = type === 'index';

  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );

  const trackRegistration = () => {
    if (type === 'index') {
      analytics.gtm.trackNewsletterRegistrationIndex();
    } else {
      analytics.gtm.trackNewsletterRegistration();
    }
  };

  const onSubmit = async (email: string) => {
    const sourceParam = {
      index: t('sourceParam.index'),
      landing: t('sourceParam.landing'),
    };

    try {
      await http.axiosInstance.post(formatApiUrl('v1/newsletter/subscribe'), {
        email,
        sourceParam: sourceParam[type],
      });

      setSuccess(true);
      trackRegistration();
    } catch (axiosError: unknown) {
      // TODO: error handling (if necessary?)
    }
  };

  return success ? (
    <NewsletterSignupSuccess showTitle={isIndexType} />
  ) : (
    <NewsletterSignup
      showImage={isIndexType}
      showTitle={isIndexType}
      showHeadline={isIndexType}
      onSubmit={onSubmit}
    />
  );
};

export { NewsletterRegistration };
