import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { mq } from '../../Theme';
import { StyledTextProps, TextColor, mapColor } from '../textUtils';

type BodyBoldMixinProps = {
  textColor?: TextColor;
  theme: Theme;
};

const bodyBold = ({ textColor, theme }: BodyBoldMixinProps) => css`
  font-size: 1.063rem;
  font-weight: 600;
  line-height: 1.412em;

  ${mq('lg')} {
    font-size: 1.125rem;
    line-height: 1.333em;
  }

  color: ${mapColor(theme, textColor)};
`;

const BodyBoldTextWrapper = styled.p<StyledTextProps>`
  ${bodyBold}
`;

const InlineBodyBoldTextWrapper = styled.span<StyledTextProps>`
  ${bodyBold}
`;

export { bodyBold, BodyBoldTextWrapper, InlineBodyBoldTextWrapper };
export type { BodyBoldMixinProps };
