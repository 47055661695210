import React from 'react';
import { useTranslations } from 'next-intl';

import { WidgetSearchForm } from '../SearchFormLegacy';
import { Wrapper, LogoWrapper, Logo } from './WidgetStage.styles';

type Props = {
  baseImageUrl: string;
};

/**
 * Rendered sizes:
 * - 250x250
 * - 300x250
 * - 300x400
 * - 300x600
 * - 320x480
 * - 800x250
 * - 970x250
 * @see https://silvertours.atlassian.net/browse/DGN-1785
 * @see https://silvertours.atlassian.net/browse/DGN-1784
 */
const WidgetStage = ({ baseImageUrl }: Props) => {
  const t = useTranslations('features.stageLegacy.stage.searchForm');

  return (
    <Wrapper baseImageUrl={baseImageUrl}>
      <WidgetSearchForm />
      <LogoWrapper id="logo">
        <Logo>
          <img
            src={`${baseImageUrl}${t('widget.logo.src')}`}
            alt={t('widget.logo.alt')}
            loading="lazy"
          />
        </Logo>
      </LogoWrapper>
    </Wrapper>
  );
};

export { WidgetStage };
