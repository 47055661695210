import { useEffect, useRef, useState } from 'react';
import { useKey, useKeyPressEvent } from 'react-use';

const revealFocused = (
  parent: HTMLElement | null,
  child: HTMLElement | null,
) => {
  if (!child || !parent) {
    return;
  }

  const itemOffsetRelativeToParent =
    child.offsetParent === parent
      ? child.offsetTop
      : child.offsetTop - parent.offsetTop;

  let { scrollTop } = parent;

  // Top not visible
  if (itemOffsetRelativeToParent < scrollTop) {
    scrollTop = itemOffsetRelativeToParent;
  }

  // Bottom not visible
  if (
    itemOffsetRelativeToParent + child.offsetHeight >
    scrollTop + parent.offsetHeight
  ) {
    scrollTop =
      itemOffsetRelativeToParent + child.offsetHeight - parent.offsetHeight;
  }

  if (scrollTop !== parent.scrollTop) {
    parent.scrollTo({ top: scrollTop });
  }
};

const useKeyNavigation = <TItem = any>(
  items: Array<TItem>,
  listRef: React.RefObject<HTMLElement>,
  handleEnter: (item?: TItem) => void,
) => {
  const [focusedItem, setFocusedItem] = useState<number | null>(0);
  const ignoreMouse = useRef(false);

  const up = () => {
    setFocusedItem(item => {
      if (item === null) {
        return 0;
      }
      if (item === 0) {
        return items.length - 1;
      }
      return item - 1;
    });
  };

  const down = () => {
    setFocusedItem(item => {
      if (item === null || item === items.length - 1) {
        return 0;
      }
      return item + 1;
    });
    return 0;
  };

  const navigate = (directionHandler: () => void) => {
    ignoreMouse.current = true;
    directionHandler();
    setTimeout(() => {
      ignoreMouse.current = false;
    }, 100);
  };

  useKey('ArrowUp', () => {
    navigate(up);
  });

  useKey('ArrowDown', () => {
    navigate(down);
  });

  useKeyPressEvent('Enter', e => {
    if (focusedItem === null) {
      return;
    }
    e.preventDefault();
    handleEnter(items[focusedItem]);
  });

  useEffect(() => {
    const parent = listRef.current;
    const child =
      focusedItem !== null
        ? (parent?.children[focusedItem] as HTMLElement)
        : null;

    revealFocused(parent, child);
  }, [listRef, focusedItem]);

  const onMouseEnter = (index: number) => {
    if (!ignoreMouse.current) {
      setFocusedItem(index);
    }
  };

  const onMouseLeave = () => {
    if (!ignoreMouse.current) {
      setFocusedItem(null);
    }
  };

  return { focusedItem, onMouseEnter, onMouseLeave };
};

export { useKeyNavigation };
