import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const RotateAnimation = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

export const LoadingCircle = styled.span`
  display: inline-block;
  padding: 0.1em;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  box-sizing: content-box;

  &::after {
    border-width: 0.1em;
    border-style: solid;
    border-color: ${({ theme }) => {
      const c = theme.color.brand;
      return `${c} ${c} ${c} transparent`;
    }};
    content: '';
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    animation: ${RotateAnimation} 0.8s linear infinite;
  }
`;
