import type { Type } from '@silvertours/front-shared';
import { TextProps } from '../textUtils';
import { SubtitleWrapper } from './Subtitle.styles';

type SubtitleProps = {
  as?: Type.HeadlineLevel | 'p' | 'span' | 'div';
} & TextProps;

const Subtitle: React.FC<React.PropsWithChildren<SubtitleProps>> = ({
  children,
  className,
  textColor,
  as = 'div',
}) => (
  <SubtitleWrapper as={as} className={className} textColor={textColor}>
    {children}
  </SubtitleWrapper>
);

export { Subtitle };
