import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Theme, mq } from '../../Theme';
import { mapColor } from '../textUtils';
import type { TextColor, TextSize } from '../textUtils';

type InteractiveTextMixinProps = {
  textColor?: TextColor;
  theme: Theme;
  size?: TextSize;
};

const getCss = (size: TextSize) => {
  switch (size) {
    case 'small':
      return css`
        font-size: ${'0.938rem'};
        line-height: ${'1.6em'};

        ${mq('lg')} {
          font-size: ${'1rem'};
          line-height: ${'1.25em'};
        }
      `;
    case 'xs':
      return css`
        font-size: ${'0.75rem'};
        line-height: ${'1.5em'};

        ${mq('lg')} {
          font-size: ${'0.875rem'};
          line-height: ${'1.429em'};
        }
      `;
    default:
      return css`
        font-size: ${'1.125rem'};
        line-height: ${'1.333em'};
      `;
  }
};

const interactiveText = ({
  textColor,
  theme,
  size = 'normal',
}: InteractiveTextMixinProps) => css`
  font-family: ${theme.fontFamily.alternate};
  font-weight: 700;
  color: ${mapColor(theme, textColor)};

  ${getCss(size)}
`;

type InteractiveProps = {
  textColor?: TextColor;
  size: TextSize;
};

const InteractiveWrapper = styled.p<InteractiveProps>`
  ${interactiveText}
`;

const InteractiveInlineWrapper = styled.span<InteractiveProps>`
  ${interactiveText}
`;

export { interactiveText, InteractiveWrapper, InteractiveInlineWrapper };
