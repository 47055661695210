import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import { analytics, Calendar } from '@silvertours/front-entities';
import { useSearchContext } from '../SearchFormLegacy/context';
import { SceneProps, WizardOverlay } from './common';
import { getDays } from './datetime';

const CalendarScene = ({ onConfirm, ...props }: SceneProps) => {
  const { state, setDate: setDateInSearchContext } = useSearchContext();
  const [dates, setDates] = useState({
    dep: state?.depDate ? new Date(state.depDate) : new Date(),
    dest: state?.destDate ? new Date(state.destDate) : new Date(),
  });
  const depDate = dates.dep;
  const destDate = dates.dest;

  const t = useTranslations('features.stage.search');

  if (!state) {
    return null;
  }

  const dayCountText = t('calendar.dayCount', {
    days: getDays(depDate, destDate),
  });

  const handleDatesConfirm = () => {
    analytics.gtm.trackConfirmDates();
    setDateInSearchContext('departure', depDate);
    setDateInSearchContext('destination', destDate);
    onConfirm();
  };

  const invalid = destDate <= depDate;

  return (
    <WizardOverlay
      {...props}
      onConfirm={handleDatesConfirm}
      title={t('calendar.headline')}
      buttonText={dayCountText}
      buttonDisabled={invalid}
    >
      <Calendar
        from={depDate}
        to={destDate}
        onChange={d => {
          analytics.gtm.trackSelectDates();
          setDates({
            dep: d.from!,
            dest: d.to!,
          });
        }}
        vertical
      />
    </WizardOverlay>
  );
};

export { CalendarScene };
