import Calendar from '@engineering/icons/calendar';
import { Wrapper, Button as StyledButton } from './Button.styles';
import { useFormattedDate } from './useFormattedDate';

type DatePickerButtonProps = {
  title: string;
  from?: Date;
  to?: Date;
  size?: 'small';
  onClick?: () => void;
};

const Button = ({ title, from, to, size, onClick }: DatePickerButtonProps) => {
  const { localDate: fromDate, isoDate: isoFromDate } = useFormattedDate(from);
  const { localDate: toDate, isoDate: isoToDate } = useFormattedDate(to);

  return (
    <Wrapper role="presentation">
      <StyledButton
        type="button"
        id="datepicker"
        aria-haspopup="true"
        title={title}
        size={size}
        onClick={onClick}
      >
        {fromDate && toDate && (
          <span
            id="datepickerValue"
            data-from={isoFromDate}
            data-to={isoToDate}
          >
            {fromDate} – {toDate}
          </span>
        )}
      </StyledButton>
      <Calendar id="datepicker-cal-icon" />
    </Wrapper>
  );
};

export { Button };
