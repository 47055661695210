import { useRuntime } from './useRuntime';

export const usePath = () => {
  const {
    page: { path },
  } = useRuntime();

  return {
    isHomepage: /^index\.?/.test(path),
  };
};
