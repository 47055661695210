import { css } from '@emotion/react';
import styled from '@emotion/styled';

type ModuleContainerWrapperProps = {
  isFirstColumn: boolean | undefined;
  isSecondColumn: boolean | undefined;
  isLargeAtLG: boolean | undefined;
  isNoneAtXL: boolean | undefined;
  isSmallAtXL: boolean | undefined;
  isLargeAtXL: boolean | undefined;
};

const ModuleContainerWrapper = styled.div<ModuleContainerWrapperProps>`
  ${({ isFirstColumn }) =>
    isFirstColumn &&
    css`
      grid-column-start: 1;
      grid-row-start: 1;
    `}
  ${({ isSecondColumn }) =>
    isSecondColumn &&
    css`
      grid-column-start: 2;
      grid-row-start: 1;
    `}
  ${({ isLargeAtLG, theme }) =>
    isLargeAtLG &&
    css`
      ${theme.mq('lg')} {
        padding-top: 4rem;
      }
    `}
  ${({ theme, isNoneAtXL, isSmallAtXL, isLargeAtXL }) => css`
    ${theme.mq('xl')} {
      padding-top: ${isNoneAtXL ? '0' : ''};
      padding-top: ${isSmallAtXL ? '0.5rem' : ''};
      padding-top: ${isLargeAtXL ? '4rem' : ''};
    }
  `}
`;

export { ModuleContainerWrapper };
