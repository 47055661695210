import {
  forwardRef,
  KeyboardEventHandler,
  useImperativeHandle,
  useRef,
} from 'react';
import { String } from '@silvertours/front-shared';
import { useLocale } from '../Runtime';
import {
  Bar,
  ForbiddenIcon,
  Line,
  NoDataTextWrapper,
  PriceWrapper,
} from './Graph.styles';
import { Card, CheapestMonthIndicator } from './Card';

type Props = {
  barHeight: string | null;
  value: {
    date: string;
    priceMedian: number;
    priceMin: number;
    priceMax: number;
  };
  cheapestMonth: boolean;
  onClick: () => void;
  triggerSearch: (cityCode: string, cityName: string, date: string) => void;
  onCardClose: () => void;
  isSelected: boolean;
  translations?: {
    averagePrice: string;
    buttonText: string;
    priceRange: string;
    cheapestMonthLabel: string;
    noDataText: string;
  };
  cityCode: string;
  cityName: string;
};

const getMonthYear = (inputDate: string) => {
  const date = new Date(Date.parse(inputDate));
  return `${date.getMonth() + 1}/${date.getFullYear().toString().slice(-2)}`;
};

export const GraphBar = forwardRef(
  (
    {
      barHeight,
      value: item,
      cheapestMonth,
      onClick,
      onCardClose,
      triggerSearch,
      isSelected,
      translations,
      cityCode,
      cityName,
    }: Props,
    ref: React.Ref<HTMLDivElement | null>,
  ) => {
    const { language } = useLocale();
    const internalDivRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => internalDivRef.current);

    const onCardClick = () => {
      if (internalDivRef.current) {
        internalDivRef.current.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
      if (event.key === 'Enter') {
        onCardClick();
      }
    };

    return (
      <div
        role="button"
        tabIndex={0}
        ref={internalDivRef}
        onClick={onCardClick}
        onKeyDown={handleKeyDown}
      >
        {isSelected ? (
          <Card
            date={item.date}
            priceMedian={item.priceMedian}
            priceMin={item.priceMin}
            priceMax={item.priceMax}
            cheapestMonth={cheapestMonth}
            onClick={onCardClose}
            translations={translations}
            triggerSearch={triggerSearch}
            cityName={cityName}
            cityCode={cityCode}
          />
        ) : (
          <Bar
            style={{ height: `${barHeight}` }}
            pricesAreAvailable={item.priceMedian !== 0}
            onClick={onClick}
          >
            {cheapestMonth && (
              <CheapestMonthIndicator
                cheapestMonthLabel={(translations || {}).cheapestMonthLabel}
              />
            )}
            {getMonthYear(item.date)}
            <Line />
            {item.priceMedian ? (
              <PriceWrapper>
                {String.formatPrice(item.priceMedian, 'EUR', language)}
              </PriceWrapper>
            ) : (
              <NoDataTextWrapper textColor="default">
                <ForbiddenIcon />
                <span>{(translations || {}).noDataText}</span>
              </NoDataTextWrapper>
            )}
          </Bar>
        )}
      </div>
    );
  },
);
