import { Runtime, account, analytics } from '@silvertours/front-entities';
import { http } from '@silvertours/front-shared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoginContext } from './LoginState';

const LoginProvider = ({ children }: React.PropsWithChildren) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showMobileLogin, setShowMobileLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [error, setError] = useState<account.LoginError | null>(null);

  const sessionData = account.useSessionData();
  const { formatSessionUrl } = Runtime.useAccountConfig();
  const url = formatSessionUrl(http.API.accountSession);

  const getAccountSession = useCallback(async () => {
    try {
      const res = await account.getAccountSession(url);
      setLoggedIn(Boolean(res.access_token));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [url]);

  useEffect(() => {
    getAccountSession();
  }, [getAccountSession]);

  const handleShowLogin = useCallback(
    (show: boolean) => {
      setShowLogin(show);
      if (show) {
        analytics.gtm.trackShowLogin({ isLoggedIn });
      }
    },
    [isLoggedIn],
  );

  const handleShowMobileLogin = useCallback(
    (show: boolean) => {
      setShowMobileLogin(show);
      if (show) {
        analytics.gtm.trackShowLogin({ isLoggedIn });
      }
    },
    [isLoggedIn],
  );

  const value = useMemo(
    () => ({
      isLoggedIn,
      setLoggedIn,
      error,
      setError,
      showLogin,
      setShowLogin: handleShowLogin,
      showMobileLogin,
      setShowMobileLogin: handleShowMobileLogin,
      securityToken: sessionData.securityTokenValue || '',
      username,
      setUsername,
    }),
    [
      error,
      handleShowLogin,
      handleShowMobileLogin,
      isLoggedIn,
      sessionData.securityTokenValue,
      showLogin,
      showMobileLogin,
      username,
    ],
  );

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

export { LoginProvider };
