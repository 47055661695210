import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const fixed1 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 34">
        <path d="M375 0H0v15.385c81.391 32.491 172.951 15.514 250.717 1.094C299.568 7.42 342.976-.628 375 5.229V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 136">
        <path d="M1440 0H0v61.539c312.543 129.966 664.132 62.056 962.752 4.377 187.588-36.234 354.278-68.43 477.248-45V0Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 31">
        <path d="M0 29.213V31h375V0c-.371.381-.728.596-1.071.63-40.742 4.11-84.426 3.748-129.173 3.378C162.89 3.33 77.466 2.623 0 29.214Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 151">
        <path d="M0 142.298V151h1440V0c-1.42 1.857-2.8 2.9-4.11 3.07-156.45 20.017-324.2 18.255-496.027 16.45h-.001C625.496 16.22 297.473 12.776 0 142.299Z" />
      </WaveSvg>
    ),
  },
  height: {
    small: 580,
    large: 870,
  },
} as WaveSet;

export { fixed1 };
