import styled from '@emotion/styled';
import Info from '@engineering/icons/info';
import { Ui } from '@silvertours/front-shared';

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

const Dropdown = styled(Ui.Dropdown)`
  min-width: 8em;
`;

const InfoIcon = styled(Info)`
  fill: ${({ theme }) => theme.color.info};
  width: 1.6rem;
  height: 1.6rem;
  vertical-align: middle;
  cursor: pointer;
`;

export { DropdownWrapper, Dropdown, InfoIcon };
