import { RuntimeModule } from '@silvertours/common-landingpages-view';
import { Context } from '@silvertours/front-shared';

// have to do this because type assertion functions have some stupid restrictions
const hasContext: Context.AssertHasContext = Context.guardContext;

export const useRuntime = () => {
  const result = Context.useClientContext<RuntimeModule>('runtime');

  hasContext<RuntimeModule>('runtime', result);

  return result.value;
};
