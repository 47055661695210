import { ComponentProps, useState, useEffect } from 'react';
import type { ChangeEvent } from 'react';

import { UiLegacy } from '@silvertours/front-legacy-shared';
import {
  StyledLabel,
  StyledCheckbox,
  CheckboxWrapper,
} from './Checkbox.styles';

type CheckboxProps = ComponentProps<'input'> & {
  label?: string;
  checked?: boolean;
};

const Checkbox = ({
  label,
  checked = false,
  onChange,
  ...props
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onChange?.(event);
  };

  return (
    <StyledLabel>
      <StyledCheckbox {...props} type="checkbox" onChange={handleChange} />
      <CheckboxWrapper isChecked={isChecked}>
        {isChecked && <UiLegacy.SvgIcon name="check" />}
      </CheckboxWrapper>
      {label}
    </StyledLabel>
  );
};

export { Checkbox };
