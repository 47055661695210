import styled from '@emotion/styled';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  justify-content: space-around;
  margin: 0 auto;
`;

export { Container };
