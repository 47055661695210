import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import type {
  Market,
  LanguageCode,
  MarketCode,
} from '@silvertours/common-landingpages-view';
import { MarketPicker } from '@silvertours/front-features';
import { analytics, Runtime, useSiteInfo } from '@silvertours/front-entities';

type Props = {
  onSubmit: () => void;
};

const deOptions = ['de', 'en', 'tu'] as const;
const frOptions = ['fr', 'en', 'nl'] as const;

export const LocalizedMarketPicker = ({ onSubmit }: Props) => {
  const locale = Runtime.useLocale();
  const lang = locale.language === 'de' ? deOptions : frOptions;
  const t = useTranslations('features.navigationLegacy.navigation.header');
  const markets = lang.map(l => ({
    country: t.raw(`marketPicker.markets.${l}.country`),
    language: t.raw(`marketPicker.markets.${l}.language`),
    comingSoonMessage: t(`marketPicker.markets.${l}.comingSoonMessage`),
  }));

  const {
    settings: { market, validMarkets },
  } = useSiteInfo();

  const initialMarket: Market =
    markets.find((m: Market) => market === m.country.code) || markets[0];

  const filteredMarkets = markets.filter(m =>
    validMarkets.includes(m.country.code),
  );
  const [selectedMarket, selectMarket] = useState<Market>(initialMarket);
  const [selectedLanguage, selectLanguage] = useState<LanguageCode>(
    selectedMarket.language.code,
  );

  const handleLanguageChange = (
    languageCode: LanguageCode,
    marketCode: MarketCode,
  ) => {
    selectMarket(
      markets.find((m: Market) => m.language.code === languageCode) ||
        (markets[0] as Market),
    );
    selectLanguage(languageCode);
    analytics.gtm.trackLanguageChangeAttempted(marketCode, languageCode);
  };

  const handleMarketChange = (newMarket: Market) => {
    selectMarket(newMarket);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <MarketPicker
      markets={filteredMarkets}
      selectedLanguage={selectedLanguage}
      selectedMarket={selectedMarket}
      onMarketChange={handleMarketChange}
      onLanguageChange={handleLanguageChange}
      onSubmit={handleSubmit}
      disableLanguageChange={false}
      disableMarketChange
      disableSubmit
    />
  );
};
