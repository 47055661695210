import styled from '@emotion/styled';
import { tint } from 'polished';

export const MarkerWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  top: -6px;
  left: 0;
  z-index: ${p => (p.isSelected ? '1' : '')};
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

export const MarkerIconWrapper = styled.div<{ isSelected: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  fill: ${({ isSelected, theme }) =>
    isSelected ? theme.color.white : theme.color.brand};
  filter: drop-shadow(0 1px 3px rgb(0 0 0 / 30%));

  & > svg {
    width: 48px;
    height: 48px;
  }
`;

export const StationTypeIcon = styled.div<{ isSelected: boolean }>`
  position: absolute;
  top: 3px;
  left: 5px;
  z-index: 1;

  & > svg {
    fill: ${({ isSelected, theme }) =>
      isSelected ? tint(0.202, theme.color.brand!) : theme.color.white};
    width: 40px;
    height: 32px;
  }
`;
