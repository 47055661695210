import { http, String as Strings, Ui } from '@silvertours/front-shared';
import { format as dateFormat } from 'date-fns/format';
import { useEffect, useState } from 'react';
import { useFormatter } from 'next-intl';
import { useConfig, useLocale } from '../Runtime';
import {
  CurrentRating,
  AggregatedRating,
  Name,
  StyledThirdPartyLogo,
  RatingLine,
  RatingsList,
  ReviewBody,
  ReviewContainer,
  ReviewFooter,
  ReviewHeader,
  ReviewList,
  Stars,
  StyledReviewsChart,
  Summary,
  ToggleChartButton,
  Title,
} from './ReviewOverlay.styles';
import {
  RatingChartTranslations,
  SupplierDetails,
  SupplierReview,
} from './types';
import { trackReviewShowDetailsClicked } from '../analytics/gtm';

type Translations = {
  chart: RatingChartTranslations;
  title: string;
  showMore: string;
  showLess: string;
  rating: string;
  ratings: string;
};

type Props = {
  localization: string;
  supplier: string;
  cityId?: string;
  stateId?: string;
  countryCode?: string;
  onClose: () => void;
  translations: Translations;
};

export const Review = ({
  review,
  translations,
}: {
  review: SupplierReview;
  translations: Translations;
}) => {
  const format = useFormatter();
  const { language } = useLocale();
  const [showChart, setShowChart] = useState(false);

  const formatValue = (value: number, minDigits = 1, maxDigits = 1) =>
    format.number(value, {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });

  const openChart = () => {
    trackReviewShowDetailsClicked();
    setShowChart(true);
  };

  return (
    <ReviewContainer>
      <ReviewHeader>
        <RatingLine value={formatValue(review.totalRating.value)}>
          <CurrentRating>{formatValue(review.totalRating.value)}</CurrentRating>
          /{formatValue(review.totalRating.max)}
        </RatingLine>
        <Stars value={review.totalRating.value} />
        <span>
          <time
            dateTime={dateFormat(
              review.date,
              Strings.dateFormats[language].isoDate,
            )}
          >
            {dateFormat(review.date, Strings.dateFormats[language].date)}
          </time>{' '}
          | {review.customerName}
        </span>
      </ReviewHeader>
      <ReviewBody>{review.quote}</ReviewBody>
      <ReviewFooter>
        {showChart ? (
          <>
            <StyledReviewsChart
              translations={translations.chart}
              ratings={review}
            />
            <ToggleChartButton
              as="button"
              variant="text"
              onClick={() => setShowChart(false)}
            >
              {translations.showLess}
            </ToggleChartButton>
          </>
        ) : (
          <ToggleChartButton as="button" variant="text" onClick={openChart}>
            {translations.showMore}
          </ToggleChartButton>
        )}
      </ReviewFooter>
    </ReviewContainer>
  );
};

export const ReviewOverlay = ({
  onClose,
  translations,
  localization,
  supplier,
  cityId,
  stateId,
  countryCode,
}: Props) => {
  const format = useFormatter();
  const {
    api: { host },
  } = useConfig();
  const { data, request } = http.useClientSideRequest<SupplierDetails | null>(
    host,
    http.API.customerReviews,
  );

  useEffect(() => {
    request('get', {
      supplier,
      localization,
      ...(cityId && { cityId }),
      ...(stateId && { stateId }),
      ...(countryCode && { countryCode }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier, localization, cityId, stateId, countryCode]);

  const formatValue = (value: number, minDigits = 1, maxDigits = 1) =>
    format.number(value, {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });

  const title = (
    <Title>
      {translations.title} <Name>{supplier}</Name>
    </Title>
  );

  if (data === null) {
    return (
      <Ui.Overlay
        title={title}
        titlePosition="left"
        open
        width="wide"
        onClose={onClose}
      >
        <Ui.LoadingCircle />
      </Ui.Overlay>
    );
  }

  return (
    <Ui.Overlay
      title={title}
      titlePosition="left"
      open
      width="wide"
      onClose={onClose}
    >
      <Summary>
        <StyledThirdPartyLogo name={supplier} withPadding="vertical" />
        <AggregatedRating>
          <RatingLine value={formatValue(data.summary.averageRating.value)}>
            <CurrentRating>
              {formatValue(data.summary.averageRating.value)}
            </CurrentRating>
            /{formatValue(data.summary.averageRating.max)}
          </RatingLine>
          <Stars value={data.summary.averageRating.value} />
          <div>
            {data.summary.count > 1
              ? `${data.summary.count} ${translations.ratings}`
              : `${data.summary.count} ${translations.rating}`}
          </div>
        </AggregatedRating>
        <RatingsList>
          {[
            [data.summary.star5Count, 5],
            [data.summary.star4Count, 4],
            [data.summary.star3Count, 3],
            [data.summary.star2Count, 2],
            [data.summary.star1Count, 1],
          ].map(([count, value]) => (
            <Ui.RatingBar
              key={`rating-${value}`}
              label={<Stars value={value} withSpacing />}
              value={count}
              maxValue={data.summary.count}
              unformattedValue
            />
          ))}
        </RatingsList>
      </Summary>
      <ReviewList>
        {/* TODO: use better key than date */}
        {data.list.map(review => (
          <Review
            key={review.date}
            review={review}
            translations={translations}
          />
        ))}
      </ReviewList>
    </Ui.Overlay>
  );
};
