import { ContentChunkSet } from '@silvertours/common-landingpages-view';

import { Heading, Image, LinkButton, RichText } from '../../element';
import { Columns } from '../../rule';
import {
  ColumnContainer,
  Container,
  TextContainer,
} from './StandardContentRowBlock.styles';

export type StandardContentRowBlockProps = {
  content: ContentChunkSet;
};

const getCols = (chunks: number) => {
  if (chunks === 2) {
    return 6;
  }

  return chunks === 3 ? 4 : 3;
};

const getContainerCols = (chunks: number): Array<Columns> => [
  [12],
  [getCols(chunks), 'md', { condition: chunks > 1 && chunks < 5 }],
];

const getContentCols = (chunks: number): Array<Columns> => [
  [12],
  [8, 'lg', { condition: chunks === 1 }],
];

export const StandardContentRowBlock = ({
  content: { chunks },
}: StandardContentRowBlockProps) => (
  <>
    {chunks.map(({ body, image, heading, link }) => {
      const containerColumns = getContainerCols(chunks.length);
      const columns = getContentCols(chunks.length);

      return (
        <Container rules={{ columns: containerColumns }} key={heading.text}>
          <ColumnContainer>
            <Image content={image} rules={{ hasMinSize: chunks.length < 3 }} />
            <TextContainer>
              <Heading
                content={{ ...heading, level: heading.level || 'h3' }}
                rules={{ columns }}
              />
              <RichText content={body} rules={{ columns }} />
            </TextContainer>
            <LinkButton
              content={link}
              rules={{ variant: 'secondary', size: 'small' }}
            />
          </ColumnContainer>
        </Container>
      );
    })}
  </>
);
