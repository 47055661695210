import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ArrowNext from '@engineering/icons/arrow-next';
import ArrowLast from '@engineering/icons/arrow-last';
import { Button, Ui } from '@silvertours/front-shared';

export const CarouselWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

export const SlideWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: -2rem;
`;

export const ChildrenWrapper = styled.div<{ snap: boolean }>`
  width: max-content;
  height: max-content;

  ${({ snap }) =>
    snap &&
    css`
      & > * {
        scroll-snap-align: center;
      }
    `};
`;

export const CarouselSlide = styled.div<{ snap: boolean }>`
  flex: 0 0 auto;
  overflow-x: auto;
  padding-bottom: 2rem;
  width: 100%;
  scrollbar-width: none;
  scroll-snap-type: ${({ snap }) => (snap ? 'x mandatory' : '')};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Caption = styled(Ui.Caption)`
  /* just for extending styles */
`;

export const ButtonWrapper = styled.nav`
  align-self: end;
  display: flex;
  gap: ${({ theme }) => theme.spacing[40]};

  ${({ theme }) => theme.mq('md')} {
    & + ${Caption} {
      margin-top: -4.5rem; /* align caption with start of the buttons */
      max-width: min(calc(100% - 320px), 44rem);
    }
  }
`;

export const SliderButton = styled(Button)`
  padding: 0.375rem;
`;

const ArrowIcon = css`
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
`;

export const ArrowNextIcon = styled(ArrowNext)`
  ${ArrowIcon};
`;

export const ArrowBackIcon = styled(ArrowLast)`
  ${ArrowIcon};
`;

export const CardBackground = styled.div<{ showSlideBackground: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ showSlideBackground, theme }) =>
    showSlideBackground
      ? css`
          overflow: hidden;
          border-radius: ${theme.borderRadius.md};
          background-color: ${theme.color.grey05};
        `
      : ''};
`;
