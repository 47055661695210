import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme, Ui } from '@silvertours/front-shared';
import { desaturate, lighten, tint } from 'polished';

const getPrimaryStyles = (
  defaultColor: string,
  highlightColor: string,
  theme: Theme.Theme,
) => css`
  color: ${theme.color.white};
  background-color: ${defaultColor};
  transition: background 1s;

  &:hover,
  &:focus-visible {
    background-color: ${highlightColor};
    background-image: radial-gradient(
      circle,
      transparent 1%,
      ${highlightColor} 1%
    );
    background-position: center;
    background-size: 15000%;
  }

  &:active {
    background-color: ${tint(0.15, highlightColor)};
    background-size: 100%;
    transition: background 0s;
  }
`;

const getSecondaryStyles = (
  defaultColor: string,
  highlightColor: string,
  isSmallButton: boolean,
  theme: Theme.Theme,
) => css`
  padding: ${isSmallButton ? '0.5rem 0.75rem' : '0.875rem 1.875rem'};
  border: 2px solid currentcolor;
  color: ${defaultColor};
  background-color: ${theme.color.white};
  transition-property: color, background, border-color;
  transition-duration: 1s;

  &:hover,
  &:focus-visible {
    color: ${highlightColor};
    background-color: ${theme.color.white};
    background-image: radial-gradient(
      circle,
      transparent 1%,
      ${theme.color.white} 1%
    );
    background-position: center;
    background-size: 15000%;
  }

  &:active {
    color: ${highlightColor};
    background-color: ${tint(0.8, highlightColor)};
    background-size: 100%;
    transition: background 0s;
  }
`;

const getBasicStyles = (
  isCentered: boolean,
  isSmallButton: boolean,
  theme: Theme.Theme,
) => css`
  display: block;
  margin: ${theme.spacing[50]} ${isCentered ? 'auto' : '0'} 0;
  padding: ${isSmallButton ? '0.625rem 1rem' : '1rem 2rem'};
  border: 0;
  outline: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  max-width: fit-content;
  white-space: nowrap;
  text-decoration: none;
  fill: currentcolor;
  background-position: center;
`;

const getVariantSpecificStyles = (
  isSecondaryButton: boolean,
  isSmallButton: boolean,
  theme: Theme.Theme,
) => {
  const defaultColor = theme.color.brand;
  const highlightColor = lighten(0.3, desaturate(0.6, defaultColor!));

  if (isSecondaryButton) {
    return getSecondaryStyles(
      defaultColor!,
      highlightColor,
      isSmallButton,
      theme,
    );
  }

  return getPrimaryStyles(defaultColor!, highlightColor, theme);
};

const StyledAnchor = styled.a<{
  isCentered: boolean;
  isSmallButton: boolean;
  isSecondaryButton: boolean;
}>`
  ${({ theme, isCentered, isSmallButton }) =>
    getBasicStyles(isCentered, isSmallButton, theme)}

  ${({ theme, isSecondaryButton, isSmallButton }) =>
    getVariantSpecificStyles(isSecondaryButton, isSmallButton, theme)};
`;

const StyledMaskedLink = styled(Ui.MaskedLink)<{
  isCentered: boolean;
  isSmallButton: boolean;
  isSecondaryButton: boolean;
}>`
  ${({ theme, isCentered, isSmallButton }) =>
    getBasicStyles(isCentered, isSmallButton, theme)}

  ${({ theme, isSecondaryButton, isSmallButton }) =>
    getVariantSpecificStyles(isSecondaryButton, isSmallButton, theme)};
`;

export { StyledAnchor, StyledMaskedLink };
