import { Context } from '@silvertours/front-shared';

type Locale = 'de' | 'fr' | 'es' | 'it' | 'nl' | 'en';

type HostContext = {
  baseImageUrl: string;
  baseUrl: {
    current: string;
    alternatives: { [K in Locale]?: string };
  };
};

const { useClientContext } = Context;
// have to do this because type assertion functions have some stupid restrictions
const hasContext: Context.AssertHasContext = Context.guardContext;

const useHost = () => {
  const result = useClientContext<HostContext>('host');

  hasContext<HostContext>('host', result);

  const { baseUrl, baseImageUrl } = result.value;

  return {
    alternativeBaseUrls: baseUrl.alternatives,
    baseUrl: baseUrl.current,
    formatImage(path: string) {
      if (!path) {
        return '';
      }

      const join = path.indexOf('/') === 0 ? '' : '/';

      if (path.indexOf('/car_image.php') === 0) {
        // car_image.php only works with '290' width at the moment
        return `${baseUrl.current}${join}${path.replace(
          /width=120/,
          'width=290',
        )}`;
      }

      return `${baseImageUrl}${join}${path}`;
    },
  };
};

export { useHost };
export type { HostContext };
