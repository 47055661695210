import { baseConfig, DataLayerSettings } from './dataLayer';
import type { TrackingProps } from './types';

const RegexType = /$/;
const PLACEHOLDER_VAR = '%s';

type RouteProps = {
  path: string | typeof RegexType;
  /** ISO 639-1 language code */
  lang: 'de' | 'fr' | 'es' | 'it' | 'nl';
  tracking: TrackingProps;
};

const getRoutes = (settings: DataLayerSettings) => {
  const config = {
    ...baseConfig,
    ...settings,
  };

  const routes: RouteProps[] = [
    {
      path: '/',
      lang: 'de',
      tracking: {
        ...config,
        virtPath: '/cars/bm/Index',
        landingpageKategorie: '',
        websitebereich: 'Startseite, Mietwagen-Suche',
        englishPageName: 'd/funnel/index',
        landingPageType: 'index',
      },
    },
    {
      path: '/vermieter-bewertung.html',
      lang: 'de',
      tracking: {
        ...config,
        virtPath: '/vermieter-bewertung.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/partnerlist',
        landingPageType: 'index',
      },
    },
    {
      path: '/mietwagen-flughafen.html',
      lang: 'de',
      tracking: {
        ...config,
        virtPath: '/mietwagen-flughafen.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/mietwagen/flughafen',
        landingPageType: 'index',
      },
    },
    {
      path: '/mietwagen-bahnhof.html',
      lang: 'de',
      tracking: {
        ...config,
        virtPath: '/mietwagen-bahnhof.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/mietwagen/bahnhof',
        landingPageType: 'index',
      },
    },
    {
      path: '/mietwagen.htm',
      lang: 'de',
      tracking: {
        ...config,
        virtPath: '/mietwagen.htm',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/weltweit',
        landingPageType: 'index',
      },
    },
    {
      path: '/mietwagen-bewertung.html',
      lang: 'de',
      tracking: {
        ...config,
        virtPath: '/mietwagen-bewertung.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/feedbacklist/',
        landingPageType: 'index',
      },
    },
    {
      // regex, e.g. autovermieter-avis.html
      path: /\/(autovermieter-(.*))\.html/,
      lang: 'de',
      tracking: {
        ...config,
        virtPath: `/partner-${PLACEHOLDER_VAR}.html`,
        landingpageKategorie: PLACEHOLDER_VAR,
        websitebereich: 'Landingpage, Mietwagen-Suche',
        englishPageName: `d/seo/partner-${PLACEHOLDER_VAR}`,
        landingPageType: 'seo',
      },
    },
    {
      // regex, e.g. mietwagen-muenchen.html
      path: /\/(mietwagen-(.*))\.html/,
      lang: 'de',
      tracking: {
        ...config,
        virtPath: `/mietwagen-${PLACEHOLDER_VAR}.html`,
        landingpageKategorie: PLACEHOLDER_VAR,
        websitebereich: 'Landingpage, Mietwagen-Suche',
        englishPageName: `d/seo/mietwagen/${PLACEHOLDER_VAR}`,
        landingPageType: 'seo',
      },
    },
    {
      // regex, e.g. autovermietung-berlin.html
      path: /\/(autovermietung-(.*))\.html/,
      lang: 'de',
      tracking: {
        ...config,
        virtPath: `/autovermietung-${PLACEHOLDER_VAR}.html`,
        landingpageKategorie: PLACEHOLDER_VAR,
        websitebereich: 'Landingpage, Mietwagen-Suche',
        englishPageName: `d/seo/mietwagen/${PLACEHOLDER_VAR}`,
        landingPageType: 'seo',
      },
    },
    {
      // regex, e.g. rentacar-lax.html
      path: /\/(rentacar-(.*))\.html/,
      lang: 'de',
      tracking: {
        ...config,
        virtPath: `/rentacar-${PLACEHOLDER_VAR}.html`,
        landingpageKategorie: PLACEHOLDER_VAR,
        websitebereich: 'Landingpage, Mietwagen-Suche',
        englishPageName: `d/seo/rentacar/${PLACEHOLDER_VAR}`,
        landingPageType: 'seo',
      },
    },
    {
      path: '/monde.html',
      lang: 'fr',
      tracking: {
        ...config,
        virtPath: '/monde.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/weltweit',
        landingPageType: 'index',
      },
    },
    {
      path: '/avis-client.html',
      lang: 'fr',
      tracking: {
        ...config,
        virtPath: '/avis-client.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/feedbacklist/',
        landingPageType: 'index',
      },
    },
    {
      path: '/avis-par-loueur.html',
      lang: 'fr',
      tracking: {
        ...config,
        virtPath: '/avis-par-loueur.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/partnerlist',
        landingPageType: 'index',
      },
    },
    {
      path: '/location-voiture-aeroport.html',
      lang: 'fr',
      tracking: {
        ...config,
        virtPath: '/location-voiture-aeroport.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/mietwagen/flughafen',
        landingPageType: 'index',
      },
    },
    {
      path: '/location-voiture-gare.html',
      lang: 'fr',
      tracking: {
        ...config,
        virtPath: '/location-voiture-gare.html',
        landingpageKategorie: '',
        websitebereich: 'Content-Seite',
        englishPageName: 'd/seo/mietwagen/bahnhof',
        landingPageType: 'index',
      },
    },
    {
      // regex, e.g. loueur-jucy-rentals.html
      path: /\/(loueur-(.*))\.html/,
      lang: 'fr',
      tracking: {
        ...config,
        virtPath: `/partenaire-${PLACEHOLDER_VAR}.html`,
        landingpageKategorie: PLACEHOLDER_VAR,
        websitebereich: 'Landingpage, Mietwagen-Suche',
        englishPageName: `d/seo/partenaire-${PLACEHOLDER_VAR}`,
        landingPageType: 'seo',
      },
    },
    {
      // regex, e.g. louer-gare-lille.html
      path: /\/(louer-(.*))\.html/,
      lang: 'fr',
      tracking: {
        ...config,
        virtPath: `/location-voiture-${PLACEHOLDER_VAR}.html`,
        landingpageKategorie: PLACEHOLDER_VAR,
        websitebereich: 'Landingpage, Mietwagen-Suche',
        englishPageName: `d/seo/location-voiture-${PLACEHOLDER_VAR}`,
        landingPageType: 'seo',
      },
    },
    {
      // regex, e.g. /alquiler-coche/espana/navarra
      path: /\/(alquiler-coche\/(.*))/,
      lang: 'es',
      tracking: {
        ...config,
        virtPath: `/alquiler-coche/${PLACEHOLDER_VAR}`,
        landingpageKategorie: PLACEHOLDER_VAR,
        websitebereich: 'Landingpage, Mietwagen-Suche',
        englishPageName: `d/seo/alquiler-coche/${PLACEHOLDER_VAR}`,
        landingPageType: 'seo',
      },
    },
  ];

  return routes;
};

export { getRoutes, RegexType, PLACEHOLDER_VAR };
