import styled from '@emotion/styled';
import { Button } from '@silvertours/front-shared';
import Checked from '@engineering/icons/checked';

const SwirlWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mq('lg')} {
    display: block;
    position: absolute;
    top: 30px;
    left: -170px;
    width: 550px;
    height: 113px;
    transform: rotate(-20deg);
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing[50]};
  padding-bottom: ${({ theme }) => theme.spacing[50]};
`;

const TitleWrapper = styled.div`
  button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin: 0;
    padding: 0;
    border: 0;
    appearance: none;
    background: none;
    cursor: pointer;
  }
`;

const Title = styled.p`
  margin: 0 0 1rem;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.text};
  text-align: left;

  ${({ theme }) => theme.mq('lg')} {
    margin: 0 0 2rem;
    padding-top: 140px;
    font-size: 1.5rem;
    text-align: center;
  }
`;

const FormActionArea = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing[50]};
`;

const MaxWidthWrapper = styled.div`
  max-width: 455px;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const PasswordLostWrapper = styled.p`
  margin: -0.75rem 1rem 1rem;
  font-size: 0.875rem;
  text-align: right;

  ${({ theme }) => theme.mq('lg')} {
    margin: 1rem 0 2rem;
    font-size: 1rem;
    text-align: left;
  }
`;

const SubmitButton = styled(Button)`
  ${({ theme }) => theme.mq('lg')} {
    margin: 0 auto;
    padding-left: 3rem;
    padding-right: 3rem;
    width: auto;
  }
`;

const Footer = styled.div`
  padding: 1rem;
  text-align: center;
  background-color: ${({ theme }) => theme.color.grey04};
`;

const CheckIcon = styled(Checked)`
  margin-left: 0.5em;
  width: 1rem;
  height: 1rem;
  fill: ${({ theme }) => theme.color.brand};
  vertical-align: middle;
`;

export {
  CheckIcon,
  Footer,
  FormActionArea,
  InputWrapper,
  MaxWidthWrapper,
  PasswordLostWrapper,
  StyledForm,
  SubmitButton,
  SwirlWrapper,
  Title,
  TitleWrapper,
};
