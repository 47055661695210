import styled from '@emotion/styled';
import {
  InteractiveText,
  Theme as ThemeNamespace,
} from '@silvertours/front-shared';

type Theme = ThemeNamespace.Theme;

const CollapseToggleWrapper = styled(InteractiveText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[20]};
`;

const StyledButton = styled.button<{ isCollapsed: boolean }>`
  z-index: 20;
  ${({ isCollapsed }) =>
    isCollapsed
      ? `padding-top: ${({ theme }: { theme: Theme }) => theme.spacing[70]}`
      : `
  margin-top: -3rem;
  position: absolute;
  `}
  background-color: #0000;
  border: none;
  cursor: pointer;
`;

export { CollapseToggleWrapper, StyledButton };
