import {
  Heading as HeadingView,
  SubModule,
  TabsContentModule,
} from '@silvertours/common-landingpages-view';
import { Ui } from '@silvertours/front-shared';

import { Badge, Heading, ModuleContainer } from '../../element';
import { Tabs, TabsRules } from './Tabs';

export type TabListContentBlockBehavior = {
  onTabSelected: (tabName: string) => void;
};

export type TabListContentBlockContent = Pick<
  TabsContentModule,
  'badge' | 'heading'
> & {
  modules: Pick<SubModule<unknown>, 'name' | 'position'>[];
};

export type TabListContentBlockProps = {
  behavior: TabListContentBlockBehavior;
  content: TabListContentBlockContent;
  rules?: TabsRules;
};

export const TabListContentBlock = ({
  behavior,
  content: { badge, heading, modules },
  rules,
}: TabListContentBlockProps) => {
  const { textStyle = Ui.RichTextStyle.Primary } = rules || {};
  const headingContent = heading
    ? ({ level: heading.level || 'h2', ...heading } as HeadingView)
    : undefined;

  return (
    <ModuleContainer>
      <Badge content={badge} rules={{ badge: { style: 'orange' } }} />
      <Heading
        content={headingContent}
        rules={{
          textStyle: {
            style: textStyle,
          },
        }}
      />
      <Tabs behavior={behavior} content={modules} rules={rules} />
    </ModuleContainer>
  );
};
