import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';
import SoloDaysImage from './SoloDaysPromo.jpg';

export const Wrapper = styled.aside`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  transform: translateY(-50%);
`;

export const StyledBadge = styled(Ui.Badge)`
  position: absolute;
  top: -1.25rem;
  left: 1.25rem;
`;

export const Content = styled.button`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 0;
  padding: 1em 0.9em 0.275em;
  border: 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  outline: 0;
  box-shadow: ${({ theme }) => theme.boxShadow.md};
  width: 443px;
  height: 150px;
  box-sizing: border-box;
  font: inherit;
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.6;
  text-align: left;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.grey01} url(${SoloDaysImage.src})
    no-repeat;
  background-size: cover;
  appearance: none;
  cursor: pointer;

  span:last-child {
    text-transform: uppercase;
  }
`;

export const Code = styled.code`
  display: block;
  font-family: inherit;
  font-size: 1.65em;
  line-height: 1;
`;

export const P = styled.p`
  margin-top: 1em;
`;
