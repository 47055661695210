import { StaticLink } from '@silvertours/common-landingpages-view';

export const useStaticLinks = () => ({
  getHeaderLinks: (staticLinks: StaticLink[]) =>
    staticLinks.filter(({ type }) => type === 'main'),
  getFooterLinks: (staticLinks: StaticLink[]) =>
    staticLinks.filter(
      ({ type, text, href }) => type === 'footer' && text !== '' && href !== '',
    ),
  getLegalLinks: (staticLinks: StaticLink[]) =>
    staticLinks.filter(
      ({ type, text, href }) => type === 'legal' && text !== '' && href !== '',
    ),
  getSocialMediaLinks: (staticLinks: StaticLink[]) =>
    staticLinks.filter(
      ({ type, text, href }) =>
        type === 'socialMedia' && text !== '' && href !== '',
    ),
  getAppLinks: (staticLinks: StaticLink[]) =>
    staticLinks.filter(
      ({ type, text, href }) => type === 'app' && text !== '' && href !== '',
    ),
});
