import { OffersByCategory } from '@silvertours/common-landingpages-view';
import { analytics } from '@silvertours/front-entities';
import { OffersList } from '../OffersList';
import { OfferBox } from '../OfferBox';
import { OfferDetails } from './OfferDetails';

export type Props = {
  content: OffersByCategory;
  onClick?: (query: string) => void;
  translations: {
    disclaimer: string;
    disclaimerOverlayCTA: string;
    badgeText: string;
    durationFrom: string;
    headline: string;
    offerDetailsTitle: string;
    pricePrefix: string;
    priceSuffix: string;
    searchButtonText: string;
    carTypes: {
      [Key: string]: string;
      compact: string;
      convertible: string;
      medium: string;
      mini: string;
      premium: string;
      small: string;
      sport: string;
      station: string;
      suv: string;
      transporter: string;
      upper: string;
      van: string;
    };
  };
};

const MAX_OFFERS = 3;

const OffersModule = ({ content, onClick, translations }: Props) => (
  <OffersList
    badge={translations.badgeText}
    disclaimer={translations.disclaimer}
    disclaimerOverlayCTA={translations.disclaimerOverlayCTA}
    title={translations.headline}
    titleColor="white"
    waveColor="orange"
    offerDetails={
      <OfferDetails content={content} translations={translations} />
    }
    offerDetailsTitle={translations.offerDetailsTitle}
  >
    {Object.entries(content)
      .slice(0, MAX_OFFERS)
      .map(([category, offersData]) => {
        const handleClick = onClick
          ? () => {
              if (onClick) {
                onClick(offersData.searchQuery);
                analytics.gtm.trackSearchButtonOfferBoxClicked();
              }
            }
          : undefined;

        return (
          <OfferBox
            category={translations.carTypes[category]}
            image={offersData.offers[0].image}
            key={category}
            onClick={handleClick}
            offers={offersData.offers.map(offer => ({
              currency: 'EUR',
              price: offer.price,
              supplier: offer.supplier,
            }))}
            translations={{
              pricePrefix: translations.pricePrefix,
              priceSuffix: translations.priceSuffix,
              searchButtonText: translations.searchButtonText,
            }}
          />
        );
      })}
  </OffersList>
);

export { OffersModule };
