import { ReactNode } from 'react';

import { Container } from './ContentContainer.styles';

type ContentContainerProps = {
  children: ReactNode;
};

const ContentContainer = ({ children }: ContentContainerProps) => (
  <Container>{children}</Container>
);

export { ContentContainer };
export type { ContentContainerProps };
