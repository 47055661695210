import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';
import type { LinkAs, LinkVariant } from './types';

const StyledLink = styled.a<{
  as?: LinkAs;
  variant?: LinkVariant;
  disabled?: boolean;
  $disabled?: boolean;
  blendIn?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  color: ${({ theme, variant, blendIn, $disabled }) => {
    if ($disabled) {
      return '#b6bfc1';
    }
    if (blendIn) {
      return 'inherit';
    }
    return variant === 'secondary' ? theme.color.secondary : theme.color.brand;
  }};
  font: inherit;
  text-decoration: ${({ variant, blendIn, $disabled }) => {
    if ($disabled) {
      return 'none';
    }
    return variant === 'text' || blendIn ? 'underline' : 'none';
  }};
  text-underline-offset: 0.125rem;
  transition: text-underline-offset 0.2s linear;
  cursor: ${({ $disabled }) => ($disabled ? '' : 'pointer')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : '')};

  &:hover {
    color: ${({ theme }) => shade(0.271, theme.color.brand!)};
    text-decoration: ${({ blendIn }) => (blendIn ? 'none' : 'underline')};
  }

  &:focus-visible {
    border-radius: 0.5rem;
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
    text-decoration: ${({ blendIn }) => (blendIn ? 'none' : 'underline')};
  }

  &:active {
    color: ${({ theme }) => shade(0.271, theme?.color.brand!)};
    text-decoration: ${({ blendIn }) => (blendIn ? 'none' : 'underline')};
    text-underline-offset: 0.25rem;
  }

  ${({ as }) =>
    as === 'button'
      ? css`
          margin: 0;
          padding: 0;
          border: 0;
          background: none;
        `
      : ''}
`;

export { StyledLink };
