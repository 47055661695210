import styled from '@emotion/styled';

const StyledQuote = styled.q`
  quotes: '„ ' ' “' '»' '«';
  color: ${({ theme }) => theme.color.text};
  font-style: italic;
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
`;

export { StyledQuote };
