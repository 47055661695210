import { useTranslations } from 'next-intl';
import {
  Card,
  LogoContainer,
  Lower,
  StarsContainer,
  Upper,
} from './Trustpilot.styles';

type Props = {
  className?: string;
  label: string;
  link: string;
  logo: string;
  numberOfReviews: number;
  stars: string;
  trustScore: number;
};

const Trustpilot = ({
  label,
  link,
  logo,
  numberOfReviews,
  stars,
  trustScore,
  className,
}: Props) => {
  const t = useTranslations('features.trustElements.trustpilot');

  const handleCardClick = () => {
    window.open(link, '_blank');
  };

  if (!logo || !stars) {
    return null;
  }

  return (
    <Card onClick={handleCardClick} className={className}>
      <Upper>
        {/* eslint-disable react/no-danger */}
        <LogoContainer dangerouslySetInnerHTML={{ __html: logo }} />
        <StarsContainer dangerouslySetInnerHTML={{ __html: stars }} />
        {/* eslint-enable react/no-danger */}
      </Upper>
      <Lower>
        {t('trustScore', { score: trustScore })} |
        <b>&nbsp;{t('reviews', { numberOfReviews })}&nbsp;</b> | {label}
      </Lower>
    </Card>
  );
};

export { Trustpilot };
