import {
  Image,
  RichText as RichTextData,
} from '@silvertours/common-landingpages-view';

import { RichText } from '../../../element';
import { Figure } from './Figure';
import { AccordionItemContentWrapper } from './AccordionItem.styles';

export type AccordionItemContentProps = {
  content: RichTextData;
  image?: Image;
};

export const AccordionItemContent = ({
  content,
  image,
}: AccordionItemContentProps) => (
  <AccordionItemContentWrapper>
    <RichText
      content={content}
      rules={{
        columns: [[10], [11, 'sm'], [8, 'lg'], [7, 'lg', { override: true }]],
      }}
    />
    <Figure
      content={{ image }}
      rules={{
        columns: [[10], [11, 'sm'], [5, 'lg']],
      }}
    />
  </AccordionItemContentWrapper>
);
