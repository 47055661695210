import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Styles, Theme, Ui } from '@silvertours/front-shared';

type SegmentContainerProps = {
  fillColor?: keyof Theme.ColorProps;
  hasPadding: boolean;
  spacingScale: number;
  needLayoutContentPadding?: boolean;
};

const SegmentContainer = styled.section<SegmentContainerProps>`
  display: block;
  position: relative;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme, fillColor }) =>
    fillColor ? theme.color[fillColor] : 'transparent'};
  ${({ needLayoutContentPadding = true }) =>
    needLayoutContentPadding && Styles.Global.layoutContentOnlyPadding};

  ${({ theme, hasPadding, spacingScale }) =>
    hasPadding &&
    css`
      padding-top: ${`${spacingScale * 0.75}rem`};
      padding-bottom: ${`${spacingScale * 2}rem`};
      ${theme.mq('xl')} {
        padding-top: ${`${spacingScale * 1.25}rem`};
        padding-bottom: ${`${spacingScale * 2.5}rem`};
      }
    `};
`;

const HeaderContainer = SegmentContainer.withComponent('header');

const NavigationContainer = SegmentContainer.withComponent('nav');

type SegmentImageContainerProps = {
  isStage?: boolean;
  includeSearch?: boolean;
  isMinimal?: boolean;
  isPlain?: boolean;
};

const SegmentImageContainer = styled.div<SegmentImageContainerProps>`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  display: block;

  img {
    /* Stage image usally has important details on right side, make them visible in any resolution */
    ${({ isStage, includeSearch }) =>
      isStage &&
      includeSearch &&
      css`
        width: 100%;
        object-fit: cover;
        object-position: right;
      `}
  }
  ${({ theme, isStage, includeSearch }) =>
    isStage &&
    !includeSearch &&
    css`
      position: relative;
      aspect-ratio: 1.66/1;

      ${theme.mq('md')} {
        aspect-ratio: 2/1;
      }

      ${theme.mq('lg')} {
        position: absolute;
        aspect-ratio: unset;
      }
    `}

  ${({ theme, isStage, includeSearch, isMinimal }) =>
    isStage &&
    !includeSearch &&
    isMinimal &&
    css`
      position: relative;
      aspect-ratio: 6/1;

      ${theme.mq('md')} {
        aspect-ratio: 6/1;
      }

      ${theme.mq('lg')} {
        position: absolute;
        aspect-ratio: unset;
      }
    `}

    ${({ isPlain, theme }) =>
    isPlain &&
    css`
      display: none;
      ${theme.mq('sm')} {
        display: block;
      }
    `}

  ${({ theme }) => theme.mq('lg')} {
    height: 100%;
  }
`;

const SegmentForegroundContainer = styled.div`
  left: 0;
  width: 100%;

  ${({ theme }) => theme.mq('lg')} {
    position: relative;
    margin: 0 auto;
  }
`;

const SegmentImage = styled(Ui.Image)`
  object-fit: cover;
`;

const Picture = styled.picture<{ $breakpoint: string }>`
  display: none;

  ${({ theme, $breakpoint }) => theme.mq($breakpoint)} {
    display: block;
  }
`;

export {
  HeaderContainer,
  NavigationContainer,
  Picture,
  SegmentContainer,
  SegmentForegroundContainer,
  SegmentImageContainer,
  SegmentImage,
};
