import { ReactNode, useState, useRef } from 'react';
import { I18nLegacy } from '@silvertours/front-legacy-shared';
import { Ui } from '@silvertours/front-shared';
import {
  SearchSuggestion,
  useSearchSuggestions,
  getLocation,
  Runtime,
} from '@silvertours/front-entities';
import { LocationSuggest } from '../LocationSuggest';
import { SearchHistory } from '../SearchHistory/SearchHistory';

import { Container } from './SearchInput.styles';
import { useSearchContext } from '../context';
import type { RentalDirection } from '../context/types';

type CommonSearchInputProps = {
  direction: RentalDirection;
  label: string;
  placeholder: string;
  id: string;
  name?: string;
  icon?: ReactNode;
};

type DepartureProps = {
  direction: 'departure';
  onServiceNumbersSubmit: () => void;
} & CommonSearchInputProps;

type DestinationProps = {
  direction: 'destination';
} & CommonSearchInputProps;

type SearchInputProps = DepartureProps | DestinationProps;

const isDeparture = (props: SearchInputProps): props is DepartureProps =>
  (props as DepartureProps).direction === 'departure';

const getSubmit = (props: SearchInputProps) =>
  isDeparture(props) ? props.onServiceNumbersSubmit : undefined;

const SearchInput = (props: SearchInputProps) => {
  const {
    getInputValue,
    isInternalCall,
    state,
    setInputValue,
    makeSetLocation,
    setError,
  } = useSearchContext();
  const { direction, icon, label, placeholder, id, name } = props;
  const handleSubmit = getSubmit(props);
  const setLocation = makeSetLocation(direction);
  const value = getInputValue(direction);
  const shouldSearchServiceNumbers =
    isInternalCall && direction === 'departure' && value[0] === '#';

  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [showLocationSuggest, setShowLocationSuggest] = useState(false);
  const fieldRef = useRef<HTMLInputElement>(null);

  const { language, market } = I18nLegacy.useLocale();
  const { consumerApiSettings } = Runtime.useConsumerApi();

  const { clearResults, fetchSuggestions, loading, results } =
    useSearchSuggestions(language, market, consumerApiSettings);

  const handleChange = (inputValue: string) => {
    setInputValue(direction, inputValue);

    if (inputValue === '') {
      clearResults();
      setShowSearchHistory(true);
      setShowLocationSuggest(false);
      setLocation(null);
    } else {
      setShowSearchHistory(false);
      if (shouldSearchServiceNumbers) {
        setShowLocationSuggest(false);
      } else {
        setShowLocationSuggest(true);
        setLocation(null);
        fetchSuggestions(inputValue);
      }
    }
  };

  const handleSuggestionValue = (suggestion?: SearchSuggestion) => {
    if (suggestion) {
      const location = getLocation(suggestion);
      setShowLocationSuggest(false);
      setInputValue(direction, location.name);
      setLocation(location);
      clearResults();
    } else {
      handleChange(value);
      if (shouldSearchServiceNumbers && !!handleSubmit) {
        handleSubmit();
      }
    }
  };

  if (!state) {
    return null;
  }

  return (
    <Container role="combobox" aria-expanded={showSearchHistory}>
      <Ui.Input
        ref={fieldRef}
        value={value}
        label={label}
        name={name ?? id}
        id={id}
        placeholder={placeholder}
        icon={icon}
        error={state.locationError}
        autoComplete="off"
        forcedLabel
        onFocus={e => {
          setError({ kind: 'locationError', message: '' });
          if (e.target.value === '') {
            setShowSearchHistory(true);
          } else {
            fetchSuggestions(e.target.value);
            setShowLocationSuggest(true);
          }
        }}
        onBlur={e => {
          if (!e.relatedTarget?.className.includes('searchHistory')) {
            setShowSearchHistory(false);
          }
          if (
            !(
              e.relatedTarget?.className.includes('inputCloseIcon') ||
              e.relatedTarget?.id.includes('locationSuggest')
            )
          ) {
            setShowLocationSuggest(false);
          }
        }}
        onChange={event => handleChange(event.target.value)}
      />
      {showSearchHistory && (
        <SearchHistory
          onItemClick={clearResults}
          onLastElementBlur={() => {
            setShowSearchHistory(false);
          }}
        />
      )}

      {showLocationSuggest && (
        <LocationSuggest
          connectedInputContainer={fieldRef}
          loading={loading}
          onLastElementBlur={() => {
            setShowLocationSuggest(false);
          }}
          onItemClick={handleSuggestionValue}
          results={results}
        />
      )}
    </Container>
  );
};

export { SearchInput };
