import React, { useState, useRef, useEffect } from 'react';
import { useIntersection } from 'react-use';
import { analytics } from '@silvertours/front-entities';
import { InfoBanner as InfoBannerType } from '@silvertours/back-domain-page';
import Close from '@engineering/icons/close';
import { Ui } from '@silvertours/front-shared';
import { Wrapper, CloseButton } from './InfoBanner.styles';

const InfoBanner = ({ closable, sticky, theme, content }: InfoBannerType) => {
  const [isInfoBannerVisible, setInfobannerVisible] = useState(true);

  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  useEffect(() => {
    const banner = document.getElementById('infobanner');
    const links = banner?.getElementsByTagName('a');
    if (links) {
      const linkList = [].slice.call(links);
      linkList.forEach(link => {
        const anchor = link as HTMLAnchorElement;
        const label = anchor.pathname.replace(/^\/|\/$/g, '');
        anchor.onclick = () => analytics.gtm.trackInfoBannerLinkClicked(label);
      });
    }
  }, []);

  const onClose = () => {
    setInfobannerVisible(false);
    analytics.gtm.trackInfoBannerCloseClicked();
  };

  return (
    <div ref={intersectionRef}>
      {isInfoBannerVisible && (
        <Wrapper
          isIntersecting={
            sticky &&
            Boolean(intersection && intersection.intersectionRatio < 1)
          }
          className={theme}
          id="infobanner"
        >
          <Ui.RichText content={content} />
          {closable && (
            <CloseButton
              icon={Close}
              title="close" // @TODO: Add translation
              onClick={onClose}
            />
          )}
        </Wrapper>
      )}
    </div>
  );
};
export { InfoBanner };
