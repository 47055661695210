import React from 'react';
import { Theme } from '@silvertours/front-shared';
import { useMedia } from 'react-use';
import styled from '@emotion/styled';
import { useTranslations } from 'next-intl';
import { useSiteInfo } from '@silvertours/front-entities';
import Phone from '@engineering/icons/phone';
import { FamilyTripPictogram } from './FamilyTripPictogram';
import { Schedule } from './Schedule';

const StyledFamilyTripPictogram = styled(FamilyTripPictogram)`
  display: block;
  width: 100%;
  height: auto;
  ${({ theme }) => theme.mq('md')} {
    margin-top: 1.5rem;
    width: calc(100% - 350px);
  }
  ${({ theme }) => theme.mq('lg')} {
    margin-top: 0;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: block;
  margin: 1rem 0;
  padding: 2rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 10px 25px rgb(0 0 0 / 8%);
  font-weight: 600;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  fill: currentcolor;

  ${({ theme }) => theme.mq('lg')} {
    margin-top: 0;
  }
`;

const Text = styled.span`
  display: block;
  font-size: 1.125rem;
  font-family: ${({ theme }) => theme.fontFamily.default};
`;

const PhoneNumber = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.25rem;
  color: ${({ theme }) => theme.color.brand};
  fill: currentcolor;
  white-space: nowrap;
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
  }
`;

const PhoneNumberIcon = styled(Phone)`
  fill: currentcolor;
  width: 1.5rem;
  height: 1.5rem;
`;

const Support = () => {
  const t = useTranslations('features.supportInfoLegacy');
  const {
    settings: { featureToggles, phoneNumber },
  } = useSiteInfo();

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    true,
  );

  if (featureToggles.hideInfoline) {
    return <StyledFamilyTripPictogram />;
  }

  const handleClick = () => {
    window.location.assign(`tel:${t('phoneNumber.link')}`);
  };

  return (
    <Wrapper>
      {!isMobile && <Text>{t('contactUs.title')}</Text>}
      <PhoneNumber onClick={handleClick}>
        <PhoneNumberIcon />
        <span>{phoneNumber}</span>
      </PhoneNumber>
      {isMobile && <Schedule />}
    </Wrapper>
  );
};

export { Support };
