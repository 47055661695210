import {
  APIProvider,
  ControlPosition,
  Map,
  useMap,
} from '@vis.gl/react-google-maps';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Theme, Ui } from '@silvertours/front-shared';
import { Station } from './types';
import { Markers } from './Markers';
import { MobileStationInfoContainer, Wrapper } from './GoogleMap.styles';
import { gtm } from '../analytics';
import { StationInfoBox } from './StationInfoBox';
import { MapContentContext } from './Context';
import { Services, useConsent } from '../Runtime';

const defaultZoom = 14;

// Map ID for styling based on predefined theming
const MAP_ID = '90171eac382c3c31';

type GoogleMapProps = {
  bbox?: {
    east: number;
    south: number;
    north: number;
    west: number;
  };
  stations?: Station[];
  translations: {
    supplier: string;
    cookieConsentMessage: string;
    cookieConsentLink: string;
  };
};

const getBoundsZoomLevel = (
  bounds: google.maps.LatLngBounds,
  mapDim: { height: number; width: number },
) => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

const MapComponent = ({ bbox, stations, translations }: GoogleMapProps) => {
  const map = useMap();
  const ref = useRef<HTMLDivElement>(null);
  const [selectedMarker, selectMarker] = useState<string | null>(null);
  const [zoom, setZoom] = useState(defaultZoom);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  const handleStationClick = (
    stationId: string | null,
    stationKind: string | null,
  ) => {
    if (stationKind) {
      // Does not track deselecting a station
      gtm.trackStationMapStationClick(stationKind);
    }
    selectMarker(stationId);
  };

  useEffect(() => {
    if (map && ref.current) {
      const bounds = new google.maps.LatLngBounds();
      if (bbox) {
        bounds.extend({ lat: bbox.north, lng: bbox.west });
        bounds.extend({ lat: bbox.south, lng: bbox.west });
        bounds.extend({ lat: bbox.north, lng: bbox.east });
        bounds.extend({ lat: bbox.south, lng: bbox.east });
      } else if (stations)
        stations.forEach(station => {
          bounds.extend({ lat: station.latitude, lng: station.longitude });
        });
      map.fitBounds(bounds);
      map.panToBounds(bounds);

      const { width: actualWidth, height: actualHeight } =
        ref.current.getBoundingClientRect();

      const zoomLevel = getBoundsZoomLevel(bounds, {
        height: actualHeight,
        width: actualWidth,
      });
      setZoom(zoomLevel);
      setCenter({
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      });
    }
  }, [bbox, map, ref, stations]);

  const isMobile = Theme.useIsMobileTouchDevice();
  const selectedStation = stations?.find(
    station => station.id === selectedMarker,
  );

  const contextValue = useMemo(() => ({ translations }), [translations]);

  const handleClose = () => {
    selectMarker(null);
  };

  return stations?.length ? (
    <MapContentContext.Provider value={contextValue}>
      <Wrapper ref={ref}>
        <Map
          mapId={MAP_ID}
          style={{ width: '100%', height: '100%' }}
          defaultCenter={center}
          defaultZoom={zoom}
          zoomControlOptions={{ position: ControlPosition.TOP_RIGHT }}
          streetViewControl={false}
          mapTypeControl={false}
        >
          <Markers
            stations={stations}
            select={handleStationClick}
            selected={selectedMarker}
          />
        </Map>
      </Wrapper>
      {isMobile && selectedStation && (
        <MobileStationInfoContainer>
          <StationInfoBox
            selected
            station={selectedStation}
            closable
            handleClose={handleClose}
          />
        </MobileStationInfoContainer>
      )}
    </MapContentContext.Provider>
  ) : null;
};

export const GoogleMap = ({
  apiKey,
  ...props
}: GoogleMapProps & { apiKey?: string }) => {
  const consent = useConsent(Services.googlemaps);

  if (!consent || typeof window === 'undefined') {
    return (
      <Ui.Message>
        {props.translations.cookieConsentMessage}{' '}
        <Ui.TextLink href="#uc-central-modal-show" variant="text">
          {props.translations.cookieConsentLink}
        </Ui.TextLink>
      </Ui.Message>
    );
  }

  return (
    <APIProvider apiKey={apiKey ?? ''}>
      <MapComponent {...props} />
    </APIProvider>
  );
};
