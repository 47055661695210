import { setMinutes } from 'date-fns/setMinutes';
import { setHours } from 'date-fns/setHours';
import { de as LocaleDe } from 'date-fns/locale/de';
import { fr as LocaleFr } from 'date-fns/locale/fr';
import { es as LocaleEs } from 'date-fns/locale/es';
import { it as LocaleIt } from 'date-fns/locale/it';
import { nl as LocaleNl } from 'date-fns/locale/nl';
import { enUS as LocaleEn } from 'date-fns/locale/en-US';
import { tr as LocaleTu } from 'date-fns/locale/tr';

export const dateLocales = {
  de: LocaleDe,
  fr: LocaleFr,
  es: LocaleEs,
  it: LocaleIt,
  nl: LocaleNl,
  en: LocaleEn,
  tu: LocaleTu,
};

export const addTimeToDate = (date: Date, hours: number, minutes: number) =>
  setMinutes(setHours(date, hours), minutes);
