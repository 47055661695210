import { isAxiosError } from 'axios';
import { http } from '@silvertours/front-shared';

type SessionData = {
  securityTokenValue: string;
  isMobileUserAgent: boolean;
  gtmEnvironment: 'dev' | 'live';
  anonymizeIP: boolean;
};

enum LoginError {
  Blocked = 'blocked',
  ExpiredPassword = 'expiredPassword',
  Unauthorized = 'unauthorized',
  Unknown = 'unknown',
  UnverifiedEmail = 'unverifiedEmail',
  Validation = 'validation',
  WeakPassword = 'weakPassword',
}

type LoginResponse = {
  access_token: string;
  id_token: string;
  refresh_token: string;
};

type LoginErrorResponse = {
  type: string;
  details?: string[];
};

type AccountSessionResponse = {
  access_token: string | null;
  refresh_token: string | null;
};

type LoginData = {
  loggedIn: boolean;
  error?: LoginError;
};

const handleLoginError = (error: string) => {
  switch (error) {
    case '/general/errors/bad-request':
    case '/auth/errors/login/bad-request':
    case '/auth/errors/login/invalid-credentials':
      return LoginError.Validation;
    case '/auth/errors/login/expired-password':
      return LoginError.ExpiredPassword;
    case '/auth/errors/login/unverified-email':
      return LoginError.UnverifiedEmail;
    case '/auth/errors/login/weak-password':
      return LoginError.WeakPassword;
    case '/general/errors/undefined':
    case '/auth/errors/login/undefined':
    default:
      return LoginError.Unknown;
  }
};

const getAccountSession = async (url: string) => {
  const res = await http.axiosInstance.get(url, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.data as AccountSessionResponse;
};

const updateAccountSession = async (
  url: string,
  accessToken: string,
  refreshToken: string,
) => {
  const res = await http.axiosInstance.post(url, {
    access_token: accessToken,
    refresh_token: refreshToken,
  });
  return res.status === 204;
};

const login = async (
  url: string,
  urlSession: string,
  username: string,
  password: string,
  whitelabel: string,
) => {
  try {
    const res = await http.axiosInstance.post<
      LoginResponse | LoginErrorResponse
    >(
      url,
      {
        username,
        password,
      },
      {
        headers: {
          'X-Whitelabel': whitelabel,
        },
      },
    );

    if (res.status >= 200 && res.status <= 299) {
      await updateAccountSession(
        urlSession,
        (res.data as LoginResponse).access_token,
        (res.data as LoginResponse).refresh_token,
      );
    }

    return {
      loggedIn: true,
    } as LoginData;
  } catch (error) {
    if (isAxiosError(error)) {
      return {
        loggedIn: false,
        error: handleLoginError(error.response?.data.type),
      } as LoginData;
    }

    return {
      loggedIn: false,
      error: LoginError.Unknown,
    } as LoginData;
  }
};

const resendConfirmUser = async (
  url: string,
  emailAddress: string,
  whitelabel: string,
) => {
  try {
    const res = await http.axiosInstance.post(
      url,
      {
        email_address: emailAddress,
      },
      {
        headers: {
          'X-Whitelabel': whitelabel,
        },
      },
    );

    return res.status === 204;
  } catch (error) {
    return false;
  }
};

export { login, getAccountSession, resendConfirmUser, LoginError };
export type { SessionData };
