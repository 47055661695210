import { forwardRef, type AnchorHTMLAttributes } from 'react';
import type { LinkAs, LinkVariant } from './types';
import { StyledLink } from './TextLink.styles';

type Props = {
  text?: string;
  as?: LinkAs;
  variant?: LinkVariant;
  disabled?: boolean;
  blendIn?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const TextLink = forwardRef<HTMLAnchorElement | null, Props>(
  (
    {
      children,
      text,
      as = 'a',
      variant = 'primary',
      disabled = false,
      blendIn = false,
      ...props
    },
    ref,
  ) => (
    <StyledLink
      ref={ref}
      type={as === 'button' ? 'button' : undefined}
      {...props}
      href={as === 'button' ? undefined : props.href}
      as={as}
      variant={variant}
      $disabled={disabled}
      disabled={disabled && as === 'button' ? disabled : undefined}
      aria-disabled={disabled ? 'true' : undefined}
      blendIn={blendIn}
    >
      {children || text}
    </StyledLink>
  ),
);

export { TextLink };
