import React from 'react';
import { LoadingIndicator, Wrapper } from './ActivityIndicator.styles';

const ActivityIndicator = () => (
  <Wrapper>
    <LoadingIndicator />
  </Wrapper>
);

export { ActivityIndicator };
