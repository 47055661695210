import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ArrowLast from '@engineering/icons/arrow-last';
import ArrowNext from '@engineering/icons/arrow-next';
import Close from '@engineering/icons/close';
import { Theme } from '@silvertours/front-shared';

type AffiliateProps = {
  customColor?: string;
};

const Header = styled.div`
  position: relative;
  min-height: 2.5rem;
  margin-bottom: 0.625rem;
`;

const Title = styled.b<AffiliateProps>`
  display: inline-block;
  padding: 0.75rem 0 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.125;

  ${({ theme }) => theme.mq('sm')} {
    max-width: 18.5rem;
  }

  ${({ customColor }) =>
    customColor && `color: ${customColor}; font-weight: 700;`}
`;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.color.text};
  font-size: 1rem;
`;

const FormContainer = styled.div`
  width: 505px;
  flex-grow: 0;

  > *:not(:last-of-type) {
    margin-bottom: 0.625rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;

  aside {
    width: 320px;
    flex-grow: 1;
  }
`;

const ButtonWrapper = styled.div<AffiliateProps>`
  button {
    margin: 0.625rem 0;
    ${({ customColor }) => customColor && `background-color: ${customColor};`}
  }
`;

type FooterLinkProps = {
  iconOnLeft?: boolean;
};

const FooterLink = styled.div<FooterLinkProps>`
  text-align: right;
  margin-bottom: 0;

  a {
    ${({ iconOnLeft }) => iconOnLeft && 'flex-direction: row-reverse;'}
  }
`;

const getOptionsStyles = (theme: Theme.Theme) => css`
  width: 0.75rem;
  height: 0.75rem;
  fill: ${theme.color.brand};
`;

const OptionsOpenIcon = styled(ArrowLast)`
  ${({ theme }) => getOptionsStyles(theme)};
`;

const OptionsCloseIcon = styled(ArrowNext)`
  ${({ theme }) => getOptionsStyles(theme)};
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export {
  ButtonWrapper,
  FooterLink,
  FormContainer,
  Header,
  StyledForm,
  Subtitle,
  Title,
  OptionsOpenIcon,
  OptionsCloseIcon,
  CloseIcon,
};
