import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button, Theme } from '@silvertours/front-shared';

import { SpacingDirection } from '../rule';

type ExpandButtonProps = {
  isCentered: boolean;
  isExpanded: boolean;
  spacingDirection: SpacingDirection;
};

const getExpandedStyles = (theme: Theme.Theme) => css`
  margin-block: 2rem;
  min-width: 100%;

  ${theme.mq('sm')} {
    min-width: 100px;
  }
`;

const getCenteredStyles = (theme: Theme.Theme) => css`
  display: flex; /* must be a block-level element for centering */
  margin-inline: auto;
  justify-content: center;

  ${theme.mq('sm')} {
    left: 50%;
  }
`;

const ExpandButton = styled(Button)<ExpandButtonProps>`
  margin-top: ${({ spacingDirection }) =>
    spacingDirection === 'bottom' ? '1.5rem' : ''};
  ${({ theme, isExpanded }) => isExpanded && getExpandedStyles(theme)};
  ${({ theme, isCentered }) => isCentered && getCenteredStyles(theme)};
`;

export { ExpandButton };
