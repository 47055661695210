import type { ReactNode } from 'react';
import { useMedia } from 'react-use';

import { ContentModuleStyle } from '@silvertours/common-landingpages-view';

import { WaveSets } from './WaveSets';

import {
  FixedWave,
  FixedWaveContent,
  FixedWaveWaves,
  FixedWaveEnd,
  ScalableWave,
  ScalableWaveEnd,
} from './Wave.styles';

type WaveProps = {
  type: keyof typeof WaveSets;
  children: ReactNode;
  fullWidth?: boolean;
  backgroundStyle?: ContentModuleStyle;
};

const Wave = ({ type, children, fullWidth, backgroundStyle }: WaveProps) => {
  const isLargeViewport = useMedia('(min-width: 992px)', false);
  const size = isLargeViewport ? 'large' : 'small';

  const { start, end, height } = WaveSets[type];
  const startWave = start?.[size];
  const endWave = end?.[size];
  const heightWave = height?.[size];

  // Fixed waves have a fixed height and are positioned underneath the content
  // (basically a background that does not get cut off).
  const isFixedWave = type.startsWith('fixed');
  if (isFixedWave) {
    return (
      <FixedWave>
        <FixedWaveWaves
          isFullWidth={fullWidth}
          height={heightWave}
          backgroundStyle={backgroundStyle}
          aria-hidden="true"
        >
          {startWave}
          {endWave && <FixedWaveEnd>{endWave}</FixedWaveEnd>}
        </FixedWaveWaves>
        <FixedWaveContent>{children}</FixedWaveContent>
      </FixedWave>
    );
  }

  return (
    <ScalableWave
      waveType={type}
      isFullWidth={fullWidth}
      backgroundStyle={backgroundStyle}
    >
      {startWave}
      {children}
      {endWave && (
        <ScalableWaveEnd waveType={type} isFullWidth={fullWidth}>
          {endWave}
        </ScalableWaveEnd>
      )}
    </ScalableWave>
  );
};

export { Wave };
