import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const scalable1 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 57">
        <path d="M0 0h375v52.916c-48 7.144-101.5 4.166-158.5-3.158s-101-9.553-213.285 0A68.175 68.175 0 0 1 0 50.782V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M0 0h1440v88.84c-148.5 19.626-318.5 9.198-573 .243S466.5 59.23 12.344 83.538c-4.16.628-8.275 1.2-12.344 1.718V0Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 57">
        <path d="M375 60V8.028C203 25.5 143.5 23.5 0 0v60h375Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M1440 100.307V13.686C796.5 82.188 527.5 66.9 0 .306v100h1440Z" />
      </WaveSvg>
    ),
  },
} as WaveSet;

export { scalable1 };
