import { MouseEventHandler } from 'react';

import { ActiveButton, InactiveButton } from './TabButton.styles';
import { RichTextStyle } from '../RichText';
import { InteractiveText } from '../../Text';

export type TabBehavior = {
  onSelectTab: (tabName: string) => void;
};

export type TabRules = {
  activeTabName: string;
  textStyle?: RichTextStyle;
};

export type TabContent = {
  name: string;
  position: number;
  tabGroup: string;
};

export type TabProps = {
  behavior: TabBehavior;
  content: TabContent;
  rules?: TabRules;
};

const TabButton = ({
  behavior: { onSelectTab },
  content: { name, position, tabGroup },
  rules,
}: TabProps) => {
  const { activeTabName, textStyle = RichTextStyle.Primary } = rules || {};
  const isCurrentTab = activeTabName === name;

  const handleTabClick: MouseEventHandler<HTMLButtonElement> = ev => {
    ev.preventDefault();
    onSelectTab(name);
    ev.currentTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const Button = isCurrentTab ? ActiveButton : InactiveButton;

  return (
    <Button
      aria-selected={isCurrentTab}
      aria-controls={`${tabGroup}-panel-${position}`}
      id={`${tabGroup}-tab-${position}`}
      key={name}
      onClick={handleTabClick}
      role="tab"
      type="button"
      textStyle={textStyle}
    >
      <InteractiveText inline textColor="current">
        {name}
      </InteractiveText>
    </Button>
  );
};

export { TabButton };
