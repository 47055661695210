import styled from '@emotion/styled';

const H1Title = styled.h1`
  color: ${({ theme }) => theme.color.text};
  margin: 0.625rem 0;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.875rem;
  font-weight: normal;
  line-height: 1.875rem;
`;

const H2Title = styled.h2`
  color: ${({ theme }) => theme.color.text};
  margin: 0 0 1rem;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.125;
`;

const Plain = styled.p`
  line-height: 1.5;
  color: ${({ theme }) => theme.color.text};
`;

const Callout = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.text};
  margin-bottom: 1rem;
`;

const Small = styled.p`
  font-size: 0.75rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.text};
`;

const Tiny = styled.p`
  font-size: 0.625rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.text};
`;

export { Callout, H1Title, H2Title, Plain, Small, Tiny };
