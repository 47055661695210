import { Icon as IconData } from '@silvertours/common-landingpages-view';

import { Icon } from '../../element';
import { IconGridContainer, IconWrapper, Label } from './IconGrid.styles';

export type IconGridProps = {
  content: Array<IconData>;
};

export const IconGrid = ({ content }: IconGridProps) => (
  <IconGridContainer>
    {content.map(icon => {
      const { key, label } = icon;

      return (
        <IconWrapper key={key}>
          <Icon
            content={icon}
            rules={{ color: 'white', indent: 40, size: 'full' }}
          />
          <Label textColor="white">{label}</Label>
        </IconWrapper>
      );
    })}
  </IconGridContainer>
);
