/* eslint-disable no-underscore-dangle */
import { RefObject, FocusEvent, useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';

import {
  SearchSuggestion,
  useKeyNavigation,
} from '@silvertours/front-entities';
import { Theme } from '@silvertours/front-shared';

import {
  StyledList,
  StyledButton,
  ErrorMessage,
  ListItem,
} from './LocationSuggest.styles';
import { SearchResultItem } from './SearchResultItem';
import { useSearchContext } from '../context';
import { useStageContext } from '../../StageLegacy/context';

type LocationSuggestProps = {
  connectedInputContainer?: RefObject<HTMLInputElement>;
  onLastElementBlur: (event: FocusEvent<HTMLElement, Element>) => void;
  loading: boolean;
  onItemClick: (suggestion?: SearchSuggestion) => void;
  results: Array<SearchSuggestion> | null;
};

const LocationSuggest = ({
  connectedInputContainer,
  onLastElementBlur,
  loading,
  onItemClick,
  results,
}: LocationSuggestProps) => {
  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const { state } = useSearchContext();
  const { setStageImage } = useStageContext();
  const listRef = useRef<HTMLUListElement>(null);

  const isMobileTouchDevice = Theme.useIsMobileTouchDevice();

  const handleEnter = (item?: SearchSuggestion) => {
    onItemClick(item);
  };

  const { focusedItem, onMouseEnter, onMouseLeave } =
    useKeyNavigation<SearchSuggestion>(results || [], listRef, handleEnter);

  useEffect(() => {
    if (isMobileTouchDevice && connectedInputContainer?.current) {
      connectedInputContainer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isMobileTouchDevice, connectedInputContainer]);

  const handleClick = (suggestion: SearchSuggestion) => {
    onItemClick(suggestion);
    setStageImage(suggestion.image_path);
  };

  if (!loading && !results?.length && !state?.depLocation) {
    return <ErrorMessage>{t('errorTexts.dep')}</ErrorMessage>;
  }

  return (
    results && (
      <StyledList ref={listRef} id="locationSuggest">
        {results.map((suggestion: SearchSuggestion, index) => (
          <ListItem key={suggestion.objectID}>
            <StyledButton
              type="button"
              tabIndex={0}
              onBlur={onLastElementBlur}
              onMouseDown={() => handleClick(suggestion)}
              onMouseEnter={() => onMouseEnter(index)}
              onMouseLeave={onMouseLeave}
              id={`locationSuggestItem-${index + 1}`}
              aria-selected={index === focusedItem}
            >
              <SearchResultItem suggestion={suggestion} />
            </StyledButton>
          </ListItem>
        ))}
      </StyledList>
    )
  );
};

export { LocationSuggest };
