import { useCallback, useState } from 'react';
import type { FormEvent } from 'react';
import { useTranslations } from 'next-intl';
import { useMedia } from 'react-use';
import Search from '@engineering/icons/search';
import { DateRangePicker } from '@silvertours/front-entities';
import { SearchInput } from './SearchInput';
import { SearchProvider, useSearchContext } from './context';
import { useSession } from './hooks';
import { matchError, useSubmitHandler } from './hooks/useSubmitHandler';

import { StyledForm } from './SearchForm.styles';
import {
  Wrapper,
  Title,
  FormContainer,
  StyledButton,
} from './WidgetSearchForm.styles';
import { isFormError } from './context/types';

const WidgetSearchForm = () => {
  const { setDate, setError, state } = useSearchContext();

  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const submitHandler = useSubmitHandler();

  const { trackClickTimestamp, removeClickTimestamp } = useSession();
  const [loading, setLoading] = useState(false);

  const isSmallWidgetWidth = useMedia('(max-width: 768px)', true);
  const isSmallWidgetHeight = useMedia(
    '(max-height: 320px) and (max-width: 600px)',
    true,
  );
  const isVerticalLayout = isSmallWidgetWidth || isSmallWidgetHeight;

  const onError = useCallback((error: any) => {
    setLoading(false);
    removeClickTimestamp();

    const { type: kind, message } = matchError(error);

    if (isFormError(kind)) {
      setError({ kind, message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchSubmit = async () => {
    setLoading(true);
    trackClickTimestamp();
    await submitHandler(state, '', () => setLoading(false), onError, true);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearchSubmit();
  };

  if (!state) {
    return null;
  }

  return (
    <Wrapper>
      <Title>{t('title')}</Title>
      <StyledForm
        onSubmit={handleSubmit}
        id="search"
        role="search"
        target="_blank"
      >
        <FormContainer>
          <SearchInput
            id="dep"
            direction="departure"
            onServiceNumbersSubmit={handleSearchSubmit}
            label={t('departure.label')}
            placeholder={t('departure.placeholder')}
            icon={<Search />}
          />
          <DateRangePicker
            from={new Date(state.depDate)}
            to={new Date(state.destDate)}
            error={state.dateError || ''}
            onDatesChange={dates => {
              setDate('departure', dates.from!);
              setDate('destination', dates.to!);
            }}
            size="small"
            vertical={isVerticalLayout}
          />
          <div>
            <StyledButton
              type="submit"
              id="find"
              size="small"
              animateLoading
              loading={loading}
              fullWidth
            >
              {t('button.label')}
            </StyledButton>
          </div>
        </FormContainer>
      </StyledForm>
    </Wrapper>
  );
};

const WidgetSearchFormWithContext = ({ ...formProps }) => (
  <SearchProvider>
    <WidgetSearchForm {...formProps} />
  </SearchProvider>
);

export { WidgetSearchFormWithContext as WidgetSearchForm };
