import React, { FC } from 'react';

import {
  ContentPageType,
  GeneralPageContent,
  HomePageContent,
  GlobalPageContent,
  PillarPageContent,
  RentalCompanyPageContent,
  ReviewsPillarPageContent,
  SupplierRatingsPageContent,
  TransporterPageContent,
  PlainPageContent,
  MinimalPageContent,
} from '@silvertours/common-landingpages-view';

import { LandingPageProps, PageProps } from '../LandingPageProps';
import { GeneralContent } from './General';
import { HomeContent } from './Home';
import { GlobalContent } from './Global';
import { PillarContent } from './Pillar';
import { RentalCompanyContent } from './RentalCompany';
import { ReviewsPillarContent } from './ReviewsPillar';
import { SupplierRatingsContent } from './SupplierRatings';
import { TransporterContent } from './Transporter';
import { PlainPage } from '../common/Plain';
import { MinimalPage } from '../common/Minimal';

const isPageOfType = <TContent extends Record<string, unknown>>(
  contentPageType: ContentPageType,
  props: PageProps<ContentPageType, Record<string, unknown>>,
): props is PageProps<ContentPageType, TContent> =>
  props.content.type === contentPageType;

const LandingPage: FC<LandingPageProps> = (props: LandingPageProps) => {
  if (isPageOfType<HomePageContent>('home', props)) {
    return <HomeContent {...props} />;
  }

  if (isPageOfType<GeneralPageContent>('general', props)) {
    return <GeneralContent {...props} />;
  }

  if (isPageOfType<GlobalPageContent>('global', props)) {
    return <GlobalContent {...props} />;
  }

  if (isPageOfType<PillarPageContent>('pillar', props)) {
    return <PillarContent {...props} />;
  }

  if (isPageOfType<RentalCompanyPageContent>('rentalCompany', props)) {
    return <RentalCompanyContent {...props} />;
  }

  if (isPageOfType<ReviewsPillarPageContent>('reviewsPillar', props)) {
    return <ReviewsPillarContent {...props} />;
  }

  if (isPageOfType<SupplierRatingsPageContent>('supplierRatings', props)) {
    return <SupplierRatingsContent {...props} />;
  }

  if (isPageOfType<TransporterPageContent>('transporter', props)) {
    return <TransporterContent {...props} />;
  }

  if (isPageOfType<PlainPageContent>('plain', props)) {
    return <PlainPage {...props} />;
  }

  if (isPageOfType<MinimalPageContent>('minimal', props)) {
    return <MinimalPage {...props} />;
  }

  return null;
};

export { LandingPage };
export type { PageProps };
