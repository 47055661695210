import { RichText as RichTextData } from '@silvertours/common-landingpages-view';
import { Ui } from '@silvertours/front-shared';

import { HasTextStyleRules } from './rule';
import { HasColumns } from '../rule';

import { RichTextWrapper } from './RichText.styles';

export type RichTextRules = HasColumns & HasTextStyleRules;

export type RichTextProps = {
  content: RichTextData;
  rules?: RichTextRules;
};

export const RichText = (props: RichTextProps) => {
  const { content, rules = {} } = props;
  const { textStyle } = rules;

  return (
    <RichTextWrapper rules={rules}>
      <Ui.RichText content={content} rules={{ textStyle }} />
    </RichTextWrapper>
  );
};
