import { Marker, MarkerClusterer, Renderer } from '@googlemaps/markerclusterer';
import { useMap } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';

import { Station } from './types';
import { StationMarker } from './StationMarker';

const createClusterMarkerSvg = (
  number: number,
  position: google.maps.LatLng,
): google.maps.Marker => {
  const svgMarker: string = `
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <style>
      .cluster {
        font-family: '"Source Sans Pro"', 'ui-sans-serif', 'system-ui', 'sans-serif';
        font-weight: light;
        font-size="16px"
      }
    </style>
    <circle cx="24" cy="24" r="22" fill="#00977d" />
    <text class="cluster" x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#ffffff">${number}</text>
  </svg>
  `;

  const svgUrl: string = `data:image/svg+xml;base64,${btoa(svgMarker)}`;

  const marker: google.maps.Marker = new google.maps.Marker({
    position,
    icon: {
      url: svgUrl,
      scaledSize: new google.maps.Size(40, 40),
    },
  });

  return marker;
};

const customRenderer: Renderer = {
  render: ({ count, position }) => createClusterMarkerSvg(count, position),
};

type MarkersProps = {
  stations?: Station[];
  selected: string | null;
  select: (stationId: string | null, stationKind: string | null) => void;
};

export const Markers = ({ select, selected, stations }: MarkersProps) => {
  const map = useMap();
  const clusterer = useRef<MarkerClusterer | null>(null);
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        renderer: customRenderer,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers(prev => {
      if (marker) {
        return { ...prev, [key]: marker };
      }
      const newMarkers = { ...prev };
      delete newMarkers[key];
      return newMarkers;
    });
  };

  const makeHandleSelect =
    (stationId: string | null, stationKind: string | null) => () =>
      select(stationId, stationKind);

  const deselect = makeHandleSelect(null, null);

  return stations ? (
    <>
      {stations.map(station => {
        const handleSelect = makeHandleSelect(station.id, station.stationKind);
        return (
          <StationMarker
            key={station.id}
            setMarkerRef={setMarkerRef}
            station={station}
            deselect={deselect}
            select={handleSelect}
            selected={selected === station.id}
          />
        );
      })}
    </>
  ) : null;
};
