import styled from '@emotion/styled';
import { secondaryText, StyledTextProps, Ui } from '@silvertours/front-shared';

const FigureListContainer = styled.div<{ contentLength: number }>`
  margin-top: ${({ theme }) => theme.spacing[50]};
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  justify-items: center;
  gap: ${({ theme }) => `${theme.spacing[60]} ${theme.spacing[70]}`};
  padding: ${({ theme }) => theme.spacing[60]};
  border-color: ${({ theme }) => theme.color.grey04};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border-width: ${({ theme }) => theme.spacing[5]};

  ${({ theme }) => theme.mq('sm')} {
    padding-top: ${({ theme }) => theme.spacing[70]};
    grid-template-columns: ${({ contentLength }) =>
      `repeat(${contentLength <= 5 ? contentLength : 4}, minmax(0, 1fr))`};
  }
`;

const Figure = styled.figure`
  grid-column: span 2 / span 2;
  display: flex;
  flex-direction: column;
  max-height: 200px;

  &:nth-of-type(odd) {
    &:last-child {
      grid-column-start: 2;
      ${({ theme }) => theme.mq('sm')} {
        grid-column-start: auto;
      }
    }
  }

  ${({ theme }) => theme.mq('sm')} {
    grid-column: span 1 / span 1;
  }
`;

const FigureImage = styled(Ui.Image)`
  margin: ${({ theme }) => `auto 0 ${theme.spacing[40]} 0`};
  max-height: 48px;
  max-width: 120px;
`;

const PositionLabel = styled.div<StyledTextProps>`
  ${secondaryText}
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
`;

export { Figure, FigureImage, FigureListContainer, PositionLabel };
