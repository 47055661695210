import { Fragment, useId } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';
import { useFormatter } from 'next-intl';
import {
  RatingBarLabel,
  RatingBarChart,
  RatingBarCount,
  RatingBarWrapper,
} from './RatingBar.styles';

type RatingBarProps = {
  id?: string;
  label: ReactNode;
  value?: number;
  maxValue?: number;
  unformattedValue?: boolean;
  wrapped?: boolean;
} & Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export const RatingBar = ({
  id,
  label,
  value,
  maxValue = 5,
  unformattedValue = false,
  wrapped = false,
}: RatingBarProps) => {
  const format = useFormatter();
  const uniqueId = useId();
  const ratingBarId = `rating${uniqueId}${id ?? label}label`;

  const hasNoValue = !value || value === 0;

  let valueToDisplay;
  if (hasNoValue) {
    valueToDisplay = '-';
  } else if (unformattedValue) {
    valueToDisplay = value;
  } else {
    valueToDisplay = format.number(value, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  }

  const WrapperComponent = wrapped ? RatingBarWrapper : Fragment;
  const wrapperProps = wrapped
    ? { role: 'group', 'aria-labelledby': ratingBarId }
    : {};

  return (
    <WrapperComponent {...wrapperProps}>
      <RatingBarLabel id={ratingBarId} hasNoValue={hasNoValue}>
        {label}
      </RatingBarLabel>
      <RatingBarChart
        value={hasNoValue ? 0 : (value / maxValue) * 100}
        role="img"
        aria-labelledby={ratingBarId}
      />
      <RatingBarCount aria-labelledby={ratingBarId}>
        {valueToDisplay}
      </RatingBarCount>
    </WrapperComponent>
  );
};
