import styled from '@emotion/styled';

export const ContentModuleSegment = styled.div<{
  bottomMargin: 'default' | 'small';
}>`
  margin-bottom: ${({ bottomMargin, theme }) =>
    bottomMargin === 'small' ? theme.spacing[30] : theme.spacing[120]};

  ${({ theme }) => theme.mq('sm')} {
    margin-bottom: ${({ bottomMargin, theme }) =>
      bottomMargin === 'small' ? theme.spacing[40] : theme.spacing[140]};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
