import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

type StyledBadgeProps = {
  isLargeAtMD?: boolean;
};

const StyledBadge = styled(Ui.Badge)<StyledBadgeProps>`
  margin-bottom: 1.5rem;
  ${({ isLargeAtMD, theme }) =>
    isLargeAtMD &&
    `${theme.mq('sm')} {
    margin-bottom: 3rem;
  }`}
`;

export { StyledBadge };
