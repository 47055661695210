import type { ReactNode } from 'react';
import {
  ContentChunkSetModule,
  TabsContentModule,
  SubModule,
} from '@silvertours/common-landingpages-view';
import { SectionContainer } from './SectionRows.styles';

type SectionContent = TabsContentModule | ContentChunkSetModule;

export type SectionRowsProps = {
  children: (section: SubModule<SectionContent>) => ReactNode;
  sections: Array<SubModule<SectionContent>>;
};

export const SectionRows = ({ children, sections }: SectionRowsProps) => (
  <>
    {sections.map(section => (
      <SectionContainer key={section.position}>
        {children(section)}
      </SectionContainer>
    ))}
  </>
);
