import { HtmlStringRichText } from '@silvertours/common-landingpages-view';
import { MarkupContainer } from './MarkupRichText.styles';

export type MarkupRichTextProps = {
  content: HtmlStringRichText;
};

export const MarkupRichText = ({ content }: MarkupRichTextProps) => {
  if (!content?.text) {
    return null;
  }

  return <MarkupContainer dangerouslySetInnerHTML={{ __html: content.text }} />;
};
