import { AccordionEntries, AccordionItem } from './AccordionItem';

import { AccordionWrapper } from './Accordion.styles';

export type AccordionProps = {
  content: AccordionEntries;
};

export const Accordion = ({ content }: AccordionProps) => (
  <AccordionWrapper>
    {content.map((item, index) => (
      <AccordionItem item={item} index={index} key={item.prompt} />
    ))}
  </AccordionWrapper>
);
