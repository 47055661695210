import styled from '@emotion/styled';
import { SecondaryText } from '@silvertours/front-shared';

const Category = styled.div`
  margin-top: ${({ theme }) => theme.spacing[70]};
`;

const Details = styled.div`
  gap: ${({ theme }) => theme.spacing[40]};
  margin-top: ${({ theme }) => theme.spacing[50]};
`;

const Pricing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Dates = styled(SecondaryText)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export { Category, Dates, Details, Pricing };
