import {
  ColoredImageLink,
  GridSetOf1,
  GridSetOf2,
  GridSetOf3,
  GridSetOf4,
  GridSetOf5,
  GridSetOf6,
  GridSetOf7,
  GridSetOf8,
} from './GridSet';
import { LinkGridContainer } from './LinkGrid.styles';

export type LinkGridContent = {
  sets: Array<Array<ColoredImageLink>>;
};

export type LinkGridProps = {
  content: LinkGridContent;
};

export const LinkGrid = ({ content: { sets } }: LinkGridProps) => (
  <LinkGridContainer>
    {sets.map((set, setIndex) => {
      const key = setIndex;

      switch (set.length) {
        case 1:
          return <GridSetOf1 content={{ set }} key={key} />;
        case 2:
          return <GridSetOf2 content={{ set }} key={key} />;
        case 3:
          return <GridSetOf3 content={{ set }} key={key} />;
        case 4:
          return <GridSetOf4 content={{ set }} key={key} />;
        case 5:
          return <GridSetOf5 content={{ set }} key={key} />;
        case 6:
          return <GridSetOf6 content={{ set }} key={key} />;
        case 7:
          return <GridSetOf7 content={{ set }} key={key} />;
        default:
          return <GridSetOf8 content={{ set }} key={key} />;
      }
    })}
  </LinkGridContainer>
);
