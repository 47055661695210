import styled from '@emotion/styled';
import ArrowNext from '@engineering/icons/arrow-next';
import { InteractiveText } from '@silvertours/front-shared';
import { tint } from 'polished';

export const Anchor = styled.a`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.color.brand};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => tint(0.202, theme.color.brand!)};
  }
`;

export const Text = styled(InteractiveText)`
  margin-right: ${({ theme }) => theme.spacing[60]};
`;

export const ArrowNextIcon = styled(ArrowNext)`
  height: ${({ theme }) => theme.spacing[50]};
  width: ${({ theme }) => theme.spacing[50]};
  flex-shrink: 0;
  fill: currentcolor;
`;
