import styled from '@emotion/styled';

export const LogoWrapper = styled.span<{ grayScale?: boolean }>`
  display: inline-flex;
  filter: ${({ grayScale }) => (grayScale ? 'grayscale() opacity(0.7)' : '')};
`;

export const Svg = styled.svg`
  width: auto;
  height: 100%;
`;
