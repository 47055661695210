import { FaqsContentModule } from '@silvertours/common-landingpages-view';

import {
  Badge,
  HasBadgeRules,
  HasModuleContainerSpacingRules,
  HasTextStyleRules,
  Heading,
  LinkButton,
  ModuleContainer,
} from '../../element';
import { Accordion } from './Accordion';
import { HasAccordion } from './AccordionItem';

export type AccordionContentBlockRules = HasTextStyleRules &
  HasBadgeRules &
  HasModuleContainerSpacingRules;

type AccordionContentBlockContent = HasAccordion &
  Pick<FaqsContentModule, 'badge' | 'heading'>;

type AccordionContentBlockTranslations = {
  supportUrl: string;
  buttonLabel: string;
};

export type AccordionContentBlockProps = {
  content: AccordionContentBlockContent;
  rules?: AccordionContentBlockRules;
  translations?: AccordionContentBlockTranslations;
};

export const AccordionContentBlock = ({
  content,
  rules,
  translations,
}: AccordionContentBlockProps) => {
  const { accordion, badge, heading } = content;
  const { badge: badgeRules, textStyle, verticalSpacing } = rules || {};
  const { style } = textStyle || {};
  const { buttonLabel, supportUrl } = translations || {};

  return (
    <ModuleContainer rules={{ verticalSpacing }}>
      <Badge content={badge} rules={{ badge: badgeRules }} />
      <Heading content={heading} rules={{ textStyle: { style } }} />
      <Accordion content={accordion} />
      {supportUrl && buttonLabel && (
        <LinkButton
          content={{
            href: supportUrl,
            target: '_blank',
            rel: 'noopener noreferrer',
            label: buttonLabel,
          }}
          rules={{ horizontalSpacing: { isCentered: true } }}
          variant="secondary"
        />
      )}
    </ModuleContainer>
  );
};
