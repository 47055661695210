import type { RichText as RichTextContent } from '@silvertours/common-landingpages-view';

import { ContentfulRichText } from './ContentfulRichText';
import type { HasTextStyleRules } from './rules';
import { MarkupRichText } from './MarkupRichText';

export type RichTextRules = HasTextStyleRules;

export type RichTextProps = {
  content?: RichTextContent;
  rules?: RichTextRules;
};

export const RichText = ({ content, rules }: RichTextProps) => {
  if (!content) {
    return null;
  }

  if (content.type === 'html-string') {
    return <MarkupRichText content={content} />;
  }

  return <ContentfulRichText content={content} rules={rules} />;
};
