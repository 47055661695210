import styled from '@emotion/styled';
import Locked from '@engineering/icons/locked';
import User from '@engineering/icons/user';
import { Ui } from '@silvertours/front-shared';

const List = styled.ul`
  padding: 0 ${({ theme }) => theme.spacing[50]};
  width: 238px;

  * {
    font-family: ${({ theme }) => theme.fontFamily.alternate};
  }
`;

const MembershipPanelWrapper = styled.div`
  width: 403px;
`;

const NavigationItem = styled.li`
  padding: ${({ theme }) => theme.spacing[40]} 0;
  color: ${({ theme }) => theme.color.black};
  text-overflow: ellipsis;
  overflow-x: hidden;
  ${({ theme }) => theme.mq('lg')} {
    display: block;
  }
`;

const StyledLink = styled(Ui.TextLink)`
  ${({ theme }) => theme.mq('lg')} {
    display: block;
  }
`;

const LockIcon = styled(Locked)`
  fill: currentcolor;
  width: 1.5rem;
  height: 1.5rem;

  ${({ theme }) => theme.mq('lg')} {
    display: none;
  }
`;

const UserIcon = styled(User)`
  display: none;
  fill: currentcolor;
  width: 1.5rem;
  height: 1.5rem;

  ${({ theme }) => theme.mq('lg')} {
    display: block;
  }
`;

export {
  List,
  MembershipPanelWrapper,
  NavigationItem,
  StyledLink,
  LockIcon,
  UserIcon,
};
