import { ReactNode } from 'react';
import { CardContainer } from './Card.styles';

type CardProps = {
  children: ReactNode;
  hasBorder?: boolean;
  id?: string;
};

const Card = ({ children, hasBorder = false, id }: CardProps) => (
  <CardContainer id={id} hasBorder={hasBorder}>
    {children}
  </CardContainer>
);

export { Card };
export type { CardProps };
