import styled from '@emotion/styled';
import Phone from '@engineering/icons/phone';

const ContactText = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const PhoneNumberContainer = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PhoneNumberIcon = styled(Phone)`
  fill: currentcolor;
  width: 1.5rem;
  height: 1.5rem;
`;

export { ContactText, PhoneNumberContainer, PhoneNumberIcon };
