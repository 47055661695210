import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import {
  LinkListTranslations,
  PlainLinkList as PlainLinkListView,
} from '@silvertours/common-landingpages-view';

import { Theme } from '@silvertours/front-shared';
import { Button, Heading } from '../../../element';
import { ArrowLink } from './ArrowLink';
import { List, ListItem, Wrapper } from './PlainLinkList.styles';

const MAX_LINKS_MOBILE = 10;
const MAX_LINKS_DESKTOP = 15;

export type PlainLinkListProps = {
  content: PlainLinkListView;
  translations?: LinkListTranslations;
};

export const PlainLinkList = ({
  content: { heading, links },
  translations,
}: PlainLinkListProps) => {
  const { showAllLabel = '', showLessLabel = '' } = translations || {};
  const [maxVisibleLinks, setMaxVisibleLinks] = useState(MAX_LINKS_MOBILE);
  const hasHiddenLinks = links.length > maxVisibleLinks;
  const [linksAreExpanded, setLinksExpanded] = useState(false);

  const wideViewPort = useMedia(
    `(min-width:${Theme.getBreakpoint('lg')}px)`,
    false,
  );

  useEffect(() => {
    const maxLinks = wideViewPort ? MAX_LINKS_DESKTOP : MAX_LINKS_MOBILE;
    setMaxVisibleLinks(maxLinks);
  }, [wideViewPort, links]);

  return (
    <Wrapper>
      <Heading
        content={heading}
        rules={{ verticalSpacing: { spacingDirection: 'neither' } }}
      />
      <List length={links.length}>
        {links.map((link, index) => (
          <ListItem
            linksAreExpanded={linksAreExpanded}
            maxVisibleLinks={maxVisibleLinks}
            index={index}
            key={link.href + link.label}
          >
            <ArrowLink link={link} />
          </ListItem>
        ))}
      </List>
      {hasHiddenLinks && (
        <Button
          content={{ label: linksAreExpanded ? showLessLabel : showAllLabel }}
          onClick={() => setLinksExpanded(!linksAreExpanded)}
          rules={{
            variant: 'secondary',
            expanded: true,
            horizontalSpacing: { isCentered: true },
            verticalSpacing: { spacingDirection: 'neither' },
          }}
        />
      )}
    </Wrapper>
  );
};
