// helper for international date format, results from a provided string timestamp
const formatDate = (timestamp?: string, locale?: string) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
    timeStyle: 'short',
  }).format(date);
};

export { formatDate };
