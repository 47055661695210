import { datetime } from '@silvertours/front-shared';
import { Day } from 'date-fns';
import { Information, OpeningHours, ScheduleRow } from './OpeningTimes.styles';
import { useSiteInfo } from '../../SiteInfo';

type Props = {
  showVoucherInfo: boolean;
};

export const Schedule = ({ showVoucherInfo }: Props) => {
  const {
    settings: { language },
    openingTimes,
  } = useSiteInfo();
  const locale = datetime.dateLocales[language];

  const [monday, friday, saturday, sunday] = ([1, 5, 6, 0] as Day[]).map(day =>
    locale.localize.day(day, { width: 'abbreviated' }),
  );

  const commonSchedule = Object.entries(openingTimes.common)
    .map(([key, hours]) => {
      if (typeof hours === 'undefined') {
        return undefined;
      }

      const { open, close } = hours;
      let days: string;

      switch (key) {
        case 'always':
          days = `${monday} - ${sunday}`;
          break;
        case 'weekdays':
        case 'workdays':
          days = `${monday} - ${friday}`;
          break;
        case 'weekends':
          days = `${saturday} - ${sunday}`;
          break;
        case 'saturday':
          days = saturday;
          break;
        case 'sunday':
          days = sunday;
          break;
        default:
          days = key;
      }
      return (
        <ScheduleRow type="common" key={key}>
          <div>{days}</div>
          <div>
            {open} - {close}
          </div>
        </ScheduleRow>
      );
    })
    .filter(Boolean);

  return (
    <OpeningHours textColor="grey" showVoucherInfo={showVoucherInfo}>
      <Information>{openingTimes.common ? commonSchedule : null}</Information>
    </OpeningHours>
  );
};
