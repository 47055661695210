import { ReactNode } from 'react';

import { ContentModulePageModule, ModuleType } from '../module';

type ContentModuleBuilder = (
  key: string,
  content: ReactNode,
  renderCondition?: boolean,
) => ContentModulePageModule;

const buildContentModule: ContentModuleBuilder = (
  key,
  content,
  renderCondition = true,
) => ({
  content,
  key,
  renderCondition,
  type: ModuleType.ContentModule,
});

export { buildContentModule };
export type { ContentModuleBuilder };
