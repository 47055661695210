import styled from '@emotion/styled';

const Picture = styled.picture`
  display: none;
  height: 100%;
  width: 100%;
  right: 0;

  @media (min-width: calc(36rem + 1px)) {
    display: block;
  }
`;

const Image = styled.img`
  height: 100%;
`;

export { Image, Picture };
