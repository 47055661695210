import React from 'react';
import { SearchWidgetPageContent } from '@silvertours/common-landingpages-view';
import { AffiliateSearchForm } from '@silvertours/front-features';
import { ThemeProvider } from '@emotion/react';
import { Theme } from '@silvertours/front-shared';

const AffiliatePage = ({ color, showPoweredBy }: SearchWidgetPageContent) => {
  const theme = {
    ...Theme.defaultTheme,
    color: { ...Theme.defaultTheme.color, primary: color },
  };

  return (
    <ThemeProvider theme={theme}>
      <AffiliateSearchForm color={color} showPoweredBy={showPoweredBy} />
    </ThemeProvider>
  );
};

export { AffiliatePage };
