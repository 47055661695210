import { ButtonHTMLAttributes, SVGProps } from 'react';
import { Button } from './IconButton.styles';

type Props = {
  id?: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  size?: 'small' | 'medium' | 'large';
  selected?: boolean;
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'title' | 'className' | 'onClick'
>;

const IconButton = ({
  id,
  icon: Icon,
  title,
  className,
  onClick,
  size = 'medium',
  selected = false,
  ...rest
}: Props) => (
  <Button
    id={id}
    type="button"
    size={size}
    title={title}
    className={className}
    onClick={onClick}
    isSelected={!!selected}
    {...rest}
  >
    <Icon id={id && `${id}-icon`} width="100%" height="100%" />
  </Button>
);

export { IconButton };
