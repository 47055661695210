import { useCallback, useMemo, useState } from 'react';
import {
  ConsumerApiSettings,
  LanguageCode,
  MarketCode,
} from '@silvertours/common-landingpages-view';
import debounce from 'lodash/debounce';

import { SearchSuggestion, SuggestProviderInterface } from './types';
import { SuggestProvider } from './SuggestProvider';

const DEBOUNCE_TIME_MS = 250;

export const useSearchSuggestions = (
  language: LanguageCode,
  market: MarketCode,
  settings: ConsumerApiSettings,
) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Array<SearchSuggestion> | null>(null);

  const searchSuggestProvider = useMemo<SuggestProviderInterface>(
    () => SuggestProvider.getInstance(settings, language, market),
    [settings, language, market],
  );

  const sendRequest = useCallback(
    (searchString: string) => {
      searchSuggestProvider
        .sendRequest(searchString)
        .then(searchResults => {
          setLoading(false);
          setResults(searchResults);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [searchSuggestProvider],
  );

  const debouncedSendRequest = useMemo(
    () => debounce(sendRequest, DEBOUNCE_TIME_MS),
    [sendRequest],
  );

  const fetchSuggestions = useCallback(
    (searchString: string) => {
      setLoading(true);
      debouncedSendRequest(searchString);
    },
    [debouncedSendRequest],
  );

  return {
    clearResults: () => {
      setResults([]);
    },
    fetchSuggestions,
    loading,
    results,
  };
};
