import { Context } from '@silvertours/front-shared';
import type { SessionData } from './sessionData';

const useSessionData = (): Partial<SessionData> => {
  const result = Context.useClientContext<SessionData>('sessionData');

  const securityTokenValue = result.hasContext
    ? result.value.securityTokenValue
    : '';
  const gtmEnvironment = result.hasContext
    ? result.value.gtmEnvironment
    : 'live';
  const anonymizeIP = result.hasContext ? result.value.anonymizeIP : true;

  return {
    securityTokenValue,
    gtmEnvironment,
    anonymizeIP,
  };
};

export { useSessionData };
