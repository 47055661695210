import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const List = styled.ul<{ length: number }>`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0;

  ${({ theme }) => theme.mq('md')} {
    column-gap: ${({ length, theme }) => (length >= 6 ? theme.spacing[80] : 0)};
  }

  ${({ length, theme }) =>
    length >= 6 &&
    css`
      ${theme.mq('md')} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      ${theme.mq('lg')} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    `}
`;

const ListItem = styled.li<{
  linksAreExpanded: boolean;
  maxVisibleLinks: number;
  index: number;
}>`
  min-width: 150px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.grey04}`};
  padding-top: ${({ theme }) => theme.spacing[50]};
  padding-bottom: ${({ theme }) => theme.spacing[40]};
  position: relative;

  ${({ linksAreExpanded, maxVisibleLinks, index }) =>
    !linksAreExpanded &&
    maxVisibleLinks <= index &&
    css`
      display: none;
    `}
`;

export { Wrapper, List, ListItem };
