import { FocusEvent, useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { format } from 'date-fns/format';
import { de as LocaleDe } from 'date-fns/locale/de';

import type { LanguageCode } from '@silvertours/common-landingpages-view';
import { analytics } from '@silvertours/front-entities';
import { String } from '@silvertours/front-shared';
import { I18nLegacy } from '@silvertours/front-legacy-shared';
import { useSearchContext } from '../context/useSearchContext';
import {
  getDefaultDepartureDatePerLanguage,
  getDefaultDestinationDate,
} from '../context/reducer';
import { SearchFormState } from '../context/types';
import {
  formatDate as formatDateForForm,
  isDeptDateInLessThanDays,
  isDestDateBeforeDepDate,
} from '../context/utils';

import type { SearchHistoryItem } from './types';
import {
  Wrapper,
  Headline,
  List,
  ItemButton,
  Destination,
  Duration,
  DeleteButton,
} from './SearchHistory.styles';
import { clearSearchHistory } from './utils';

const mapHistoryItemToForm = (
  item: SearchHistoryItem,
  language: LanguageCode,
): Partial<SearchFormState> => {
  const {
    depName,
    depDate,
    depTime,
    destName,
    destDate,
    destTime,
    depAirportCode,
    destAirportCode,
  } = item;

  const depDateTime = `${depDate}T${depTime}`;
  const destDateTime = `${destDate}T${destTime}`;

  const revertToDefaultDates =
    isDeptDateInLessThanDays(depDateTime, 0) ||
    isDestDateBeforeDepDate(destDateTime, depDateTime);

  return {
    depLocation: {
      name: depName,
      cityCode: depAirportCode,
      type: depAirportCode ? 'airport' : 'city',
    },
    depDate: revertToDefaultDates
      ? formatDateForForm(getDefaultDepartureDatePerLanguage(language))
      : depDateTime,
    destLocation: destName
      ? {
          cityCode: destAirportCode,
          name: destName,
          type: destAirportCode ? 'airport' : 'city',
        }
      : null,
    destDate: revertToDefaultDates
      ? formatDateForForm(getDefaultDestinationDate())
      : destDateTime,
  };
};

interface SearchHistoryProps {
  onLastElementBlur: (event: FocusEvent<HTMLButtonElement, Element>) => void;
  onItemClick: () => void;
}

export const SearchHistory = ({
  onItemClick,
  onLastElementBlur,
}: SearchHistoryProps) => {
  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const { language } = I18nLegacy.useLocale();
  const {
    getHistoryItemsByLanguage,
    setSearchFromHistory,
    clearSearchHistory: clearHistoryState,
  } = useSearchContext();

  const handleSetItem = useCallback((item: SearchHistoryItem) => {
    setSearchFromHistory(mapHistoryItemToForm(item, language));
    analytics.gtm.trackSearchHistorySelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearItem = useCallback(() => {
    clearSearchHistory();
    clearHistoryState();
    analytics.gtm.trackSearchHistoryDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localDateFormats =
    String.dateFormats[language as keyof typeof String.dateFormats];

  const formatDateLong = (value: string) =>
    format(new Date(value), localDateFormats.dateLong, {
      locale: LocaleDe,
    });

  const formatDuration = (start: string, end: string) =>
    [formatDateLong(start), formatDateLong(end)].join(' – ');

  const items = getHistoryItemsByLanguage(language);

  if (items.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Headline>{t('searchHistory.title')}</Headline>
      <List>
        {items.map((item, index) => (
          <li
            // We pass the index as part of the key since we are not modifying this list on the same render.
            // eslint-disable-next-line react/no-array-index-key
            key={item.depName + index}
          >
            <ItemButton
              onMouseDown={e => {
                e.stopPropagation();
                handleSetItem(item);
                onItemClick();
              }}
            >
              <Destination>
                {item.depName} {item.destName ? ` - ${item.destName}` : ''}
              </Destination>
              <Duration>{formatDuration(item.depDate, item.destDate)}</Duration>
            </ItemButton>
          </li>
        ))}
      </List>
      {/* using onMouseDown on the input to guarantee that the button is still rendered before losing focus, triggering the event */}
      <DeleteButton onMouseDown={handleClearItem} onBlur={onLastElementBlur}>
        {t('searchHistory.deleteLink')}
      </DeleteButton>
    </Wrapper>
  );
};
