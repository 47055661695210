export { GridSetOf1 } from './GridSetOf1';
export { GridSetOf2 } from './GridSetOf2';
export { GridSetOf3 } from './GridSetOf3';
export { GridSetOf4 } from './GridSetOf4';
export { GridSetOf5 } from './GridSetOf5';
export { GridSetOf6 } from './GridSetOf6';
export { GridSetOf7 } from './GridSetOf7';
export { GridSetOf8 } from './GridSetOf8';

export type { ColoredImageLink } from './GridSet';
