import { useState } from 'react';
import { Runtime, account } from '@silvertours/front-entities';
import { Ui, http } from '@silvertours/front-shared';
import { useTranslations } from 'next-intl';
import { CheckIcon } from './LoginForm.styles';
import { useLogin } from './LoginState';

const LoginError = () => {
  const { username, error: type } = useLogin();
  const [isSuccessful, setIsSuccessful] = useState<Boolean | null>(false);
  const { formatApiUrl } = Runtime.useAccountConfig();
  const { whitelabel } = Runtime.useWhitelabel();
  const { error } = useLogin();

  const t = useTranslations('features.navigationLegacy.navigation');
  const headline = error ? t(`login.error.${error}Title`) : '';
  const message = error ? t(`login.error.${error}`) : '';

  if (!type) {
    return null;
  }

  if (type === account.LoginError.UnverifiedEmail) {
    const handleClick = async () => {
      const res = await account.resendConfirmUser(
        formatApiUrl(http.API.accountResendConfirmUser),
        username ?? '',
        whitelabel,
      );
      setIsSuccessful(res);
    };

    return (
      <Ui.Message headline={headline}>
        {message}
        <br />
        <Ui.TextLink
          as="button"
          text={t('login.error.resendConfirmUser')}
          onClick={handleClick}
        />
        {isSuccessful && <CheckIcon />}
      </Ui.Message>
    );
  }

  if (
    [
      account.LoginError.ExpiredPassword,
      account.LoginError.WeakPassword,
    ].includes(type)
  ) {
    return (
      <Ui.Message
        headline={headline}
        severity={Ui.MessageSeverity.ERROR}
        role={Ui.MessageRole.ALERT}
      >
        {message}{' '}
        <Ui.TextLink
          href={t('passwordLost.href')}
          text={t('login.error.resetPasswordLink')}
        />
      </Ui.Message>
    );
  }

  return (
    <Ui.Message severity={Ui.MessageSeverity.ERROR} role={Ui.MessageRole.ALERT}>
      {message}
    </Ui.Message>
  );
};

export { LoginError };
