import {
  ReactNode,
  useState,
  MouseEvent,
  useCallback,
  useId,
  useRef,
  useEffect,
} from 'react';
import { useMedia } from 'react-use';

import { Overlay } from '../../../../Ui/Overlay';
import { getBreakpoint } from '../../../../Theme';

import { TooltipWrapper, TooltipContent } from './Tooltip.styles';

type TooltipProps = {
  content: ReactNode;
  title?: string;
  trigger: ReactNode;
  width?: 'default' | 'wide';
};

const Tooltip = ({
  content,
  trigger,
  title,
  width = 'default',
}: TooltipProps) => {
  const [showModal, setShowModal] = useState(false);
  const id = `tooltip-content-${useId()}`;
  const ref = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  const isMobile = useMedia(`(max-width: ${getBreakpoint('lg')}px)`, false);

  useEffect(() => {
    const value = ref.current?.offsetWidth || 0;
    setOffset(Math.round(value / 2));
  }, []);

  const handleShowModal = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (isMobile) {
        setShowModal(true);
      }
    },
    [isMobile],
  );

  const handleHideModal = () => {
    setShowModal(false);
  };

  return (
    <TooltipWrapper
      aria-describedby={id}
      hover={!isMobile}
      onClick={handleShowModal}
      ref={ref}
    >
      {trigger}

      {showModal ? (
        // Modal view on mobile
        <Overlay open={showModal} title={title} onClose={handleHideModal}>
          {content}
        </Overlay>
      ) : (
        // Tooltip Overlay on desktop
        <TooltipContent role="tooltip" id={id} $width={width} $offset={offset}>
          {content}
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};

export { Tooltip };
