import { Logger } from '@silvertours/lib-core-logging';
import { Check, Guard } from '@silvertours/lib-core-types';

import { LandingPage, LinksSegment, PageAttribute } from '../../entity';
import { PageContentRepository, PageLinksRepository } from '../../repository';

import { makeAttributePageContentLoader } from './loadAttributePage';
import { makeAttributePagesLoader } from './loadAttributePages';

export type PageAncestorContent = [PageAttribute, LandingPage | undefined];

export interface PageContentService {
  getPageAncestorReferences(
    slug: string,
    attribute?: PageAttribute,
  ): Promise<Array<PageAncestorContent>>;

  getPageContentForSlug(slug: string): Promise<LandingPage>;

  getPageLinks(page: LandingPage): Promise<LinksSegment>;
}

export const makePageContentService = (
  logger: Logger,
  contentRepo: PageContentRepository,
  linksRepo?: PageLinksRepository,
): PageContentService => {
  const loadAttributePageContent = makeAttributePageContentLoader(
    logger,
    contentRepo,
  );
  const loadAttributePages = makeAttributePagesLoader(loadAttributePageContent);

  return {
    async getPageAncestorReferences(
      slug: string,
      primaryAttribute?: PageAttribute,
    ): Promise<Array<PageAncestorContent>> {
      if (!Check.isDefined(primaryAttribute)) {
        logger.warn('A page was loaded without any parent attribute', {
          slug,
        });

        return [];
      }

      const {
        attributes: { city, cityDistrict, country, state },
        pages: [
          pageForAttribute,
          pageForCityDistrict,
          pageForCity,
          pageForState,
          pageForCountry,
        ],
      } = await loadAttributePages(slug, primaryAttribute);

      /**
       * Return the pages for those entities which are available
       */
      return [
        [country, pageForCountry],
        [state, pageForState],
        [city, pageForCity],
        [cityDistrict, pageForCityDistrict],
        [primaryAttribute, pageForAttribute],
      ].filter(([entity]) =>
        Check.isTruthy(entity),
      ) as Array<PageAncestorContent>;
    },

    getPageContentForSlug(slug) {
      return contentRepo.loadPageContentForSlug(slug);
    },

    getPageLinks({ attributeSet, product, slug }) {
      Guard.isDefined(linksRepo, {
        errorMessage: 'The page links repository is not defined',
        errorData: { slug, product },
      });

      return linksRepo.loadPageLinks(slug, product.id, attributeSet);
    },
  };
};
