export { makePageClassificationService } from './pageClassificationService';

export type { AttributeTypeClassificationRule } from './attributeTypeClassification';
export type { CatchAllClassificationRule } from './catchAllClassification';
export type { DomainKeyClassificationRule } from './domainKeyClassification';
export type { PageClassification } from './pageClassification';
export type {
  ClassifyPage,
  PageClassificationService,
} from './pageClassificationService';
export type { ClassificationRules } from './rules';
export type { SlugClassificationRule } from './slugClassification';
