import { PropsWithChildren, useState } from 'react';
import Close from '@engineering/icons/close';
import { IconButton } from '../IconButton';
import {
  Wrapper,
  Headline,
  Content,
  InfoIcon,
  TipIcon,
  ErrorIcon,
  SuccessIcon,
} from './Message.styles';
import { MessageRole, MessageSeverity } from './Message.types';

type Props = PropsWithChildren<{
  headline?: string;
  hasCloseButton?: boolean;
  severity?: MessageSeverity;
  role?: MessageRole;
}>;

const icons = {
  [MessageSeverity.TIP]: TipIcon,
  [MessageSeverity.INFO]: InfoIcon,
  [MessageSeverity.ERROR]: ErrorIcon,
  [MessageSeverity.SUCCESS]: SuccessIcon,
};

const Message = ({
  children,
  headline,
  hasCloseButton,
  severity = MessageSeverity.INFO,
  role = MessageRole.STATUS,
}: Props) => {
  const [visible, setVisible] = useState(true);
  const handleClose = () => {
    setVisible(false);
  };

  const Icon = icons[severity];

  return (
    <Wrapper
      role={role}
      aria-live={role === MessageRole.STATUS ? 'polite' : undefined}
      severity={severity}
      visible={visible}
    >
      <Icon />
      <Content>
        {headline && <Headline>{headline}</Headline>}
        {children}
      </Content>
      {hasCloseButton && (
        <IconButton
          icon={Close}
          title="close" // @TODO: Add translation
          onClick={handleClose}
        />
      )}
    </Wrapper>
  );
};

export { Message };
