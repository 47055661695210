// These are date formats originally defined in the booking funnel (CarSPA),
// being re-used in this Next.js project.

type DateFormat = Record<
  | 'date'
  | 'dateLong'
  | 'dateGeneric'
  | 'isoDate'
  | 'isoDateTime'
  | 'dateTime'
  | 'dayDate'
  | 'dayDateLong'
  | 'dayDateLongYear'
  | 'dayTimeLong'
  | 'dayTimeShort'
  | 'time'
  | 'timeGeneric'
  | 'dayOfMonth'
  | 'dayOfWeek'
  | 'monthYear',
  string
>;

export const isoDate = 'yyyy-MM-dd';
export const isoDateTime = "yyyy-MM-dd'T'HH:mm";
export const isoDateTimeWithSeconds = "yyyy-MM-dd'T'HH:mm:ss";

const de: DateFormat = {
  date: 'dd.MM.yy',
  dateLong: 'dd.MM.yyyy',
  dateGeneric: 'dd.MM.yyyy',
  isoDate,
  isoDateTime,
  dateTime: 'eeeeee, dd.MM.yy, HH:mm',
  dayDate: 'eeeeee, dd.MM.yy',
  dayDateLong: 'eeee, dd.MM.yyyy',
  dayDateLongYear: 'eeeeee, dd.MM.yyyy',
  dayTimeLong: 'EEEEEE, dd.MM.yyyy - HH:mm',
  dayTimeShort: 'EEEEEE, dd.MM.yy - HH:mm',
  time: 'HH:mm',
  timeGeneric: 'HH:mm',
  dayOfMonth: 'dd',
  dayOfWeek: 'eeee',
  monthYear: 'MMM yyyy',
};

const fr: DateFormat = {
  date: 'dd/MM/yy',
  dateLong: 'dd/MM/yyyy',
  dateGeneric: 'dd.MM.yyyy',
  isoDate,
  isoDateTime,
  dateTime: "eeeeee, dd/MM/yy, HH'h'mm",
  dayDate: 'eeeeee, dd/MM/yy',
  dayDateLong: 'eeee, dd/MM/yyyy',
  dayDateLongYear: 'eeeeee, dd/MM/yyyy',
  dayTimeLong: 'EEEEEE, dd/MM/yyyy - HH:mm',
  dayTimeShort: 'EEEEEE, dd/MM/yy - HH:mm',
  time: "HH'h'mm",
  timeGeneric: 'HH:mm',
  dayOfMonth: 'dd',
  dayOfWeek: 'eeee',
  monthYear: 'MMM yyyy',
};

const es: DateFormat = {
  date: 'dd/MM/yy',
  dateLong: 'dd/MM/yyyy',
  dateGeneric: 'dd.MM.yyyy',
  isoDate,
  isoDateTime,
  dateTime: 'eeeeee, dd/MM/yy, HH:mm',
  dayDate: 'eeeeee, dd/MM/yy',
  dayDateLong: 'eeee, dd/MM/yyyy',
  dayDateLongYear: 'eeeeee, dd/MM/yyyy',
  dayTimeLong: 'EEEEEE, dd/MM/yyyy - HH:mm',
  dayTimeShort: 'EEEEEE, dd/MM/yy - HH:mm',
  time: 'HH:mm',
  timeGeneric: 'HH:mm',
  dayOfMonth: 'dd',
  dayOfWeek: 'eeee',
  monthYear: 'MMM yyyy',
};

const it: DateFormat = {
  date: 'dd/MM/yy',
  dateLong: 'dd/MM/yyyy',
  dateGeneric: 'dd.MM.yyyy',
  isoDate,
  isoDateTime,
  dateTime: 'eeeeee dd/MM/yy, HH:mm',
  dayDate: 'eeeeee dd/MM/yy',
  dayDateLong: 'eeee dd/MM/yyyy',
  dayDateLongYear: 'eeeeee dd/MM/yyyy',
  dayTimeLong: 'EEEEEE dd/MM/yyyy - HH:mm',
  dayTimeShort: 'EEEEEE dd/MM/yy - HH:mm',
  time: 'HH:mm',
  timeGeneric: 'HH:mm',
  dayOfMonth: 'dd',
  dayOfWeek: 'eeee',
  monthYear: 'MMM yyyy',
};

const nl: DateFormat = {
  date: 'dd-MM-yy',
  dateLong: 'dd-MM-yyyy',
  dateGeneric: 'dd.MM.yyyy',
  isoDate,
  isoDateTime,
  dateTime: 'eeeeee dd-MM-yy, HH:mm',
  dayDate: 'eeeeee dd-MM-yy',
  dayDateLong: 'eeee dd-MM-yyyy',
  dayDateLongYear: 'eeeeee dd-MM-yyyy',
  dayTimeLong: 'EEEEEE dd-MM-yyyy - HH:mm',
  dayTimeShort: 'EEEEEE dd-MM-yy - HH:mm',
  time: 'HH:mm',
  timeGeneric: 'HH:mm',
  dayOfMonth: 'dd',
  dayOfWeek: 'eeee',
  monthYear: 'MMM yyyy',
};

const en: DateFormat = {
  date: 'yy-MM-dd',
  dateLong: 'yyyy-MM-dd',
  dateGeneric: 'yyyy-MM-dd',
  isoDate,
  isoDateTime,
  dateTime: 'eeeeee, yy-MM-dd, HH:mm',
  dayDate: 'eeeeee, yy-MM-dd',
  dayDateLong: 'eeee, yyyy-MM-dd',
  dayDateLongYear: 'eeeeee, yyyy-MM-dd',
  dayTimeLong: 'EEEEEE, yyyy-MM-dd - HH:mm',
  dayTimeShort: 'EEEEEE, yy-MM-dd - HH:mm',
  time: 'HH:mm',
  timeGeneric: 'HH:mm',
  dayOfMonth: 'dd',
  dayOfWeek: 'eeee',
  monthYear: 'MMM yyyy',
};

const tu: DateFormat = {
  date: 'dd.MM.yy',
  dateLong: 'dd.MM.yyyy',
  dateGeneric: 'dd.MM.yyyy',
  isoDate,
  isoDateTime,
  dateTime: 'eeeeee, dd.MM.yy, HH:mm',
  dayDate: 'eeeeee, dd.MM.yy',
  dayDateLong: 'eeee, dd.MM.yyyy',
  dayDateLongYear: 'eeeeee, dd.MM.yyyy',
  dayTimeLong: 'EEEEEE, dd.MM.yyyy - HH:mm',
  dayTimeShort: 'EEEEEE, dd.MM.yy - HH:mm',
  time: 'HH:mm',
  timeGeneric: 'HH:mm',
  dayOfMonth: 'dd',
  dayOfWeek: 'eeee',
  monthYear: 'MMM yyyy',
};

const dateFormats = { de, fr, es, it, nl, en, tu };

export { dateFormats };
