import { RichTextStyle } from '../RichText';
import { Nav, NavContainer } from './TabNavigation.styles';

type Props = React.PropsWithChildren<{
  textStyle?: RichTextStyle;
}>;

const TabNavigation = ({
  children,
  textStyle = RichTextStyle.Primary,
}: Props) => (
  <NavContainer>
    <Nav aria-label="Tabs" role="tablist" textStyle={textStyle}>
      {children}
    </Nav>
  </NavContainer>
);
export { TabNavigation };
