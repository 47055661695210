import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { useTranslations } from 'next-intl';
import { Theme, Ui } from '@silvertours/front-shared';

type ErrorProps = {
  statusCode?: number;
};

const Error = ({ statusCode }: ErrorProps) => {
  const t = useTranslations('features.stageLegacy.stage');

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    false,
  );

  const [showModal, setShowModal] = useState(true);

  const handleHideModal = () => {
    setShowModal(false);
  };

  const messageProps: Record<
    number,
    {
      headline: string;
      children: string;
    }
  > = {
    404: {
      headline: t('errors.404.title'),
      children: t('errors.404.text'),
    },
    500: {
      headline: t('errors.500.title'),
      children: t('errors.500.text'),
    },
    503: {
      headline: t('errors.503.title'),
      children: t('errors.503.text'),
    },
  };

  const hasError = statusCode
    ? Object.keys(messageProps).includes(statusCode.toString())
    : false;

  if (!statusCode || !hasError) {
    return null;
  }

  if (isMobile) {
    if (showModal) {
      const { headline, children } = messageProps[statusCode];
      return (
        <Ui.Overlay title={headline} open={showModal} onClose={handleHideModal}>
          <Ui.Message
            severity={Ui.MessageSeverity.ERROR}
            role={Ui.MessageRole.ALERT}
          >
            {children}
          </Ui.Message>
        </Ui.Overlay>
      );
    }
    return null;
  }
  return (
    <Ui.Message
      severity={Ui.MessageSeverity.ERROR}
      role={Ui.MessageRole.ALERT}
      {...messageProps[statusCode]}
    />
  );
};

export { Error };
