import { BodyText, String, Ui } from '@silvertours/front-shared';
import { Runtime } from '@silvertours/front-entities';
import { format } from 'date-fns/format';
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
import { parseISO } from 'date-fns/parseISO';
import type { Props } from './OffersModule';
import { Category, Dates, Details, Pricing } from './OfferDetails.styles';

const OfferDetails = ({ content, translations }: Props) => {
  const locale = Runtime.useLocale();
  const { dateLong } = String.dateFormats[locale.language];

  return (
    <div>
      {Object.entries(content).map(([category, data]) => (
        <Category key={category}>
          <Ui.Typography as="h2">
            {translations.carTypes[category]}
          </Ui.Typography>
          {data.offers.map(offer => {
            const destinationDate = new Date(offer.destinationDate);

            return (
              <Details key={`${offer.category}-${offer.supplier}`}>
                <Pricing>
                  <BodyText>{offer.supplier}</BodyText>
                  <BodyText>
                    {translations.pricePrefix}
                    {offer.price}
                    {'€ '}
                    {translations.priceSuffix}
                  </BodyText>
                </Pricing>
                <Dates textColor="grey">
                  {differenceInCalendarDays(
                    parseISO(offer.destinationDate),
                    parseISO(offer.departureDate),
                  )}
                  {translations.durationFrom}
                  {format(destinationDate, dateLong)}
                  {'; '}
                  {offer.city}
                </Dates>
              </Details>
            );
          })}
        </Category>
      ))}
    </div>
  );
};

export { OfferDetails };
