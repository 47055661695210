import World from '@engineering/icons/world';
import { Ui } from '@silvertours/front-shared';
import { useTranslations } from 'next-intl';
import { LocalizedMarketPicker } from './LocalizedMarketPicker';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const MarketPickerModal = ({ show, setShow }: Props) => {
  const t = useTranslations('features.navigationLegacy.navigation.header');
  const header = t('marketPicker.header');
  return (
    <>
      <Ui.IconButton icon={World} onClick={() => setShow(true)} />
      {show && (
        <Ui.Overlay title={header} open={show} onClose={() => setShow(false)}>
          <LocalizedMarketPicker onSubmit={() => setShow(false)} />
        </Ui.Overlay>
      )}
    </>
  );
};

export { MarketPickerModal };
