import type { HTMLAttributes, ReactNode, SVGAttributes } from 'react';
import { StyledSvg } from './Logo.styles';

type LogoProps = Pick<
  SVGAttributes<SVGSVGElement>,
  'width' | 'height' | 'viewBox' | 'className'
> &
  Pick<HTMLAttributes<HTMLElement>, 'title'> & {
    children: ReactNode;
  };

const Logo = ({ width, height, viewBox, title, ...rest }: LogoProps) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    role="img"
    aria-label={title}
    {...rest}
  />
);

export { Logo };
