import { Context } from '@silvertours/front-shared';

type PathContext = {
  alternatives: Array<{
    locale: Locale;
    path: string;
  }>;
  current: string;
};

type Locale = 'de' | 'fr' | 'es' | 'it' | 'nl' | 'en';

const hasContext: Context.AssertHasContext = Context.guardContext;

const usePath = () => {
  const pathContext = Context.useClientContext<PathContext>('path');
  hasContext('path', pathContext);

  const { alternatives, current } = pathContext.value;
  const isHomepage = current.startsWith('index.');
  const currentPath = isHomepage ? '' : current;

  return {
    alternatives,
    current: currentPath,
    isHomepage,
  };
};

export { usePath };
