import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Calendar } from './DatePicker/Calendar';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  user-select: none;
`;

const StyledCalendar = styled(Calendar)`
  position: ${({ vertical }) => (vertical ? 'fixed' : 'absolute')};
  inset: ${({ vertical }) => (vertical ? '0' : 'auto')};
  z-index: 101;
  margin-bottom: 0 !important;

  ${({ theme, vertical }) =>
    !vertical &&
    css`
      margin-top: -1px;
      border: 1px solid ${theme.color.grey03};
      border-radius: ${theme.borderRadius.md};
      width: 688px;
      overflow: hidden;
    `}
`;

const TimeRange = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;

  > * {
    width: 100%;
  }
`;

export { Backdrop, StyledCalendar, TimeRange };
