import styled from '@emotion/styled';
import { CarouselProps } from 'frontend/entities/src/Carousel/Carousel';

const Container = styled.div<CarouselProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacing[50]};

  & > * {
    width: 313px;
  }

  ${({ theme }) => theme.mq('sm')} {
    & > * {
      width: 360px;
    }
  }
`;

export { Container };
