import { useReducer } from 'react';

const initialState = {
  openMenu: null,
};

const menuReducer = (
  state: { openMenu: number | null },
  action: { type: string; index: number },
) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        openMenu: state.openMenu === action.index ? null : action.index,
      };
    default:
      return state;
  }
};

/**
 * Convenience hook for controlling mobile menus when multiple menus are needed
 * (only one should be visible at once)
 */
export const useMobileMenus = () => {
  const [{ openMenu }, dispatch] = useReducer(menuReducer, initialState);
  const setOpenMenu = (index: number) =>
    dispatch({ type: 'TOGGLE_MENU', index });
  return { openMenu, setOpenMenu };
};
