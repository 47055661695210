import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { lighten } from 'polished';
import {
  Button,
  interactiveText,
  TertiaryText,
  Ui,
} from '@silvertours/front-shared';
import PiggyBank from '@engineering/icons/details/piggy-bank';
import Forbidden from '@engineering/icons/forbidden';

const Section = styled.section`
  width: 100%;
  display: flex;
  align-items: end;
  gap: ${({ theme }) => theme.spacing[20]};
  text-align: center;
  height: 22.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.color.grey05};
  padding: ${({ theme }) => `
    ${theme.spacing[50]}
    ${theme.spacing[50]}
    ${theme.spacing[80]}
    ${theme.spacing[50]}
  `};
  margin: ${({ theme }) => theme.spacing[0]};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1rem;
  color: ${({ theme }) => theme.color.black};
  overflow-x: hidden;
`;

const Bar = styled.div<{ pricesAreAvailable?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) =>
    lighten(0.285, theme.color.primaryHighlight!)};
  cursor: pointer;
  max-height: ${({ pricesAreAvailable }) =>
    pricesAreAvailable ? '16rem' : '12.5rem'};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: 1rem ${({ theme }) => theme.spacing[20]};
  overflow: visible;
  line-height: 150%;
  color: ${({ theme }) => theme.color.grey01};
  width: 5.875rem;

  ${({ theme }) => theme.mq('lg')} {
    width: 6.25rem;
  }
`;

const Line = styled.hr`
  color: ${({ theme }) => theme.color.grey02};
  border: 0.5px solid ${({ theme }) => theme.color.grey02};
  width: 2.75rem;
`;

const PriceWrapper = styled.span`
  ${({ theme }) => interactiveText({ textColor: 'grey', theme, size: 'small' })}
  text-align: center;
`;

const Label = styled.div`
  ${({ theme }) => interactiveText({ textColor: 'orange', theme, size: 'xs' })}
  text-align: center;
  text-transform: uppercase;
  white-space: wrap;
`;

const CardContainer = styled(Ui.Card)<{ pricesAreAvailable?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 12.125rem;
  height: ${({ pricesAreAvailable }) =>
    pricesAreAvailable ? '19.875rem' : '13.375rem'};
  position: relative;
  top: 2rem;
  padding: ${({ theme }) => theme.spacing[30]};
  color: ${({ theme }) => theme.color.grey01};
  font-size: 0.75rem;
  cursor: pointer;
`;

type ImageWrapperProps = {
  selected?: boolean;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  ${({ selected }) =>
    selected
      ? ''
      : css`
          margin-top: -46px;
        `}
  text-align: center;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

const MonthContainer = styled.div`
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
`;

const NoDataTextWrapper = styled(TertiaryText)<{ selected?: boolean }>`
  ${({ selected }) =>
    selected
      ? css`
          position: relative;
          top: -4px;
          color: #939393;
          fill: #939393;
        `
      : ''}
  text-align: center;
  text-transform: none;
  font-weight: 400;
  line-height: 150%;
  max-width: 4.75rem;

  span {
    display: block;
  }
`;

const ForbiddenIcon = styled(Forbidden)`
  width: 1.125rem;
  height: 1.125rem;
`;

const PiggyBankIcon = styled(PiggyBank)`
  width: 2.5rem;
  height: 2.5rem;
`;

const StyledButton = styled(Button)`
  height: 2.75rem;
  font-size: 1rem;
  padding: 1rem 0;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
`;

export {
  Bar,
  CardContainer,
  ImageWrapper,
  Label,
  Line,
  MonthContainer,
  NoDataTextWrapper,
  PriceWrapper,
  Section,
  ForbiddenIcon,
  PiggyBankIcon,
  StyledButton,
  TextBlock,
};
