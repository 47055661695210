import {
  SearchSuggestion,
  useKeyNavigation,
} from '@silvertours/front-entities';
import { useRef, FocusEvent, RefObject, useEffect } from 'react';
import { Theme } from '@silvertours/front-shared';
import { StyledButton, SuggestionWrapper } from './SelectStation.styles';
import { StationItem } from './StationItem';

type Props = {
  connectedInputContainer?: RefObject<HTMLInputElement>;
  onLastElementBlur: (event: FocusEvent<HTMLElement, Element>) => void;
  onItemClick: (suggestion?: SearchSuggestion) => void;
  results: Array<SearchSuggestion> | null;
};

const Suggestion = ({
  connectedInputContainer,
  onLastElementBlur,
  onItemClick,
  results,
}: Props) => {
  const listRef = useRef<HTMLUListElement>(null);
  const isMobileTouchDevice = Theme.useIsMobileTouchDevice();

  useEffect(() => {
    if (isMobileTouchDevice && connectedInputContainer?.current) {
      connectedInputContainer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isMobileTouchDevice, connectedInputContainer]);

  const handleEnter = (item?: SearchSuggestion) => {
    onItemClick(item);
  };

  const { focusedItem, onMouseEnter, onMouseLeave } =
    useKeyNavigation<SearchSuggestion>(results || [], listRef, handleEnter);

  if (!Array.isArray(results) || !results?.length) {
    return null;
  }

  return (
    <SuggestionWrapper ref={listRef} id="locationSuggest" role="grid">
      {results.map((suggestion, index) => (
        <li key={suggestion.objectID} role="presentation">
          <StyledButton
            type="button"
            tabIndex={0}
            onBlur={onLastElementBlur}
            onMouseDown={() => onItemClick(suggestion)}
            onMouseEnter={() => onMouseEnter(index)}
            onMouseLeave={onMouseLeave}
            id={`locationSuggestItem-${index + 1}`}
            role="row"
            aria-label={`${suggestion.cityName} ${suggestion.typeDisplay}`}
            aria-selected={index === focusedItem}
          >
            <StationItem suggestion={suggestion} />
          </StyledButton>
        </li>
      ))}
    </SuggestionWrapper>
  );
};

export { Suggestion };
