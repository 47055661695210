import { PropsWithChildren } from 'react';

import { CollapsedOverlay, Container } from './CollapsableContainer.styles';
import { HasCollapseContentRef } from '../CollapseContentRef';

export type CollapsableContainerBehavior<TElement extends Element> =
  HasCollapseContentRef<TElement>;

export type CollapsableContainerRules = {
  canBeCollapsed: boolean;
  isCollapsed: boolean;
};

export type CollapsableContainerProps<TElement extends Element> =
  PropsWithChildren & {
    behavior: CollapsableContainerBehavior<TElement>;
    rules: CollapsableContainerRules;
  };

export const CollapsableContainer = ({
  behavior: { ref },
  children,
  rules: { canBeCollapsed, isCollapsed },
}: CollapsableContainerProps<HTMLDivElement>) => (
  <Container isCollapsed={isCollapsed} ref={ref}>
    {children}
    {canBeCollapsed && <CollapsedOverlay isCollapsed={isCollapsed} />}
  </Container>
);
