import { FC, ReactNode } from 'react';
import { ColumnRatioOption } from './ColumnRatioOption';

import { Column, ColumnContainer } from './Columns.styles';

type ColumnProps = {
  children: ReactNode;
};

type TwoColumnsRenderer = (
  Column1: FC<ColumnProps>,
  Column2: FC<ColumnProps>,
) => [columnContent1: ReactNode, columnContent2: ReactNode];

type TwoColumnsRatio = [
  antecedent: ColumnRatioOption,
  consequent: ColumnRatioOption,
];

type TwoColumnsProps = {
  children: TwoColumnsRenderer;
  id: string;
  ratio: TwoColumnsRatio;
};

// eslint-disable-next-line react/jsx-no-useless-fragment
const ColumnBody = ({ children }: ColumnProps) => <>{children}</>;

const column = { columnCount: 2 };

const TwoColumns = ({
  children,
  id,
  ratio: [part1, part2],
}: TwoColumnsProps) => {
  const consequent = part1 + part2;
  const [columnContent1, columnContent2] = children(ColumnBody, ColumnBody);

  return (
    <ColumnContainer consequent={consequent} id={id}>
      <Column antecedent={part1} {...column}>
        {columnContent1}
      </Column>
      <Column antecedent={part2} {...column}>
        {columnContent2}
      </Column>
    </ColumnContainer>
  );
};

export { TwoColumns };
export type { TwoColumnsProps, TwoColumnsRatio };
