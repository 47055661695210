import { Session } from '@silvertours/front-shared';

export const SESSION_STORAGE_NAME_FOR_FIND_CLICK = 'userClickOnFindTimestamp';

export const useSession = () => {
  // Track current click timestamp
  const trackClickTimestamp = () => {
    Session.SessionStorageManager.setData(
      SESSION_STORAGE_NAME_FOR_FIND_CLICK,
      Date.now(),
    );
  };

  // Remove current click timestamp
  const removeClickTimestamp = () => {
    Session.SessionStorageManager.removeData(
      SESSION_STORAGE_NAME_FOR_FIND_CLICK,
    );
  };

  return { trackClickTimestamp, removeClickTimestamp };
};
