import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.default};
  text-align: left;
`;

const Label = styled.label<{
  isHidden: boolean;
  isFilled: boolean;
  isInvalid: boolean;
  isDisabled?: boolean;
}>`
  position: absolute;
  top: -0.5625rem;
  left: 0.75rem;
  clip: ${({ isHidden }) => (isHidden ? 'rect(0 0 0 0)' : '')};
  z-index: 2;
  margin: ${({ isHidden }) => (isHidden ? '-1px' : '')};
  padding: ${({ isHidden }) => (isHidden ? '0' : '0 0.25rem')};
  width: ${({ isHidden }) => (isHidden ? '1px' : '')};
  height: ${({ isHidden }) => (isHidden ? '1px' : '')};
  color: ${({ theme, isInvalid, isDisabled }) => {
    let color = theme.color.grey01;
    if (isDisabled) {
      color = theme.color.grey03;
    }
    if (isInvalid) {
      color = theme.color.error;
    }
    return color;
  }};
  background-color: ${({ theme }) => theme.color.white};
  font-size: 0.75rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: ${({ isFilled }) => (isFilled ? '1' : '0')};
  transform: ${({ isFilled }) =>
    isFilled ? 'translate3d(0, 0, 0)' : 'translate3d(0, 0.375rem, 0)'};
  transition:
    transform 0.15s,
    opacity 0.1s,
    background-color 0.1s;
  pointer-events: none;
`;

const Input = styled.input<{
  isInvalid: boolean;
  isFilled: boolean;
  isActive: boolean;
  isCleanable: boolean;
  iconPosition?: 'left' | 'right';
}>`
  display: ${({ type }) => (type === 'date' ? 'inline-flex' : 'inline-block')};
  margin: 0;
  padding: ${({ iconPosition, type, isCleanable }) => {
    switch (iconPosition) {
      case 'left':
        if (type === 'password' || isCleanable) {
          return '0.625rem 2.85rem';
        }
        return '0.625rem 1rem 0.625rem 2.85rem';
      case 'right':
        if (type === 'password' || isCleanable) {
          return '0.625rem 5.5rem 0.625rem 1rem';
        }
        return '0.625rem 2.85rem 0.625rem 1rem';
      default:
        if (type === 'password' || isCleanable) {
          return '0.625rem 2.85rem 0.625rem 1rem';
        }
        return '0.625rem 1rem';
    }
  }};
  border: ${({ theme, isInvalid }) =>
    `1px solid ${isInvalid ? theme.color.error : theme.color.grey03}`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  outline: 0;
  width: 100%;
  height: 3.125rem;
  font-family: inherit;
  font-size: 1rem; /* 16px size prevents focus zoom on iOS */
  line-height: 1.5;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  background-clip: padding-box; /* removes inner shadow on iOS */
  box-shadow: none; /* removes validation outlines in Firefox */
  box-sizing: border-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: ${
    ({ type }) =>
      type === 'number' && 'textfield' /* remove spinners in Gecko browsers */
  };
  appearance: ${
    ({ type }) =>
      type === 'date' && 'none' /* remove ugly iOS date input look */
  };

  ${p =>
    (p.type === 'number' || p.type === 'date') &&
    css`
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0; /* apparently some margin are still there even though it's hidden */
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none; /* remove spinners on WebKit desktop browsers */
      }
    `}

  ${({ type }) =>
    type === 'date' &&
    css`
      &::-webkit-date-and-time-value {
        text-align: left;
      }
    `}

  ${({ type }) =>
    type === 'search' &&
    css`
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    `}

  &:hover,
  &:focus {
    border-color: ${({ theme, isInvalid }) =>
      isInvalid ? theme.color.error : theme.color.grey02};
  }

  &::placeholder {
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.text : theme.color.grey01};
    opacity: 1;
  }

  &::-ms-clear {
    display: none;
  }

  &[readonly],
  &[disabled] {
    border-color: ${({ theme, isInvalid }) =>
      isInvalid ? theme.color.error : theme.color.grey03};
    color: ${({ theme }) => theme.color.grey03};
    cursor: not-allowed;
    pointer-events: none;
  }

  & + svg {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 0.75rem;
    left: ${({ iconPosition }) => (iconPosition === 'left' ? '1rem' : '')};
    right: ${({ iconPosition, type }) => {
      if (iconPosition === 'right') {
        if (type === 'password') {
          return '3rem';
        }
        return '1rem';
      }

      return '';
    }};
    width: 1.6rem;
    height: 1.6rem;
    fill: ${({ theme, isFilled, isActive }) => {
      let color = theme.color.grey03;
      if (isFilled || isActive) {
        color = theme.color.text;
      }
      return color;
    }};
  }
`;

const ActionButton = styled.button`
  display: inline-flex;
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 100%;
  width: 1.6rem;
  height: 1.6rem;
  font: inherit;
  color: inherit;
  background: none;
  fill: ${({ theme }) => theme.color.text};
  cursor: pointer;

  &:focus-visible {
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export { Wrapper, Label, Input, ActionButton };
