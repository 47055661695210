import { TextImageContentModule } from '@silvertours/common-landingpages-view';

import {
  Badge,
  HasBadgeRules,
  HasHeadingStyle,
  HasModuleContainerSpacingRules,
  HasTextStyleRules,
  Heading,
  LinkButton,
  ModuleContainer,
  RichText,
} from '../element';
import { HasColumns } from '../rule';

export type TextContentBlockRules = {
  isContainerMultiColumn: boolean;
  isSecondColumn?: boolean;
} & HasBadgeRules &
  HasColumns &
  HasHeadingStyle &
  HasModuleContainerSpacingRules &
  HasTextStyleRules;

type TextContentBlockContent = Pick<
  TextImageContentModule,
  'badge' | 'heading' | 'content' | 'link'
>;

export type TextContentBlockProps = {
  content: TextContentBlockContent;
  rules?: TextContentBlockRules;
};

export const TextContentBlock = ({
  content: { badge, heading, content, link },
  rules,
}: TextContentBlockProps) => {
  const {
    badge: badgeRules,
    columns,
    heading: headingStyle = {},
    isContainerMultiColumn,
    isSecondColumn,
    textStyle,
    verticalSpacing,
  } = rules || {};
  const { style } = textStyle || {};

  return (
    <ModuleContainer
      rules={{
        isSecondColumn,
        verticalSpacing,
      }}
    >
      <Badge content={badge} rules={{ badge: badgeRules }} />
      <Heading
        content={heading}
        rules={{ textStyle: { style }, ...headingStyle }}
      />
      {content && (
        <RichText content={content} rules={{ columns, textStyle: { style } }} />
      )}
      <LinkButton
        content={link}
        rules={{
          variant: 'secondary',
          size: 'small',
          horizontalSpacing: { isCentered: !isContainerMultiColumn },
        }}
      />
    </ModuleContainer>
  );
};
