import { useMemo, useState } from 'react';

import { OptionalImageLink } from '@silvertours/common-landingpages-view';

import { Theme } from '@silvertours/front-shared';
import { Badge, Button, Heading } from '../../../element';
import { SET_SIZE } from './GridSetSize';
import { LinkGrid, ColoredImageLink } from './LinkGrid';
import { GridLinkListProps } from './types';
import { Container } from './LinkGrid/LinkGrid.styles';

const BACKGROUND_COLORS: Array<Theme.Colors> = [
  'brand',
  'primaryHighlight',
  'secondary',
  'secondaryHighlight',
];

const getColor = () =>
  BACKGROUND_COLORS[Math.floor(Math.random() * BACKGROUND_COLORS.length)];

const addColorsToLinks = (
  links: Array<OptionalImageLink>,
): Array<ColoredImageLink> =>
  links.map(link => ({
    ...link,
    color: getColor(),
    hidden: false,
  }));

const splitIntoSets = (links: Array<ColoredImageLink>) => {
  const sets = [];

  for (let index = 0; index < links.length; index += SET_SIZE) {
    sets.push(links.slice(index, index + SET_SIZE));
  }

  return sets;
};

export const GridLinkList = ({
  content: { badge, heading, links },
  translations,
}: GridLinkListProps) => {
  const { allDestinationsLabel = '', moreDestinationsLabel = '' } =
    translations || {};
  const [visibleSetCount, setVisibleSetCount] = useState<number>(1);
  const coloredLinks = useMemo(() => addColorsToLinks(links), [links]);

  const visibleItems = visibleSetCount * SET_SIZE;
  const hasMoreItemsToShow = visibleItems < links.length;
  const hasOneMoreSetRemaining = visibleItems + SET_SIZE > links.length;
  const sets = splitIntoSets(coloredLinks);
  const visibleSets = sets.map((set, index) => {
    if (visibleSetCount < index + 1) {
      return set.map(link => ({
        ...link,
        hidden: true,
      }));
    }

    return set;
  });

  const incrementSets = () => {
    setVisibleSetCount(visibleSetCount + 1);
  };

  return (
    <Container>
      <Badge content={badge} rules={{ badge: { style: 'orange' } }} />
      <Heading
        content={heading}
        rules={{ verticalSpacing: { spacingDirection: 'both' } }}
      />
      <LinkGrid content={{ sets: visibleSets }} />
      {hasMoreItemsToShow && (
        <Button
          content={{
            label: hasOneMoreSetRemaining
              ? allDestinationsLabel
              : moreDestinationsLabel,
          }}
          onClick={incrementSets}
          rules={{
            variant: 'secondary',
            expanded: true,
            horizontalSpacing: { isCentered: true },
            verticalSpacing: { spacingDirection: 'neither' },
          }}
        />
      )}
    </Container>
  );
};
