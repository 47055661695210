import { ContentModuleStyle } from '@silvertours/common-landingpages-view';
import { Ui } from '@silvertours/front-shared';

export const BadgeStyle: Record<string, Ui.BadgeType> = {
  default: 'orange',
  gray: 'orange',
  green: 'light',
  mint: 'light',
  orange: 'light',
};

export const selectBadgeStyle = (
  style: ContentModuleStyle = 'default',
): Ui.BadgeType => BadgeStyle[style];
