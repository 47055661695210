import { Ui } from '@silvertours/front-shared';
import styled from '@emotion/styled';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { ReviewsChart } from './ReviewChart';
import { ThirdPartyLogo } from '../ThirdPartyLogo';

const Bar = styled.data<{ value: number }>`
  display: block;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  height: 0.5rem;
  width: 5rem;
  background-color: ${({ theme }) => theme.color.grey04};

  ${({ theme }) => theme.mq('sm')} {
    width: 8rem;
  }

  &::after {
    content: '';
    display: block;
    width: ${({ value }) => `${value * 100}%`};
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.sm} 0 0
      ${({ theme }) => theme.borderRadius.sm};
    background-color: ${({ theme }) => theme.color.brand};
  }
`;

const Title = styled.span`
  ${({ theme }) => theme.mq('sm')} {
    margin-left: ${({ theme }) => theme.spacing[50]};
  }
`;

const Summary = styled.div`
  display: grid;
  grid-template-areas: 'logo ratings-list' 'aggregated-rating ratings-list';
  grid-template-columns: min-content 1fr;
  gap: ${({ theme }) => theme.spacing[10]} 1.375rem;
  hyphens: auto;

  ${({ theme }) => theme.mq('sm')} {
    margin: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[50]}`};
    grid-template-columns: max-content 1fr;
  }
`;

const Name = styled.span`
  display: block;

  ${({ theme }) => theme.mq('sm')} {
    display: inline;
  }
`;

const StyledThirdPartyLogo = styled(ThirdPartyLogo)`
  grid-area: logo;
  height: 2rem;
`;

const RatingLine = styled.data`
  font-size: ${({ theme }) => theme.fontSize.sm.size};
`;

const CurrentRating = styled.span`
  color: ${({ theme }) => theme.color.brand};
`;

const AggregatedRating = styled.div`
  grid-area: aggregated-rating;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[10]};

  ${RatingLine} {
    margin-bottom: ${({ theme }) => theme.spacing[10]};
    font-family: ${({ theme }) => theme.fontFamily.alternate};
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.grey02};
  }
`;

const RatingsList = styled.div`
  grid-area: ratings-list;
  justify-self: end;
  display: grid;
  grid-template-columns: max-content 1fr auto;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[20]};
  width: 100%;
  max-width: 21.5rem;
`;

const Stars = styled(UiLegacy.Rating)<{ withSpacing?: boolean }>`
  ${({ theme }) => theme.mq('sm')} {
    margin-right: ${({ theme, withSpacing }) =>
      withSpacing ? theme.spacing[60] : ''};
  }

  & path {
    fill: ${({ theme }) => theme.color.brand};
  }
`;

const ReviewList = styled.div`
  background-color: ${({ theme }) => theme.color.grey05};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing[50]};
  margin-top: ${({ theme }) => theme.spacing[60]};
`;

const ReviewContainer = styled.article`
  /* stylelint-disable-next-line no-empty-source */
`;

const ReviewHeader = styled.header`
  color: ${({ theme }) => theme.color.grey01};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[30]}`};

  ${CurrentRating} {
    font-weight: 600;
  }
`;

const ReviewBody = styled.blockquote`
  margin: ${({ theme }) => theme.spacing[30]} 0;
`;

const ReviewFooter = styled.footer`
  /* stylelint-disable-next-line no-empty-source */
`;

const ToggleChartButton = styled(Ui.TextLink)`
  margin-bottom: ${({ theme }) => theme.spacing[50]};
`;

const StyledReviewsChart = styled(ReviewsChart)`
  margin-bottom: ${({ theme }) => theme.spacing[30]};
  max-width: 20rem;
`;

export {
  Bar,
  Summary,
  Title,
  Name,
  StyledThirdPartyLogo,
  RatingLine,
  CurrentRating,
  AggregatedRating,
  RatingsList,
  Stars,
  ReviewList,
  ReviewContainer,
  ReviewHeader,
  ReviewBody,
  ReviewFooter,
  ToggleChartButton,
  StyledReviewsChart,
};
