import { SearchLocation } from '@silvertours/common-landingpages-view';
import { SearchFormState, useSearchInitializer } from '../context';
import { useSubmitHandler } from './useSubmitHandler';

// Creates a search handler that submits a search with default form state
const useSubmitInitialSearch = () => {
  const handleSearch = useSubmitHandler();
  const { getDefaultState } = useSearchInitializer();

  return {
    makeSearchHandler: (location?: SearchLocation | null) => {
      if (!location || !location.query) {
        return undefined;
      }

      const searchData = getDefaultState(location || null);

      return (query: string, searchDataOverride?: Partial<SearchFormState>) => {
        handleSearch(
          {
            ...searchData,
            ...searchDataOverride,
          },
          query,
        );
      };
    },
  };
};

export { useSubmitInitialSearch };
