import styled from '@emotion/styled';
import { useTranslations } from 'next-intl';
import { datetime } from '@silvertours/front-shared';
import { useSiteInfo } from '@silvertours/front-entities';
import { Day } from 'date-fns';

const OpeningHours = styled.div`
  width: 100%;
  max-width: 222px;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fontFamily.default};
`;

const Information = styled.div`
  margin-top: 0.5rem;
`;

const ScheduleRow = styled.div<{ type: 'common' | 'exception' }>`
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ type }) => type === 'common' && 'bold'};
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
`;

export const Schedule = () => {
  const t = useTranslations('features.supportInfoLegacy');
  const {
    settings: { language },
    openingTimes,
  } = useSiteInfo();
  const locale = datetime.dateLocales[language];

  const [monday, friday, saturday, sunday] = ([1, 5, 6, 0] as Day[]).map(day =>
    locale.localize.day(day, { width: 'abbreviated' }),
  );

  const commonSchedule = Object.entries(openingTimes.common)
    .map(([key, hours]) => {
      if (typeof hours === 'undefined') {
        return undefined;
      }

      const { open, close } = hours;
      let days: string;

      switch (key) {
        case 'always':
          days = `${monday} - ${sunday}`;
          break;
        case 'weekdays':
        case 'workdays':
          days = `${monday} - ${friday}`;
          break;
        case 'weekends':
          days = `${saturday} - ${sunday}`;
          break;
        case 'saturday':
          days = saturday;
          break;
        case 'sunday':
          days = sunday;
          break;
        default:
          days = key;
      }
      return (
        <ScheduleRow type="common" key={key}>
          <div>{days}</div>
          <div>
            {open} - {close}
          </div>
        </ScheduleRow>
      );
    })
    .filter(Boolean);

  return (
    <OpeningHours>
      {t('openingTimes.footer')}
      <Information>{openingTimes.common ? commonSchedule : null}</Information>
    </OpeningHours>
  );
};
