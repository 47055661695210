import type { PropsWithChildren } from 'react';
import { Wrapper, Swirl, Text } from './PromotionBanner.styles';
import SwirlImage from './swirl.png';

type PromotionBannerProps = PropsWithChildren<{ title?: string }>;

export const PromotionBanner = ({ children, title }: PromotionBannerProps) => (
  <Wrapper>
    <Text>{title}</Text>
    {children}
    <Swirl src={SwirlImage} width={360} height={192} alt="" />
  </Wrapper>
);
