import { ImagePosition } from '@silvertours/common-landingpages-view';

export const getSpacingDirectionFromImagePosition = (
  imagePosition: ImagePosition,
): 'top' | 'bottom' | undefined => {
  if (imagePosition === 'top' || imagePosition === 'bottom') {
    return imagePosition;
  }

  return undefined;
};
