import styled from '@emotion/styled';

const SectionContainer = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 1rem;

  :not(:last-child) {
    margin-bottom: 4rem;
  }

  ${({ theme }) => theme.mq('sm')} {
    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[100]};
    }
  }

  ${({ theme }) => theme.mq('xl')} {
    column-gap: ${({ theme }) => theme.spacing[50]};
  }
`;

export { SectionContainer };
