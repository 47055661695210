import Image from 'next/image';
import styled from '@emotion/styled';

const Container = styled.div<{ hasMinSize: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing[50]};
  ${({ theme }) => theme.mq('sm')} {
    margin-bottom: ${({ theme }) => theme.spacing[70]};
    ${({ hasMinSize }) => (hasMinSize ? 'min-height: 350px;' : '')}
  }

  position: relative;
  height: 100%;
  min-height: 250px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

const StyledImage = styled(Image)`
  object-fit: cover;
`;

export { Container, StyledImage };
