import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.spacing[110]});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  margin: ${({ theme }) => theme.spacing[40]} 0 0 0;
`;

const StyledInput = styled(Ui.Input)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[50]};
`;

const SuggestionWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[40]};
  overflow-y: scroll;
`;

const StyledButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  font-style: inherit;
  color: inherit;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ theme }) => theme.spacing[60]} 1fr;
  grid-template-rows: auto;
  grid-gap: 0 ${({ theme }) => theme.spacing[40]};
  justify-items: start;
  background-color: ${({ theme }) => theme.color.white};
  cursor: pointer;
`;

const IconWrapper = styled.div`
  grid-area: 1 / 1 / 3 / 2;
  width: ${({ theme }) => theme.spacing[60]};
  height: ${({ theme }) => theme.spacing[60]};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing[30]};
`;

const Country = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  color: ${({ theme }) => theme.color.grey02};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.xs.size};
  font-weight: ${({ theme }) => theme.fontWeight.body};
  line-height: 1.5;
`;

const Station = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  color: ${({ theme }) => theme.color.text};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  font-weight: ${({ theme }) => theme.fontWeight.heading};
  line-height: 1.5;
  text-align: left;
`;

export {
  Container,
  StyledInput,
  SuggestionWrapper,
  StyledButton,
  IconWrapper,
  Country,
  Station,
};
