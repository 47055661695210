import {
  Badge as BadgeView,
  Image,
  ImagePosition,
} from '@silvertours/common-landingpages-view';
import { Image as StyledImage } from './ImageContentBlock.styles';

import {
  HasOptionalAlignmentRules,
  Badge,
  HasBadgeRules,
  HasModuleContainerSpacingRules,
  ModuleContainer,
} from '../element';

export type ImageContentBlockContent = {
  badge?: BadgeView;
  image?: Image;
};

export type ImageContentBlockRules = {
  imagePosition?: ImagePosition;
  isFirstColumn?: boolean;
} & HasOptionalAlignmentRules &
  HasBadgeRules &
  HasModuleContainerSpacingRules;

export type ImageContentBlockProps = {
  content: ImageContentBlockContent;
  rules?: ImageContentBlockRules;
};

export const ImageContentBlock = ({
  content: { badge, image },
  rules,
}: ImageContentBlockProps) => {
  if (!image) {
    return null;
  }

  const {
    badge: badgeRules,
    imagePosition,
    isFirstColumn,
    verticalAlign,
    verticalSpacing,
  } = rules || {};

  return (
    <ModuleContainer
      rules={{
        isFirstColumn,
        verticalSpacing,
      }}
    >
      <Badge
        content={badge}
        rules={{
          badge: badgeRules,
          verticalSpacing: { isLargeAtMD: true },
        }}
      />
      <StyledImage
        verticalAlign={verticalAlign}
        {...image}
        roundedBorder
        sizes={
          imagePosition === 'bottom' || imagePosition === 'top'
            ? '100vw'
            : undefined
        }
      />
    </ModuleContainer>
  );
};
