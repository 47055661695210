import { useCallback, useEffect, useRef, useState } from 'react';
import type { UIEvent } from 'react';
import debounce from 'lodash/debounce';
import { Wrapper, Hours, Minutes, Item, Separator } from './Scroller.styles';

const ITEM_HEIGHT_PX = 48;
const DEBOUNCE_DELAY_MS = 100;
const STEPS_MIN = 15;
const HOURS = Array.from({ length: 24 }, (_, index) => index);
const MINUTES = Array.from(
  { length: 60 / STEPS_MIN },
  (_, index) => index * STEPS_MIN,
);

const formatTime = (value1: number, value2?: number) => {
  if (value2 === undefined) {
    return String(value1).padStart(2, '0');
  }

  return [value1, value2].map(v => String(v).padStart(2, '0')).join(':');
};

type Props = {
  id: string;
  value: string;
  translations: {
    label: string;
    hours: string;
    minutes: string;
  };
  onChange?: (value: string) => void;
};

export const Scroller = ({
  id,
  value,
  translations: { label, hours: hoursLabel, minutes: minutesLabel },
  onChange,
}: Props) => {
  const [hourFromValue, minuteFromValue] = value.split(':').map(Number);
  const [hour, setHour] = useState(hourFromValue);
  const [minute, setMinute] = useState(minuteFromValue);
  const hourRef = useRef<HTMLUListElement | null>(null);
  const minuteRef = useRef<HTMLUListElement | null>(null);

  const selectHour = useCallback(
    (selectedValue: number) => {
      if (hourRef.current) {
        const scrollTop = selectedValue * ITEM_HEIGHT_PX;
        hourRef.current.scrollTop = scrollTop;
      }
    },
    [hourRef],
  );

  const selectMinute = useCallback(
    (selectedValue: number) => {
      if (minuteRef.current) {
        const scrollTop = MINUTES.indexOf(selectedValue) * ITEM_HEIGHT_PX;
        minuteRef.current.scrollTop = scrollTop;
      }
    },
    [minuteRef],
  );

  useEffect(() => {
    selectHour(hourFromValue);
    selectMinute(minuteFromValue);

    // set scroll behaviour after scrolling to the value so it doesn't animate on initial render
    if (hourRef.current && minuteRef.current) {
      hourRef.current.style.scrollBehavior = 'smooth';
      minuteRef.current.style.scrollBehavior = 'smooth';
    }
  }, [hourFromValue, minuteFromValue, selectHour, selectMinute]);

  const handleChange = useCallback(() => {
    if (typeof onChange === 'function') {
      onChange(formatTime(hour, minute));
    }
  }, [onChange, hour, minute]);

  const handleHourScroll = (event: UIEvent<HTMLUListElement>) => {
    debounce(() => {
      if (event.type === 'scroll') {
        const { scrollTop } = event.target as HTMLUListElement;
        const selectedValue = Math.round(
          Math.floor(scrollTop) / ITEM_HEIGHT_PX,
        );
        setHour(selectedValue);
      }
    }, DEBOUNCE_DELAY_MS)();
    handleChange();
  };

  const handleMinuteScroll = (event: UIEvent<HTMLUListElement>) => {
    debounce(() => {
      if (event.type === 'scroll') {
        const { scrollTop } = event.target as HTMLUListElement;
        const selectedValue =
          Math.round(Math.floor(scrollTop) / ITEM_HEIGHT_PX) * STEPS_MIN;
        setMinute(selectedValue);
      }
    }, DEBOUNCE_DELAY_MS)();
    handleChange();
  };

  return (
    <Wrapper id={id} role="group" aria-label={label}>
      <Hours
        role="listbox"
        aria-label={hoursLabel}
        aria-required="true"
        ref={hourRef}
        onScroll={handleHourScroll}
      >
        {HOURS.map(h => (
          <Item
            role="option"
            aria-label={`${formatTime(h)}`}
            aria-selected={hour === h ? 'true' : undefined}
            onClick={() => selectHour(h)}
            key={`hour-${h}`}
          >
            {formatTime(h)}
          </Item>
        ))}
      </Hours>
      <Separator aria-hidden="true">:</Separator>
      <Minutes
        role="listbox"
        aria-label={minutesLabel}
        aria-required="true"
        ref={minuteRef}
        onScroll={handleMinuteScroll}
      >
        {MINUTES.map(m => (
          <Item
            role="option"
            aria-label={`${formatTime(m)}`}
            aria-selected={minute === m ? 'true' : undefined}
            onClick={() => selectMinute(m)}
            key={`minute-${m}`}
          >
            {formatTime(m)}
          </Item>
        ))}
      </Minutes>
    </Wrapper>
  );
};
