import type { ReactNode } from 'react';
import { Ui } from '@silvertours/front-shared';
import {
  ContactIcon,
  PhoneInfoPopoverWrapper,
  PhoneNumber,
  PhoneNumberWrapperSection,
} from './InfolinePopover.styles';
import { useSiteInfo } from '../../SiteInfo';
import { useBrand, usePath } from '../../Runtime';

type Props = {
  popupContent: ReactNode;
};

const HIDE_PHONE_NUMBER_FOR_BRAND = {
  floyt: false,
  'billiger-mietwagen': true,
  carigami: false,
};

const InfolinePopover = ({ popupContent }: Props) => {
  const {
    settings: { phoneNumber, language },
  } = useSiteInfo();
  const { isHomepage } = usePath();
  const { brandName } = useBrand();

  const hidePhoneNumber = isHomepage || HIDE_PHONE_NUMBER_FOR_BRAND[brandName];

  return (
    <PhoneInfoPopoverWrapper>
      <Ui.Popover
        button={{
          as: 'div',
          children: (
            <PhoneNumberWrapperSection>
              <PhoneNumber
                phoneNumber={phoneNumber}
                language={language}
                icon={<ContactIcon />}
                hidePhoneNumber={hidePhoneNumber}
              />
            </PhoneNumberWrapperSection>
          ),
        }}
        triggerAction="click"
      >
        <Ui.PopoverPanel>
          <Ui.PanelContentWrapper
            width="14rem"
            widthMd="21.875rem"
            padding="1.5rem 0 0 0"
          >
            {popupContent}
          </Ui.PanelContentWrapper>
        </Ui.PopoverPanel>
      </Ui.Popover>
    </PhoneInfoPopoverWrapper>
  );
};

export { InfolinePopover as InfolinePopoverButton };
