import React, { ReactNode } from 'react';

import { ModuleOfType, ModuleType } from './ModuleType';

type ContentModuleProps = {
  content: ReactNode;
  renderCondition: boolean;
};

type ContentModulePageModule = ModuleOfType<
  ModuleType.ContentModule,
  ContentModuleProps
>;

const ContentModule = ({ content, renderCondition }: ContentModuleProps) =>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  renderCondition ? <>{content}</> : null;

export { ContentModule };
export type { ContentModulePageModule, ContentModuleProps };
