import { Theme } from '@silvertours/front-shared';
import { useMedia } from 'react-use';
import { BrandName } from '@silvertours/common-landingpages-view';
import { useBrand, useHost } from '../Runtime';

import { BMLogo } from './brands/BMLogo';
import { CGLogo } from './brands/CGLogo';
import { BrandLogoProps } from './BrandLogo.types';
import { LogoLink } from './BrandLogo.styles';

const RESPONSIVE_LOGOS: Record<
  BrandName,
  {
    height: { mobile: number; desktop: number };
    title: string;
  }
> = {
  'billiger-mietwagen': {
    title: 'billiger-mietwagen.de',
    height: {
      mobile: 30,
      desktop: 40,
    },
  },
  carigami: {
    height: {
      mobile: 22,
      desktop: 40,
    },
    title: 'CARIGAMI',
  },
};

const LOGO_COMPONENTS = {
  'billiger-mietwagen': {
    height: '54px',
    component: BMLogo,
  },
  carigami: {
    height: '54px',
    component: CGLogo,
  },
};

const BrandLogo = ({
  brand: brandName,
  width,
  height,
  withClaim = false,
  plain = false,
}: BrandLogoProps) => {
  const logo = LOGO_COMPONENTS[brandName];
  const LogoComponent = logo.component;

  return (
    <LogoComponent
      withClaim={withClaim}
      plain={plain}
      width={width}
      height={height ?? logo.height}
    />
  );
};

const ResponsiveLogo = () => {
  const { baseLocalizedUrl } = useHost();
  const { brandName } = useBrand();

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    true,
  );

  const { height, title } = RESPONSIVE_LOGOS[brandName];

  return (
    <LogoLink href={baseLocalizedUrl} title={title} aria-label="logo">
      <BrandLogo
        brand={brandName}
        height={`${height[isMobile ? 'mobile' : 'desktop']}px`}
        withClaim={!isMobile}
      />
    </LogoLink>
  );
};

export { BrandLogo, ResponsiveLogo };
