import React from 'react';
import { CookieConsent, useConsent } from '@silvertours/front-shared';

type CustomContentModuleProps = {
  slug: string;
};

const CustomContentModule = ({ slug }: CustomContentModuleProps) => {
  const consent = useConsent();
  if (consent === CookieConsent.Enabled) {
    return (
      <div>
        <iframe
          width="100%"
          src="https://c0001.m1ca.de/billiger-mietwagen-slotmachine"
          title={slug}
          scrolling="no"
          style={{
            width: '100%',
            height: '50rem',
            border: '0',
          }}
        >
          &nbsp;
        </iframe>
      </div>
    );
  }
  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
        height: '50rem',
        border: '0',
        backgroundColor: 'black',
      }}
    >
      &nbsp;
    </div>
  );
};

export { CustomContentModule };
