import { HasCollapseContentRef } from '../CollapseContentRef';
import { CollapseToggle } from './CollapseToggle';
import { CollapseToggleWrapper, StyledButton } from './CollapseButton.styles';
import { HEIGHT_LIMIT } from '../useCollapse';

export type CollapseButtonBehavior<TElement extends Element> =
  HasCollapseContentRef<TElement> & {
    onCollapseToggled: (isCollapsed: boolean) => void;
  };

export type CollapseButtonRules = {
  canBeCollapsed: boolean;
  isCollapsed: boolean;
};

type CollapseButtonLabels = {
  showLess: string;
  showMore: string;
};

export type CollapseButtonProps<TElement extends Element> = {
  behavior: CollapseButtonBehavior<TElement>;
  rules: CollapseButtonRules;
  labels: CollapseButtonLabels;
};

export const CollapseButton = <TElement extends Element>({
  behavior: { onCollapseToggled, ref },
  rules: { canBeCollapsed, isCollapsed },
  labels: { showLess: showLessLabel, showMore: showMoreLabel },
}: CollapseButtonProps<TElement>) => {
  if (!canBeCollapsed) {
    return null;
  }

  const handleClick = () => {
    onCollapseToggled(!isCollapsed);

    if (!isCollapsed && ref.current) {
      const topBeforeCollapse =
        document.documentElement.scrollTop || document.body.scrollTop;

      const collapsedHeight = Math.min(ref.current.clientHeight, HEIGHT_LIMIT);

      const newTop =
        topBeforeCollapse - (ref.current.clientHeight - collapsedHeight);

      window.scroll({
        top: newTop,
      });
    }
  };

  return (
    <StyledButton isCollapsed={isCollapsed} type="button" onClick={handleClick}>
      <CollapseToggleWrapper inline textColor="green">
        <CollapseToggle isCollapsed={isCollapsed} />
        {isCollapsed ? showMoreLabel : showLessLabel}
      </CollapseToggleWrapper>
    </StyledButton>
  );
};
