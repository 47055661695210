/**
 * SilverTours Legacy API v1
 * https://engineering.p.silvertours.net/rentalcars/core/mietwagen/
 */

import { api } from '@silvertours/front-shared';

class SubscriptionService extends api.LegacyHTTPService {
  private static classInstance?: SubscriptionService;

  public static getInstance() {
    if (!SubscriptionService.classInstance) {
      SubscriptionService.classInstance = new SubscriptionService();
    }
    return SubscriptionService.classInstance;
  }

  // Newsletter subscription via Salesforce Marketing Cloud
  public submitNewsletterSignup = (email: string, sourceParam: string) =>
    this.instance.post('/newsletter/subscribe', {
      email,
      sourceParam,
    });
}

const subscriptionService = SubscriptionService.getInstance;

export { SubscriptionService, subscriptionService as api };
