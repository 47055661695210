import { Context } from '@silvertours/front-legacy-shared';

const { useClientContext } = Context;
const hasContext: Context.AssertHasContext = Context.guardContext;

type SearchContext = {
  baseUrl: string;
};

const join = (baseUrl: string, path: string) => {
  const delimiter = path.indexOf('/') === 0 ? '' : '/';

  return `${baseUrl}${delimiter}${path}`;
};

const useSearch = () => {
  const result = useClientContext<SearchContext>('search');

  hasContext<SearchContext>('search', result);

  const { baseUrl } = result.value;

  return {
    formatApiUrl(path: string) {
      return join(baseUrl, path);
    },
  };
};

export { useSearch };
export type { SearchContext };
