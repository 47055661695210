import React from 'react';

import { LayoutLegacy } from '@silvertours/front-legacy-entities';

import { ColumnContent, ColumnContentModule } from './ColumnContent';
import { ModuleOfType, ModuleType } from '../ModuleType';

const RATIO: LayoutLegacy.TwoColumnsRatio = [
  LayoutLegacy.ColumnRatioOption.One,
  LayoutLegacy.ColumnRatioOption.Two,
];

type ColumnsModuleProps = {
  columns: [Array<ColumnContentModule>, Array<ColumnContentModule>];
  id: string;
};

type ColumnsPageModule = ModuleOfType<ModuleType.Columns, ColumnsModuleProps>;

const ColumnsModule = ({
  columns: [column1Content, column2Content],
  id,
}: ColumnsModuleProps) => (
  <LayoutLegacy.TwoColumns ratio={RATIO} id={id}>
    {(Column1, Column2) => [
      <Column1>
        <ColumnContent content={column1Content} />
      </Column1>,
      <Column2>
        <ColumnContent content={column2Content} />
      </Column2>,
    ]}
  </LayoutLegacy.TwoColumns>
);

export { ColumnsModule };
export type { ColumnsPageModule, ColumnsModuleProps };
