import { String, Subtitle, Button, Theme } from '@silvertours/front-shared';
import Image from 'next/image';
import { useMedia } from 'react-use';
import { useTranslations } from 'next-intl';
import {
  Anchor,
  Footer,
  InfoWrapper,
  InteractivePhoneNumber,
  OpeningTimesWrapper,
  VoucherText,
  VoucherWrapper,
} from './OpeningTimes.styles';
import { useSiteInfo } from '../../SiteInfo';
import email from './email.png';
import call from './call.png';
import { Schedule } from './Schedule';

type OpeningTimesProps = {
  showHeader?: boolean;
  showNumber?: boolean;
  showVoucherInfo?: boolean;
};

const OpeningTimes = ({
  showHeader = true,
  showNumber = true,
  showVoucherInfo = false,
}: OpeningTimesProps) => {
  const {
    settings: { phoneNumber, language },
  } = useSiteInfo();

  const formattedNumber = String.parsePhoneNumber(phoneNumber, language);

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    false,
  );
  const t = useTranslations('features.supportInfoLegacy');

  return (
    <OpeningTimesWrapper onTouchMove={e => e.stopPropagation()}>
      <InfoWrapper>
        {showHeader && (
          <Subtitle as="p">{t('openingTimes.tooltip.title')}</Subtitle>
        )}

        <Image src={email} alt="" width="185" height="150" />
        <Button
          href={t('newDesign.buttonHref')}
          variant="primary"
          fullWidth
          target="_blank"
        >
          {t('newDesign.buttonLabel')}
        </Button>

        {showNumber && (
          <>
            <Image src={call} alt="" width="185" height="150" />
            {!isMobile && (
              <InteractivePhoneNumber textColor="green">
                <Anchor href={formattedNumber.url}>
                  {formattedNumber.international}
                </Anchor>
              </InteractivePhoneNumber>
            )}

            {isMobile && (
              <Button href={formattedNumber.url} variant="secondary" fullWidth>
                {formattedNumber.international}
              </Button>
            )}

            <Schedule showVoucherInfo={showVoucherInfo} />
          </>
        )}
      </InfoWrapper>

      {showVoucherInfo && (
        <Footer>
          <VoucherWrapper>
            <Button
              href={t('newDesign.voucher.href')}
              rel="nofollow"
              variant="secondary"
              fullWidth
            >
              {t('newDesign.voucher.buttonLabel')}
            </Button>
            <VoucherText textColor="grey">
              {t('newDesign.voucher.text')}
            </VoucherText>
          </VoucherWrapper>
        </Footer>
      )}
    </OpeningTimesWrapper>
  );
};

export { OpeningTimes };
