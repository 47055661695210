import { ColumnContentModule, ColumnsPageModule, ModuleType } from '../module';

type ColumnBuilder = (
  key: string,
  column1Content: Array<ColumnContentModule>,
  column2Content: Array<ColumnContentModule>,
) => ColumnsPageModule;

const buildColumns: ColumnBuilder = (key, column1Content, column2Content) => ({
  columns: [column1Content, column2Content],
  id: key,
  key,
  type: ModuleType.Columns,
});

export { buildColumns };
export type { ColumnBuilder };
