import { MouseEvent } from 'react';
import { useTheme } from '@emotion/react';
import Checked from '@engineering/icons/checked';
import { Subtitle, Button, Ui } from '@silvertours/front-shared';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import AccountIllustration from './account_illustration.png';
import {
  BenefitsWrapper,
  BenefitText,
  BenefitWrapper,
  ImageWrapper,
  JoinButton,
  LinkWrapper,
  MembershipWrapper,
} from './MembershipPopover.styles';
import { gtm } from '../../analytics';

const BenefitItem = ({ benefit }: { benefit: string }) => {
  const theme = useTheme();
  return benefit && benefit !== '' && benefit !== 'null' ? (
    <BenefitWrapper>
      <Checked width="24px" fill={theme.color.brand} />
      <BenefitText key={benefit} inline>
        {benefit}
      </BenefitText>
    </BenefitWrapper>
  ) : null;
};

type MembershipPopoverProps = {
  handleLoginClick: (event: MouseEvent) => void;
  handleSignUpClick: () => void;
};

const MembershipPopover = ({
  handleLoginClick,
  handleSignUpClick,
}: MembershipPopoverProps) => {
  const onJoinClick = () => {
    gtm.trackNewUser({ isLoggedIn: false });
    handleSignUpClick();
  };
  const t = useTranslations('features.navigationLegacy.navigation');
  const benefits = useTranslations(
    'features.navigationLegacy.navigation.login.membership.benefits',
  );
  const benefitKeys = [
    'membership_benefit_1',
    'membership_benefit_2',
    'membership_benefit_3',
  ] as const;

  return (
    <MembershipWrapper>
      <Subtitle as="p">{t('login.membership.welcomeSubtitle')}</Subtitle>
      <ImageWrapper>
        <Image src={AccountIllustration} alt="" />
      </ImageWrapper>
      <Button onClick={handleLoginClick}>
        {t('login.membership.loginButton')}
      </Button>
      <JoinButton onClick={onJoinClick} variant="secondary">
        {t('login.membership.joinButton')}
      </JoinButton>
      <Subtitle>{t('login.membership.benefitsSubtitle')}</Subtitle>
      <BenefitsWrapper>
        {benefitKeys.map(key => (
          <BenefitItem benefit={benefits(key)} key={benefits(key)} />
        ))}
      </BenefitsWrapper>
      <LinkWrapper>
        <Ui.TextLink
          text={t('login.membership.moreInfoLink.text')}
          href={t('login.membership.moreInfoLink.href')}
        />
      </LinkWrapper>
    </MembershipWrapper>
  );
};

export { MembershipPopover };
