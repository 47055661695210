export enum RichTextStyle {
  Primary = 'primary',
  Alternative = 'alternative',
  Muted = 'muted',
}

export type TextStyleRules = {
  style?: RichTextStyle;
  hasSpacedParagraphs?: boolean;
};

export type HasTextStyleRules = {
  textStyle?: TextStyleRules;
};
