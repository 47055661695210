import { ComponentType } from 'react';

import { I18nLegacy } from '@silvertours/front-legacy-shared';

import dynamic from 'next/dynamic';
import { LandingPageProps } from './LandingPageProps';

const DELandingPage = dynamic(
  () => import('./de').then(module => module.LandingPage),
  { ssr: false },
);
const FRLandingPage = dynamic(
  () => import('./fr').then(module => module.LandingPage),
  { ssr: false },
);

const localizedComponents: Record<string, ComponentType<LandingPageProps>> = {
  de: DELandingPage,
  fr: FRLandingPage,
};

// For use in environments which we don't want server-side rendered (e.g. contentful live preview)
const useDynamicLocalizedLandingPage = () => {
  const { language } = I18nLegacy.useLocale();

  if (!Object.keys(localizedComponents).includes(language)) {
    return null;
  }

  return localizedComponents[language];
};

export { useDynamicLocalizedLandingPage };
