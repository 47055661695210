import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useState } from 'react';
import { API, getApi } from './axios';

type Method = 'get' | 'delete' | 'post' | 'put' | 'patch';

export const useClientSideRequest = <T = any>(
  apiHost: string,
  apiRoute: API,
  options: AxiosRequestConfig = { withCredentials: true },
) => {
  const [data, setData] = useState<AxiosResponse<T>['data'] | null>(null);
  const [error, setError] = useState(null);
  const api = getApi(apiHost, { ...options });

  return {
    request: async (method: Method, params?: { [key: string]: string }) => {
      try {
        const result = (await api.request({
          method,
          url: apiRoute,
          params,
        })) as AxiosResponse;
        setData(result?.data);
      } catch (e: any) {
        setError(e.message);
      }
    },
    data,
    error,
  };
};
