import styled from '@emotion/styled';
import ArrowUp from '@engineering/icons/arrow-up';
import ArrowDown from '@engineering/icons/arrow-down';

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  text-align: start;
  color: ${({ theme }) => theme.color.text};
  background: none;
  width: 100%;
  cursor: pointer;

  &:focus-visible {
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }
`;

export const Label = styled.span<{ $isInvalid: boolean }>`
  position: absolute;
  top: -0.5625rem;
  left: 0.75rem;
  padding: 0 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: ${({ theme, $isInvalid }) =>
    $isInvalid ? theme.color.error : theme.color.grey01};
  background-color: ${({ theme }) => theme.color.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
`;

export const Value = styled.time<{ $isInvalid: boolean }>`
  display: inline-block;
  padding: 0 1rem;
  border: 1px solid
    ${({ theme, $isInvalid }) =>
      $isInvalid ? theme.color.error : theme.color.grey03};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  width: 100%;
  font-size: 1rem;
  line-height: 3.375;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  box-sizing: border-box;

  &:hover {
    border-color: ${({ theme, $isInvalid }) =>
      $isInvalid ? theme.color.error : theme.color.grey02};
  }
`;

export const Spinner = styled.span`
  display: inline-flex;
  flex-direction: column;
  fill: currentcolor;
`;

export const ArrowDownIcon = styled(ArrowDown)`
  width: 1rem;
  height: 1rem;
`;

export const ArrowUpIcon = styled(ArrowUp)`
  width: 1rem;
  height: 1rem;
`;
