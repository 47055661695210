class SessionStorageManager {
  public static getData(key: string): any {
    try {
      const data = sessionStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
    } catch (error) {
      // TODO: error handling (if necessary?)
    }
    return undefined;
  }

  public static setData(key: string, data: any): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      // TODO: error handling (if necessary?)
    }
  }

  public static removeData(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      // TODO: error handling (if necessary?)
    }
  }
}

export { SessionStorageManager };
