import {
  HeadingLevel,
  Heading as HeadingView,
} from '@silvertours/common-landingpages-view';
import {
  Ui,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Subtitle,
  SecondaryText,
} from '@silvertours/front-shared';

import { HasTextStyleRules, HeadingStyle } from './rule';
import { HasColumns, HasOptionalVerticalSpacingRules } from '../rule';
import { Icon } from './Icon';
import { HeadingWrapper } from './Heading.styles';

const headingMap: Record<HeadingLevel, any> = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
};

export type ContentModuleHeadingRules = HasColumns &
  HasTextStyleRules &
  HasOptionalVerticalSpacingRules<'spacingDirection'> &
  HeadingStyle;

export type ContentModuleHeadingContent = HeadingView;

export type ContentModuleHeadingProps = {
  content?: ContentModuleHeadingContent;
  rules?: ContentModuleHeadingRules;
};

export const Heading = ({ content, rules }: ContentModuleHeadingProps) => {
  if (!content) {
    return null;
  }

  const {
    columns = [],
    isSubTitle = false,
    verticalSpacing,
    textStyle,
  } = rules || {};
  const { style = Ui.RichTextStyle.Primary } = textStyle || {};
  const isPrimaryColor = style === Ui.RichTextStyle.Primary;
  const { icon, level = 'h2', text, lead } = content;
  const element = isSubTitle ? 'div' : level;
  const HeadingComponent = isSubTitle ? Subtitle : headingMap[level];

  return (
    <HeadingWrapper rules={{ columns, verticalSpacing }} hasIcon={!!icon}>
      <HeadingComponent
        as={element}
        textColor={isPrimaryColor ? 'default' : 'white'}
      >
        <Icon
          content={icon}
          rules={{
            color: isPrimaryColor ? 'brand' : 'white',
            size: [[50], [60, 'sm']],
          }}
        />
        {text}
      </HeadingComponent>
      {lead && <SecondaryText>{lead}</SecondaryText>}
    </HeadingWrapper>
  );
};
