import {
  Anchor,
  GradientBackground,
  StyledImage,
  StyledImageWithFocalPoint,
  Subtitle,
} from '../LinkGrid.styles';
import { GridImageLinkProps } from '../../types';

export const GridImageLink = ({
  content: { href, image, label },
  rules: { color, layout, hidden },
}: GridImageLinkProps) => (
  <Anchor bgColor={color} layout={layout} href={href} hidden={!!hidden}>
    {image?.src && image.focalPoint && <StyledImageWithFocalPoint {...image} />}
    {image?.src && !image.focalPoint && (
      <StyledImage {...image} fill width={undefined} height={undefined} />
    )}
    <GradientBackground>
      <Subtitle as="span" textColor="white">
        {label}
      </Subtitle>
    </GradientBackground>
  </Anchor>
);
