import {
  HasProducts,
  RentalOffer,
} from '@silvertours/common-landingpages-view';

export const productData = ({ products }: HasProducts) => {
  if (!products || (!products.aggregateRating && !products.offersByCategory)) {
    return null;
  }

  const aggregateRating = {
    '@type': 'AggregateRating',
    worstRating: 1,
    bestRating: 5,
    ratingValue: products.aggregateRating?.ratingValue,
    reviewCount: products.aggregateRating?.reviewCount,
  };

  const result: RentalOffer[] = Object.values(products.offersByCategory || {})
    .map(category => category.offers)
    .flat();

  const offersStructure = {
    type: 'AggregateOffer',
    priceCurrency: 'EUR',
    offerCount: result.length,
    lowPrice: result.map(item => item.price).sort((a, b) => a - b)[0],
    highPrice: result.map(item => item.price).sort((a, b) => b - a)[0],
    offers: result.map(item => ({
      '@type': 'Offer',
      price: item.price,
      priceCurrency: 'EUR',
      name: item.carName,
      seller: {
        '@type': 'Brand',
        name: item.supplier,
      },
    })),
  };

  return {
    '@type': 'Product',
    brand: products.brand,
    name: products.name,
    ...(products.aggregateRating?.reviewCount && { aggregateRating }),
    ...(products.offersByCategory &&
      result.length > 0 && { offers: offersStructure }),
  };
};
