import styled from '@emotion/styled';

const Label = styled.label<{ htmlFor?: string }>`
  display: block;
  position: relative;
  margin: 0.5em 1rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.error};
  cursor: ${({ htmlFor }) => (htmlFor ? 'pointer' : '')};
`;

export { Label };
