import { DataInterop } from '@silvertours/front-shared';
import { Station } from '../Map/types';

type AutoRentalStructuredDataProps = {
  stations: Station[];
};

const AutoRentalData = ({ stations }: AutoRentalStructuredDataProps) => {
  if (!stations) return null;

  const filteredStations = Array.from(
    new Map(
      stations
        .filter(station => !!station.supplier)
        .map(station => [station.supplier, station]),
    ).values(),
  ) as Station[];

  return (
    <>
      {filteredStations.map(station => (
        <DataInterop.StructuredData
          key={station.id}
          type="AutoRental"
          subType={station.id}
          data={{
            name: station.supplier || 'unknown supplier',
            address: station.address,
          }}
          includeContext
        />
      ))}
    </>
  );
};

export { AutoRentalData };
