import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const scalable5 = {
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 60">
        <path d="M0 60V30.329c197.5 9.733 222.5-38.12 375-29.22V60H0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M0 100V16.126C498 100 908-20.617 1440 3.17V100H0Z" />
      </WaveSvg>
    ),
  },
} as WaveSet;

export { scalable5 };
