enum ModuleType {
  Columns = 'columns',
  Container = 'container',
  ContentModule = 'content-module',
}

type ModuleOfType<
  TPartType extends ModuleType,
  TPart extends Record<string, unknown>,
> = {
  key: string;
  type: TPartType;
} & TPart;

export { ModuleType };
export type { ModuleOfType };
