import { ContentModule } from '@silvertours/common-landingpages-view';

import { Features } from '@silvertours/common-landingpages-i18n';
import { ContentModuleSegment } from './ContentArticle.styles';
import { renderContentModule } from './contentModule';

type ContentArticleProps = { translations: Features['article'] } & Omit<
  ContentModule,
  'moduleType'
>;

export const ContentArticle = ({
  modules,
  translations,
}: ContentArticleProps) => {
  if (!modules || modules.length === 0) {
    return null;
  }

  return (
    <>
      {modules.map((module, index) => {
        const key = `${module.moduleType}-${index}`;
        const id = `content-module-${index}`;

        return (
          <ContentModuleSegment
            id={id}
            key={key}
            bottomMargin={module.bottomMargin || 'default'}
          >
            {renderContentModule(module, translations)}
          </ContentModuleSegment>
        );
      })}
    </>
  );
};
