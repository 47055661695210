import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const scalable4 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 57">
        <path d="M0 0h375v52.916c-48 7.144-101.5 4.166-158.5-3.158s-101-9.553-213.285 0A68.175 68.175 0 0 1 0 50.782V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M1440 0H0v53.192C178.5 25.9 283.5 27.773 573 59.507s416.5 70.413 867 4.328V0Z" />
      </WaveSvg>
    ),
  },
} as WaveSet;

export { scalable4 };
