import { TextProps } from '../textUtils';
import { BodyTextWrapper, InlineBodyTextWrapper } from './BodyText.styles';

const BodyText: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className,
  inline = false,
  textColor,
}) => {
  const Wrapper = inline ? InlineBodyTextWrapper : BodyTextWrapper;
  return (
    <Wrapper className={className} textColor={textColor}>
      {children}
    </Wrapper>
  );
};

export { BodyText };
