import styled from '@emotion/styled';
import { css } from '@emotion/react';

/**
 * TODO: Make sure tooltip fulfills a11y criteria by using `visibility` instead of `display`
 * (doesn't work with Intersection observer, though)
 */

type TooltipWrapperProps = {
  hover?: boolean;
};

const TooltipWrapper = styled.div<TooltipWrapperProps>`
  position: relative;

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        > [role='tooltip'] {
          display: block;
        }
      }
    `}
`;

type TooltipContentProps = {
  visible?: boolean;
  $width?: 'default' | 'wide';
  $offset?: number;
};

const TooltipContent = styled.div<TooltipContentProps>`
  min-width: ${({ $width }) => ($width === 'wide' ? '31.25rem' : '20rem')};
  max-width: 31.25rem;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.color.info};
  border-radius: 0.75rem;
  position: absolute;
  font-weight: normal;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fontFamily.default};
  z-index: 100;
  transform: ${({ $offset }) => `translate(calc(-50% + ${$offset}px), 15px)`};
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  a {
    color: ${({ theme }) => theme.color.brand};
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: -20px;
    height: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: ${({ theme }) => theme.color.white};
    margin-bottom: -9px;
    margin-left: -10px;
    bottom: 100%;
    left: 50%;
    transform: rotate(-135deg);
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.info};
    border-image: none;
    border-top: 0;
    border-left: 0;
  }
`;

export { TooltipWrapper, TooltipContent };
