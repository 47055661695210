import styled from '@emotion/styled';
import { Button } from '../Button';
import { ErrorMessage as RawErrorMessage } from '../../Ui';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ConfirmButton = styled(Button)`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.color.grey03};
  border-radius: 0;
  width: 100%;

  &:focus-visible {
    outline-offset: -0.125rem;
  }
`;

export const ErrorMessage = styled(RawErrorMessage)`
  margin: 0.5em 1rem 0;
`;
