import styled from '@emotion/styled';

export const MaskedLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
  display: inline;
  font-size: inherit;
  font-weight: inherit;
`;
