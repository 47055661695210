import { setMinutes } from 'date-fns/setMinutes';
import { setHours } from 'date-fns/setHours';
import { format } from 'date-fns/format';

export const getTime = (date: Date) => format(date, 'HH:mm');

export const setTime = (date: Date, time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return setMinutes(setHours(date, hours), minutes);
};

export const getDays = (from: Date, to: Date) => {
  // Inspired by https://youmightnotneed.com/date-fns#differenceInDays,
  // because differenceInHours() from date-fns always rounds which is not what we want
  const HOUR_IN_MS = 1000 * 60 * 60;
  const days = ((to as any) - (from as any)) / HOUR_IN_MS / 24;
  return Math.max(1, Math.ceil(days));
};
