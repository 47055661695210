import {
  ReviewCard,
  TextContentBlock,
  useContentModule,
  analytics,
  Carousel,
} from '@silvertours/front-entities';
import { Button, Theme, Ui } from '@silvertours/front-shared';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { CustomerReviewsContentModule } from '@silvertours/common-landingpages-view';
import {
  PageAttributeType,
  SupplierReview,
} from '@silvertours/back-domain-page';
import { ReviewCardProps } from 'frontend/entities/src/Review/ReviewCard';
import {
  ReviewCarousel,
  ReviewContainer,
  ReviewList,
  ReviewText,
  StyledAdditionalInfo,
  StyledPlainContentModuleContainer,
} from './SupplierReviews.styles';
import { useSubmitInitialSearch } from '../../../SearchFormLegacy';

const Background = ({ children }: React.PropsWithChildren<{}>) => (
  <Ui.Wave type="scalable6" fullWidth backgroundStyle="brand">
    {children}
  </Ui.Wave>
);

const ReviewListCarousel = ({
  supplierReviews,
  renderReview,
}: {
  supplierReviews: SupplierReview[];
  renderReview: (review: SupplierReview) => ReactNode;
}) => {
  const trackCarouselSlide = useCallback((offset: number) => {
    analytics.gtm.trackReviewsCarouselSlide(offset);
  }, []);
  const trackCarouselClick = useCallback(
    (direction: 'forward' | 'backward') => {
      analytics.gtm.trackReviewsCarouselClicked(direction);
    },
    [],
  );

  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    true,
  );

  const maxVisibleReviews = isMobile ? 1 : 2;

  const CarouselContainer = ReviewCarousel as any;
  return (
    <ReviewContainer fullWidth>
      <CarouselContainer
        as={Carousel}
        showSlideBackground={false}
        shouldLoop
        snap
        hasItemsToReveal={supplierReviews.length > maxVisibleReviews}
        prevText="prev"
        nextText="next"
        onSlideChange={trackCarouselClick}
        onSlideDrag={trackCarouselSlide}
        onMouseWheel={trackCarouselSlide}
      >
        {supplierReviews.map(renderReview)}
      </CarouselContainer>
    </ReviewContainer>
  );
};

const ReviewListCollapsible = ({
  supplierReviews,
  renderReview,
  translations,
}: {
  supplierReviews: SupplierReview[];
  renderReview: (
    review: SupplierReview,
    props: Pick<ReviewCardProps, 'hidden'>,
  ) => ReactNode;
  translations: { showMore: string; showLess: string };
}) => {
  const isMd = useMedia(`(min-width: ${Theme.getBreakpoint('md')}px)`, true);
  const isXl = useMedia(`(min-width: ${Theme.getBreakpoint('xl')}px)`, true);
  const maxVisibleReviews = isMd && !isXl ? 2 : 3;

  const [allVisible, setAllVisible] = useState(false);

  const adjustScrollPosition = useRef(false);

  const buttonRef = useRef<HTMLButtonElement | undefined>(undefined);

  useEffect(() => {
    if (buttonRef.current && adjustScrollPosition.current) {
      buttonRef.current.scrollIntoView({
        block: 'center',
      });
      adjustScrollPosition.current = false;
    }
  }, [allVisible]);

  const toggleAllVisible = () => {
    analytics.gtm.trackShowMoreProvidersReviews();
    if (allVisible) {
      adjustScrollPosition.current = true;
    }
    setAllVisible(!allVisible);
  };

  if (supplierReviews.length === 0) {
    return null;
  }

  const showButton = supplierReviews.length > maxVisibleReviews;

  let visibleReviews = supplierReviews.length;
  if (!allVisible) {
    visibleReviews = maxVisibleReviews;
  }
  return (
    <ReviewContainer>
      <ReviewList amount={visibleReviews}>
        {supplierReviews.map((review, index) =>
          renderReview(review, { hidden: index >= visibleReviews }),
        )}
      </ReviewList>
      {showButton && (
        <Button ref={buttonRef} variant="secondary" onClick={toggleAllVisible}>
          {allVisible ? translations?.showLess : translations?.showMore}
        </Button>
      )}
    </ReviewContainer>
  );
};

export const SupplierReviews = ({
  content,
  badge,
  heading,
  translations,
}: CustomerReviewsContentModule) => {
  const isMd = useMedia(`(min-width: ${Theme.getBreakpoint('md')}px)`, true);

  const { getSupplierReviews } = useContentModule();
  const { supplierReviews } = getSupplierReviews();

  const { makeSearchHandler } = useSubmitInitialSearch();

  if (supplierReviews.length === 0) {
    return null;
  }

  const renderReview = (
    review: SupplierReview,
    props: Pick<ReviewCardProps, 'hidden'> = {},
  ) => {
    const handleSearch = makeSearchHandler({
      cityCode: review.offerSearchParams.cityCode,
      cityName: '',
      query: review.offerSearchParams.cityCode,
      displayName: '',
      type: PageAttributeType.City,
    });
    let onSearchButtonClick: (() => void) | undefined;
    if (handleSearch && review.searchParams.cityId) {
      onSearchButtonClick = () => {
        analytics.gtm.trackReviewSearchClicked(review.name);
        handleSearch(
          new URLSearchParams({
            supplier: review.name,
          }).toString(),
        );
      };
    }
    return (
      <ReviewCard
        key={review.name}
        name={review.name}
        customerRatingCount={review.reviewCount}
        averageRating={review.totalRating}
        searchParams={review.searchParams}
        ratings={{
          carConditionRating: review.carConditionRating,
          processingSpeedRating: review.processingSpeedRating,
          serviceLevelRating: review.serviceLevelRating,
        }}
        translations={translations!.card}
        onSearchButtonClick={onSearchButtonClick}
        {...props}
      />
    );
  };

  return (
    <>
      <Background>
        <StyledPlainContentModuleContainer>
          <ReviewText>
            <TextContentBlock
              content={{ content, heading, badge }}
              rules={{
                badge: { style: 'light' },
                isContainerMultiColumn: false,
                textStyle: { style: Ui.RichTextStyle.Alternative },
              }}
            />
            {translations?.info && (
              <StyledAdditionalInfo>{translations.info}</StyledAdditionalInfo>
            )}
          </ReviewText>
        </StyledPlainContentModuleContainer>
      </Background>
      {isMd ? (
        <ReviewListCollapsible
          supplierReviews={supplierReviews}
          renderReview={renderReview}
          translations={translations!}
        />
      ) : (
        <ReviewListCarousel
          supplierReviews={supplierReviews}
          renderReview={renderReview}
        />
      )}
    </>
  );
};
