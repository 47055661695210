import { RentalLocation } from '@silvertours/front-entities';
import { String } from '@silvertours/front-shared';
import { addDays } from 'date-fns/addDays';
import { format } from 'date-fns/format';

export const isDeptDateInLessThanDays = (depDate: string, days: number) =>
  new Date(depDate) <= addDays(new Date(), days);

export const isDestDateBeforeDepDate = (destDate: string, depDate: string) =>
  new Date(destDate) <= new Date(depDate);

export const isSearchInCuba = (
  dep: RentalLocation | null,
  dest: RentalLocation | null,
) => dep?.countryCode === 'CU' || dest?.countryCode === 'CU';

export const formatDate = (date: Date) =>
  format(date, String.isoDateTimeWithSeconds);
