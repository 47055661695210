import { css } from '@emotion/react';
import { mq } from '../Theme';

const fonts = css`
  @font-face {
    src:
      local(''),
      url('/imgs/fonts/quicksand-bold.woff2') format('woff2'),
      url('/imgs/fonts/quicksand-bold.woff') format('woff');
    font-family: Quicksand;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    src:
      local(''),
      url('/imgs/fonts/sourcesanspro-regular.woff2') format('woff2'),
      url('/imgs/fonts/sourcesanspro-regular.woff') format('woff');
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    src:
      local(''),
      url('/imgs/fonts/sourcesanspro-semibold.woff2') format('woff2'),
      url('/imgs/fonts/sourcesanspro-semibold.woff') format('woff');
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
`;

const layoutContentOnlyPadding = css`
  padding-left: 1rem;
  padding-right: 1rem;

  ${mq('sm')} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  ${mq('xl')} {
    padding-left: clamp(2rem, calc((100vw - 1220px) / 2), 22rem);
    padding-right: clamp(2rem, calc((100vw - 1220px) / 2), 22rem);
  }

  ${mq('xxl')} {
    padding-left: clamp(2rem, 350px, 22rem);
    padding-right: clamp(2rem, 350px, 22rem);
  }
`;

const layoutContentOnlyMargin = css`
  margin-left: 1rem;
  margin-right: 1rem;

  ${mq('sm')} {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  ${mq('xl')} {
    margin-left: clamp(2rem, calc((100vw - 1220px) / 2), 22rem);
    margin-right: clamp(2rem, calc((100vw - 1220px) / 2), 22rem);
  }

  ${mq('xxl')} {
    margin-left: clamp(2rem, 350px, 22rem);
    margin-right: clamp(2rem, 350px, 22rem);
  }
`;

const layoutContainerContent = css`
  ${layoutContentOnlyPadding};

  margin-left: -1rem;
  margin-right: -1rem;

  ${mq('sm')} {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  ${mq('xl')} {
    margin-left: calc(max(2rem, min(calc((100vw - 1220px) / 2), 22rem)) * -1);
    margin-right: calc(max(2rem, min(calc((100vw - 1220px) / 2), 22rem)) * -1);
  }

  ${mq('xxl')} {
    margin-left: calc(max(2rem, min(350px, 22rem)) * -1);
    margin-right: calc(max(2rem, min(350px, 22rem)) * -1);
  }
`;

export {
  fonts,
  layoutContainerContent,
  layoutContentOnlyPadding,
  layoutContentOnlyMargin,
};
