import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import {
  ContentfulRichText,
  Faqs,
  RichText,
} from '@silvertours/common-landingpages-view';
import { DataInterop } from '@silvertours/front-shared';

type FaqsStructuredDataProps = {
  faqs: Faqs;
  name?: string;
};

const extractContent = (answer: RichText) => {
  const contentfulAnswer = answer as ContentfulRichText;

  return contentfulAnswer.text;
};

export const FaqData = ({ faqs, name = '' }: FaqsStructuredDataProps) => (
  <DataInterop.StructuredData
    data={{
      name,
      mainEntity: faqs.map(({ answer, question }) => ({
        '@type': 'Question',
        name: question,
        acceptedAnswer: [
          {
            '@type': 'Answer',
            text: documentToPlainTextString(extractContent(answer)),
          },
        ],
      })),
    }}
    type="FAQPage"
    includeContext
  />
);
