import { useRef } from 'react';
import type { ReactNode } from 'react';

import {
  ContentChunkSetModule,
  StandardContentModule,
  TabsContentModule,
  SubModule,
} from '@silvertours/common-landingpages-view';

import { Badge, HasBadgeRules, Heading } from '../../element';
import { CollapsableContainer } from './CollapsableContainer';
import { CollapseButton } from './CollapseButton';
import { useCollapse } from './useCollapse';
import { SectionRows } from './SectionRows';

export type CollapsableContentBlockRules = HasBadgeRules & {
  allowCollapse: boolean;
};

export type CollapsableContentBlockContent = Pick<
  StandardContentModule,
  'badge' | 'heading' | 'sections'
>;

type CollapsableContentBlockTranslations = {
  collapseButton: {
    showLessLabel: string;
    showMoreLabel: string;
  };
};

export type CollapsableContentBlockProps = {
  children: (
    section: SubModule<TabsContentModule | ContentChunkSetModule>,
  ) => ReactNode;
  content: CollapsableContentBlockContent;
  rules: CollapsableContentBlockRules;
  translations?: CollapsableContentBlockTranslations;
};

export const CollapsableContentBlock = ({
  children,
  content: { badge, heading, sections },
  rules: { allowCollapse },
  translations,
}: CollapsableContentBlockProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { canBeCollapsed, isCollapsed, setIsCollapsed } = useCollapse(
    allowCollapse,
    ref,
  );

  const handleCollapseToggled = (isNowCollapsed: boolean) => {
    setIsCollapsed(isNowCollapsed);
  };

  const {
    collapseButton: { showLessLabel = '', showMoreLabel = '' },
  } = translations || { collapseButton: {} };

  return (
    <>
      <CollapsableContainer
        behavior={{ ref }}
        rules={{ canBeCollapsed, isCollapsed }}
      >
        <Badge content={badge} rules={{ badge: { style: 'orange' } }} />
        <Heading content={heading} />
        <SectionRows sections={sections}>{children}</SectionRows>
      </CollapsableContainer>
      <CollapseButton
        behavior={{ ref, onCollapseToggled: handleCollapseToggled }}
        rules={{ canBeCollapsed, isCollapsed }}
        labels={{ showLess: showLessLabel, showMore: showMoreLabel }}
      />
    </>
  );
};
