import { GridSetProps } from './GridSet';
import { GridImageLink } from './GridImageLink';

export const GridSetOf3 = ({ content: { set } }: GridSetProps) => {
  if (set.length === 0) {
    return null;
  }

  return (
    <>
      <GridImageLink
        content={set[0]}
        rules={{
          color: set[0].color,
          layout: [12, { xl: 4 }],
          hidden: set[0].hidden,
        }}
      />
      <GridImageLink
        content={set[1]}
        rules={{
          color: set[1].color,
          layout: [6, { xl: 4 }],
          hidden: set[1].hidden,
        }}
      />
      <GridImageLink
        content={set[2]}
        rules={{
          color: set[2].color,
          layout: [6, { xl: 4 }],
          hidden: set[2].hidden,
        }}
      />
    </>
  );
};
