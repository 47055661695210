import styled from '@emotion/styled';
import type { HasColumns } from '../rule';
import { formatColumns } from '../particle';

const RichTextWrapper = styled.div<{ rules: HasColumns }>`
  ${({ theme, rules }) =>
    rules.columns?.map(columns => formatColumns(columns, theme))};
`;

export { RichTextWrapper };
