import styled from '@emotion/styled';
import { shade } from 'polished';
import { Ui } from '@silvertours/front-shared';

const Container = styled.div`
  ${({ theme }) => theme.mq('lg')} {
    display: flex;
    gap: 1rem;
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Image = styled(Ui.Image)`
  display: none;

  ${({ theme }) => theme.mq('lg')} {
    display: block;
    position: relative;
    max-width: 300px;
    height: 300px;
    flex: 0 0 50%;
  }
`;

const Headline = styled.div`
  margin: 0 0 1rem;
  font-weight: bold;
  font-size: 1rem;
`;

const LegalText = styled.div`
  margin: 1rem 0;
  color: ${({ theme }) => theme.color.text};
  font-size: 0.75rem;

  a {
    color: ${({ theme }) => theme.color.brand};
    text-decoration: underline;
    text-underline-offset: 0.125rem;
    transition: text-underline-offset 0.2s linear;

    &:hover,
    &:active {
      color: ${({ theme }) => shade(0.271, theme.color.brand!)};
    }

    &:focus-visible {
      border-radius: 0.5rem;
      outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
      outline-offset: 0.25rem;
    }

    &:active {
      text-underline-offset: 0.25rem;
    }
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export { Container, SuccessContainer, Form, Image, Headline, LegalText };
