import { GridSetProps } from './GridSet';
import { GridImageLink } from './GridImageLink';
import { GridLayout } from '../../types';

const LAYOUT: GridLayout = [12, { md: 6, xl: 3 }];

export const GridSetOf4 = ({ content: { set } }: GridSetProps) => {
  if (set.length === 0) {
    return null;
  }

  return (
    <>
      <GridImageLink
        content={set[0]}
        rules={{ color: set[0].color, layout: LAYOUT, hidden: set[0].hidden }}
      />
      <GridImageLink
        content={set[1]}
        rules={{ color: set[1].color, layout: LAYOUT, hidden: set[1].hidden }}
      />
      <GridImageLink
        content={set[2]}
        rules={{ color: set[2].color, layout: LAYOUT, hidden: set[2].hidden }}
      />
      <GridImageLink
        content={set[3]}
        rules={{ color: set[3].color, layout: LAYOUT, hidden: set[3].hidden }}
      />
    </>
  );
};
