import type {
  CLSMetricWithAttribution,
  FIDMetricWithAttribution,
  INPMetricWithAttribution,
  LCPMetricWithAttribution,
} from 'web-vitals';
import {
  CriteoPageType,
  CriteoSiteType,
  EventType,
  EventCategory,
  EventAction,
  EventLabel,
} from './types';
import type {
  CriteoEventProps,
  EventProps,
  LoginProps,
  TrackingProps,
} from './types';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Push data to the data layer
 * https://developers.google.com/tag-platform/tag-manager/web/datalayer
 */

const trackEvent = async (
  data: TrackingProps | CriteoEventProps | EventProps,
) => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * PageView events
 */
const trackPageView = (data: TrackingProps | null) => {
  if (!data) {
    return;
  }
  trackEvent({
    event: EventType.PAGE_VIEW,
    ...data,
  });
};

/**
 * Custom events
 * https://silvertours.atlassian.net/wiki/spaces/PAGES/pages/2369650717/Tracking+events+on+the+landing+pages#Current-events
 */

// Account menu opened
const trackShowLogin = ({ isLoggedIn = false }: LoginProps) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.INDEX,
    eventAction: EventAction.LOGIN_MENU,
    eventLabel: isLoggedIn ? EventLabel.LOGGED : EventLabel.UNLOGGED,
  });
};

// Landing page loaded | Criteo event
const trackCriteo = () => {
  trackEvent({
    event: EventType.CUSTOM_CRITEO,
    criteo_tag_params: {
      CriteoPageType: CriteoPageType.Homepage,
      CriteoSiteType: CriteoSiteType.desktop,
    },
  });
};

// Login form submitted
const trackLogin = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ACCOUNT_LOGIN,
    eventAction: EventAction.LOGIN_MENU_LOGIN,
    eventLabel: EventLabel.LOGGED,
  });
};

// "Password forgotten" link clicked
const trackPasswordForgotten = ({ isLoggedIn = false }: LoginProps) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ACCOUNT_LOGIN,
    eventAction: EventAction.LOGIN_MENU_FORGOTPWD,
    eventLabel: isLoggedIn ? EventLabel.LOGGED : EventLabel.UNLOGGED,
  });
};

// "Register new user" link clicked
const trackNewUser = ({ isLoggedIn = false }: LoginProps) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ACCOUNT_LOGIN,
    eventAction: EventAction.LOGIN_MENU_CREATE_ACCOUNT,
    eventLabel: isLoggedIn ? EventLabel.LOGGED : EventLabel.UNLOGGED,
  });
};

// Logout
const trackLogout = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ACCOUNT_LOGIN,
    eventAction: EventAction.LOGIN_LOGOUT,
    eventLabel: EventLabel.UNLOGGED,
  });
};

// "My bookings" link clicked
const trackMyBookings = ({ isLoggedIn = false }: LoginProps) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ACCOUNT_LOGIN,
    eventAction: EventAction.LOGIN_MENU_MYBOOKINGS,
    eventLabel: isLoggedIn ? EventLabel.LOGGED : EventLabel.UNLOGGED,
  });
};

// "My data“" link clicked
const trackMyData = ({ isLoggedIn = false }: LoginProps) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ACCOUNT_LOGIN,
    eventAction: EventAction.LOGIN_MENU_MYDATA,
    eventLabel: isLoggedIn ? EventLabel.LOGGED : EventLabel.UNLOGGED,
  });
};

// "My payment data" link clicked
const trackMyPayment = ({ isLoggedIn = false }: LoginProps) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ACCOUNT_LOGIN,
    eventAction: EventAction.LOGIN_MENU_MYPAYMENT,
    eventLabel: isLoggedIn ? EventLabel.LOGGED : EventLabel.UNLOGGED,
  });
};

// "Get voucher" button clicked
const trackVoucherButtonClick = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ALL,
    eventAction: EventAction.CLICK_PHONE_TOOLTIP_VOUCHER_LINK,
    eventLabel: true,
  });
};

// Opening times tooltip opened (via hover)
const trackOpeningTimesTooltipOpened = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.TOOLTIP,
    eventAction: EventAction.HOVER,
    eventLabel: 'callcenter',
  });
};

// Newsletter form submitted on index page
const trackNewsletterRegistrationIndex = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.INDEX,
    eventAction: EventAction.CLICK,
    eventLabel: 'click_NewsletterSigninBoxEbook',
  });
};

// Newsletter form submitted anywhere else
const trackNewsletterRegistration = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.NEWSLETTER_REGISTRATION,
    eventAction: EventAction.CLICK,
    eventLabel: 'submit',
  });
};

// "More options" in search form clicked
const trackToggleMoreOptions = (showOptions: boolean) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.INDEX,
    eventAction: EventAction.SEARCHFORM_MORE_OPTIONS,
    eventLabel: showOptions ? EventLabel.OPEN : EventLabel.CLOSED,
  });
};

// Filter in search form clicked
const trackOptionsChange = (id: string) => {
  if (!id) {
    return;
  }
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.INDEX,
    eventAction: EventAction.SEARCHFORM_MORE_OPTIONS_FILTER_CLICK,
    eventLabel: id,
  });
};

// Selecting an item from the search history
const trackSearchHistorySelect = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH_HISTORY,
    eventAction: EventAction.CLICK,
    eventLabel: 'history_item',
  });
};

// Deleting an item from the search history
const trackSearchHistoryDelete = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH_HISTORY,
    eventAction: EventAction.CLICK,
    eventLabel: 'delete_history',
  });
};

// Opening times tooltip footer opened (via hover)
const trackFooterTooltipOpened = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.TOOLTIP,
    eventAction: EventAction.HOVER,
    eventLabel: 'callcenter_footer',
  });
};

// Main navigation list item clicked
const trackNavigationClicked = (name: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.NAVIGATION,
    eventAction: EventAction.CLICK,
    eventLabel: name, // name of the item clicked
  });
};

// Hover on free cancellation badge
const trackCancellationHover = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.TOOLTIP,
    eventAction: EventAction.HOVER,
    eventLabel: 'cancellation_stage',
  });
};

const trackSavingHover = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.TOOLTIP,
    eventAction: EventAction.HOVER,
    eventLabel: 'saving_stage',
  });
};

// Info Banner link clicked
const trackInfoBannerLinkClicked = (label: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.INFO_BANNER,
    eventAction: EventAction.CLICK,
    eventLabel: `info_banner_${label}`,
  });
};

const trackInfoBannerCloseClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.INFO_BANNER,
    eventAction: EventAction.CLICK,
    eventLabel: 'info_banner_close',
  });
};

// Hover on driver age
const trackDriverAgeHover = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.TOOLTIP,
    eventAction: EventAction.HOVER,
    eventLabel: 'driver_age',
  });
};

// Change roundtrip to oneway dropdown
const trackRoundtripOneway = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.ROUNDTRIP,
    eventAction: EventAction.ONEWAY_SELECT,
    eventLabel: '',
  });
};

// Change driver age dropdown from default age
const trackDriverAgeSelect = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.DRIVERAGE,
    eventAction: EventAction.SELECT,
    eventLabel: '',
  });
};

// Confienza clicked (just Floyt)
const trackConfienzaClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.CONFIENZA,
    eventAction: EventAction.CLICK,
    eventLabel: 'confienza_stage',
  });
};

// FAQ list item clicked (open expandable)
const trackFaqItemClicked = (pagepath: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.FAQ,
    eventAction: EventAction.CLICK,
    eventLabel: pagepath, // pagepath or faq-subject
  });
};

// Button to Helpcenter is clicked
const trackHelpcenterButtonClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.FAQ,
    eventAction: EventAction.CLICK,
    eventLabel: 'zendesk_button',
  });
};

// Button search in offer boxes clicked
const trackSearchButtonOfferBoxClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH_BUTTON_OFFERBOX,
    eventAction: EventAction.CLICK,
    eventLabel: '',
  });
};

// iOS App link clicked
const trackIosAppLinkClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.APP_ICON,
    eventAction: EventAction.CLICK,
    eventLabel: 'landingpage_promo_banner_appstore_tap',
  });
};

// iOS App link footer clicked
const trackIosAppLinkFooterClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.APP_ICON,
    eventAction: EventAction.CLICK,
    eventLabel: 'iOS_download_footer',
  });
};

// Android App link clicked
const trackAndroidAppLinkClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.APP_ICON,
    eventAction: EventAction.CLICK,
    eventLabel: 'landingpage_promo_banner_playstore_tap',
  });
};

// Android App footer link clicked
const trackAndroidAppLinkFooterClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.APP_ICON,
    eventAction: EventAction.CLICK,
    eventLabel: 'ANDROID_download_footer',
  });
};

// Facebook footer link clicked
const trackFacebookFooterClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SOCIAL_MEDIA_ICON,
    eventAction: EventAction.CLICK,
    eventLabel: 'FB_footer',
  });
};

// Instagram footer link clicked
const trackInstagramFooterClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SOCIAL_MEDIA_ICON,
    eventAction: EventAction.CLICK,
    eventLabel: 'insta_footer',
  });
};

// Pinterest footer link clicked
const trackPinterestFooterClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SOCIAL_MEDIA_ICON,
    eventAction: EventAction.CLICK,
    eventLabel: 'PIN_footer',
  });
};

// All other footer links clicked
const trackFooterClicked = (name: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.GENERAL_FOOTER_LINKS,
    eventAction: EventAction.CLICK,
    eventLabel: name, // name of link_clicked
  });
};

const trackStationMapStationClick = (stationKind: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.STATIONS_MAP,
    eventAction: EventAction.CLICK,
    eventLabel: `station_type_${stationKind}`,
  });
};

const trackPriceGraphClicked = (month: string | null) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.PRICE_GRAPH,
    eventAction: EventAction.CLICK,
    eventLabel: `select_month_${month}`,
  });
};

const trackPriceGraphSearch = (date: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.PRICE_GRAPH,
    eventAction: EventAction.CLICK,
    eventLabel: `search_${date}`,
  });
};

const trackPriceGraphCityClick = (city: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.PRICE_GRAPH,
    eventAction: EventAction.CLICK,
    eventLabel: `select_city_${city}`,
  });
};

const trackOffersSliderOffferClick = (offer: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.OFFERS_SLIDER,
    eventAction: EventAction.CLICK,
    eventLabel: offer,
  });
};

const trackOffersSliderButtonClick = (direction: 'forward' | 'backward') => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.OFFERS_SLIDER,
    eventAction: EventAction.SLIDE_OFFER_BOXES_LP,
    eventLabel: direction,
  });
};

const trackOffersSliderDragged = (offset: number) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.OFFERS_SLIDER,
    eventAction: EventAction.DRAG_OFFER_BOXES_LP,
    eventLabel: `${offset}`,
  });
};

const trackOffersSliderMouseWheel = (offset: number) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.OFFERS_SLIDER,
    eventAction: EventAction.MOUSEWHEEL_OFFER_BOXES_LP,
    eventLabel: `${offset}`,
  });
};

const trackLanguageChangeAttempted = (country: string, language: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.MARKET_PICKER,
    eventAction: EventAction.LANGUAGE_CHANGE,
    eventLabel: `${country}_${language}`,
  });
};

const trackReviewSearchClicked = (provider: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.CUSTOMER_REVIEW,
    eventAction: EventAction.CLICK,
    eventLabel: `review_search_${provider}`,
  });
};

const trackShowCustomerReviews = (provider: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.CUSTOMER_REVIEW,
    eventAction: EventAction.CLICK,
    eventLabel: `show_overlay_${provider}`,
  });
};

const trackReviewShowDetailsClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.CUSTOMER_REVIEW,
    eventAction: EventAction.CLICK,
    eventLabel: 'review_show_details',
  });
};

const trackShowMoreProvidersReviews = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.CUSTOMER_REVIEW,
    eventAction: EventAction.CLICK,
    eventLabel: 'show_more_providers_reviews',
  });
};

const trackReviewsCarouselSlide = (offset: number) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.CUSTOMER_REVIEW,
    eventAction: EventAction.REVIEWS_CAROUSEL_SLIDE,
    eventLabel: `reviews_carousel_${offset}`,
  });
};

const trackReviewsCarouselClicked = (direction: 'forward' | 'backward') => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.CUSTOMER_REVIEW,
    eventAction: EventAction.CLICK,
    eventLabel: `reviews_carousel_${direction}`,
  });
};

const trackBeginSearchClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'begin_search',
  });
};

const trackSelectStation = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.SELECT,
    eventLabel: 'select_station_v2',
  });
};

const trackConfirmDestination = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'confirm_destination_v2',
  });
};

const trackSelectDates = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.SELECT,
    eventLabel: 'select_dates_v2',
  });
};

const trackConfirmDates = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'confirm_dates_v2',
  });
};

const trackEditDepartureClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'edit_departure_v2',
  });
};

const trackEditDestinationClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'edit_destination_v2',
  });
};

const trackEditDatesClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'edit_dates_v2',
  });
};

const trackEditTimeClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'edit_time_v2',
  });
};

const trackEditAgeClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'edit_age_v2',
  });
};

const trackConfirmSearchClicked = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'confirm_search_v2',
  });
};

const trackSelectTime = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.SELECT,
    eventLabel: 'select_time_v2',
  });
};

const trackConfirmTime = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'confirm_time_v2',
  });
};

const trackSelectAge = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.SELECT,
    eventLabel: 'select_age_v2',
  });
};

const trackConfirmAge = () => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.SEARCH,
    eventAction: EventAction.CLICK,
    eventLabel: 'confirm_age_v2',
  });
};

const trackPromoClicked = (promo: string) => {
  trackEvent({
    event: EventType.GA,
    eventCategory: EventCategory.PROMO,
    eventAction: EventAction.CLICK,
    eventLabel: promo,
  });
};

type MetricWithAttribution =
  | INPMetricWithAttribution
  | CLSMetricWithAttribution
  | LCPMetricWithAttribution
  | FIDMetricWithAttribution;

function isINPMetric(
  metric: MetricWithAttribution,
): metric is INPMetricWithAttribution {
  return metric.name === 'INP';
}

const trackWebVitalsMetric = (metric: MetricWithAttribution) => {
  const { name, delta, id } = metric;

  const eventPayload: EventProps = {
    event: EventType.GA,
    eventCategory: EventCategory.WEB_VITALS,
    eventAction: name,
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    eventLabel: id,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
    // Use a non-interaction event to avoid affecting bounce rate.
    nonInteraction: true,
    // Use `sendBeacon()` if the browser supports it.
    transport: 'beacon',
  };

  if (isINPMetric(metric)) {
    const { attribution } = metric;
    // Element that is interacted with.
    eventPayload.eventTarget = attribution.interactionTarget;
    // Type of interaction with the element.
    eventPayload.eventType = attribution.interactionType;
  }

  trackEvent(eventPayload);
};

export {
  trackEvent,
  trackCriteo,
  trackPageView,
  trackShowLogin,
  trackLogin,
  trackPasswordForgotten,
  trackNewUser,
  trackLogout,
  trackMyBookings,
  trackMyPayment,
  trackMyData,
  trackVoucherButtonClick,
  trackOpeningTimesTooltipOpened,
  trackNewsletterRegistrationIndex,
  trackNewsletterRegistration,
  trackToggleMoreOptions,
  trackOptionsChange,
  trackSearchHistorySelect,
  trackSearchHistoryDelete,
  trackFooterTooltipOpened,
  trackNavigationClicked,
  trackCancellationHover,
  trackSavingHover,
  trackInfoBannerLinkClicked,
  trackInfoBannerCloseClicked,
  trackDriverAgeHover,
  trackRoundtripOneway,
  trackDriverAgeSelect,
  trackConfienzaClicked,
  trackFaqItemClicked,
  trackHelpcenterButtonClicked,
  trackSearchButtonOfferBoxClicked,
  trackIosAppLinkClicked,
  trackIosAppLinkFooterClicked,
  trackAndroidAppLinkClicked,
  trackAndroidAppLinkFooterClicked,
  trackFacebookFooterClicked,
  trackInstagramFooterClicked,
  trackPinterestFooterClicked,
  trackFooterClicked,
  trackWebVitalsMetric,
  trackStationMapStationClick,
  trackPriceGraphClicked,
  trackPriceGraphSearch,
  trackPriceGraphCityClick,
  trackOffersSliderOffferClick,
  trackOffersSliderButtonClick,
  trackOffersSliderDragged,
  trackOffersSliderMouseWheel,
  trackLanguageChangeAttempted,
  trackReviewShowDetailsClicked,
  trackShowCustomerReviews,
  trackReviewSearchClicked,
  trackShowMoreProvidersReviews,
  trackReviewsCarouselSlide,
  trackReviewsCarouselClicked,
  trackBeginSearchClicked,
  trackSelectStation,
  trackConfirmDestination,
  trackSelectDates,
  trackConfirmDates,
  trackConfirmSearchClicked,
  trackSelectTime,
  trackConfirmTime,
  trackEditDatesClicked,
  trackEditTimeClicked,
  trackEditAgeClicked,
  trackEditDepartureClicked,
  trackEditDestinationClicked,
  trackConfirmAge,
  trackSelectAge,
  trackPromoClicked,
};
