import styled from '@emotion/styled';
import {
  Button,
  BodyText,
  Heading3,
  SecondaryText,
  Ui,
} from '@silvertours/front-shared';

const PricePrefixTextWrapper = styled(SecondaryText)`
  white-space: nowrap;
  padding-top: ${({ theme }) => theme.spacing[20]};
  padding-bottom: ${({ theme }) => theme.spacing[20]};
  text-align: right;
  font-variant-numeric: tabular-nums;
`;

const SubtitleWrapper = styled(Heading3)`
  margin-top: ${({ theme }) => theme.spacing[20]};
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${({ theme }) => theme.mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing[40]};
    margin-bottom: ${({ theme }) => theme.spacing[40]};
  }
`;

const Container = styled(Ui.Card)<{ hasAction: boolean }>`
  height: 100%;
  padding: ${({ theme }) => theme.spacing[40]};
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'initial')};

  ${({ theme }) => theme.mq('lg')} {
    padding: ${({ theme }) => theme.spacing[50]};
  }
`;

const ImageWrapper = styled.div`
  border-bottom: ${({ theme }) => `${theme.color.grey04} 1px solid`};
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  display: flex;
  position: relative;
  height: 130px;

  ${({ theme }) => theme.mq('lg')} {
    height: 150px;
    margin-bottom: ${({ theme }) => theme.spacing[40]};
    padding-bottom: ${({ theme }) => theme.spacing[40]};
  }
`;

const Image = styled(Ui.Image)`
  object-fit: contain;
`;

const ProviderOffersList = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ProviderOffer = styled.li`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[40]};
`;

const ProviderName = styled(BodyText)`
  padding-top: ${({ theme }) => theme.spacing[20]};
  padding-bottom: ${({ theme }) => theme.spacing[20]};
`;

const SearchButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing[40]};
  ${({ theme }) => theme.mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing[50]};
  }
`;

export {
  Container,
  ImageWrapper,
  Image,
  ProviderName,
  ProviderOffer,
  ProviderOffersList,
  PricePrefixTextWrapper,
  SearchButton,
  SubtitleWrapper,
};
