import { DestinationContentModule } from '@silvertours/common-landingpages-view';
import { Ui } from '@silvertours/front-shared';
import { Heading, ModuleContainer, RichText } from '../../element';
import { Figure, FigureList } from './FigureList';

export type TextContentBlockWithFiguresContent = Pick<
  DestinationContentModule,
  'content' | 'heading' | 'translations'
> & { figures: Array<Figure>; positionLabel: string };

export type TextContentBlockWithFiguresRules = {
  textStyle?: Ui.RichTextStyle;
};

export type TextContentBlockWithFiguresProps = {
  content: TextContentBlockWithFiguresContent;
  rules?: TextContentBlockWithFiguresRules;
};

export const TextContentBlockWithFigures = ({
  content: { content, heading, figures, positionLabel },
  rules,
}: TextContentBlockWithFiguresProps) => {
  const style = rules?.textStyle || Ui.RichTextStyle.Primary;
  return (
    <ModuleContainer>
      <Heading content={heading} rules={{ textStyle: { style } }} />
      <RichText
        content={content}
        rules={{
          columns: [[12], [8, 'lg']],
          textStyle: { hasSpacedParagraphs: true, style },
        }}
      />
      <FigureList content={figures} positionLabel={positionLabel} />
    </ModuleContainer>
  );
};
