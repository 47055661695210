export { ContentContainer } from './ContentContainer';
export { ColumnRatioOption, TwoColumns } from './Columns';
export {
  FilledSegment,
  FooterSegment,
  HeaderSegment,
  ImageSegment,
  PlainSegment,
} from './Segment';

export type { ContentContainerProps } from './ContentContainer';
export type { TwoColumnsProps, TwoColumnsRatio } from './Columns';
