import { desaturate, lighten } from 'polished';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 3.5rem;
  z-index: 50;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.white};
  border: 0.125rem solid
    ${({ theme }) =>
      lighten(0.065, desaturate(0.25, theme.color.primaryHighlight!))};
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -2px rgb(0 0 0 / 10%);
  font-size: 1rem;
  line-height: 1.5;
  list-style-type: none;
`;

const Headline = styled.div`
  padding: 0.75rem 0.75rem 0.25rem;
  color: ${({ theme }) => theme.color.grey02};
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    border-bottom: 1px solid ${({ theme }) => theme.color.grey03};
  }
`;

const ItemButton = styled.button`
  margin: 0;
  padding: 0.75rem;
  border: 0;
  width: 100%;
  font: inherit;
  text-align: left;
  color: inherit;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      lighten(0.065, desaturate(0.25, theme.color.primaryHighlight!))};
  }
`;

const Destination = styled.span`
  display: block;
  font-weight: 600;
`;

const Duration = styled.time`
  display: block;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.color.grey02};
`;

const DeleteButton = styled.button`
  margin: 0;
  padding: 0.75rem;
  border: 0;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  color: ${({ theme }) => theme.color.brand};
  background: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export {
  Wrapper,
  Headline,
  List,
  ItemButton,
  Destination,
  Duration,
  DeleteButton,
};
