import type { PropsWithChildren, ReactNode } from 'react';
import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import Close from '@engineering/icons/close';
import type { OverlaySize, OverlayTitlePosition } from './types';
import {
  Backdrop,
  Wrapper,
  Content,
  TitleWrapper,
  Title,
  ScrollableContent,
  StyledIconButton,
  Footer,
} from './Overlay.styles';

type Props = PropsWithChildren<{
  title?: ReactNode;
  titlePosition?: OverlayTitlePosition;
  footerContent?: ReactNode;
  closeLabel?: string;
  open: boolean;
  width?: OverlaySize;
  withoutPadding?: boolean;
  centered?: boolean;
  onClose: () => void;
}>;

const Overlay = ({
  title,
  titlePosition = 'auto',
  footerContent,
  closeLabel = 'close', // you should set this to the correct translation
  children,
  open,
  width = 'normal',
  withoutPadding = false,
  centered = false,
  onClose,
}: Props) => (
  <Dialog onClose={onClose} open={open}>
    <DialogBackdrop as={Backdrop} />
    <Wrapper role="presentation" $width={width} $centered={!!centered}>
      <DialogPanel>
        <Content
          role="presentation"
          $fullViewport={width === 'full'}
          $rounded={!!centered}
        >
          <TitleWrapper $hasTitle={!!title}>
            {title && (
              <DialogTitle as="div">
                <Title $titlePosition={titlePosition}>{title}</Title>
              </DialogTitle>
            )}
            <CloseButton
              as={StyledIconButton}
              icon={Close}
              title={closeLabel}
            />
          </TitleWrapper>
          <ScrollableContent
            role="presentation"
            $fullViewport={width === 'full'}
            $withoutPadding={withoutPadding}
          >
            {children}
          </ScrollableContent>
          {footerContent && (
            <Footer $fullViewport={width === 'full'}>{footerContent}</Footer>
          )}
        </Content>
      </DialogPanel>
    </Wrapper>
  </Dialog>
);

export { Overlay };
