import React, { ReactNode } from 'react';

import { LayoutContainer, PageBaseContainer } from './Page.styles';

type PageBaseProps = {
  children: ReactNode;
  isAffiliate?: boolean;
};

const PageBase = ({ children, isAffiliate }: PageBaseProps) => (
  <LayoutContainer isAffiliate={isAffiliate}>
    <PageBaseContainer id="page-body" isAffiliate={isAffiliate}>
      {children}
    </PageBaseContainer>
  </LayoutContainer>
);

export { PageBase };
export type { PageBaseProps };
