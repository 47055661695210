import { DataInterop } from '@silvertours/front-shared';
import { useMessages, useTranslations } from 'next-intl';
import { Features } from '@silvertours/common-landingpages-i18n';
import { HasProducts } from '@silvertours/common-landingpages-view';
import { useHost } from '../_legacy/Host';
import { productData } from './data/product';

const OrganizationData = ({ products }: HasProducts) => {
  const messages = useMessages() as unknown as { features: Features };
  const days = Object.keys(
    messages.features.documentLegacy.siteInformation.openingHoursSpecification
      .dayOfWeek,
  ) as 'monday'[];

  const { formatImage } = useHost();
  const siteInfo = useTranslations('features.documentLegacy.siteInformation');

  const openingHours = days
    .map(day => siteInfo(`openingHoursSpecification.dayOfWeek.${day}`))
    .filter(translation => !translation.startsWith('features'));

  const openingHoursSpecification = {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: openingHours,
    opens: siteInfo('openingHoursSpecification.opens'),
    closes: siteInfo('openingHoursSpecification.closes'),
  };

  const address = {
    '@type': 'PostalAddress',
    streetAddress: siteInfo('address.streetAddress'),
    postalCode: siteInfo('address.postalCode'),
    addressLocality: siteInfo('address.addressLocality'),
    addressCountry: siteInfo('address.addressCountry'),
  };

  const sameAs = [
    siteInfo('sameAs.facebook'),
    siteInfo('sameAs.instagram'),
    siteInfo('sameAs.pinterest'),
  ];

  return (
    <DataInterop.StructuredData
      type="Organization"
      data={{
        name: siteInfo('name'),
        image: formatImage(siteInfo('image')),
        telephone: siteInfo('telephone'),
        owns: productData({ products }),
        sameAs,
        address,
        openingHoursSpecification,
      }}
      includeContext
    />
  );
};

export { OrganizationData };
