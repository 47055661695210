import { Module } from '../module';
import { buildColumns, ColumnBuilder } from './columns';
import { buildContainer, ContainerBuilder } from './container';
import {
  buildContainerModule,
  ContainerModuleBuilder,
} from './containerModule';
import { buildContentModule, ContentModuleBuilder } from './contentModule';

type ContentBuilders = {
  columns: ColumnBuilder;
  container: ContainerBuilder;
  containerModule: ContainerModuleBuilder;
  contentModule: ContentModuleBuilder;
};

type ContentModulesRenderer = (builders: ContentBuilders) => Array<Module>;

const contentBuilders: ContentBuilders = {
  columns: buildColumns,
  container: buildContainer,
  containerModule: buildContainerModule,
  contentModule: buildContentModule,
};

export { contentBuilders };
export type { ContentModulesRenderer };
