import { useCallback, useEffect, useRef, useState } from 'react';
import { gtm } from '../analytics';
import { Section } from './Graph.styles';
import { GraphBar } from './Bar';

type GraphProps = {
  prices: {
    date: string;
    priceMax: number;
    priceMedian: number;
    priceMin: number;
  }[];
  translations?: {
    averagePrice: string;
    buttonText: string;
    priceRange: string;
    cheapestMonthLabel: string;
    noDataText: string;
  };
  cityCode: string;
  cityName: string;
  triggerSearch: (cityCode: string, cityName: string, date: string) => void;
  currentTab: number;
  containerRef: React.RefObject<HTMLDivElement> | undefined;
};
export const Graph = ({
  prices,
  translations,
  cityCode,
  cityName,
  triggerSearch,
  currentTab,
  containerRef,
}: GraphProps) => {
  const [selectedBarId, setSelectedBarId] = useState<string | null>(null);
  const [cheapestMonth, setCheapestMonth] = useState<string | null>(null);
  const barRefs = useRef<Array<HTMLDivElement | null>>([]);

  const maxPrice = Math.max(...prices.map(price => price.priceMedian));

  const averagePrice =
    prices.reduce((prev, curr) => prev + curr.priceMedian, 0) / prices.length;

  useEffect(() => {
    const newCheapestMonth = prices.reduce((prev, curr) =>
      curr.priceMedian !== 0 && curr.priceMedian < prev.priceMedian
        ? curr
        : prev,
    ).date;
    setCheapestMonth(newCheapestMonth);
    setSelectedBarId(newCheapestMonth);
    const cheapestMonthIndex = prices.findIndex(
      price => price.date === newCheapestMonth,
    );
    const barElement = barRefs.current[cheapestMonthIndex];
    const container = containerRef?.current;
    if (barElement && container) {
      const containerWidth = container.offsetWidth;
      const barOffset = barElement.offsetLeft;
      const barWidth = barElement.offsetWidth;
      const scrollLeft = barOffset - containerWidth / 2 + barWidth / 2;
      container.scrollLeft = scrollLeft;
    }
  }, [currentTab, prices, containerRef]);

  const handleBarSelect = useCallback(
    (id: string | null) => {
      setSelectedBarId(id);
      gtm.trackPriceGraphClicked(id);
    },
    [setSelectedBarId],
  );

  const handleCardClick = useCallback(() => {
    setSelectedBarId(null);
  }, [setSelectedBarId]);

  return (
    <Section>
      {prices.map((item, index) => (
        <GraphBar
          key={item.date}
          barHeight={
            item.priceMedian !== 0
              ? // calculates bar height based on median price relative to max height
                `${(item.priceMedian / maxPrice) * 16}rem`
              : `${(averagePrice / maxPrice) * 16}rem`
          }
          value={item}
          cheapestMonth={item.date === cheapestMonth}
          isSelected={selectedBarId === item.date}
          onClick={() => handleBarSelect(item.date)}
          onCardClose={handleCardClick}
          translations={translations}
          cityCode={cityCode}
          cityName={cityName}
          triggerSearch={triggerSearch}
          ref={el => {
            barRefs.current[index] = el;
          }}
        />
      ))}
    </Section>
  );
};
