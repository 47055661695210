import {
  ContainerPageModule,
  ContentModulePageModule,
  ModuleType,
} from '../module';

type ContainerBuilder = (
  key: string,
  modules: ContentModulePageModule | Array<ContentModulePageModule>,
  renderCondition?: boolean,
) => ContainerPageModule;

const buildContainer: ContainerBuilder = (
  key,
  modules,
  renderCondition = true,
) => ({
  id: key,
  key,
  modules,
  renderCondition,
  type: ModuleType.Container,
});

export { buildContainer };
export type { ContainerBuilder };
