import {
  AccordionItemPromptWrapper,
  AccordionItemTitle,
  AccordionItemArrow,
  ArrowDownIcon,
} from './AccordionItem.styles';

export type AccordionItemPromptProps = {
  prompt: string;
};

export const AccordionItemPrompt = ({ prompt }: AccordionItemPromptProps) => (
  <AccordionItemPromptWrapper>
    <AccordionItemTitle as="span">{prompt}</AccordionItemTitle>
    <AccordionItemArrow>
      <ArrowDownIcon />
    </AccordionItemArrow>
  </AccordionItemPromptWrapper>
);
