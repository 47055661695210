import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import type { NextApiRequest, NextApiResponse } from 'next';
import { setCookie } from 'nookies';

import * as jwt from '../jwt';
import { DEFAULT_TIMEOUT, getWhitelabel } from './config';

interface Config extends AxiosRequestConfig {
  retry?: boolean;
}

export type ApiHostConfig = {
  baseUrl: string;
  refreshTokenUrl: string;
};

export abstract class HTTPService {
  private hostConfig: ApiHostConfig;

  protected readonly path: string;

  protected readonly ssrMode: boolean;

  protected nextRequest?: NextApiRequest;

  protected nextResponse?: NextApiResponse;

  protected accessToken?: string;

  protected refreshToken?: string;

  protected idToken?: string;

  protected locale?: string;

  protected instance: AxiosInstance;

  protected constructor(
    apiHostConfig: ApiHostConfig,
    path: string,
    nextRequest: NextApiRequest,
    nextResponse: NextApiResponse,
    locale?: string,
    accessToken?: string,
    refreshToken?: string,
    idToken?: string,
  ) {
    this.hostConfig = apiHostConfig;
    this.path = path;
    this.ssrMode = typeof window === 'undefined';

    if (nextRequest) {
      this.nextRequest = nextRequest;
    }

    if (nextResponse) {
      this.nextResponse = nextResponse;
    }

    if (this.ssrMode && nextRequest?.cookies) {
      this.accessToken = nextRequest?.cookies?.access_token;
      this.refreshToken = nextRequest?.cookies?.refresh_token;
      this.idToken = nextRequest?.cookies?.id_token;
    }

    if (accessToken) {
      this.accessToken = accessToken;
    }

    if (refreshToken) {
      this.refreshToken = refreshToken;
    }

    if (idToken) {
      this.idToken = idToken;
    }

    this.locale = locale || (nextRequest?.headers?.locale as string) || 'de-DE';

    this.instance = axios.create({
      baseURL: `${apiHostConfig.baseUrl}${path}`,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'X-Whitelabel': getWhitelabel(this.locale),
        Authorization: `Bearer ${this.accessToken}`,
      },
      withCredentials: true, // to send cookie
    });

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  // Request interceptor for API calls
  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      async config => config,
      err => {
        Promise.reject(err);
      },
    );
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(res => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      res.config.headers['x-access-token'] = this.accessToken || '';
      res.config.headers['x-refresh-token'] = this.refreshToken || '';
      res.config.headers['x-id-token'] = this.idToken || '';
      return res;
    }, this.handleError);
  };

  private handleError = async (err: AxiosError) => {
    const originalRequest = <Config>err.config;
    const { refreshTokenUrl } = this.hostConfig;

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (
      err.response?.status === 401 &&
      !originalRequest.retry &&
      refreshTokenUrl
    ) {
      originalRequest.retry = true;

      const userId = jwt.decodeToken(this.idToken)?.user_id;
      const res = await axios.post(
        refreshTokenUrl,
        {
          user_id: userId,
          refresh_token: this.refreshToken,
        },
        {
          headers: originalRequest.headers,
          withCredentials: true,
        },
      );

      // @ts-ignore
      originalRequest.headers.Authorization = `Bearer ${res.data?.access_token}`;
      this.accessToken = res.data?.access_token;
      this.idToken = res.data?.id_token;

      setCookie(
        { res: this.nextResponse },
        'access_token',
        res.data?.access_token,
        {
          httpOnly: true,
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'strict',
          path: '/',
          maxAge: 60 * 60, // 1 hour
        },
      );

      return this.instance(originalRequest);
    }

    return Promise.reject(err);
  };
}
