import { FC } from 'react';
import { PageLegacy } from '@silvertours/front-features';

import { CustomContentModule } from './CustomContentModule';

type BodyProps = {
  slug: string;
};

const BodyLight: FC<BodyProps> = ({ slug }) => (
  <PageLegacy.PageContentBase>
    <CustomContentModule slug={slug} />
  </PageLegacy.PageContentBase>
);
export { BodyLight };
