import {
  AttributeTypeClassificationRule,
  AttributeTypeClassificationStrategy,
} from './attributeTypeClassification';
import {
  CatchAllClassificationRule,
  CatchAllClassificationStrategy,
} from './catchAllClassification';
import {
  DomainKeyClassificationRule,
  DomainKeyClassificationStrategy,
} from './domainKeyClassification';
import {
  PageClassificationRule,
  PageClassificationRuleType,
} from './pageClassificationRule';
import { ClassificationRules } from './rules';
import {
  SlugClassificationRule,
  SlugClassificationStrategy,
} from './slugClassification';
import { ClassificationStrategies } from './strategies';

const isRuleOfType = <
  TRuleType extends PageClassificationRuleType,
  TRule extends PageClassificationRule<TRuleType>,
>(
  ruleType: TRuleType,
  rule: PageClassificationRule<PageClassificationRuleType>,
): rule is TRule => rule.type === ruleType;

const initializeStrategies = (
  ruleset: Array<ClassificationRules>,
  catchAllRule: CatchAllClassificationRule,
): Array<ClassificationStrategies> => [
  ...(ruleset
    .map(rule => {
      if (
        isRuleOfType<'attributeType', AttributeTypeClassificationRule>(
          'attributeType',
          rule,
        )
      ) {
        return new AttributeTypeClassificationStrategy(rule);
      }

      if (
        isRuleOfType<'domainKey', DomainKeyClassificationRule>(
          'domainKey',
          rule,
        )
      ) {
        return new DomainKeyClassificationStrategy(rule);
      }

      if (isRuleOfType<'slug', SlugClassificationRule>('slug', rule)) {
        return new SlugClassificationStrategy(rule);
      }
    })
    .filter(rule => !!rule) as Array<ClassificationStrategies>),
  new CatchAllClassificationStrategy(catchAllRule),
];

export { initializeStrategies };
