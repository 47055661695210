import { TextProps } from '../textUtils';
import { TertiaryTextWrapper } from './TertiaryText.styles';

const TertiaryText: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className,
  textColor,
}) => (
  <TertiaryTextWrapper className={className} textColor={textColor}>
    {children}
  </TertiaryTextWrapper>
);

export { TertiaryText };
