import styled from '@emotion/styled';

const BreadcrumbsContentContainer = styled.div`
  position: relative;
  z-index: 10;
  margin: -0.375rem 0; /* give link focus style some space */
  padding: 0.375rem 0; /* give link focus style some space */
  overflow-x: auto;

  ${({ theme }) => theme.mq('lg')} {
    position: static;
  }
`;

const BreadcrumbList = styled.ul`
  display: inline-flex;
  flex-flow: row wrap;
  font-size: 0.75rem;

  ${({ theme }) => theme.mq('sm')} {
    font-size: inherit;
  }
`;

const BreadcrumbItem = styled.li`
  white-space: nowrap;

  &:not(:last-child)::after {
    content: '\0000a0|\0000a0';
  }
`;

const BreadcrumbText = styled.span<{ isLast: boolean }>`
  font-weight: ${({ isLast }) => (isLast ? 'bold' : '')};
`;

export {
  BreadcrumbsContentContainer,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbText,
};
