import styled from '@emotion/styled';

const CardContainer = styled.div<{ hasBorder: boolean }>`
  &:empty {
    display: none;
  }

  &:not(:empty) {
    border: ${({ theme, hasBorder }) =>
      hasBorder ? `1px solid ${theme.color.grey03}` : 0};
    border-radius: 1px;
    container-type: inline-size;
  }
`;

export { CardContainer };
