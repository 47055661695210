import { createContext, useContext, useEffect, useRef } from 'react';
import { useDayPicker } from 'react-day-picker';
import { useIntersection } from 'react-use';

export const CustomMonthsContext = createContext<{
  onScrollToBottom?: () => void;
  vertical: boolean;
}>({
  onScrollToBottom: undefined,
  vertical: false,
});

export const CustomMonths = ({ children }: { children?: React.ReactNode }) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const { onScrollToBottom, vertical } = useContext(CustomMonthsContext);

  const intersection = useIntersection(bottomRef, {
    root: null,
    threshold: 0.5,
  });

  useEffect(() => {
    // Only call the callback after rendering has finished
    if (intersection?.isIntersecting && vertical) {
      onScrollToBottom?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection?.isIntersecting, vertical]);
  const { classNames, styles } = useDayPicker();

  return (
    <div className={classNames.months} style={styles?.months}>
      {children}
      <div ref={bottomRef} />
    </div>
  );
};
