import styled from '@emotion/styled';
import { Ui, interactiveText } from '@silvertours/front-shared';
import Comment from '@engineering/icons/comment';
import { HeaderIconStyles } from '../Header.styles';

const PhoneInfoPopoverWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneNumberWrapperSection = styled.div`
  ${({ theme }) => interactiveText({ textColor: 'grey', theme })};
`;

const PhoneNumber = styled(Ui.PhoneNumber)`
  display: none;
  color: ${({ theme }) => theme.color.brand};

  ${({ theme }) => theme.mq('lg')} {
    display: flex;
    gap: ${({ theme, hidePhoneNumber }) =>
      !hidePhoneNumber && theme.spacing[20]};
    white-space: nowrap;
  }
`;

const ContactIcon = styled(Comment)`
  ${HeaderIconStyles};
  fill: ${({ theme }) => theme.color.brand};
  min-width: ${({ theme }) => theme.spacing[50]};
`;

export {
  ContactIcon,
  PhoneInfoPopoverWrapper,
  PhoneNumber,
  PhoneNumberWrapperSection,
};
