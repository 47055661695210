import styled from '@emotion/styled';
import { tint } from 'polished';

const StyledButton = styled.button`
  position: relative;
  margin: 1.25rem 0 0.625rem;
  padding: 0.9375rem 1.375rem;
  border: 0;
  background-color: ${({ theme }) => theme.color.brand};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1rem;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => tint(0.202, theme.color.brand!)};
  }
`;

export { StyledButton };
