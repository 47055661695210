import { MouseEventHandler, ReactNode } from 'react';
import { LinkProps } from '../LinkProps';
import { MaskedLinkButton } from './MaskedLink.styles';

export type MaskedLinkProps = LinkProps<{
  body: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
}>;

export const MaskedLink = ({
  body,
  href,
  target,
  title,
  className,
  onClick,
}: MaskedLinkProps) => {
  if (!href) {
    return null;
  }

  const openLink: MouseEventHandler<HTMLElement> = event => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
    if (target && target !== '_self') {
      window.open(href, target);
    } else {
      window.location.href = href;
    }
  };

  return (
    <MaskedLinkButton
      type="button"
      onClick={openLink}
      title={title}
      className={className}
    >
      {body}
    </MaskedLinkButton>
  );
};
