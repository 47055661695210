import { useRuntime } from './useRuntime';

type Whitelabel = 'DE_billiger-mietwagen' | 'FR_carigami';

// Map locales to Whitelabels
const whitelabels: Record<string, Whitelabel> = {
  'de-DE': 'DE_billiger-mietwagen',
  'fr-FR': 'FR_carigami',
};

const getWhitelabel = (locale: string = 'de-DE') => whitelabels[locale];

export const useWhitelabel = () => {
  const { locale } = useRuntime();
  const whitelabel = getWhitelabel(locale);
  return { whitelabel };
};
