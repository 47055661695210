import { ImageWithFocalPoint as ImageWithFocalPointProps } from '@silvertours/common-landingpages-view';
import { ImageProps } from 'next/image';
import { StyledImage } from './ImageWithFocalPoint.style';

export const ImageWithFocalPoint = ({
  src,
  alt,
  width,
  height,
  focalPoint,
  ...props
}: ImageWithFocalPointProps & ImageProps) => {
  const xPercentage =
    focalPoint && width ? (focalPoint.x * 100) / Number(width) : undefined;
  const yPercentage =
    focalPoint && height ? (focalPoint.y * 100) / Number(height) : undefined;
  return (
    <StyledImage
      {...props}
      src={src}
      alt={alt}
      fill
      x={xPercentage}
      y={yPercentage}
    />
  );
};
