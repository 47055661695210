import Image from 'next/image';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  padding: 1rem 1rem 1.75rem;
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 14%);
  color: ${({ theme }) => theme.color.white};
  background: linear-gradient(
    103.44deg,
    ${({ theme }) => theme.color.primaryHighlight} 3.17%,
    ${({ theme }) => theme.color.brand} 100%
  );
  background-repeat: no-repeat;
  overflow: hidden;
`;

const Swirl = styled(Image)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
`;

const Text = styled.p`
  position: relative;
  z-index: 1;
  margin: 0 auto 1.5rem;
  max-width: 92%;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.375rem;
  line-height: 1.5;
  text-align: center;
`;

export { Swirl, Text, Wrapper };
