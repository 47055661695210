import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { css } from '@emotion/react';
import { Theme, Ui } from '@silvertours/front-shared';
import { StaticLink } from '@silvertours/back-domain-page';

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ${({ theme }) => theme.mq('md')} {
    columns: 2;
    gap: 1rem;
  }
`;

const Item = styled.li`
  display: block;
  margin-bottom: 1.5rem;
`;

const getLinkStyles = ({ theme }: { theme: Theme.Theme }) => css`
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 0.5px;
  font-family: ${theme.fontFamily.alternate};
  color: ${theme.color.grey01};
  text-decoration: none;
  text-align: left;
  white-space: normal;

  &:focus-visible {
    border-radius: ${theme.borderRadius.md};
    outline: 0.125rem dashed ${theme.color.error};
    outline-offset: 0.25rem;
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

const MaskedLink = styled(Ui.MaskedLink)`
  ${getLinkStyles}
`;

const Anchor = styled(Link)`
  ${getLinkStyles}
  text-decoration: none;
`;

type Props = {
  links: StaticLink[];
};

const FooterLinks = ({ links }: Props) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <List>
      {links.map(({ text, target, href, title, masked }) => {
        const linkProps = {
          href,
          target,
          title,
        };

        return (
          <Item key={`footer_${href}`}>
            {masked ? (
              <MaskedLink {...linkProps} body={text} />
            ) : (
              <Anchor
                {...linkProps}
                rel={target === '_blank' ? 'noopener noreferrer' : undefined}
              >
                {text}
              </Anchor>
            )}
          </Item>
        );
      })}
    </List>
  );
};

export { FooterLinks };
