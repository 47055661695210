import axios from 'axios';
import { http } from '@silvertours/front-shared';
import { I18nLegacy } from '@silvertours/front-legacy-shared';

import {
  addItemToSearchHistory,
  mapFormToSearchItem,
} from '../SearchHistory/utils';
import { SearchFormState, useSearchContext } from '../context';

import { useSearch } from './useSearch';
import { useAffiliate } from './useAffiliate';

const searchEndpoint = 'v1/quotes';

export interface SearchError {
  error: {
    name:
      | 'request_invalid'
      | 'form_validation_error'
      | 'validation_error'
      | 'search_unavailable';
    meta?: {
      errors: Record<string, string> | Array<string>;
    };
  };
}

const createPayload = (
  {
    affiliate,
    customerAge,
    depDate,
    depLocation,
    destDate,
    destLocation,
    inputValue,
  }: SearchFormState,
  isInternalCall: boolean,
) => {
  const shouldSearchServiceNumbers =
    isInternalCall && inputValue.departure[0] === '#';
  return shouldSearchServiceNumbers
    ? {
        customerAge,
        depDate,
        depLocation: { name: inputValue.departure },
        destDate,
      }
    : {
        aff: affiliate,
        customerAge,
        depDate,
        depLocation,
        destDate,
        destLocation,
      };
};

export const matchError = (axiosError: http.AxiosError) => {
  const errorData = axiosError.response?.data as SearchError;
  const errors = errorData?.error?.meta?.errors;

  if (
    errorData &&
    ['validation_error', 'form_validation_error'].includes(
      errorData.error?.name,
    )
  ) {
    if (errors && Array.isArray(errors)) {
      return { type: 'locationError', message: errors[0] };
    }

    if (errors?.depDate) {
      return { type: 'dateError', message: errors.depDate };
    }

    if (errors?.destDate) {
      return { type: 'dateError', message: errors.destDate };
    }

    if (errors?.depLocation) {
      return { type: 'locationError', message: errors.depLocation };
    }

    if (errors?.destLocation) {
      return { type: 'locationError', message: errors.destLocation };
    }
  }

  return { type: 'axiosError', message: axiosError.message };
};

export const useSubmitHandler = () => {
  const { formatApiUrl } = useSearch();
  const { redirectToTradedoubler } = useAffiliate();
  const { prefilterQuery, isInternalCall } = useSearchContext();
  const { language, market } = I18nLegacy.useLocale();

  return async (
    formState: SearchFormState | null,
    queryParams: string,
    onSuccess?: (payload: any) => void,
    onError?: (error: http.AxiosError) => void,
    openInNewWindow = false,
  ) => {
    if (!formState) {
      return;
    }

    const payload = createPayload(formState, isInternalCall);

    try {
      const { data } = await http.axiosInstance.post(
        formatApiUrl(searchEndpoint),
        payload,
      );

      if (onSuccess) {
        onSuccess(data);
      }

      if (data.redirectUrl) {
        const savedSearchItem = {
          ...mapFormToSearchItem(formState),
          language: language || '',
          market: market || '',
        };
        addItemToSearchHistory(savedSearchItem);

        const url = [data.redirectUrl, queryParams, prefilterQuery]
          .filter(Boolean)
          .join('&');

        const tdUrl = redirectToTradedoubler(url);
        const targetUrl = tdUrl || url;
        if (openInNewWindow) {
          window.open(targetUrl, '_blank');
        } else {
          window.location.assign(targetUrl);
        }
      }
    } catch (axiosError: unknown) {
      if (axiosError && axios.isAxiosError(axiosError)) {
        // eslint-disable-next-line no-console
        console.error(
          `Request error when fetching ${searchEndpoint}`,
          axiosError.message,
          payload,
        );
        if (onError) {
          onError(axiosError);
        } else {
          throw new Error(axiosError.message);
        }
      }
    }
  };
};
