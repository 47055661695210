import React from 'react';

import { MembershipPopover } from '@silvertours/front-entities';

import { useLogin } from '@silvertours/front-features';
import { Button, Ui } from '@silvertours/front-shared';
import { useTranslations } from 'next-intl';
import {
  LockIcon,
  UserIcon,
  MembershipPanelWrapper,
} from './LoginButton.styles';
import { LoggedInActions } from '../LoggedInActions';

type Props = {
  onClick?: () => void;
};

const LoginButton = ({ onClick }: Props) => {
  const { isLoggedIn, setShowLogin } = useLogin();
  const t = useTranslations('features.navigationLegacy.navigation');

  const handleLoginClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    setShowLogin(true);
  };

  const handleSignUpClick = () => {
    window.location.assign(t('newUser.href'));
  };

  if (!isLoggedIn) {
    return (
      <Ui.Popover
        button={{
          as: 'div',
          children: (
            <Button icon={<LockIcon />} variant="secondary" size="small">
              {t('login.button.label')}
            </Button>
          ),
        }}
        triggerAction="click"
      >
        <Ui.PopoverPanel>
          <Ui.PanelContentWrapper width="403px">
            <MembershipPanelWrapper>
              <MembershipPopover
                handleLoginClick={handleLoginClick}
                handleSignUpClick={handleSignUpClick}
              />
            </MembershipPanelWrapper>
          </Ui.PanelContentWrapper>
        </Ui.PopoverPanel>
      </Ui.Popover>
    );
  }

  return (
    <Ui.Popover
      button={{
        as: 'div',
        children: (
          <Button icon={<UserIcon />} variant="secondary" size="small">
            {t('login.button.account')}
          </Button>
        ),
      }}
      triggerAction="click"
    >
      <Ui.PopoverPanel>
        <LoggedInActions />
      </Ui.PopoverPanel>
    </Ui.Popover>
  );
};

export { LoginButton };
