import { ReactNode } from 'react';

import { ContainerPageModule, ModuleType } from '../module';
import { buildContentModule } from './contentModule';

type ContainerModuleBuilder = (
  key: string,
  content: ReactNode,
  renderCondition?: boolean,
) => ContainerPageModule;

const buildContainerModule: ContainerModuleBuilder = (
  key,
  content,
  renderCondition = true,
) => ({
  id: key,
  key,
  modules: [buildContentModule(key, content)],
  type: ModuleType.Container,
  renderCondition,
});

export { buildContainerModule };
export type { ContainerModuleBuilder };
