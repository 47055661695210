import styled from '@emotion/styled';
import Info from '@engineering/icons/info';
import { Ui } from '@silvertours/front-shared';

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.default};
  margin-top: 1.5rem;
`;

export const DropdownWrapper = styled(InputWrapper)`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const InputButtonLabel = styled.div`
  position: absolute;
  top: -0.5625rem;
  left: 0.75rem;
  padding: 0 ${({ theme }) => theme.spacing[10]};
  z-index: 2;
  color: ${({ theme }) => theme.color.grey01};
  background-color: ${({ theme }) => theme.color.white};
  font-size: 0.75rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InputButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  border: 1px solid ${({ theme }) => theme.color.grey03};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  width: 100%;
  font-family: inherit;
  font-size: 1rem;
  line-height: 3.375;
  text-align: left;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.color.grey01};
  }

  &:focus-visible {
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }
`;

export const StyledDropdown = styled(Ui.Dropdown)`
  min-width: 8em;
  height: 3.375rem;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  fill: currentcolor;
`;

export const InfoIcon = styled(Info)`
  fill: ${({ theme }) => theme.color.brand};
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  cursor: pointer;
`;
