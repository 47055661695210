import { ButtonSize } from '@silvertours/front-shared';
import {
  HasOptionalHorizontalSpacingRules,
  HasOptionalVerticalSpacingRules,
} from '../rule';

import { ExpandButton } from './Button.styles';

export type ButtonRules = HasOptionalVerticalSpacingRules<'spacingDirection'> &
  HasOptionalHorizontalSpacingRules<'isCentered'> & {
    expanded?: boolean;
    size?: ButtonSize;
    variant?: 'primary' | 'secondary';
  };

export type ButtonContent = {
  label: string;
};

export type ButtonProps = {
  content: ButtonContent;
  onClick: () => void;
  rules?: ButtonRules;
};

export const Button = ({ content: { label }, onClick, rules }: ButtonProps) => {
  const {
    expanded = false,
    horizontalSpacing: { isCentered = false } = {},
    size = 'large',
    variant = 'primary',
    verticalSpacing: { spacingDirection = 'bottom' } = {},
  } = rules || {};

  return (
    <ExpandButton
      size={size}
      variant={variant}
      isCentered={isCentered}
      isExpanded={expanded}
      spacingDirection={spacingDirection}
      onClick={onClick}
    >
      {label}
    </ExpandButton>
  );
};
