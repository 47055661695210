import React, { ReactNode } from 'react';

import { PageContentContainer } from './Page.styles';

type PageContentBaseProps = {
  children: ReactNode;
};

const PageContentBase = ({ children }: PageContentBaseProps) => (
  <PageContentContainer id="page-content">{children}</PageContentContainer>
);

export { PageContentBase };
export type { PageContentBaseProps };
