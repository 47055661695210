import { scalable1 } from './Scalable1';
import { scalable2 } from './Scalable2';
import { scalable3 } from './Scalable3';
import { scalable4 } from './Scalable4';
import { scalable5 } from './Scalable5';
import { scalable6 } from './Scalable6';
import { fixed1 } from './Fixed1';
import { fixed2 } from './Fixed2';
import { fixed3 } from './Fixed3';
import { fixed4 } from './Fixed4';
import { fixed5 } from './Fixed5';

const WaveSets = {
  scalable1,
  scalable2,
  scalable3,
  scalable4,
  scalable5,
  scalable6,
  fixed1,
  fixed2,
  fixed3,
  fixed4,
  fixed5,
};

export { WaveSets };
