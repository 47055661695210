import { LandingPage } from '../../../entity';
import {
  PageClassificationStrategy,
  PageClassificationStrategyBase,
} from '../pageClassificationStrategy';
import { AttributeTypeClassificationRule } from './rule';

class AttributeTypeClassificationStrategy
  extends PageClassificationStrategyBase
  implements PageClassificationStrategy
{
  private attributeTypes: string[];

  constructor({
    attributeType,
    includeBreadcrumbs,
    pageType,
    pillars,
  }: AttributeTypeClassificationRule) {
    super(pageType, pillars, includeBreadcrumbs);

    this.attributeTypes = Array.isArray(attributeType)
      ? attributeType
      : [attributeType];

    this.classify = this.classify.bind(this);
    this.strategyApplies = this.strategyApplies.bind(this);
  }

  classify(_page: LandingPage) {
    return this.withCommonValues({});
  }

  strategyApplies({ primaryAttribute }: LandingPage): boolean {
    if (!primaryAttribute) {
      return false;
    }

    return !!this.attributeTypes.find(type => type === primaryAttribute.type);
  }
}

export { AttributeTypeClassificationStrategy };
