import {
  Badge,
  Heading,
  LinkList,
} from '@silvertours/common-landingpages-view';
import {
  LinkListBlock,
  PlainContentModuleContainer,
} from '@silvertours/front-entities';

export type SingleLinkListProps = {
  badge?: Badge;
  heading?: Heading;
  list: LinkList;
  translations: any;
};

export const SingleLinkList = ({
  badge,
  heading,
  list,
  translations,
}: SingleLinkListProps) => {
  if (list.links.length === 0) {
    return null;
  }

  return (
    <PlainContentModuleContainer>
      <LinkListBlock
        content={{ ...list, badge, heading }}
        translations={translations}
      />
    </PlainContentModuleContainer>
  );
};
