import styled from '@emotion/styled';
import { shade } from 'polished';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.75rem;

  img {
    height: 30px;
    vertical-align: middle;
  }

  a {
    color: ${({ theme }) => shade(0.271, theme.color.brand!)};
  }
`;

export { LogoWrapper };
