import { css, Global } from '@emotion/react';
import { Theme } from '../../Theme';

interface CSSResetProps {
  theme: Theme;
}

export const CSSReset: React.FC<CSSResetProps> = ({ theme }) => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
      }

      html {
        font-family: ${theme.fontFamily.default};
      }

      body {
        margin: 0;
        font-size: ${theme.fontSize.xs.size};
        line-height: 1.25rem; /* FIXME: Use unitless line-height */

        & > div {
          overflow-x: hidden;

          &#storybook-root {
            overflow-x: unset;
          }
        }
      }

      p {
        margin: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      /* For styling Google Maps InfoWindow component, which doesn't allow for custom styling */
      /* Component @ frontend/entities/src/Map/CustomMarker/CustomMarker.tsx */
      .gm-style-iw.gm-style-iw-c {
        background-color: transparent;
        padding: 0;
        box-shadow: none;

        & > button {
          display: none !important;
        }
      }

      .gm-style-iw-t > .gm-style-iw-tc {
        top: -5px;
      }

      .gm-style-iw-d {
        height: 99px;
        max-width: 380px;
        background-color: transparent;
        overflow: hidden !important;

        & > div {
          height: 99px;
        }
      }

      .gm-style-iw-chr {
        display: none;
      }

      .gm-style {
        font: inherit;
      }
    `}
  />
);
