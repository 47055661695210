import { ChangeEvent } from 'react';
import Close from '@engineering/icons/close';
import { Button, Ui } from '@silvertours/front-shared';
import type {
  LanguageCode,
  Market,
  MarketCode,
} from '@silvertours/common-landingpages-view';

import { useTranslations } from 'next-intl';
import { Container, Element, Header } from './MarketPicker.styles';

type Props = {
  disableMarketChange?: boolean;
  disableLanguageChange?: boolean;
  disableSubmit?: boolean;
  selectedLanguage: LanguageCode;
  selectedMarket: Market;
  markets: Market[];
  onLanguageChange?: (
    languageCode: LanguageCode,
    marketCode: MarketCode,
  ) => void;
  onMarketChange: (market: Market) => void;
  onClose?: () => void;
  onSubmit: (market: Market) => void;
};

const MarketPicker = ({
  disableMarketChange,
  disableLanguageChange = true,
  disableSubmit,
  selectedLanguage,
  selectedMarket: { redirectInfo, comingSoonMessage, ...selectedMarket },
  markets,
  onLanguageChange,
  onMarketChange,
  onClose,
  onSubmit,
}: Props) => {
  const t = useTranslations(
    'features.navigationLegacy.navigation.header.marketPicker',
  );

  const validMarkets = markets.map(({ country }) => ({
    label: country.name,
    value: country.code,
  }));

  const validLanguages = markets.map(({ language }) => ({
    label: language.name,
    value: language.code,
  }));

  const handleMarketChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as MarketCode;
    const marketFromValue = markets.find(
      ({ country }) => country.code === value,
    ) as Market;
    onMarketChange(marketFromValue);
  };

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as LanguageCode;
    if (onLanguageChange) {
      onLanguageChange(value, selectedMarket.country.code);
    }
  };

  const handleSubmit = () => {
    onSubmit(selectedMarket);
  };
  const header = t('header');

  return (
    <Container hasHeader={!!header}>
      <Header>
        <Ui.Typography as="h3">{header}</Ui.Typography>
        {onClose && (
          <Ui.IconButton
            icon={Close}
            title={t('close')}
            onClick={onClose}
            size="large"
          />
        )}
      </Header>
      <Element>
        <Ui.Dropdown
          id="market"
          label={t('country')}
          options={validMarkets}
          value={selectedMarket.country.code}
          onChange={handleMarketChange}
          disabled={disableMarketChange}
        />
      </Element>
      <Element>
        <Ui.Dropdown
          id="language"
          label={t('language')}
          options={validLanguages}
          value={selectedLanguage}
          onChange={handleLanguageChange}
          disabled={disableLanguageChange}
        />
      </Element>
      {redirectInfo && (
        <Element>
          <Ui.Message headline={redirectInfo.header}>
            {redirectInfo.text}
            <br />
            {redirectInfo.link && (
              <Ui.TextLink
                href={redirectInfo.link.url}
                text={redirectInfo.link.text}
              />
            )}
          </Ui.Message>
        </Element>
      )}
      {comingSoonMessage && comingSoonMessage !== '' && (
        <Element>
          <Ui.Message>{comingSoonMessage}</Ui.Message>
        </Element>
      )}
      <Button
        variant="primary"
        fullWidth
        onClick={handleSubmit}
        disabled={disableSubmit || selectedMarket.redirectOnly}
      >
        {t('close')}
      </Button>
    </Container>
  );
};

export { MarketPicker };
