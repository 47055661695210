import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { transparentize } from 'polished';

const sizes = {
  small: 16,
  medium: 24,
  large: 32,
};

const Button = styled.button<{
  size: 'small' | 'medium' | 'large';
  isSelected: boolean;
}>`
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  width: ${({ size }) => `${sizes[size]}px`};
  height: ${({ size }) => `${sizes[size]}px`};
  text-decoration: underline;
  color: inherit;
  background: none;
  fill: currentcolor;
  cursor: pointer;

  &:focus-visible {
    border-radius: 100%;
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      &::before {
        content: '';
        position: absolute;
        inset: -4px;
        border-radius: 50%;
        background-color: ${transparentize(
          0.8,
          theme.color.brand || '#FFFFFF',
        )};
      }
    `}
`;

export { Button };
