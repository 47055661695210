import {
  AssertHasContext,
  guardContext,
  useClientContext,
} from '../../Context';
import { LocaleContext } from './context';

// have to do this because type assertion functions have some stupid restrictions
const hasContext: AssertHasContext = guardContext;

const useLocale = () => {
  const context = useClientContext<LocaleContext>('locale');

  hasContext('locale', context);

  const { locale, language, market } = context.value;

  return { locale, language, market };
};

export { useLocale };
