import {
  LinkList,
  LinkListTranslations,
} from '@silvertours/common-landingpages-view';
import {
  LinkListBlock,
  PlainContentModuleContainer,
} from '@silvertours/front-entities';

export type SequentialLinkListsProps = {
  lists: Array<LinkList>;
  translations?: LinkListTranslations;
};

export const SequentialLinkLists = ({
  lists,
  translations,
}: SequentialLinkListsProps) => (
  <PlainContentModuleContainer>
    {lists.map(list => {
      if (list.links.length === 0) {
        return null;
      }

      return (
        <LinkListBlock
          content={list}
          key={list.heading?.text || list.links[0].label}
          translations={translations}
        />
      );
    })}
  </PlainContentModuleContainer>
);
