// Format checked options for use in the booking funnel
export const getOptionsParam = (checkedOptions: string[]) => {
  const queryParams: Record<string, any> = {};
  if (checkedOptions.includes('insfull')) {
    queryParams.coverage_type = 'without_excess';
  }
  if (checkedOptions.includes('fib_ab')) {
    queryParams.car_class = 'mini_economy';
  }
  if (checkedOptions.includes('fib_ad')) {
    queryParams.car_class = 'compact';
  }
  if (checkedOptions.includes('fib_al')) {
    queryParams.car_class = 'standard_elite_full_size';
  }
  if (checkedOptions.includes('fib_ae')) {
    queryParams.car_type = 'wagon_estate';
  }
  if (checkedOptions.includes('fib_af')) {
    queryParams.car_class = 'intermediate_standard';
  }
  if (checkedOptions.includes('fib_ed')) {
    queryParams.all_km_included = true;
  }
  if (checkedOptions.includes('fib_ec')) {
    queryParams.additional_driver_included = true;
  }
  if (checkedOptions.includes('fib_eb')) {
    queryParams.winterwheel = true;
  }
  if (checkedOptions.includes('fib_ee')) {
    queryParams.fair_fuel_policy = true;
  }
  const queryString = new URLSearchParams(queryParams).toString();
  return queryString;
};
