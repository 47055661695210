import { ReactNode } from 'react';

import { SubModule } from '@silvertours/common-landingpages-view';
import { Ui } from '@silvertours/front-shared';
import { Block } from './TabBodyContentBlock.styles';

export type TabBodyContentBlockRules = {
  textStyle?: Ui.RichTextStyle;
};

export type RenderTabBody<TModule> = (module: TModule) => ReactNode;

export type TabBodyContentBlockContent<TModule> = {
  currentTab: string;
  subModule: SubModule<TModule>;
  tabGroup: string;
};

export type TabBodyContentBlockProps<TModule> = {
  children: RenderTabBody<TModule>;
  content: TabBodyContentBlockContent<TModule>;
  rules?: TabBodyContentBlockRules;
};

export const TabBodyContentBlock = <TModule,>({
  children,
  content: { currentTab, subModule, tabGroup },
  rules,
}: TabBodyContentBlockProps<TModule>) => {
  const { name, position } = subModule;
  const { textStyle = Ui.RichTextStyle.Primary } = rules || {};

  return (
    <Block
      key={name}
      isHidden={currentTab !== name}
      id={`${tabGroup}-panel-${position}`}
      aria-labelledby={`${tabGroup}-tab-${position}`}
      role="tabpanel"
      tabIndex={0}
      textStyle={textStyle}
    >
      {children(subModule.module)}
    </Block>
  );
};
