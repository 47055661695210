import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { parseISO } from 'date-fns/parseISO';
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
import {
  useFormatter,
  useTranslations,
  DateTimeFormatOptions,
} from 'next-intl';

const important = (content: ReactNode) => (
  <b suppressHydrationWarning>{content}</b>
);

const timeFormatOptions: DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};
const dateTimeFormatOptions: DateTimeFormatOptions = {
  dateStyle: 'medium',
  timeStyle: 'medium',
};

const useRelativeDate = (date: string) => {
  const format = useFormatter();
  const t = useTranslations('shared.stringsLegacy.dates');

  const translationsMap = useMemo(() => {
    const dateValue = parseISO(date);
    return {
      dateToday: t.rich('dateToday', {
        important,
        date: format.dateTime(dateValue, timeFormatOptions),
      }),
      dateYesterday: t.rich('dateYesterday', {
        important,
        date: format.dateTime(dateValue, timeFormatOptions),
      }),
      dateLabel: t.rich('dateLabel', {
        important,
        date: format.dateTime(dateValue, dateTimeFormatOptions),
      }),
    };
  }, [date, format, t]);

  const [dateLabel, setDateLabel] = useState(translationsMap.dateLabel);

  // Do not render relative dates in SSR to avoid hydration errors
  useEffect(() => {
    const daysDifference = differenceInCalendarDays(parseISO(date), new Date());

    if (daysDifference === 0) {
      setDateLabel(translationsMap.dateToday);
    }
    if (daysDifference === -1) {
      setDateLabel(translationsMap.dateYesterday);
    }
  }, [date, translationsMap]);

  return dateLabel as ReactElement;
};

export { useRelativeDate };
