import styled from '@emotion/styled';
import { IconButton } from '../IconButton';

const CloseButton = styled(IconButton)`
  position: fixed;
  right: 0.75rem;
  top: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  color: ${({ theme }) => theme.color.white};
  z-index: 1;
  cursor: pointer;
`;

const ForegroundLayerWrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  left: 50%;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 11;
`;

export { CloseButton, ForegroundLayerWrapper };
