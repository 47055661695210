import { ImageProps } from './Image';
import { Image, Picture } from './PictureWithModilePixel.styles';

const PictureWithMobilePixel = (props: ImageProps) => (
  <Picture>
    <source
      srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      media="(max-width: 36rem)"
    />
    <Image {...props} />
  </Picture>
);

export { PictureWithMobilePixel };
