import styled from '@emotion/styled';
import { tint } from 'polished';
import { AdditionalInfoSeverity } from './AdditionalInfo.types';

const Wrapper = styled.aside<{
  hasCustomIcon: boolean;
  severity: AdditionalInfoSeverity;
}>`
  display: flex;
  align-items: ${({ hasCustomIcon }) => (hasCustomIcon ? 'center' : 'end')};
  gap: ${({ theme, hasCustomIcon }) =>
    hasCustomIcon ? theme.spacing[20] : theme.spacing[30]};
  padding: ${({ theme, hasCustomIcon }) =>
    hasCustomIcon ? theme.spacing[40] : `0 0.875rem 0 ${theme.spacing[20]}`};
  border: ${({ theme, severity }) =>
    `2px solid ${severity === AdditionalInfoSeverity.INFO ? 'currentcolor' : theme.color.brand}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme, severity }) =>
    severity === AdditionalInfoSeverity.INFO
      ? theme.color.info
      : theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  fill: ${({ theme, severity }) =>
    severity === AdditionalInfoSeverity.INFO
      ? 'currentcolor'
      : theme.color.brand};
  font-weight: 600;
  font-size: ${({ theme, hasCustomIcon }) =>
    hasCustomIcon ? theme.fontSize.xs.size : theme.fontSize.sm.size};
  line-height: 1.5;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const Content = styled.p<{ hasCustomIcon: boolean }>`
  margin: ${({ theme, hasCustomIcon }) =>
    hasCustomIcon ? '0' : `${theme.spacing[40]} 0`};
  align-self: center;
`;

const InfoIcon = styled.svg`
  margin: 4px 0 -4px;
  width: 100px;
  height: auto;
`;

const CircleWithSeverityColor = styled.circle<{
  severity: AdditionalInfoSeverity;
}>`
  fill: ${({ theme, severity }) =>
    tint(
      0.8,
      severity === AdditionalInfoSeverity.INFO
        ? theme.color.info!
        : theme.color.brand!,
    )};
`;

const PathWithSeverityColor = styled.path<{
  severity: AdditionalInfoSeverity;
}>`
  fill: ${({ theme, severity }) =>
    severity === AdditionalInfoSeverity.INFO
      ? theme.color.info
      : theme.color.brand};
`;

export {
  Wrapper,
  IconWrapper,
  Content,
  InfoIcon,
  CircleWithSeverityColor,
  PathWithSeverityColor,
};
