import { Link } from '@silvertours/common-landingpages-view';
import { Anchor, ArrowNextIcon, Text } from './ArrowLink.styles';

export type LinkProps = {
  link: Link;
};

export const ArrowLink = ({ link: { href, label } }: LinkProps) => (
  <Anchor href={href}>
    <Text inline textColor="current">
      {label}
    </Text>
    <ArrowNextIcon />
  </Anchor>
);
