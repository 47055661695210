import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@silvertours/front-shared';

const swirlSmall: string = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376 30">
    <path fill="#00977d" d="M-.444 7c228.5-26 285.499 33 376 0 90.501-33 0 200.001 0 200.001h-376s-228.5-174 0-200Z"/>
  </svg>
`;

const swirlLarge: string = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1442 20">
    <path fill="#00977d" d="M-100.973 106.356C-257.661 55.274-489.698-32.893 1.69 32.413 669.243 121.131 1365-94.5 1480.8 54.099c52.89 67.869 49.34 91.29 29.86 96.706V593.7h-1617.2V106.356h5.567Z"/>
  </svg>
`;

const svgUrl = (svg: string) => `data:image/svg+xml;base64,${btoa(svg)}`;

const Wrapper = styled.footer<{ linksCount: number }>`
  display: block;
  position: relative;
  max-width: 1920px;
  line-height: 1.15;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  color: ${({ theme }) => theme.color.grey01};
  background-color: ${({ theme }) => theme.color.grey05};
  background-image: url('${svgUrl(swirlSmall)}');
  background-repeat: no-repeat;
  background-position: bottom right;
  ${({ linksCount }) =>
    linksCount === 0 &&
    css`
      background-size: 100% 100px;
    `}
  ${({ linksCount }) =>
    linksCount >= 1 &&
    linksCount <= 2 &&
    css`
      background-size: 100% 260px;
    `}
  ${({ linksCount }) =>
    linksCount >= 3 &&
    linksCount <= 4 &&
    css`
      background-size: 100% 360px;
    `}
  ${({ linksCount }) =>
    linksCount >= 5 &&
    linksCount <= 6 &&
    css`
      background-size: 100% 460px;
    `}
  ${({ linksCount }) =>
    linksCount >= 7 &&
    linksCount <= 8 &&
    css`
      background-size: 100% 560px;
    `}
  ${({ linksCount }) =>
    linksCount >= 9 &&
    linksCount <= 10 &&
    css`
      background-size: 100% 560px;
    `}
  ${({ linksCount }) =>
    linksCount >= 11 &&
    css`
      background-size: 100% 660px;
    `}
  ${({ theme }) => theme.mq('md')} {
    background-size: 100% 230px;
  }
  ${({ theme }) => theme.mq('lg')} {
    background-image: url('${svgUrl(swirlLarge)}');
  }
`;

const Grid = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => theme.spacing[40]};
  padding-right: ${({ theme }) => theme.spacing[40]};
  min-width: 320px;
  max-width: 100%;
  box-sizing: border-box;
  ${({ theme }) => css`
    ${theme.mq('md')} {
      width: 46.125rem;
    }
    ${theme.mq('lg')} {
      width: 62rem;
    }
    ${theme.mq('xl')} {
      width: 77rem;
    }
  `};
`;

const BackToTop = styled(Button)`
  position: absolute;
  top: -1.25rem;
  right: ${({ theme }) => theme.spacing[40]};
  padding: 0.5rem 1.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: auto;
  font-size: inherit;
  line-height: inherit;

  span:first-child {
    display: inline-flex;
    flex-flow: row-reverse wrap;
    font-size: ${({ theme }) => theme.spacing[40]};
  }

  svg {
    width: 1.375rem;
    height: 1.375rem;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`;

const InnerGrid = styled.div`
  padding-top: 4rem;
  width: 100%;
  ${({ theme }) => theme.mq('lg')} {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: repeat(4, minmax(0, 1fr));
    grid-gap: 1rem;
  }
`;

const SocialMediaLinksCell = styled.div`
  margin-top: 1.5rem;
  ${({ theme }) => theme.mq('lg')} {
    grid-column: 1;
    grid-row: 2 / span 2;
    margin-top: 0.5rem;
  }
`;

const FooterLinksCell = styled.div`
  margin-top: 2.5rem;
  ${({ theme }) => theme.mq('lg')} {
    grid-column: 2;
    grid-row: 1 / span 3;
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
`;

const SupportCell = styled.div`
  ${({ theme }) => theme.mq('lg')} {
    grid-column: 3;
    grid-row: 1 / span 2;
    padding-bottom: 0;
  }
`;

const AppLinksCell = styled.div`
  ${({ theme }) => theme.mq('lg')} {
    grid-column: 3;
    grid-row: 3;
  }
`;

const LegalLinksCell = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.mq('lg')} {
    grid-column: 2 / span 2;
    grid-row: 4;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const Copyright = styled.p`
  margin: 0;
  padding-bottom: 1rem;
  font-size: 0.9375rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.mq('lg')} {
    grid-column: 1;
    grid-row: 4;
    margin-top: 1rem;
  }
`;

const Abbr = styled.abbr`
  &[title] {
    text-decoration: none;
  }
`;

const LogoCell = styled.div`
  ${({ theme }) => theme.mq('lg')} {
    grid-column: 1;
    grid-row: 1;
  }

  & svg {
    max-width: 100%;
    max-height: 40px;
    height: auto;
    fill: ${({ theme }) => theme.color.brand};
  }
`;

const HomeLink = styled.a`
  display: block;

  &:focus-visible {
    border-radius: ${({ theme }) => theme.borderRadius.md};
    outline: 0.125rem dashed ${({ theme }) => theme.color.error};
    outline-offset: 0.25rem;
  }
`;

export {
  Wrapper,
  BackToTop,
  Grid,
  InnerGrid,
  Copyright,
  Abbr,
  LogoCell,
  SocialMediaLinksCell,
  FooterLinksCell,
  SupportCell,
  AppLinksCell,
  LegalLinksCell,
  HomeLink,
};
