import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type Method = 'get' | 'delete' | 'post' | 'put' | 'patch';
type Payload = { [key: string]: string };

type HTTPClientProps = {
  url: string;
  method?: Method;
  payload?: Payload;
};

const useHttpClient = ({
  url,
  method = 'get',
  payload = {},
}: HTTPClientProps) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState('');
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();

  // FIXME: potentially error with Node v14
  // const controllerRef = useRef(new AbortController());
  // const cancel = () => {
  //   controllerRef.current.abort();
  // };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.request({
          data: payload,
          // signal: controllerRef.current.signal,
          method,
          url,
          headers: {
            locale: router.locale,
          },
          withCredentials: true,
        });
        setData(res.data);
      } catch (err: any) {
        setError(err?.message);
      } finally {
        setLoaded(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { data, error, loaded };
};

export { useHttpClient };
