import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled.div<{ isCollapsed: boolean }>`
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          overflow: hidden;
          height: 93.75rem;
          position: relative;
        `
      : ''}
`;
const CollapsedOverlay = styled.span<{ isCollapsed: boolean }>`
  width: 100%;
  height: ${({ theme }) => theme.spacing[110]};
  display: ${({ isCollapsed }) => (isCollapsed ? 'inline-block' : 'none')};
  bottom: 0;
  position: absolute;
  background-image: ${({ theme }) =>
    `linear-gradient(0deg,${theme.color.white}, #0000)`};
`;

export { CollapsedOverlay, Container };
