import type { ReactNode } from 'react';

import { LanguageCode } from '@silvertours/common-landingpages-view';

import * as String from '../../strings';
import { Anchor } from './PhoneNumber.styles';

type PhoneNumberProps = {
  className?: string;
  phoneNumber: string;
  language: LanguageCode;
  icon?: ReactNode;
  hidePhoneNumber?: boolean;
};

const PhoneNumber = ({
  className,
  phoneNumber,
  language,
  icon,
  hidePhoneNumber,
}: PhoneNumberProps) => {
  const pn = String.parsePhoneNumber(phoneNumber, language);
  const showAsText = !hidePhoneNumber && !!pn.national;

  return (
    <Anchor
      className={className}
      href={pn.url}
      target="_system"
      $showAsText={showAsText}
    >
      {icon}
      {showAsText && <span>{pn.national}</span>}
    </Anchor>
  );
};

export { PhoneNumber };
