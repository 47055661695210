import { forwardRef, useCallback, useState } from 'react';
import type { ChangeEvent, ComponentPropsWithoutRef, ReactNode } from 'react';
import Hide from '@engineering/icons/hide';
import Show from '@engineering/icons/show';
import Close from '@engineering/icons/close';
import { ErrorMessage } from '../ErrorMessage';

import {
  Wrapper,
  Label,
  Input as StyledInput,
  ActionButton,
} from './Input.styles';

type InputProps = ComponentPropsWithoutRef<'input'> & {
  appearActive?: boolean;
  error?: string;
  forcedLabel?: boolean;
  hiddenLabel?: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  label: string;
  cleanable?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      label,
      hiddenLabel,
      forcedLabel,
      icon,
      iconPosition = 'right',
      error,
      id,
      value,
      placeholder,
      appearActive,
      cleanable,
      disabled,
      readOnly,
      required,
      onChange,
      className,
      ...props
    },
    ref,
  ) => {
    const [showValue, setShowValue] = useState(false);
    const hasOnChange = typeof onChange === 'function';

    const [stateValue, setStateValue] = useState(value);
    const finalValue = hasOnChange ? value : stateValue;
    const isFilled = !!finalValue;

    const onInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (hasOnChange) {
          onChange(event);
        } else {
          setStateValue(event.target.value);
        }
      },
      [setStateValue, onChange, hasOnChange],
    );

    const isInvalid = !!error;
    const isCleanable = !!cleanable && !!finalValue;

    if (!label) {
      // eslint-disable-next-line no-console
      console.error(`Input ${id} requires a label prop.`);
    }

    return (
      <Wrapper className={className}>
        <Label
          htmlFor={id}
          isHidden={!!hiddenLabel}
          isFilled={isFilled || !!forcedLabel}
          isInvalid={isInvalid}
          isDisabled={disabled || readOnly}
        >
          {label}
        </Label>
        <StyledInput
          iconPosition={(!!icon && iconPosition) || undefined}
          type={showValue ? 'text' : type}
          {...props}
          placeholder={placeholder || label}
          id={id}
          ref={ref}
          value={finalValue}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          aria-invalid={isInvalid ? 'true' : undefined}
          aria-required={required ? 'true' : undefined}
          isInvalid={isInvalid}
          isFilled={isFilled}
          isActive={!!appearActive}
          isCleanable={isCleanable}
          onChange={onInputChange}
        />
        {icon}
        {type === 'password' && (
          <ActionButton
            type="button"
            data-testid={`${id}-visibility-toggle`}
            aria-label={showValue ? 'Hide' : 'Show'}
            onClick={() => {
              setShowValue(!showValue);
            }}
          >
            {showValue ? <Show /> : <Hide />}
          </ActionButton>
        )}
        {isCleanable && (
          <ActionButton
            type="button"
            data-testid={`${id}-clean-button`}
            onClick={() => {
              onInputChange({
                target: { value: '' },
              } as any);
            }}
          >
            <Close />
          </ActionButton>
        )}
        {isInvalid && <ErrorMessage>{error}</ErrorMessage>}
      </Wrapper>
    );
  },
);

export { Input };
