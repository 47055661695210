import { LandingPage } from '../../../entity';
import {
  PageClassificationStrategy,
  PageClassificationStrategyBase,
} from '../pageClassificationStrategy';
import { SlugClassificationRule } from './rule';

class SlugClassificationStrategy
  extends PageClassificationStrategyBase
  implements PageClassificationStrategy
{
  private slugs: (string | RegExp)[];

  constructor({
    includeBreadcrumbs,
    pageType,
    pillars,
    slug,
  }: SlugClassificationRule) {
    super(pageType, pillars, includeBreadcrumbs);

    this.slugs = Array.isArray(slug) ? slug : [slug];

    this.classify = this.classify.bind(this);
    this.strategyApplies = this.strategyApplies.bind(this);
  }

  classify(_page: LandingPage) {
    return this.withCommonValues({});
  }

  strategyApplies({ slug }: LandingPage): boolean {
    if (!slug || !slug.length) {
      return false;
    }

    return !!this.slugs.find(possibleSlug => {
      if (possibleSlug instanceof RegExp) {
        return possibleSlug.test(slug);
      }
      return possibleSlug.toLocaleLowerCase() === slug.toLocaleLowerCase();
    });
  }
}

export { SlugClassificationStrategy };
