import { UiLegacy } from '@silvertours/front-legacy-shared';
import { Badge, InfoIcon } from './TooltipBadge.styles';

type TooltipBadgeProps = {
  badgeText: string;
  tooltipTitle: string;
  tooltipText: string;
  onHover?: () => void;
};

const TooltipBadge = ({
  badgeText,
  tooltipTitle,
  tooltipText,
  onHover,
}: TooltipBadgeProps) => (
  <Badge>
    {badgeText}
    <UiLegacy.Tooltip
      content={<UiLegacy.Markup content={tooltipText} />}
      title={tooltipTitle}
      width="wide"
      trigger={<InfoIcon onMouseEnter={onHover} />}
    />
  </Badge>
);

export { TooltipBadge };
