import { FaqsContentModule } from '@silvertours/common-landingpages-view';
import {
  AccordionContentBlock,
  PlainContentModuleContainer,
} from '@silvertours/front-entities';

export const Faq = (props: FaqsContentModule) => {
  const { content, translations } = props;
  const accordion = content.map(({ answer, question, image }) => ({
    prompt: question,
    content: answer,
    image,
  }));

  return (
    <PlainContentModuleContainer>
      <AccordionContentBlock
        content={{ ...props, accordion }}
        rules={{
          badge: { style: 'orange' },
        }}
        translations={translations}
      />
    </PlainContentModuleContainer>
  );
};
