enum PageAttributeType {
  Airport = 'airport',
  BookingAttribute = 'bookingAttribute',
  Category = 'category',
  City = 'city',
  CityDistrict = 'cityDistrict',
  Country = 'country',
  Island = 'island',
  Partner = 'partner',
  Product = 'product',
  RailStation = 'railStation',
  State = 'state',
}

type CoreAttributeFields<
  TAttributeType extends PageAttributeType,
  TRefBody extends Record<string, unknown>,
> = {
  id: string;
  name: string;
  displayName: string;
  type: TAttributeType;
} & TRefBody;

type PageAttributeRefForType<
  TAttributeType extends PageAttributeType,
  TRefBody extends Record<string, unknown>,
  TQuery extends unknown,
> = TQuery extends undefined
  ? CoreAttributeFields<TAttributeType, TRefBody>
  : {
      query: TQuery;
    } & CoreAttributeFields<TAttributeType, TRefBody>;

type PageAttributeRefForTypeWithAncestors<
  TAttributeType extends PageAttributeType,
  TRefBody extends Record<string, unknown>,
  TQuery extends unknown = undefined,
  TAncestors extends Array<
    PageAttributeRefForType<PageAttributeType, Record<string, unknown>, unknown>
  > = [],
> = PageAttributeRefForType<TAttributeType, TRefBody, TQuery> & {
  ancestors: TAncestors;
};

export { PageAttributeType };
export type { PageAttributeRefForType, PageAttributeRefForTypeWithAncestors };
