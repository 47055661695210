import styled from '@emotion/styled';
import { Theme } from '@silvertours/front-shared';
import { IconColor, IconSize, IconSizes } from './rule';

const getSizes = (
  size: IconSize | IconSizes,
  theme: Theme.Theme,
  mq?: 'sm' | 'md' | 'lg' | 'xl',
) => {
  if (Array.isArray(size)) {
    const responsiveSizeForMedia = size.find(
      ([, _breakpoint]) => _breakpoint === mq,
    );
    if (responsiveSizeForMedia) {
      const [responsiveSize] = responsiveSizeForMedia;
      return responsiveSize === 'full' ? '100%' : theme.spacing[responsiveSize];
    }
    return null;
  }

  return size === 'full' ? '100%' : theme.spacing[size];
};

const IconWrapper = styled.span<{
  color: IconColor;
  indent: Theme.SpacingKeys;
  size: IconSize | IconSizes;
}>`
  display: inline-flex;
  fill: ${({ color, theme }) =>
    color === 'current' ? 'current' : theme.color[color]};

  & > svg {
    padding: ${({ indent, theme }) => `0 ${theme.spacing[indent]}`};
    border-radius: 0;
    width: ${({ size, theme }) => getSizes(size, theme)};
    height: ${({ size, theme }) => getSizes(size, theme)};

    ${({ theme }) => theme.mq('sm')} {
      width: ${({ size, theme }) => getSizes(size, theme, 'sm')};
      height: ${({ size, theme }) => getSizes(size, theme, 'sm')};
    }
    ${({ theme }) => theme.mq('md')} {
      width: ${({ size, theme }) => getSizes(size, theme, 'md')};
      height: ${({ size, theme }) => getSizes(size, theme, 'md')};
    }
    ${({ theme }) => theme.mq('lg')} {
      width: ${({ size, theme }) => getSizes(size, theme, 'lg')};
      height: ${({ size, theme }) => getSizes(size, theme, 'lg')};
    }
    ${({ theme }) => theme.mq('xl')} {
      width: ${({ size, theme }) => getSizes(size, theme, 'xl')};
      height: ${({ size, theme }) => getSizes(size, theme, 'xl')};
    }
  }
`;

export { IconWrapper };
