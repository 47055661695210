import styled from '@emotion/styled';

type ImageWrapperProps = {
  hasMarginRight?: boolean;
  w?: string;
  h?: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  min-width: ${({ w = '19px' }) => w};
  height: ${({ h = '19px' }) => h};
  margin-right: ${props => (props.hasMarginRight ? '0.3125rem' : 0)};
  margin-top: 0.25rem;
`;

export { ImageWrapper };
