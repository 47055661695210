import Script from 'next/script';

type GTMScriptProps = {
  id?: string;
};

/**
 * Google Tag Manager Script
 */
const GTMScript = ({ id }: GTMScriptProps) => {
  if (!id) return null;

  return (
    <Script
      defer
      id="google-tag-manager"
      dangerouslySetInnerHTML={{
        __html: `
          try {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');
          } catch (err) {
            window.newrelic && window.newrelic.noticeError(err);
          }
        `,
      }}
      strategy="afterInteractive"
    />
  );
};

/**
 * Google Tag Manager Script (noscript)
 */
const GTMNoScript = ({ id }: GTMScriptProps) => {
  if (!id) return null;

  return (
    <noscript
      /* eslint-disable react/no-danger */
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      }}
    />
  );
};

/**
 * Inline script to block the Usercentrics consent layer in GTM
 */
const blockUserCentricsScript = `
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ blockUserCentrics: true });
`;

/**
 * Inline script to set the cross-domain bridge for Usercentrics
 */
const crossDomainBridgeScript = (hostname = '') => `
  window.UC_UI_DOMAINS = window.UC_UI_DOMAINS || {
    crossDomainConsentSharingIFrame: '${hostname}/static/default/de-de/js/cross-domain-bridge.html',
  };
`;

export {
  GTMScript as Script,
  GTMNoScript as NoScript,
  blockUserCentricsScript,
  crossDomainBridgeScript,
};
