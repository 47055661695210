import { IconSize } from '../Icon';
import { SvgIcon } from '../Icon/SvgIcon';

type RatingStarProps = {
  appearence: RatingStarAppearance;
  size?: IconSize;
  className?: string;
};

export enum RatingStarAppearance {
  Full = 'full',
  Half = 'half',
  Empty = 'empty',
}

const RatingStar = ({
  appearence = RatingStarAppearance.Empty,
  ...props
}: RatingStarProps) => {
  switch (appearence) {
    case RatingStarAppearance.Empty: {
      return <SvgIcon name="starEmpty" {...props} />;
    }
    case RatingStarAppearance.Full: {
      return <SvgIcon name="starFull" {...props} />;
    }
    case RatingStarAppearance.Half: {
      return <SvgIcon name="starHalf" {...props} />;
    }
    default:
    // do nothing
  }
  return null;
};

export { RatingStar };
