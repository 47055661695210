import { useState, useEffect, useMemo, Fragment } from 'react';
import type { HTMLAttributes } from 'react';
import { Global, useTheme } from '@emotion/react';
import { DayPicker } from 'react-day-picker';
import type {
  DateRange,
  Matcher,
  Modifiers,
  PropsBase,
  PropsRange,
} from 'react-day-picker';
import { format } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import type { Locale } from 'date-fns';

import Close from '@engineering/icons/close';
import { Button, Ui } from '@silvertours/front-shared';
import { CustomMonths, CustomMonthsContext } from './CustomMonths';

import {
  GlobalStyles,
  Wrapper,
  StyledHeader,
  StyledFooter,
  Arrow,
} from './DatePicker.styles';

type Props = {
  locale: Locale;
  dayCountText?: string;
  closeText?: string;
  dateFormat: string;
  scrollToSelectedMonth: boolean;
  vertical: boolean;
  showHeader: boolean;
  showFooter: boolean;
  onDatesChange?: (dateRange: DateRange) => void;
  onClose?: () => void;
  onScrollToBottom?: () => void;
} & DateRange &
  PropsBase &
  PropsRange &
  Pick<HTMLAttributes<HTMLElement>, 'className'>;

const DatePicker = ({
  from,
  to,
  dayCountText,
  closeText,
  scrollToSelectedMonth = false,
  locale,
  dateFormat,
  vertical,
  showHeader,
  showFooter,
  className,
  onDatesChange,
  onScrollToBottom,
  onClose,
  ...props
}: Props) => {
  const theme = useTheme();

  useEffect(() => {
    if (!scrollToSelectedMonth) {
      return;
    }
    const selectedDay = document.querySelector('.rdp-day_selected');
    const selectedMonthElm =
      selectedDay?.parentElement?.parentElement?.parentElement?.parentElement
        ?.parentElement;
    if (selectedMonthElm) {
      selectedMonthElm.scrollIntoView({
        block: 'center',
      });
    }
  }, [scrollToSelectedMonth]);

  const [selectedFrom, setFrom] = useState(from || undefined);
  const [selectedTo, setTo] = useState(to || undefined);
  const [hoveredTo, setHoveredTo] = useState(undefined as undefined | Date);

  const isSelectingFirstDay = (date: Date) => {
    const isBeforeFirstDay = selectedFrom && isBefore(date, selectedFrom);
    const isRangeSelected = selectedFrom && selectedTo;
    return !selectedFrom || isBeforeFirstDay || isRangeSelected;
  };

  const getHighlightedDays = () => {
    if (selectedFrom && !selectedTo && !hoveredTo) {
      return { from: selectedFrom ?? undefined, to: selectedFrom ?? undefined };
    }

    if (selectedFrom && !selectedTo && hoveredTo) {
      return { from: selectedFrom ?? undefined, to: hoveredTo ?? undefined };
    }

    return { from: selectedFrom ?? undefined, to: selectedTo ?? undefined };
  };

  const getModifiers = () => {
    let modifiers: Record<string, Matcher | Matcher[]> = {};
    if (selectedFrom) {
      modifiers = { ...modifiers, from: [selectedFrom] };
    }
    if (hoveredTo) {
      modifiers = { ...modifiers, to: hoveredTo };
    } else if (selectedTo) {
      modifiers = { ...modifiers, to: selectedTo };
    }
    return modifiers;
  };

  const handleDayClick = (date: Date, modifiers: Modifiers) => {
    if (modifiers.disabled) {
      return;
    }

    if (isSelectingFirstDay(date)) {
      setFrom(date);
      setTo(undefined);
      setHoveredTo(undefined);
    } else {
      setTo(date);
      setHoveredTo(date);
      if (typeof onDatesChange !== 'undefined') {
        onDatesChange({ from: selectedFrom, to: date });
      }
    }
  };

  const handleDayMouseEnter = (date: Date) => {
    if ((from && isBefore(date, from)) || isSelectingFirstDay(date)) {
      return;
    }
    setHoveredTo(date);
  };

  // Workaround for `react-day-picker` `onSelect` bug:
  // Apparently `onSelect` cannot be `undefined` or `selected` does not work properly.
  // @see https://github.com/gpbl/react-day-picker/issues/2362
  const handleSelect = () => undefined;

  const context = useMemo(
    () => ({ onScrollToBottom, vertical }),
    [onScrollToBottom, vertical],
  );

  const WrapperComponent = showHeader || showFooter ? Wrapper : Fragment;
  const wrapperProps = showHeader || showFooter ? { className } : {};

  return (
    <CustomMonthsContext.Provider value={context}>
      <Global styles={GlobalStyles({ theme, vertical })} />
      <WrapperComponent {...wrapperProps}>
        {showHeader && (
          <StyledHeader>
            {from
              ? format(from, dateFormat, {
                  locale,
                })
              : '---'}
            <Arrow />
            {to
              ? format(to, dateFormat, {
                  locale,
                })
              : '---'}
            <Ui.IconButton icon={Close} title={closeText} onClick={onClose} />
          </StyledHeader>
        )}
        <DayPicker
          components={{ Months: CustomMonths }}
          disableNavigation={vertical}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          onSelect={handleSelect}
          selected={getHighlightedDays()}
          modifiers={getModifiers()}
          disabled={{ before: new Date() }}
          locale={locale}
          {...props}
          mode="range"
        />
        {showFooter && (
          <StyledFooter vertical={vertical}>
            {vertical && dayCountText ? (
              <Button size="small" fullWidth onClick={onClose}>
                {dayCountText}
              </Button>
            ) : (
              dayCountText
            )}
          </StyledFooter>
        )}
      </WrapperComponent>
    </CustomMonthsContext.Provider>
  );
};

export { DatePicker };
