import React from 'react';

import {
  ContentLayout,
  ErrorPageContent,
} from '@silvertours/common-landingpages-view';
import { BenefitsLegacy, StageLegacy } from '@silvertours/front-features';

import { Body } from './body';

const ErrorContent = ({
  content: {
    globalRating,
    statusCode,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<ErrorPageContent>) => (
  <Body
    title={primaryHeadline}
    stage={
      <StageLegacy.Stage
        statusCode={statusCode}
        content={stage.content}
        headline={primaryHeadline}
      />
    }
    content={({ containerModule }) => [
      containerModule(
        'usp',
        <BenefitsLegacy.Usp rating={globalRating.content} />,
      ),
    ]}
    {...commonProps}
  />
);

export { ErrorContent };
