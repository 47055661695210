import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

const IconButton = styled(Ui.IconButton)`
  fill: ${({ theme }) => theme.color.brand};
  display: block;
  flex: none;
`;

const StyledDialog = styled.div<{ show: boolean; topPosition: number }>`
  position: fixed;
  inset: 0;
  top: ${({ topPosition }) => topPosition}px;
  z-index: 20;

  ${({ show }) => css`
    visibility: ${show ? 'visible' : 'hidden'};
    transition: visibility 0.3s;
  `}
`;

const StyledDialogPanel = styled.div<{ show: boolean }>`
  display: flex;
  align-items: start;
  overflow: hidden;
  background-color: white;

  ${({ show }) => css`
    max-height: ${show ? '100%' : 0};
    transition: max-height 0.15s ${show ? 'ease-in' : 'ease-out'};
  `}
`;

const BackgroundOverlay = styled.div<{ show: boolean }>`
  background-color: ${({ theme }) => theme.color.text};
  position: relative;
  height: 100%;
  inset: 0;
  ${({ show }) => css`
    opacity: ${show ? 0.7 : 0};
    transition: opacity 0.3s ${show ? 'ease-in' : 'ease-out'};
  `}
`;

const PanelContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
  max-height: 100%;
  overflow-y: auto;
`;

const GridContainer = styled.div`
  display: grid;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  padding-top: ${({ theme }) => theme.spacing[50]};
  flex: 1;
`;

const CloseButton = styled(Ui.IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing[40]};
  right: ${({ theme }) => theme.spacing[40]};
  margin-right: ${({ theme }) => theme.spacing[20]};
`;

export {
  BackgroundOverlay,
  CloseButton,
  FlexContainer,
  GridContainer,
  IconButton,
  StyledContent,
  StyledDialog,
  StyledDialogPanel,
  PanelContainer,
};
