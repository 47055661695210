import { AlternativePath } from '../path';
import { HasImage, Image } from './image';
import { ContentSegments, TextImageSegment } from './segments';
import { InfoBanner } from './infoBanner';
import { RichText } from '@silvertours/back-data-contentful';
import { ImageWithFocalPoint } from './imageWithFocalPoint';

export enum StatusCode {
  Ok = 200,
  NoContent = 204,
  PermanentRedirect = 301,
  TemporaryRedirect = 302,
  NotFound = 404,
  Gone = 410,
}

export type PageStatusCode =
  | StatusCode.Ok
  | StatusCode.NoContent
  | StatusCode.PermanentRedirect
  | StatusCode.TemporaryRedirect
  | StatusCode.NotFound
  | StatusCode.Gone;

type PageContentForStatus<TStatusCode extends PageStatusCode, TContent = {}> = {
  landingUrl: string;
  statusCode: TStatusCode;
} & TContent;

export type GoneContent = PageContentForStatus<StatusCode.Gone>;

export type NotFoundContent = PageContentForStatus<StatusCode.NotFound>;

type NoContentContent = PageContentForStatus<StatusCode.NoContent>;

export type RedirectContent = PageContentForStatus<
  StatusCode.PermanentRedirect | StatusCode.TemporaryRedirect,
  {
    redirectUrl: string;
  }
>;

export type Icon = {
  key: string;
  label: string;
};

export type IntroContent = {
  body?: string;
  title?: string;
};

export type BanderoleType = 'green-gradient' | 'orange-gradient';

export type Banderole = {
  content?: RichText;
  text: string;
  style: BanderoleType;
};

export type HeadlineContent = {
  primary: string;
};

export type MetaContent = {
  displayName?: string;
  title: string;
  description?: string;
  canonicalUrl?: string;
  robots: string;
  keywords?: string;
};

export type HasMeta = { meta: MetaContent };

export type StageContent = {
  banderole?: Banderole;
  image?: Image;
  headline: string;
  includeSearch: boolean;
  pageTitle: string;
  partners?: Array<HasImage>;
  imageWithFocalPoint?: ImageWithFocalPoint;
};

export type BodyContent = {
  segments: ContentSegments;
};

export type HasBody = {
  body: BodyContent;
};

export type HasStage = {
  stage: StageContent;
};

export type CanHaveInfoBanner = {
  infoBanner: InfoBanner | null;
};

export type BmOkContent = CanHaveInfoBanner &
  HasBody &
  HasMeta & {
    alternativePaths: Array<AlternativePath>;
    introModule: TextImageSegment | null;
    mainKeyword: string;
    headlines: HeadlineContent;
    stage?: StageContent;
  };

export type OkContent = PageContentForStatus<StatusCode.Ok, BmOkContent>;

export type PageContent =
  | GoneContent
  | NotFoundContent
  | RedirectContent
  | NoContentContent
  | OkContent;
