import styled from '@emotion/styled';

const CardWrapper = styled.article`
  flex-shrink: 0;
  border: ${({ theme }) => `1px solid ${theme.color.grey04}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.boxShadow.md};
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
`;

export { CardWrapper };
