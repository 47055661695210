import * as browser from './base64-browser';
import * as server from './base64-server';

const nextBase64 = typeof window === 'undefined' ? server : browser;

const decodeBase64 = nextBase64.decode;
const encodeBase64 = nextBase64.encode;

interface JwtPayload {
  exp?: number;
  user_id?: string;
}

export interface IdTokenPayload {
  email: string;
  gender: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  address: {
    formatted: string;
  };
  birthdate: string;
  locale: 'de_DE' | 'fr_FR' | 'es_ES';
  user_id: string;
}

const decodeToken = (token?: string): JwtPayload | null => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    return null;
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decoded = decodeBase64(base64);
  try {
    return JSON.parse(decoded);
  } catch (err) {
    return null;
  }
};

const validToken = (token?: string): boolean => {
  if (!token) {
    return false;
  }
  const exp = decodeToken(token)?.exp;
  return exp ? exp * 1000 > Date.now() : false;
};

export { decodeBase64, encodeBase64, decodeToken, validToken };
