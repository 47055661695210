import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useAffiliate = () => {
  const router = useRouter();

  /**
   * Check for TradeDoubler affiliate params in url
   * see https://dev.tradedoubler.com/tracking/advertiser/#Pixel_Redirect
   */
  const redirectToTradedoubler = useCallback(
    (bookingUrl: string) => {
      const url = router.query.tdClick as string;

      // Example for tradeDoublerUrl: https://clk.tradedoubler.com/click?p=301944&a=3146932&g=24832700
      if (
        !url ||
        !url.match(
          /^https:\/\/clk\.tradedoubler\.com\/click\?p=\d+&a=\d+&g=\d+/,
        )
      ) {
        return null;
      }

      const queryString = `&url=${encodeURIComponent(bookingUrl)}`;
      const redirectUrl = url + queryString;
      return redirectUrl;
    },
    [router.query],
  );

  return { redirectToTradedoubler };
};
