import { useTranslations } from 'next-intl';
import {
  ContentLayout,
  RentalCompanyPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  OffersModule,
  SerpOffersModule,
  StageLegacy,
} from '@silvertours/front-features';

import { Body } from '../body';

const RentalCompanyContent = ({
  content: {
    article,
    introduction,
    links,
    offersByCategory,
    globalRating,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<RentalCompanyPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);
  const offersTranslations = useTranslations('features.offers');

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage content={stage.content} headline={primaryHeadline} />
      }
      content={({ container, containerModule, contentModule }) => [
        /** @todo:PAGES-780 change for locale and company specific behaviour */
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
        containerModule(
          'introduction-content',
          <ContentArticle
            modules={introduction?.segments ?? []}
            translations={translations}
          />,
          !!introduction?.segments,
        ),
        contentModule(
          'serpOffers',
          !!offersByCategory.content && (
            <SerpOffersModule
              content={offersByCategory.content}
              translations={{
                airConditioning: offersTranslations('car.airConditioning'),
                carTypes: t.raw('offers.car.type' as any),
                prevText: offersTranslations('prevText'),
                nextText: offersTranslations('nextText'),
                searchButtonText: offersTranslations('searchButtonText'),
                disclaimertext: offersTranslations('disclaimerSerpOffers'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                serpTitle: offersTranslations('serp.title'),
                transmissionAutomatic: offersTranslations(
                  'car.transmissionAutomatic',
                ),
                transmissionManual: offersTranslations(
                  'car.transmissionManual',
                ),
              }}
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        container(
          'offers',
          [
            contentModule(
              'offers',
              !!offersByCategory.content && (
                <OffersModule
                  content={offersByCategory.content}
                  translations={{
                    badgeText: offersTranslations('badge'),
                    headline: offersTranslations('headline'),
                    disclaimer: offersTranslations('disclaimer'),
                    disclaimerOverlayCTA: offersTranslations(
                      'disclaimerOverlayCTA',
                    ),
                    durationFrom: offersTranslations('durationFrom'),
                    pricePrefix: offersTranslations('pricePrefix'),
                    priceSuffix: offersTranslations('priceSuffix'),
                    searchButtonText: offersTranslations('searchButtonText'),
                    offerDetailsTitle: offersTranslations('offerDetailsTitle'),
                    carTypes: t.raw('offers.car.type' as any),
                  }}
                />
              ),
            ),
          ],
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule(
          'article',
          <ContentArticle
            modules={article.segments}
            translations={translations}
          />,
        ),
        contentModule(
          'linkLists',
          <ContentArticle
            modules={links.content ?? []}
            translations={translations}
          />,
          !!links.content.length,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { RentalCompanyContent };
