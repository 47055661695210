import React from 'react';

import { LayoutLegacy } from '@silvertours/front-legacy-entities';

import { contentBuilders, ContentModulesRenderer } from './content';
import {
  ColumnsModule,
  ContainerModule,
  ContentModule,
  ModuleType,
} from './module';

type ContentModulesProps = {
  content: ContentModulesRenderer;
};

const ContentModules = ({ content }: ContentModulesProps) => {
  // @todo: validate unique keys
  const pageContent = content(contentBuilders);

  return (
    <LayoutLegacy.PlainSegment id="content-modules" hasPadding spacingScale={4}>
      <LayoutLegacy.ContentContainer>
        {pageContent.map(part => {
          switch (part.type) {
            case ModuleType.Columns:
              return <ColumnsModule {...part} id={part.key} key={part.key} />;
            case ModuleType.Container:
              return <ContainerModule {...part} id={part.key} key={part.key} />;
            case ModuleType.ContentModule:
              return <ContentModule {...part} key={part.key} />;
            default:
              return null;
          }
        })}
      </LayoutLegacy.ContentContainer>
    </LayoutLegacy.PlainSegment>
  );
};

export { ContentModules };
export type { ContentModulesProps };
