export enum MessageSeverity {
  TIP = 'tip',
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
}

/** ARIA live region role */
export enum MessageRole {
  STATUS = 'status',
  ALERT = 'alert',
}
