import React, { SVGProps, ComponentType } from 'react';
import Airport from '@engineering/icons/airport';
import CityStation from '@engineering/icons/city-station';
import TrainStation from '@engineering/icons/train-station';

import {
  SearchDestinationType,
  SearchSuggestion,
} from '@silvertours/front-entities';

import { IconWrapper, Price } from './LocationSuggest.styles';

const icons: {
  [K in SearchDestinationType]?: ComponentType<SVGProps<SVGSVGElement>>;
} = {
  city: CityStation,
  citywithoutrail: CityStation,
  multiairports: Airport,
  multiairport_single: Airport,
  airport: Airport,
  multirail: TrainStation,
  rail_only: TrainStation,
  multirail_single: TrainStation,
};

export const SearchResultItem = ({
  suggestion,
}: {
  suggestion: SearchSuggestion;
}) => {
  const Icon = icons[suggestion.type];

  return (
    <>
      <IconWrapper aria-hidden="true">
        {Icon && <Icon width="24" height="24" />}
      </IconWrapper>
      <span>{suggestion.title ?? ''}</span>
      {suggestion.minPrice && <Price>{suggestion.minPrice}</Price>}
    </>
  );
};
