import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

const List = styled.ul`
  padding: 0 ${({ theme }) => theme.spacing[50]};
  width: 100%;

  ${({ theme }) => theme.mq('lg')} {
    width: 238px;
  }

  * {
    font-family: ${({ theme }) => theme.fontFamily.alternate};
  }
`;

const NavigationItem = styled.li`
  padding: ${({ theme }) => theme.spacing[40]} 0;
  color: ${({ theme }) => theme.color.black};
  text-overflow: ellipsis;
  overflow-x: hidden;
  ${({ theme }) => theme.mq('lg')} {
    display: block;
  }
`;

const StyledLink = styled(Ui.TextLink)`
  ${({ theme }) => theme.mq('lg')} {
    display: block;
  }
`;

export { List, NavigationItem, StyledLink };
