import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

// ToDo: use theme variables to ensure a unified design
const Wrapper = styled.div<{ isIntersecting: boolean }>`
  position: ${({ isIntersecting }) => (isIntersecting ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.color.white};
  font-size: 1.0625rem;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5rem;

  &::after {
    content: '';
    display: none;
    width: 100%;
    height: 0.5em;
  }

  a:link {
    color: ${({ theme }) => theme.color.white};
    font-weight: 700;
  }

  a:visited {
    color: ${({ theme }) => theme.color.white};
  }

  &.important {
    background-image: ${({ theme }) =>
      theme.linearGradient(theme.gradient.orange, 'to left')};
  }

  &.less-important {
    background-color: #c8c8c8;
    color: ${({ theme }) => theme.color.black};

    a:link {
      color: ${({ theme }) => theme.color.black};
    }

    a:visited {
      color: ${({ theme }) => theme.color.black};
    }

    .close {
      fill: ${({ theme }) => theme.color.black};
    }
  }

  &.regular-black {
    background-color: ${({ theme }) => theme.color.black};
  }

  &.regular-green {
    background-image: ${({ theme }) =>
      `linear-gradient(to right,  ${theme.color.primaryHighlight}, ${theme.color.brand})`};
  }

  .close {
    position: absolute;
    right: ${({ theme }) => theme.spacing[40]};
    top: ${({ theme }) => theme.spacing[20]};
    background-color: transparent;
    width: ${({ theme }) => theme.spacing[50]};
    border: none;
    fill: ${({ theme }) => theme.color.white};
    cursor: pointer;
  }

  ${({ theme }) => theme.mq('md')} {
    justify-content: center;
  }
`;

const CloseButton = styled(Ui.IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing[40]};
  top: ${({ theme }) => theme.spacing[20]};
  color: ${({ theme }) => theme.color.white};
`;

export { Wrapper, CloseButton };
