import {
  LinkList,
  LinkListTranslations,
} from '@silvertours/common-landingpages-view';
import { GridLinkList } from './GridLinkList';
import { PlainLinkList } from './PlainLinkList';

export type LinkListBlockContent = LinkList;

export type LinkListBlockProps = {
  content: LinkListBlockContent;
  translations?: LinkListTranslations;
};

export const LinkListBlock = ({
  content,
  translations,
}: LinkListBlockProps) => {
  const { groupType } = content;

  if (groupType === 'grid') {
    return <GridLinkList content={content} translations={translations} />;
  }

  return <PlainLinkList content={content} translations={translations} />;
};
