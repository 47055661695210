import React from 'react';
import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';
import { StaticLink } from '@silvertours/back-domain-page';

const List = styled.ul`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;

  ${({ theme }) => theme.mq('md')} {
    gap: 0.75rem;
    justify-content: start;
  }
  ${({ theme }) => theme.mq('lg')} {
    justify-content: end;
  }

  svg {
    height: 42px;
  }
`;

type Props = {
  links: StaticLink[];
};

const AppLinks = ({ links }: Props) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <List>
      {links.map(({ text, href, target, masked, title }) => {
        const isAndroid = text === 'Android';
        const Pictogram = isAndroid ? Ui.PlayStoreLink : Ui.AppStoreLink;
        return (
          <li key={href}>
            <Pictogram
              title={title}
              href={href}
              target={target}
              rel="noopener noreferrer"
              masked={masked}
            />
          </li>
        );
      })}
    </List>
  );
};

export { AppLinks };
