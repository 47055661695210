import { LinkList } from '@silvertours/common-landingpages-view';
import { PlainContentModuleContainer } from '@silvertours/front-entities';

import { Tabs } from '../../tabs';

export type TabbedLinkListsProps = {
  lists: Array<LinkList>;
};

export const TabbedLinkLists = ({ lists }: TabbedLinkListsProps) => (
  <PlainContentModuleContainer>
    <Tabs
      {...{
        moduleType: 'tabs',
        modules: lists.map((list, index) => ({
          module: {
            moduleType: 'linkList',
            ...list,
          },
          name: list.heading?.text || '',
          position: index,
        })),
      }}
    />
  </PlainContentModuleContainer>
);
