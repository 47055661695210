import type { LabelHTMLAttributes } from 'react';
import { Label } from './ErrorMessage.styles';

type Props = LabelHTMLAttributes<HTMLLabelElement>;

const ErrorMessage = ({ children, ...props }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <Label {...props} role="alert" aria-live="polite">
      {children}
    </Label>
  );
};

export { ErrorMessage };
