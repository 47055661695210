import styled from '@emotion/styled';
import { darken } from 'polished';
import { css } from '@emotion/react';
import { mq } from '../../Theme';
import { IconButton } from '../IconButton';

type AnimationProps = {
  showing: boolean;
  position: 'top' | 'bottom' | 'left' | 'right';
};

const HUIPopoverWrapper = styled.div<AnimationProps>`
  position: relative;
  z-index: 20;
  width: 100%;

  &:hover {
    /* make sure other popovers are beneath the current one */
    z-index: 30;
  }

  & > div[data-headlessui-state] {
    /* make sure the content box does not get larger then expected */
    display: flex;
  }

  /** @TODO: Add transition using https://headlessui.com/react/transition */
`;

const PanelWrapper = styled.div`
  margin: 1.5rem;
  border-color: ${({ theme }) => darken(0.01, theme.color.grey05!)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border-width: 3px;
  border-style: solid;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow:
    0 0 rgb(0 0 0 / 0%),
    0 0 rgb(0 0 0 / 0%),
    ${({ theme }) => theme.boxShadow.lg};
`;

type PanelContentWrapperProps = {
  width: string;
  widthMd?: string;
  padding?: string;
};

const PanelContentWrapper = styled.div<PanelContentWrapperProps>`
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.color.white};
  width: ${({ width }) => width && width};
  padding: ${({ padding }) => padding && padding};

  ${mq('sm')} {
    width: ${({ widthMd }) => widthMd && widthMd};
  }
`;

type ArrowProps = {
  position: 'top' | 'bottom' | 'left' | 'right';
};

const ArrowPositioner = styled.div<ArrowProps>`
  ${({ position }) => {
    if (position === 'top') {
      return css`
        bottom: 15px;
      `;
    }
    if (position === 'bottom') {
      return css`
        top: 15px;
      `;
    }
    if (position === 'left') {
      return css`
        right: 15px;
      `;
    }
    if (position === 'right') {
      return css`
        left: 15px;
      `;
    }
    return '';
  }}
`;

const Arrow = styled.div<ArrowProps>`
  height: 1.5rem;
  width: 1.5rem;
  border-width: 3px;
  border-top-style: solid;
  border-left-style: solid;
  border-color: ${({ theme }) => darken(0.01, theme.color.grey05!)};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow:
    0 0 rgb(0 0 0 / 0%),
    0 0 rgb(0 0 0 / 0%),
    0 6px 20px rgb(0 0 0 / 6%);
  clip-path: polygon(125% 0, 0 0, 0 125%);

  ${({ position }) => {
    if (position === 'top') {
      return css`
        transform: rotate(225deg);
      `;
    }
    if (position === 'left') {
      return css`
        transform: rotate(135deg);
      `;
    }
    if (position === 'right') {
      return css`
        transform: rotate(315deg);
      `;
    }
    if (position === 'bottom') {
      return css`
        transform: rotate(45deg);
      `;
    }
    return '';
  }}
`;

const CloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.color.black};
  position: absolute;
  z-index: 30;

  /* calculate 2x parent margin */
  right: ${({ theme }) => `calc(2*${theme.spacing[50]})`};
  top: ${({ theme }) => `calc(2*${theme.spacing[50]})`};
`;

export {
  Arrow,
  ArrowPositioner,
  CloseButton,
  HUIPopoverWrapper,
  PanelWrapper,
  PanelContentWrapper,
};
