import { useTranslations } from 'next-intl';
import { Heading2 } from '@silvertours/front-shared';
import { HostLegacy } from '@silvertours/front-legacy-entities';

import { SuccessContainer } from './NewsletterRegistration.styles';

type NewsletterSignupSuccessProps = {
  showTitle?: boolean;
};

const NewsletterSignupSuccess = ({
  showTitle,
}: NewsletterSignupSuccessProps) => {
  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );
  const { formatImage } = HostLegacy.useHost();

  return (
    <>
      {showTitle && <Heading2>{t('newsletterSuccessTitle')}</Heading2>}
      <SuccessContainer>
        <img
          src={formatImage('/bm/global/graphic/image/flexsite/nl_success.svg')}
          alt=""
          width={115}
          height={132}
        />
        {t('newsletterSuccessText')}
      </SuccessContainer>
    </>
  );
};

export { NewsletterSignupSuccess };
