export const LOCATION_TYPES = {
  airport: 'airport',
  all: 'all',
  city: 'city',
  citywithoutrail: 'citywithoutrail',
  multiairport_single: 'multiairport_single',
  multiairports: 'multiairports',
  multirail_single: 'multirail_single',
  normal: 'normal',
  multirail: 'multirail',
  rail_only: 'rail_only',
  rail: 'rail',
};

export type SearchDestinationType = keyof typeof LOCATION_TYPES;

export type SearchSuggestion = Readonly<{
  bmCode: string;
  cityName: string;
  countryName: string;
  id: string;
  image_path: string;
  latitude: string;
  longitude: string;
  minPrice: string;
  objectID: string;
  stateName: string;
  title: string;
  type: SearchDestinationType;
  typeDisplay: string;
  airportCode: string;
  railCode: string;
}>;

export type RentalLocation = Readonly<{
  name: string;
  cityCode?: string;
  cityName?: string;
  countryCode?: string;
  iataCode?: string;
  railCode?: string;
  type: SearchDestinationType;
}>;

export const getDisplayName = (suggestion: SearchSuggestion) =>
  `${suggestion.cityName}${
    suggestion.typeDisplay ? ` ${suggestion.typeDisplay}` : ''
  }`;

export function getLocation(suggestion: SearchSuggestion): RentalLocation {
  const name = getDisplayName(suggestion);
  const baseLocation = {
    name,
    cityCode: suggestion.bmCode,
    cityName: suggestion.cityName,
  };
  switch (suggestion.type) {
    case LOCATION_TYPES.multiairport_single:
    case LOCATION_TYPES.airport:
    case LOCATION_TYPES.multiairports:
      return {
        ...baseLocation,
        type: 'airport',
        iataCode: String(suggestion.airportCode),
      };
    case LOCATION_TYPES.city:
    case LOCATION_TYPES.citywithoutrail:
      return {
        ...baseLocation,
        type: 'city',
      };
    case LOCATION_TYPES.multirail:
    case LOCATION_TYPES.rail_only:
    case LOCATION_TYPES.multirail_single:
      return {
        ...baseLocation,
        type: 'rail',
        railCode: String(suggestion.railCode),
      };
    default:
      return {
        ...baseLocation,
        type: 'all',
      };
  }
}

export interface SuggestProviderInterface {
  suggestClient: any;
  sendRequest(searchString: string): Promise<Array<SearchSuggestion> | null>;
}
