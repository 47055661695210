import styled from '@emotion/styled';
import { RichTextStyle } from '../RichText';

const Section = styled.section<{
  isHidden: boolean;
  textStyle: RichTextStyle;
}>`
  padding-top: ${({ theme }) => theme.spacing[60]};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  color: ${({ textStyle, theme }) =>
    textStyle === RichTextStyle.Alternative
      ? theme.color.white
      : theme.color.text};
`;

export { Section };
