import Icons from '@engineering/icons';
import CarTypes from '@engineering/icons/car-types';
import { Icon as IconData } from '@silvertours/common-landingpages-view';
import { Type } from '@silvertours/front-shared';

import { IconWrapper } from './Icon.styles';
import { IconRules, IconType } from './rule/Icon';

export type IconProps = {
  content?: IconData;
  rules?: IconRules;
};

const isCarPictogram = (
  iconKey: Type.CarTypeIcon | Type.Icon,
): iconKey is Type.CarTypeIcon => Object.keys(CarTypes).includes(iconKey);

export const Icon = ({ content, rules }: IconProps) => {
  if (!content) {
    return null;
  }

  const { color = 'current', indent = 0, size = 50 } = rules || {};
  const icon = content.key as IconType;
  const IconComponent = isCarPictogram(icon) ? CarTypes[icon] : Icons[icon];

  return (
    <IconWrapper color={color} size={size} indent={indent}>
      <IconComponent />
    </IconWrapper>
  );
};
