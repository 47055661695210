import { TextProps } from '../textUtils';
import {
  InteractiveInlineWrapper,
  InteractiveWrapper,
} from './InteractiveText.styles';

type InteractiveTextProps = {
  size?: 'normal' | 'small' | 'xs';
  propertyName?: string;
  id?: string;
} & TextProps;

const InteractiveText: React.FC<
  React.PropsWithChildren<InteractiveTextProps>
> = ({
  children,
  className,
  id,
  textColor,
  size = 'normal',
  inline,
  propertyName,
}) => {
  const Wrapper = inline ? InteractiveInlineWrapper : InteractiveWrapper;
  return (
    <Wrapper
      id={id}
      itemProp={propertyName}
      className={className}
      size={size}
      textColor={textColor}
    >
      {children}
    </Wrapper>
  );
};

export { InteractiveText };
