import styled from '@emotion/styled';
import BestPriceGuarantee from '@engineering/icons/details/best-price-guarantee';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 360px;
  right: 50px;
`;

export const Seal = styled(BestPriceGuarantee)`
  width: 7.5rem;
  height: 7.5rem;
  cursor: pointer;
`;
