import React from 'react';
import { String, Subtitle } from '@silvertours/front-shared';
import { Runtime } from '@silvertours/front-entities';

import {
  Container,
  ImageWrapper,
  Image,
  PricePrefixTextWrapper,
  ProviderName,
  ProviderOffer,
  ProviderOffersList,
  SearchButton,
  SubtitleWrapper,
} from './OfferBox.styles';

export type Offer = {
  supplier: string;
  price: number;
  currency: string;
};

export type OffersColor = 'green' | 'mint' | 'orange';

type OfferProps = {
  offers: Offer[];
  category: string;
  image: string;
  translations: {
    pricePrefix: string;
    priceSuffix: string;
    searchButtonText: string;
  };
  onClick?: () => void;
};

const OfferBox = ({
  category,
  image,
  offers,
  translations: { pricePrefix, priceSuffix, searchButtonText },
  onClick,
}: OfferProps) => {
  const { language } = Runtime.useLocale();
  const { baseImageUrl } = Runtime.useHost();
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    if (onClick && !loading) {
      setLoading(true);
      onClick();
    }
  };

  return (
    <Container onClick={handleClick} hasAction={!!onClick}>
      <ImageWrapper>
        <Image src={`${baseImageUrl}${image}`} alt="" fill />
      </ImageWrapper>
      <SubtitleWrapper as="h3">{category}</SubtitleWrapper>
      <ProviderOffersList>
        {offers.map(({ supplier, price, currency }) => (
          <ProviderOffer key={supplier}>
            <ProviderName inline>{supplier}</ProviderName>
            <PricePrefixTextWrapper inline textColor="grey">
              {pricePrefix}
              <Subtitle as="span" textColor="orange">
                {String.formatPrice(price, currency, language, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Subtitle>
              {priceSuffix}
            </PricePrefixTextWrapper>
          </ProviderOffer>
        ))}
      </ProviderOffersList>
      {onClick && (
        <SearchButton
          variant="primary"
          onClick={handleClick}
          fullWidth
          animateLoading
          loading={loading}
        >
          {searchButtonText}
        </SearchButton>
      )}
    </Container>
  );
};

export { OfferBox };
