import React from 'react';

import { ContainerPageModule, ContainerModule } from '../ContainerModule';
import { ContentModule, ContentModulePageModule } from '../ContentModule';
import { ModuleType } from '../ModuleType';

type ColumnContentModule = ContainerPageModule | ContentModulePageModule;

type ColumnContentProps = {
  content: ColumnContentModule[];
};

const ColumnContent = ({ content }: ColumnContentProps) => (
  <>
    {content.map(part => {
      switch (part.type) {
        case ModuleType.Container:
          return <ContainerModule {...part} />;
        case ModuleType.ContentModule:
          return <ContentModule {...part} />;
        default:
          return null;
      }
    })}
  </>
);

export { ColumnContent };
export type { ColumnContentModule, ColumnContentProps };
