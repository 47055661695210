import axios, { AxiosInstance } from 'axios';

const BASE_URL = 'https://api.billiger-mietwagen.de/v1';

export abstract class LegacyHTTPService {
  protected instance: AxiosInstance;

  public constructor() {
    this.instance = axios.create({
      baseURL: BASE_URL,
      timeout: 8000,
    });
  }
}
