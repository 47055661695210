import { HTMLAttributes, useId, useState } from 'react';
import type { ReactNode } from 'react';
import { TabButton } from './TabButton';
import { Section } from './Tabs.styles';
import { RichTextStyle } from '../RichText';
import { TabNavigation } from './TabNavigation';

export type Tab = {
  content: ReactNode;
  name: string;
};

export type TabsProps = {
  textStyle?: RichTextStyle;
  tabs: Tab[];
} & Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

const Tabs = ({ textStyle = RichTextStyle.Primary, tabs }: TabsProps) => {
  const [current, setCurrent] = useState(tabs[0].name);
  const tabGroup = `tabGroup-${useId()}`;

  return (
    <>
      <TabNavigation>
        {tabs.map(({ name }, tabIdx) => (
          <TabButton
            key={`${tabGroup}-tab-${name}`}
            behavior={{ onSelectTab: setCurrent }}
            content={{ name, position: tabIdx, tabGroup }}
            rules={{ activeTabName: current, textStyle }}
          />
        ))}
      </TabNavigation>

      {tabs.map(({ name, content }, tabIdx) => (
        <Section
          key={`${tabGroup}-panel-${name}`}
          id={`${tabGroup}-panel-${tabIdx}`}
          isHidden={current !== name}
          aria-labelledby={`${tabGroup}-tab-${tabIdx}`}
          role="tabpanel"
          tabIndex={0}
          textStyle={textStyle}
        >
          {content}
        </Section>
      ))}
    </>
  );
};

export { Tabs };
