import styled from '@emotion/styled';

const AccordionWrapper = styled.div`
  margin-top: 2rem;

  ${({ theme }) => theme.mq('lg')} {
    margin-top: 2.5rem;
  }
`;

export { AccordionWrapper };
