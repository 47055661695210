import styled from '@emotion/styled';

type AffiliateProps = {
  isAffiliate?: boolean;
};

const HeaderContainer = styled.header`
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  box-sizing: border-box;
  height: 3rem;
  margin-bottom: -3px;

  ${({ theme }) => theme.mq('sm')} {
    height: 4.375rem;
    margin: 0;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    display: block;
    flex: 0 0 auto;
    width: auto;
    height: 27px;
    ${({ theme }) => theme.mq('sm')} {
      height: 49px;
    }
  }

  /* hack to reduce the viewBox size on small screens for the stacked BM logo */
  html[lang='de'] & {
    width: 155px !important;
    ${({ theme }) => theme.mq('sm')} {
      width: 265px !important;
    }
    ${({ theme }) => theme.mq('lg')} {
      width: auto !important;
    }
  }

  .logo-bm-part-1 {
    transform: translate(9px, 0.6px) scale(0.83);
  }

  .logo-bm-part-2 {
    transform: translate(-174.2px, 33.2px) scale(0.83);
  }

  .logo-cg-title {
    transform: translate(0, 20px);
  }

  .logo-claim {
    display: none;
  }

  ${({ theme }) => theme.mq('lg')} {
    .logo-bm-part-1,
    .logo-bm-part-2,
    .logo-cg-title {
      /* translateZ(0) fixes an issue with iOS Safari <= 14 when rotating the device */
      transform: translateZ(0);
    }

    .logo-claim {
      display: block;
    }
  }
`;

const LayoutContainer = styled.div<AffiliateProps>`
  width: 100%;
  background-color: ${({ theme, isAffiliate }) =>
    isAffiliate ? theme.color.white : theme.color.grey04};
`;

const PageBaseContainer = styled.div<AffiliateProps>`
  position: relative;
  min-width: 20rem;

  ${({ theme }) => theme.mq('lg')} {
    min-height: ${({ isAffiliate }) => (isAffiliate ? '620px' : 'auto')};
  }

  ${({ theme }) => theme.mq('xl')} {
    margin: 0 auto;
    max-width: 1920px;
    overflow-x: hidden;
  }
`;

const PageContentContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
`;

export {
  HeaderContainer,
  HeaderLogoWrapper,
  LayoutContainer,
  PageBaseContainer,
  PageContentContainer,
};
