import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { transparentize } from 'polished';
import { IconButton } from '../IconButton';
import type { OverlaySize, OverlayTitlePosition } from './types';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1002;
  background-color: ${({ theme }) => transparentize(0.3, theme.color.text!)};
  cursor: pointer;
  animation: ${fadeIn} 0.3s ease-out;
`;

const Wrapper = styled.div<{ $width: OverlaySize; $centered: boolean }>`
  position: fixed;
  inset: ${({ theme, $centered }) =>
    $centered ? `${theme.spacing[0]} ${theme.spacing[40]}` : theme.spacing[0]};
  z-index: 1002;
  display: flex;
  align-items: ${({ $centered }) => ($centered ? 'center' : 'flex-start')};
  justify-content: center;
  box-sizing: border-box;

  ${({ theme }) => theme.mq('sm')} {
    inset-inline: ${({ theme, $width, $centered }) =>
      $width !== 'full' && !$centered ? theme.spacing[40] : ''};
    align-items: center;

    & > div {
      width: 100%;
      max-width: ${({ $width }) => {
        switch ($width) {
          case 'normal':
            return '455px';
          case 'wide':
            return '810px';
          case 'full':
          default:
            return '';
        }
      }};
    }
  }
`;

const Content = styled.div<{ $fullViewport: boolean; $rounded: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1003;
  max-height: 100vmax;
  max-height: 100dvh;
  overflow: hidden;
  border-radius: ${({ theme, $rounded }) =>
    $rounded ? theme.borderRadius.md : ''};
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  box-sizing: border-box;

  ${({ theme, $fullViewport }) =>
    $fullViewport
      ? css`
          height: 100dvh;
          width: 100vw;
        `
      : css`
          ${theme.mq('sm')} {
            border: 1px solid ${theme.color.grey03};
            border-radius: ${theme.borderRadius.md};
            box-shadow: ${theme.boxShadow.md};
            max-height: calc(100vh - ${theme.spacing[40]} * 2);
            animation: ${appear} 0.3s ease-out;
            animation-fill-mode: both;
          }
        `};
`;

const Footer = styled.footer<{ $fullViewport?: boolean }>`
  padding: ${({ theme }) => theme.spacing[40]};
  text-align: center;
  background-color: ${({ $fullViewport, theme }) =>
    $fullViewport ? '' : theme.color.grey04};
`;

const TitleWrapper = styled.header<{ $hasTitle: boolean }>`
  display: flex;
  justify-content: ${({ $hasTitle }) =>
    $hasTitle ? 'space-between' : 'flex-end'};
  gap: ${({ theme }) => theme.spacing[10]};
  padding: ${({ theme }) => theme.spacing[40]};

  ${({ theme }) => theme.mq('sm')} {
    display: block;
  }

  ${({ theme }) => theme.mq('lg')} {
    padding-left: ${({ theme }) => theme.spacing[60]};
    padding-right: ${({ theme }) => theme.spacing[60]};
  }
`;

const Title = styled.p<{ $titlePosition: OverlayTitlePosition }>`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.text};
  text-align: ${({ $titlePosition }) =>
    $titlePosition === 'auto' ? 'left' : $titlePosition};

  ${({ theme }) => theme.mq('lg')} {
    font-size: 1.5rem;
    text-align: ${({ $titlePosition }) =>
      $titlePosition === 'auto' ? 'center' : ''};
  }
`;

const ScrollableContent = styled.div<{
  $fullViewport: boolean;
  $withoutPadding?: boolean;
}>`
  padding: ${({ theme, $withoutPadding }) =>
    $withoutPadding
      ? ''
      : `${theme.spacing[0]} ${theme.spacing[40]} ${theme.spacing[40]}`};
  ${({ $fullViewport }) =>
    ($fullViewport && 'height: 100%;') || 'max-height: 100%'};
  overflow-y: auto;
  box-sizing: border-box;
  background:
    linear-gradient(
      ${({ theme }) => theme.color.white} 30%,
      rgb(255 255 255 / 0%)
    ),
    linear-gradient(
        rgb(255 255 255 / 0%),
        ${({ theme }) => theme.color.white} 70%
      )
      0 100%,
    radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 20%), transparent),
    radial-gradient(farthest-side at 50% 100%, rgb(0 0 0 / 20%), transparent) 0
      100%;
  background-attachment: local, local, scroll, scroll;
  background-color: ${({ theme }) => theme.color.white};
  background-repeat: no-repeat;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;

  ${({ theme }) => theme.mq('lg')} {
    padding-inline: ${({ theme, $withoutPadding }) =>
      !$withoutPadding && theme.spacing[60]};
    padding-bottom: ${({ theme, $withoutPadding }) =>
      !$withoutPadding && theme.spacing[60]};
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-top: ${({ theme }) => theme.spacing[10]};

  ${({ theme }) => theme.mq('sm')} {
    position: absolute;
    top: ${({ theme }) => theme.spacing[40]};
    right: ${({ theme }) => theme.spacing[40]};
  }
`;

export {
  Backdrop,
  Content,
  Footer,
  ScrollableContent,
  StyledIconButton,
  Title,
  TitleWrapper,
  Wrapper,
};
