export { body, BodyText } from './BodyText';
export { bodyBold, BodyBoldText } from './BodyBoldText';
export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from './Heading';
export { subtitle, Subtitle } from './Subtitle';
export { interactiveText, InteractiveText } from './InteractiveText';
export { secondaryText, SecondaryText } from './SecondaryText';
export { TertiaryText } from './TertiaryText';

export type { StyledTextProps } from './textUtils';
