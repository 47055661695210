import { useState } from 'react';
import { useMedia } from 'react-use';
import { analytics, Runtime, useSiteInfo } from '@silvertours/front-entities';
import { Ui } from '@silvertours/front-shared';
import { Code, Content, P, StyledBadge, Wrapper } from './SoloDaysPromo.styles';

// @TODO: Remove after 2024-11-03
const SoloDaysPromo = () => {
  const {
    settings: {
      featureToggles: { soloDaysOnStageImage },
    },
  } = useSiteInfo();
  const { language } = Runtime.useLocale();
  const [showOverlay, setShowOverlay] = useState(false);
  const hasEnoughSpace = useMedia('(min-width: 1056px)', true);

  if (!soloDaysOnStageImage || !hasEnoughSpace || language !== 'de') {
    return null;
  }

  const handleShowOverlay = () => {
    setShowOverlay(true);
    analytics.gtm.trackPromoClicked('singles_day_2024');
  };

  const handleHideOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <Wrapper>
      <StyledBadge type="orange" text="Nur bis 11.11.2024" />
      <Content type="button" onClick={handleShowOverlay}>
        <span>
          <b>25&nbsp;€* App-Rabatt</b> mit dem Code: <Code>„SOLO“</Code>
        </span>
        <span>Für mehr Liebe am Singles’ Day ❤️</span>
      </Content>
      {showOverlay && (
        <Ui.Overlay open title="Details" onClose={handleHideOverlay}>
          <P>
            <b>
              * Unsere App finden Sie im{' '}
              <Ui.TextLink
                href="https://app.eu.adjust.com/1cc9vu45_1cfsh75f"
                target="_blank"
                rel="noopener noreferrer"
              >
                App Store oder Google Play Store
              </Ui.TextLink>
              .
            </b>
          </P>
          <P>
            <b>So lösen Sie den Gutschein ein:</b>
          </P>
          <P>
            Bei der Buchung in der App im letzten Schritt den Gutscheincode
            eingeben. Erstattung der 25&nbsp;€ auf den Mietwagenpreis nach
            Mietwagen-Rückgabe auf Ihr Girokonto.
          </P>
          <P>
            Der Preisvorteil gilt für Buchungen mit der aktuellen Version der
            billiger-mietwagen.de App mit einem Mindestbuchungswert von
            249&nbsp;€, die bis zum 11.11.2024 bis 23:59 Uhr gebucht und bis zum
            30.06.2025 abgeholt werden.
          </P>
          <P>
            Der Gutschein kann maximal 5.000 mal eingelöst werden. Zahlungen,
            die vor Ort getätigt werden müssen, zählen nicht zum Buchungswert.
            Der Gutschein ist nicht mit anderen Aktionen kombinierbar.
          </P>
          <P>
            Die Einlösung des Rabattes erfolgt im Cashbackverfahren (Girokonto
            in Europa erforderlich). Im Falle einer Stornierung, Modifikation
            der Buchung oder Nichtabholung wird der Rabattbetrag nicht
            erstattet. Eine Auszahlung des Rabattwertes ist nicht möglich.
          </P>
          <P>
            Der Preisvorteil gilt ausschließlich für die von uns adressierte
            Person und darf nicht an Dritte weitergegeben oder verbreitet
            werden. Der vorzeitige Abbruch der Aktion bleibt vorbehalten.
          </P>
          <P>
            Beachten Sie bitte auch unsere{' '}
            <Ui.TextLink
              href="https://www.billiger-mietwagen.de/gutschein.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gutscheinbedingungen
            </Ui.TextLink>{' '}
            sowie unsere
            <Ui.TextLink
              href="https://www.billiger-mietwagen.de/allgemeine-geschaeftsbedingungen.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              AGB
            </Ui.TextLink>
            , die weitere Erläuterungen zur Einlösung des Rabattcodes enthalten.
          </P>
        </Ui.Overlay>
      )}
    </Wrapper>
  );
};

export { SoloDaysPromo };
