import { StyledArrowDown, StyledArrowUp } from './CollapseToggle.styles';

export type CollapseToggleRules = {
  iconSize?: 40 | 60;
};

export type CollapseToggleProps = {
  isCollapsed: boolean;
  rules?: CollapseToggleRules;
};

export const CollapseToggle = ({ isCollapsed, rules }: CollapseToggleProps) => {
  const { iconSize = 60 } = rules || {};

  const ArrowComponent = isCollapsed ? StyledArrowDown : StyledArrowUp;

  return <ArrowComponent iconSize={iconSize} />;
};
