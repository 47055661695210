import { useId, useState } from 'react';

import { SubModule } from '@silvertours/common-landingpages-view';

import { Ui } from '@silvertours/front-shared';

export type TabsBehavior = {
  onTabSelected: (tabName: string) => void;
};

export type TabsRules = {
  textStyle?: Ui.RichTextStyle;
};

export type TabsProps = {
  behavior: TabsBehavior;
  content?: Pick<SubModule<unknown>, 'name' | 'position'>[];
  rules?: TabsRules;
};

export const Tabs = ({
  behavior: { onTabSelected },
  content,
  rules,
}: TabsProps) => {
  // We'll use a random string to avoid any id collisions with other tab segments
  const tabGroup = `tabGroup-${useId()}`;
  const initialName = content && content.length ? content[0].name : '';
  const [activeTabName, setActiveTabName] = useState(initialName);

  if (!content || content.length === 0) {
    return null;
  }
  const { textStyle = Ui.RichTextStyle.Primary } = rules || {};

  const handleTabSelected = (tabName: string) => {
    setActiveTabName(tabName);
    onTabSelected(tabName);
  };

  return (
    <Ui.TabNavigation textStyle={textStyle}>
      {content.map(tab => (
        <Ui.TabButton
          behavior={{ onSelectTab: handleTabSelected }}
          content={{ ...tab, tabGroup }}
          key={tab.position}
          rules={{ activeTabName, textStyle }}
        />
      ))}
    </Ui.TabNavigation>
  );
};
