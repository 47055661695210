import { svgProps } from './defaults';
import type { WaveSet } from './types';

import { WaveSvg } from '../Wave.styles';

const scalable2 = {
  start: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 57">
        <path d="M375 0H0v52.916c48 7.144 101.5 4.166 158.5-3.158s101-9.553 213.285 0c1.084.374 2.155.715 3.215 1.024V0Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M1440 0H0v88.84c148.5 19.626 318.5 9.198 573 .243s400.5-29.853 854.66-5.545c4.16.628 8.27 1.2 12.34 1.718V0Z" />
      </WaveSvg>
    ),
  },
  end: {
    small: (
      <WaveSvg {...svgProps} viewBox="0 0 375 60">
        <path d="M375 60V30.329C177.5 40.062 152.5-7.791 0 1.109V60h375Z" />
      </WaveSvg>
    ),
    large: (
      <WaveSvg {...svgProps} viewBox="0 0 1440 100">
        <path d="M1440 100.692V51.088C791 131.91 529-15.817 0 2.238v98.454h1440Z" />
      </WaveSvg>
    ),
  },
} as WaveSet;

export { scalable2 };
