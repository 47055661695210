import { ReactNode } from 'react';
import { PanelWrapper, PanelContentWrapper } from './Popover.styles';

interface PopoverPanelProps {
  children: ReactNode;
}

const PopoverPanel = ({ children }: PopoverPanelProps) => (
  <PanelWrapper>{children}</PanelWrapper>
);

export { PopoverPanel, PanelContentWrapper };
