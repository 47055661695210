import { GroupedLinkListContentModule } from '@silvertours/common-landingpages-view';

import { SequentialLinkLists } from './SequentialLinkLists';
import { SingleLinkList } from './SingleLinkList';
import { TabbedLinkLists } from './TabbedLinkLists';

export const LinkLists = (props: GroupedLinkListContentModule) => {
  const { badge, heading, lists, rules, translations } = props;
  const { renderSequentially } = rules || {};

  if (renderSequentially) {
    return <SequentialLinkLists lists={lists} translations={translations} />;
  }

  if (lists.length === 1) {
    return (
      <SingleLinkList
        badge={badge}
        heading={heading}
        list={lists[0]}
        translations={translations}
      />
    );
  }

  return <TabbedLinkLists lists={lists} />;
};
