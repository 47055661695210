import { AirportPageAttribute } from './airport';
import { BookingAttributePageAttribute } from './bookingAttribute';
import { CategoryPageAttribute } from './category';
import { CityPageAttribute } from './city';
import { CityDistrictPageAttribute } from './cityDistrict';
import { CountryPageAttribute } from './country';
import { IslandPageAttribute } from './island';
import { PartnerPageAttribute } from './partner';
import { RailStationPageAttribute } from './railStation';
import { StatePageAttribute } from './state';

type PageAttribute =
  | AirportPageAttribute
  | BookingAttributePageAttribute
  | CategoryPageAttribute
  | CityPageAttribute
  | CityDistrictPageAttribute
  | CountryPageAttribute
  | IslandPageAttribute
  | PartnerPageAttribute
  | RailStationPageAttribute
  | StatePageAttribute;

/**
 * @remarks
 * A page could potentially be associated with multiple entities so we want to
 * define a hierarchy in order to determine the canonical ancestor entity for
 * each page.
 */
type OrderedPageAttributes = [
  PartnerPageAttribute | null,
  CityDistrictPageAttribute | null,
  AirportPageAttribute | null,
  RailStationPageAttribute | null,
  CityPageAttribute | null,
  IslandPageAttribute | null,
  StatePageAttribute | null,
  CountryPageAttribute | null,
  BookingAttributePageAttribute | null,
  CategoryPageAttribute | null,
];

type PageAttributes = {
  airport: AirportPageAttribute | null;
  bookingAttributes: Array<BookingAttributePageAttribute>;
  category: CategoryPageAttribute | null;
  city: CityPageAttribute | null;
  cityDistrict: CityDistrictPageAttribute | null;
  country: CountryPageAttribute | null;
  island: IslandPageAttribute | null;
  partners: Array<PartnerPageAttribute>;
  railStation: RailStationPageAttribute | null;
  state: StatePageAttribute | null;
};

class PageAttributesOrganizer {
  constructor(public attributes: PageAttributes) {}

  get available(): Array<PageAttribute> {
    return this.ordered.filter(
      (entity: PageAttribute | null) => !!entity,
    ) as Array<PageAttribute>;
  }

  get ordered(): OrderedPageAttributes {
    const {
      cityDistrict,
      airport,
      railStation,
      city,
      island,
      state,
      country,
      bookingAttributes,
      category,
      partners,
    } = this.attributes;

    return [
      partners ? partners[0] : null,
      cityDistrict,
      airport,
      railStation,
      city,
      island,
      state,
      country,
      bookingAttributes ? bookingAttributes[0] : null,
      category,
    ] as OrderedPageAttributes;
  }

  get primary(): PageAttribute | null {
    return this.available.length > 0 ? this.available[0] : null;
  }

  get secondary(): PageAttribute | null {
    return this.available.length > 1 ? this.available[1] : null;
  }
}

export { PageAttributesOrganizer };
export type { OrderedPageAttributes, PageAttribute, PageAttributes };
