import { Children, useState } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { Heading2, Ui } from '@silvertours/front-shared';
import { OffersColor } from '../OfferBox/OfferBox';
import { Offer, Offers, Section } from './OffersList.styles';

type Props = PropsWithChildren<{
  badge?: string;
  badgeStyle?: Ui.BadgeType;
  disclaimer?: string;
  disclaimerOverlayCTA?: string;
  offerDetails?: ReactElement;
  offerDetailsTitle?: string;
  title?: string;
  titleColor?: Ui.TextColor;
  waveColor?: OffersColor;
}>;

const Background = ({
  children,
  waveColor,
}: PropsWithChildren<{ waveColor?: OffersColor }>) =>
  waveColor ? (
    <Ui.Wave type="fixed5" fullWidth backgroundStyle={waveColor}>
      {children}
    </Ui.Wave>
  ) : (
    <div>{children}</div>
  );

const OffersList = ({
  badge,
  badgeStyle = 'light',
  children,
  disclaimer,
  disclaimerOverlayCTA = '',
  offerDetails,
  offerDetailsTitle,
  title,
  titleColor = 'default',
  waveColor,
}: Props) => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      {offerDetails && (
        <Ui.Overlay
          open={showDialog}
          onClose={() => setShowDialog(false)}
          title={offerDetailsTitle}
          width="wide"
        >
          {offerDetails}
        </Ui.Overlay>
      )}
      <Background waveColor={waveColor}>
        <Section>
          {badge && <Ui.Badge text={badge} type={badgeStyle} />}
          {title && <Heading2 textColor={titleColor}>{title}</Heading2>}
          <Offers hasTitle={!!title}>
            {Children.map(children, child => (
              <Offer>{child}</Offer>
            ))}
          </Offers>
          {disclaimer && (
            <Ui.Caption>
              {disclaimer}{' '}
              <button type="button" onClick={() => setShowDialog(true)}>
                {disclaimerOverlayCTA}
              </button>
            </Ui.Caption>
          )}
        </Section>
      </Background>
    </>
  );
};

export { OffersList };
