import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

type ContainerProps = {
  consequent?: number;
};

type ColumnProps = {
  columnCount: number;
  antecedent?: number;
};

const forColumns =
  (columnCountForStyles: number) =>
  (styles: SerializedStyles) =>
  ({ antecedent, columnCount }: ColumnProps) =>
    !antecedent && columnCount === columnCountForStyles && styles;

const ColumnContainer = styled.div<ContainerProps>`
  max-width: 100%;

  ${({ theme }) => theme.mq('md')} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    column-gap: 1.25rem;
  }
`;

const Column = styled.div<ColumnProps>`
  flex: ${({ antecedent }) => antecedent || 1};
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  &:first-of-type {
    margin-bottom: 1.25rem;

    ${({ theme }) => theme.mq('md')} {
      margin-bottom: 0;
    }
  }

  ${({ theme }) =>
    forColumns(2)(css`
      flex: 0 0 100%;

      ${theme.mq('md')} {
        column-gap: 2rem;
        flex: 0 0 calc(50% - 1rem);
      }
    `)}

  ${({ theme }) =>
    forColumns(3)(css`
      flex: 0 0 100%;

      ${theme.mq('md')} {
        column-gap: 2rem;
        flex: 0 0 calc(50% - 1rem);
      }

      ${theme.mq('lg')} {
        column-gap: 2rem;
        flex: 0 0 calc(33% - 1.75rem);
      }
    `)}
`;

export { Column, ColumnContainer };
