import { css } from '@emotion/react';
import styled from '@emotion/styled';

type IconSize = 'large' | 'small' | 'tiny';

type HasSize = {
  size?: IconSize;
};

const selectSizeValue = (size: IconSize) => {
  switch (size) {
    case 'tiny':
      return 0.5;
    case 'large':
      return 1.25;
    case 'small':
    default:
      return 1;
  }
};

const setSize = ({ size }: HasSize) => {
  if (!size) {
    return null;
  }

  const sizeValue = selectSizeValue(size);

  return css`
    width: ${sizeValue}rem;
    height: ${sizeValue}rem;
  `;
};

const StyledSvg = styled.svg<HasSize>`
  width: 100%;
  height: 100%;

  ${setSize}
`;

export { StyledSvg };
export type { IconSize, HasSize };
