import { Marker } from '@googlemaps/markerclusterer';
import StationIcon from '@engineering/icons/services/rental-car-station';
import { Station } from './types';
import { CustomMarker, StationTypeIcon } from './CustomMarker';

type StationMarkerProps = {
  deselect: () => void;
  selected: boolean;
  select: () => void;
  station: Station;
  setMarkerRef: (marker: Marker | null, key: string) => void;
};

export const StationMarker = ({
  deselect,
  select,
  selected,
  setMarkerRef,
  station,
}: StationMarkerProps) => {
  const handleSelect = () => {
    select();
  };
  const handleDeselect = () => {
    deselect();
  };

  return (
    <CustomMarker
      deselect={handleDeselect}
      id={station.id}
      isSelected={selected}
      select={handleSelect}
      setMarkerRef={setMarkerRef}
      station={station}
      position={{ lat: station.latitude, lng: station.longitude }}
    >
      <StationTypeIcon isSelected={selected}>
        <StationIcon />
      </StationTypeIcon>
    </CustomMarker>
  );
};
