type Position = {
  lat: number;
  lng: number;
};

type Markers = { position: Position }[];

export const CITY_STATION_NAME = 'cityOffice';
export const RAIL_STATION_NAME = 'rail';
export const AIRPORT_STATION_NAME = 'airport';
export const AIRPORT_TERMINAL_STATION_NAME = 'airportTerminal';
export const AIRPORT_SHUTTLE_STATION_NAME = 'airportShuttle';
export const HOTEL_STATION_NAME = 'hotel';
export const DELIVERY_STATION_NAME = 'delivery';
export const STATION_KINDS = [
  CITY_STATION_NAME,
  RAIL_STATION_NAME,
  AIRPORT_STATION_NAME,
  AIRPORT_TERMINAL_STATION_NAME,
  AIRPORT_SHUTTLE_STATION_NAME,
  HOTEL_STATION_NAME,
];

const stationKinds = [...STATION_KINDS] as const;
export type StationKind = (typeof stationKinds)[number];

export type Station = {
  id: string;
  groupId?: number;
  name: string;
  stationKind: StationKind;
  latitude: number;
  longitude: number;
  selected?: boolean;
  price?: number;
  currency?: string;
  address?: string;
  countryCode?: string;
  pickupTransfer?: string;
  supplier?: string;
};

export type { Position, Markers };
