import { Theme } from '@emotion/react';
import { complement, darken, saturate } from 'polished';

type TextProps = {
  textColor?: TextColor;
  inline?: boolean;
  className?: string;
};

type StyledTextProps = {
  textColor?: TextColor;
};

type TextColor =
  | 'green'
  | 'grey'
  | 'white'
  | 'orange'
  | 'error'
  | 'current'
  | 'default';

type TextSize = 'normal' | 'small' | 'xs';

const mapColor = (theme: Theme, color: TextColor = 'default') =>
  ({
    green: theme.color.brand,
    grey: theme.color.grey01,
    default: theme.color.text,
    white: theme.color.white,
    orange: theme.color.secondary,
    error: darken(
      0.3,
      saturate(0.51, complement(theme.color.primaryHighlight!)),
    ),
    current: 'currentcolor',
  })[color];

export { mapColor };
export type { TextColor, TextSize, TextProps, StyledTextProps };
