import { BmOkContent } from '../content';

type StageContent = {
  keyword: string;
};

export class Stage implements StageContent {
  constructor(private content: BmOkContent) {}

  get keyword() {
    return this.content.mainKeyword;
  }

  get image() {
    return this.content.stage?.image;
  }

  get headline() {
    return this.content.stage?.headline || '';
  }

  get includeSearch() {
    return this.content.stage?.includeSearch || false;
  }

  get pageTitle() {
    return this.content.stage?.pageTitle || '';
  }

  get imageWithFocalPoint() {
    return this.content.stage?.imageWithFocalPoint;
  }
}
