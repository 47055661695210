import { PageContent } from '@silvertours/common-landingpages-view';
import { useTranslations } from 'next-intl';
import { createContext, useContext } from 'react';

const ContentModuleContext = createContext<PageContent>({});

const useContentModule = () => {
  const page = useContext(ContentModuleContext);
  const t = useTranslations();

  if (!('content' in page) || !page?.content) {
    throw new Error(
      'this component must be used within a ContentModuleContext.Provider',
    );
  }

  const getPriceGraph = () =>
    'priceGraph' in page.content
      ? page?.content?.priceGraph?.content
      : { priceInfos: [] };

  const getStationsMap = () => {
    const content =
      'stationsMap' in page.content
        ? page?.content?.stationsMap?.content
        : { stations: [], boundingBox: null };

    return {
      ...content,
      translations: {
        supplier: t('features.stationsMap.supplier'),
        cookieConsentMessage: t('features.stationsMap.cookieConsentMessage'),
        cookieConsentLink: t('features.stationsMap.cookieConsentLink'),
      },
    };
  };

  const getSupplierReviews = () =>
    'supplierReviews' in page.content
      ? page?.content?.supplierReviews?.content
      : { supplierReviews: [] };

  const getTrustpilotData = () =>
    'trustElement' in page.content
      ? page.content.trustElement.content.trustpilot
      : null;

  return {
    getPriceGraph,
    getStationsMap,
    getSupplierReviews,
    getTrustpilotData,
  };
};

export { ContentModuleContext, useContentModule };
