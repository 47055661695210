import { LandingPage } from '../page';
import { BodyContent } from './content';
import { ContentSegment } from './segments';

const LOCATION_PLACEHOLDER = '%LOCATION%';
const PARTNER_PLACEHOLDER = '%PARTNER%';

class ContentInterpolator {
  private page: LandingPage;

  constructor(page: LandingPage) {
    this.page = page;
    this.interpolate = this.interpolate.bind(this);
    this.interpolateLocation = this.interpolateLocation.bind(this);
  }

  private interpolate<T extends Record<string, unknown>>(
    record: T,
    placeholder: string,
    value: string,
  ): T {
    for (const key in record) {
      if (typeof record[key] === 'object' && record[key] !== null) {
        this.interpolate(
          record[key] as Record<string, unknown>,
          placeholder,
          value,
        );
      } else if (typeof record[key] === 'string') {
        record[key] = (record[key] as string).replace(
          new RegExp(placeholder, 'g'),
          value,
        ) as T[Extract<keyof T, string>];
      }
    }
    return record;
  }
  private interpolateLocation(segment: ContentSegment) {
    return this.interpolate(
      segment,
      LOCATION_PLACEHOLDER,
      this.page.locationName,
    );
  }
  private interpolatePartner(segment: ContentSegment) {
    return this.interpolate(
      segment,
      PARTNER_PLACEHOLDER,
      this.page.partners[0]?.displayName || '',
    );
  }

  interpolateBody(body: BodyContent) {
    return {
      ...body,
      segments: body.segments.map(segment => {
        let updatedSegment = this.interpolateLocation(segment);
        updatedSegment = this.interpolatePartner(updatedSegment);
        return updatedSegment;
      }),
    };
  }
}

export { ContentInterpolator };
