import { useState, useEffect } from 'react';
import { format } from 'date-fns/format';
import { de as LocaleDe } from 'date-fns/locale/de';
import { fr as LocaleFr } from 'date-fns/locale/fr';
import { es as LocaleEs } from 'date-fns/locale/es';
import { it as LocaleIt } from 'date-fns/locale/it';
import { nl as LocaleNl } from 'date-fns/locale/nl';
import { enUS as LocaleEn } from 'date-fns/locale/en-US';
import { tr as LocaleTr } from 'date-fns/locale/tr';
import { String } from '@silvertours/front-shared';
import { useLocale } from '../../Runtime';

const useFormattedDate = (date?: Date) => {
  const { language } = useLocale();

  const dateLocale = {
    de: LocaleDe,
    fr: LocaleFr,
    es: LocaleEs,
    it: LocaleIt,
    nl: LocaleNl,
    en: LocaleEn,
    tu: LocaleTr,
  }[language as keyof typeof String.dateFormats];

  const localDateFormats =
    String.dateFormats[language as keyof typeof String.dateFormats];

  const [formattedDate, setFormattedDate] = useState({
    localDate: '',
    isoDate: '',
  });

  useEffect(() => {
    if (date) {
      const localDate = format(date, localDateFormats.dayDateLongYear, {
        locale: dateLocale,
      });
      const isoDate = format(date, localDateFormats.isoDate, {
        locale: dateLocale,
      });
      setFormattedDate({
        localDate,
        isoDate,
      });
    }
  }, [
    date,
    dateLocale,
    localDateFormats.dayDateLongYear,
    localDateFormats.isoDate,
  ]);

  return formattedDate;
};

export { useFormattedDate };
