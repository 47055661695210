import { StaticLink } from '@silvertours/common-landingpages-view';
import { Links } from '@silvertours/front-shared';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { analytics } from '@silvertours/front-entities';
import {
  Anchor,
  LinkList,
  LinkListItem,
  MaskedLink,
  Nav,
  StyledText,
  StyledBadge,
} from './Navigation.styles';

interface NavigationProps {
  getHiddenElements?: (elements: ReactElement[]) => void;
  links: StaticLink[];
  mode: 'mobile' | 'desktop';
  onAction?: () => void;
}

const makeLinkMapper =
  (container: 'popover' | 'navbar', onClick?: () => void) =>
  ({
    href,
    target,
    text,
    title,
    masked,
    highlightText,
  }: Links.NavigationLink) => {
    const linkProps = {
      href,
      target,
      title,
    };

    const handleClick = () => {
      analytics.gtm.trackNavigationClicked(text);
      if (typeof onClick === 'function') {
        onClick();
      }
    };

    return (
      <LinkListItem container={container} key={href} onClick={handleClick}>
        <StyledText withPadding={container === 'navbar'}>
          {masked ? (
            <MaskedLink {...linkProps} body={text} />
          ) : (
            <Anchor
              {...linkProps}
              rel={
                linkProps.target === '_blank'
                  ? 'noopener noreferrer'
                  : undefined
              }
            >
              {text}
            </Anchor>
          )}
        </StyledText>
        {highlightText && (
          <StyledBadge
            text={highlightText}
            type="orange"
            size="small"
            container={container}
          />
        )}
      </LinkListItem>
    );
  };

const Navigation = ({
  getHiddenElements,
  links,
  mode,
  onAction,
}: NavigationProps) => {
  const getLinks = useMemo(() => Links.makeLinkTypeMapper(links), [links]);
  const headerLinks = useMemo(() => getLinks('main'), [getLinks]);

  const linksRef = useRef<HTMLUListElement>(null);
  const [visibleLinks, setVisibleLinks] = useState(headerLinks.length);
  const [linkWidths, setLinkWidths] = useState<number[]>([]);

  useEffect(() => {
    // Width of links component on first render

    const container = linksRef.current;
    if (!container) return undefined;
    // Create a ResizeObserver instance
    const resizeObserver = new ResizeObserver(() => {
      const linkElements = Array.from(container.children) as HTMLDivElement[];

      setLinkWidths(
        linkElements.map(element => element.getBoundingClientRect().width),
      );
    });

    // Attach the ResizeObserver to the container element
    resizeObserver.observe(container);

    // Cleanup function to disconnect the ResizeObserver when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!getHiddenElements || !linkWidths.length) {
      return;
    }
    const container = linksRef.current;
    if (!container) return;

    let totalWidth = 0;
    let visible = 0;

    [...linkWidths].reverse().forEach(width => {
      totalWidth += width;

      if (totalWidth <= container.clientWidth) {
        visible += 1;
      }
    });

    setVisibleLinks(visible);
    const mapLinks = makeLinkMapper('popover', onAction);
    getHiddenElements(
      headerLinks.slice(0, headerLinks.length - visible).map(mapLinks),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkWidths, headerLinks]);

  const mapLinks = makeLinkMapper('navbar', onAction);

  return (
    <Nav $mode={mode} aria-label="Main navigation">
      <LinkList ref={linksRef} hideChildren={headerLinks.length - visibleLinks}>
        {headerLinks.map(mapLinks)}
      </LinkList>
    </Nav>
  );
};

export { Navigation };
